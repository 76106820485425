import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {
  pagination,
  showCouldNotLoadRecordsMessage,
  showLoadingRecordsMessage,
  showNoRecordsMessage,
} from "config/config";
import { Card, FormGroup } from "reactstrap";
import Container from "reactstrap/lib/Container";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ValidationForm from "../../../../validation/form";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import Button from "reactstrap/lib/Button";
import ReactToPrint from "react-to-print";
import TableActionButtons from "../TableActionButtons";
import Modal from "reactstrap/lib/Modal";

// react plugin used to create datetimepicker
import DropdownItem from "reactstrap/lib/DropdownItem";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import UncontrolledDropdown from "reactstrap/lib/UncontrolledDropdown";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import userService from "services/userService";
import utils from "util/CustomUtil";
import { toast } from "react-toastify";
import AccountManagement from "./AccountManagement";

const dateFormat = "YYYY-MM-DD";

class Users extends ValidationForm {
  state = {
    formSubmitted: true,
    isLoading: false,
    history: [],
    historyModal: false,
    trackingModal: false,
    currentHistory: null,
    trackingDetail: null,
    searchTriggered: false,
    fetchingDetail: false,
    fetchingTrackingInfo: false,
    completeBookingShipmentId: 0,
    currentTrackedShipment: false,
    selectedUser: null,
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  async componentDidMount() {
    try {
      this.setState({ formSubmitted: true, searchTriggered: true });
      const history = await userService.fetchUserList();
      this.setState({ formSubmitted: false, history });
    } catch (ex) {
      this.setState({ formSubmitted: false });
    }
  }

  render() {
    let recordViewComponent;
    if (this.state.formSubmitted) {
      recordViewComponent = showLoadingRecordsMessage(
        "Loading User List... Please wait!"
      );
    } else if (this.state.couldNotLoadTableRecords) {
      recordViewComponent = showCouldNotLoadRecordsMessage(
        "Could not load User List. Please try again."
      );
    } else {
      recordViewComponent =
        this.state.history.length > 0
          ? this.loadTable()
          : showNoRecordsMessage(
              this.state.searchTriggered
                ? "No records found!"
                : "Your search results will show up here!"
            );
    }

    return (
      <>
        {this.state.selectedUser && (
          <AccountManagement
            controller={{
              selectedUser: this.state.selectedUser,
              onReturnToUserList: this.handleReturnToUserList,
            }}
          />
        )}

        {!this.state.selectedUser && (
          <>
            {this.historyModal()}
            <SimpleHeader name="Users" parentName="Tables" />
            <Container className="mt--6" fluid>
              <Row>
                <div className="col mt-4">{recordViewComponent}</div>
              </Row>
            </Container>
          </>
        )}
      </>
    );
  }

  handleReturnToUserList = () => {
    this.setState({ selectedUser: null });
  };

  handleSubmit() {}

  loadTable() {
    return (
      <Card>
        <ToolkitProvider
          data={this.state.history}
          keyField="index"
          columns={[
            {
              dataField: "index",
              text: "#",
              sort: true,
            },
            {
              dataField: "dateCreated",
              text: "Date",
              sort: true,
            },
            {
              dataField: "firstname",
              text: "Fullname",
              sort: true,
            },
            {
              dataField: "username",
              text: "Username",
              sort: true,
            },
            {
              dataField: "phoneNumber",
              text: "Phone Number",
              sort: true,
              formatter: (cell, row) => {
                return (
                  <>
                    {row.phoneNumber && row.phoneNumber !== ""
                      ? row.phoneNumber
                      : "--"}
                  </>
                );
              },
            },
            {
              dataField: "status",
              text: "Status",
              sort: true,
              formatter: (cell, row) => {
                return (
                  <>
                    <span className="text-default">{row.status}</span>
                    <br />
                  </>
                );
              },
            },
            {
              dataField: "menu",
              text: "",
              sort: false,
              formatter: (cell, row) =>
                this.loadTableContextMenu(this.toggleModal, row),
              classes: (cell, row, rowIndex, colIndex) => "px-0",
            },
          ]}
          search
        >
          {(props) => (
            <div className="py-4 table-responsive">
              <TableActionButtons {...props}>
                <ReactToPrint
                  trigger={() => (
                    <Button
                      color="primary"
                      size="sm"
                      className="buttons-copy buttons-html5"
                      id="print-tooltip"
                    >
                      Print
                    </Button>
                  )}
                  content={() => this.componentRef}
                />
              </TableActionButtons>
              <BootstrapTable
                size="sm"
                condensed
                hover
                ref={(el) => (this.componentRef = el)}
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                id="react-bs-table"
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    );
  }

  historyModal = () => {
    const currentHistory = this.state.currentHistory;
    return (
      <Modal
        className="modal-dialog-centered"
        contentClassName="bg-secondary"
        isOpen={this.state.historyModal}
        toggle={() => this.toggleModal("historyModal")}
        backdrop="static"
        size="lg"
      >
        <div className="modal-header bg-primary">
          <h6
            className="modal-title text-secondary"
            id="modal-title-notification"
          >
            Past Shipment Detail
          </h6>
          <button
            aria-label="Close"
            className="close text-secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("historyModal")}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          {this.state.fetchingDetail
            ? showLoadingRecordsMessage(
                "Loading shipment detail... Please wait."
              )
            : currentHistory && <></>}
        </div>
        <div className="modal-footer">
          <Button
            className="ml-auto"
            color="dark"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("historyModal")}
          >
            Close
          </Button>
        </div>
      </Modal>
    );
  };

  loadItemConfirmationTable(items) {
    return (
      <Card className="mb-0">
        <ToolkitProvider
          data={items}
          keyField="index"
          columns={[
            {
              dataField: "index",
              text: "#",
              sort: true,
              classes: "d-none d-md-block",
              headerClasses: "d-none d-md-block",
            },
            {
              dataField: "description",
              text: "Desc",
              sort: true,
            },
            {
              dataField: "quantity",
              text: "Qty",
              sort: true,
            },
            {
              dataField: "value",
              text: <div className="text-right">Unit Price (&#8358;) </div>,
              sort: true,
              formatter: (cell, row) => {
                return (
                  <div className="text-right">
                    {utils.numberFormatter(Number(row.value), 2)}
                  </div>
                );
              },
            },
          ]}
          search
        >
          {(props) => (
            <div className="py-4 table-responsive">
              <BootstrapTable
                size="sm"
                ref={(el) => (this.componentRef = el)}
                {...props.baseProps}
                bootstrap4={true}
                // pagination={pagination}
                bordered={false}
                id="react-bs-table"
                striped
                hover
                condensed
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    );
  }

  loadTableContextMenu(toggleModal, row) {
    return (
      <>
        <UncontrolledDropdown>
          <DropdownToggle
            className="btn-icon-only text-primary h-25 mx-0"
            color=""
            role="button"
            size="sm"
          >
            <i className="fas fa-ellipsis-v" />
          </DropdownToggle>
          <DropdownMenu
            className="dropdown-menu-arrow pt-0 overflow-hidden "
            right
          >
            <div className="px-3 py-2 mb-1 bg-gradient-primary">
              <h6 className="text-sm text-white m-0">
                <span className="font-weight-bolder"> {row.firstname}</span>
                <br />
                <span className="font-weight-normal">{row.username}</span>
              </h6>
            </div>
            <DropdownItem
              onClick={() => {
                this.setState({ selectedUser: row, fetchingDetail: true });
                // this.toggleModal("historyModal");
              }}
              className="text-default"
            >
              <i className="fas fa-wrench text-primary" />
              Account Management
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </>
    );
  }

  async loadShipmentDetail(id) {
    this.setState({ fetchingDetail: true });
    const detail = await userService.fetchUserProfile(id);
    this.setState({ fetchingDetail: false, currentHistory: detail });
  }
}

export default Users;
