import httpService from "services/httpService";

const baseApiEndPoint = process.env.REACT_APP_API_ENDPOINT;

const apiEndPoint = baseApiEndPoint + "/shipment";

export async function getRate(model) {
  const { data } = await httpService.post(apiEndPoint + "/check_rate", {
    ...model,
  });
  return data;
}

export async function bookShipment(model) {
  const { data } = await httpService.post(apiEndPoint, {
    ...model,
  });
  return data;
}

export async function payWithWallet(trackingNumber) {
  const { data } = await httpService.post(
    apiEndPoint + "/" + trackingNumber + "/make_payment/Wallet"
  );
  return data;
}

export async function payWithCashBack(trackingNumber) {
  const { data } = await httpService.post(
    apiEndPoint + "/" + trackingNumber + "/make_payment/CashBack"
  );
  return data;
}

export async function trackShipment(trackingNumber) {
  const { data } = await httpService.get(
    apiEndPoint + "/" + trackingNumber + "/track"
  );
  return data;
}

export async function fetchShipmentHistory(startDate, endDate) {
  const { data } = await httpService.get(
    apiEndPoint + "/history?startDate=" + startDate + "&endDate=" + endDate
  );
  return data;
}

export async function fetchShipmentHistoryRecentRecord() {
  const { data } = await httpService.get(apiEndPoint + "/history/recent");
  return data;
}

export async function fetchShipmentDetail(id) {
  const { data } = await httpService.get(apiEndPoint + "/" + id + "/detail");
  return data;
}

export async function fetchAddressList() {
  const { data } = await httpService.get(apiEndPoint + "/addresses");
  return data;
}

export async function fetchShippingBoxes() {
  const { data } = await httpService.get(apiEndPoint + "/shippingBoxes");
  return data;
}

export async function brandedTrackShipment(trackingNumber, brand) {
  const { data } = await httpService.get(
    apiEndPoint + "/" + trackingNumber + "/brand/" + brand + "/track"
  );
  return data;
}

export async function enableBrandedNotification(enable) {
  const { data } = await httpService.put(
    apiEndPoint + "/branded_notification/enable/" + enable
  );
  return data;
}

export async function fetchBrandedNotificationStatus() {
  const { data } = await httpService.get(
    apiEndPoint + "/branded_notification/status"
  );
  return data;
}

export async function applyDiscount(discountCode, shipmentId) {
  const { data } = await httpService.post(apiEndPoint + "/applyDiscount", {
    discountCode,
    shipmentId,
  });
  return data;
}

export async function paymentInitiated(shipmentId) {
  const { data } = await httpService.put(
    apiEndPoint + "/" + shipmentId + "/paymentInitiated",
    {}
  );
  return data;
}

export async function cancelBooking(shipmentId) {
  const { data } = await httpService.delete(
    apiEndPoint + "/" + shipmentId + "/cancelBooking",
    {}
  );
  return data;
}

export async function loadIncompleteBookings() {
  const { data } = await httpService.get(apiEndPoint + "/incomplete");
  return data;
}

export async function loadIncompleteBookingDetail(shipmentId) {
  const { data } = await httpService.get(
    apiEndPoint + "/" + shipmentId + "/incomplete/detail"
  );
  return data;
}

export async function fetchImportShipmentHistory(startDate, endDate) {
  const { data } = await httpService.get(
    apiEndPoint +
      "/import/history?startDate=" +
      startDate +
      "&endDate=" +
      endDate
  );
  return data;
}

export async function fetchImportShipmentHistoryRecentRecord() {
  const { data } = await httpService.get(
    apiEndPoint + "/import/history/recent"
  );
  return data;
}

export async function updateImportTrackingHistory(model) {
  const { data } = await httpService.post(apiEndPoint + "/import/tracking", {
    ...model,
  });
  return data;
}

export async function voidShipment(shipmentId) {
  const { data } = await httpService.delete(
    apiEndPoint + "/void/" + shipmentId
  );
  return data;
}

export async function fetchItemCategories() {
  const { data } = await httpService.get(apiEndPoint + "/item_category/all");
  return data;
}

export async function getSignatureServiceFee() {
  const { data } = await httpService.get(apiEndPoint + "/signatureServiceFee");
  return data;
}

export async function clearanceDocumentInitiated(clearanceDocumentIds) {
  const { data } = await httpService.put(
    apiEndPoint + "/clearance_document/initiated",
    {
      ...clearanceDocumentIds,
    }
  );
  return data;
}

export async function submitClearanceDocument(
  shipmentId,
  shippingDocumenType,
  formData
) {
  const { data } = await httpService.post(
    apiEndPoint +
      "/clearance_document/upload/" +
      shipmentId +
      "/" +
      shippingDocumenType,
    formData
  );
  return data;
}

export async function fetchShippingDocumentTypeList() {
  const { data } = await httpService.get(
    apiEndPoint + "/shipping_document_type/all"
  );
  return data;
}

export default {
  getRate,
  bookShipment,
  payWithWallet,
  payWithCashBack,
  trackShipment,
  fetchShipmentHistory,
  fetchShipmentDetail,
  fetchAddressList,
  fetchShippingBoxes,
  brandedTrackShipment,
  enableBrandedNotification,
  fetchBrandedNotificationStatus,
  fetchShipmentHistoryRecentRecord,
  applyDiscount,
  paymentInitiated,
  cancelBooking,
  loadIncompleteBookings,
  loadIncompleteBookingDetail,
  fetchImportShipmentHistory,
  fetchImportShipmentHistoryRecentRecord,
  updateImportTrackingHistory,
  voidShipment,
  fetchItemCategories,
  getSignatureServiceFee,
  clearanceDocumentInitiated,
  submitClearanceDocument,
  fetchShippingDocumentTypeList,
};
