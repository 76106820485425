import React from "react";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
// reactstrap components
import { Modal, Button, Card, Container, Row } from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import UncontrolledDropdown from "reactstrap/lib/UncontrolledDropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import Joi from "joi-browser";
import ValidationForm from "../../../../validation/form";
import { toast } from "react-toastify";
import organizationService from "services/organizationService";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
import { pagination } from "config/config";
import {
  showNoRecordsMessage,
  showLoadingRecordsMessage,
  showCouldNotLoadRecordsMessage,
} from "config/config";
import TableActionButtons from "../TableActionButtons";
import Col from "reactstrap/lib/Col";

class Organizations extends ValidationForm {
  state = {
    alert: null,
    newOrganizationModal: false,
    organizationDetailModal: false,
    organizations: {},
    currentOrganization: {},
    data: {},
    errors: {},
    formSubmitted: false,
    forceTableRefreshId: new Date().getMilliseconds(),
    loadingTableRecords: true,
    couldNotLoadTableRecords: false,
    billingGroupList: [],
    billingCycleList: [
      { label: "Non", value: "Non" },
      { label: "Weekly", value: "Weekly" },
      { label: "Biweekly", value: "Biweekly" },
      { label: "Monthly", value: "Monthly" },
    ],
  };
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  schema = {
    name: Joi.string().required().label("Name"),
    contactEmail: Joi.string()
      .email({ tlds: { allow: true } })
      .required()
      .label("Contact Email"),
    address: Joi.string().required().label("Address"),
    active: Joi.boolean().label("Make Active"),
    contactPhoneNumber: Joi.string()
      .required()
      .label("Contact Phone Number")
      .regex(/^\+(?:[0-9]●?){6,14}[0-9]$|^[0]\d{10}$/g)
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = '"Contact Phone Number" should not be empty';
              break;
            default:
              err.message = '"Contact Phone Number" is invalid';
              break;
          }
        });
        return errors;
      }),
    billingGroup: Joi.object({
      value: Joi.number().required().label("Value"),
      label: Joi.string().required().label("Label"),
    }).label("Billing Group"),
    billingCycle: Joi.object({
      value: Joi.string().required().label("Value"),
      label: Joi.string().required().label("Label"),
    }).label("Billing Cycle"),
    creditLimit: Joi.number()
      .min(0)
      .max(1000000)
      .required()
      .label("Credit Limit"),
  };

  async componentDidMount() {
    try {
      const responses = await Promise.all([
        organizationService.fetchAll(),
        organizationService.fetchBillingGroups(),
      ]);
      const organizations = responses[0];
      const billingGroupList = responses[1];

      this.setState({
        organizations,
        billingGroupList,
        couldNotLoadTableRecords: false,
        loadingTableRecords: false,
      });
    } catch (ex) {
      this.setState({
        couldNotLoadTableRecords: true,
        loadingTableRecords: false,
      });
    }
  }

  doSubmit = async () => {
    this.setState({ formSubmitted: true });
    let { currentOrganization, data, organizations } = this.state;
    try {
      if (currentOrganization.id) {
        currentOrganization = { id: currentOrganization.id, ...data };
        currentOrganization.billingGroup = data.billingGroup.value;
        currentOrganization.billingCycle = data.billingCycle.value;
        currentOrganization.creditLimit = data.creditLimit;

        const response = await organizationService.update(currentOrganization);
        toast.success(response.message);
        this.updateTableStateRecords(currentOrganization.id, response.data);
        this.setState({
          formSubmitted: false,
          currentOrganization: response.data,
        });
      } else {
        currentOrganization = { ...data };
        currentOrganization.billingGroup = data.billingGroup.value;
        currentOrganization.billingCycle = data.billingCycle.value;
        currentOrganization.creditLimit = data.creditLimit;
        const response = await organizationService.create(currentOrganization);
        toast.success(response.message);
        response.data.index = organizations.length + 1;
        organizations = [...organizations, response.data];
        this.setState({
          formSubmitted: false,
          currentOrganization: response.data,
          organizations,
        });
      }
    } catch (ex) {
      this.setState({ formSubmitted: false });
    }
  };

  render() {
    let recordViewComponent;
    if (this.state.loadingTableRecords) {
      recordViewComponent = showLoadingRecordsMessage();
    } else if (this.state.couldNotLoadTableRecords) {
      recordViewComponent = showCouldNotLoadRecordsMessage();
    } else {
      recordViewComponent =
        this.state.organizations.length > 0
          ? this.loadTable()
          : showNoRecordsMessage(
              "No organization present. You need to create one."
            );
    }

    return (
      <>
        {this.newOrganizationModal()}
        {this.organizationDetailModal()}
        <SimpleHeader
          name="Organizations"
          parentName="Tables"
          description="An organization is a business on the platform."
          toggleModal={() => {
            this.resetStateOrganization();
            this.toggleModal("newOrganizationModal");
          }}
          newItemButtonLabel="New Organization"
        />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">{recordViewComponent}</div>
          </Row>
        </Container>
      </>
    );
  }

  resetStateOrganization() {
    const { data } = this.state;
    data.name = "";
    data.active = false;
    data.contactPhoneNumber = "";
    data.contactEmail = "";
    data.address = "";
    data.billingGroup = null;
    data.billingCycle = null;
    data.creditLimit = 0;
    this.setState({ data, currentOrganization: {}, formSubmitted: false });
  }

  copyOrganizationToState(source) {
    const { data } = this.state;
    data.name = source.name;
    data.active = source.active;
    data.contactPhoneNumber = source.contactPhoneNumber;
    data.contactEmail = source.contactEmail;
    data.address = source.address;
    data.billingGroup = {
      label: source.billingGroupName,
      value: source.billingGroup,
    };
    data.billingCycle = {
      label: source.billingCycle,
      value: source.billingCycle,
    };
    data.creditLimit = source.creditLimit;

    this.setState({ data, formSubmitted: false });
  }

  updateTableStateRecords(currentOrganizationId, updateData) {
    let { organizations } = this.state;
    organizations = organizations.map((org) => {
      if (org.id === currentOrganizationId) {
        updateData.index = org.index;
        Object.assign(org, updateData);
      }
      return org;
    });

    this.setState({ organizations });
  }

  loadTable() {
    return (
      <Card>
        <ToolkitProvider
          data={this.state.organizations}
          keyField="index"
          columns={[
            {
              dataField: "index",
              text: "#",
              sort: true,
            },
            {
              dataField: "dateCreated",
              text: "Date Created",
              sort: true,
            },
            {
              dataField: "name",
              text: "Name",
              sort: true,
            },
            {
              dataField: "contactPhoneNumber",
              text: "Phone Number",
              sort: true,
            },
            {
              dataField: "contactEmail",
              text: "Email",
              sort: true,
            },
            {
              dataField: "billingGroupName",
              text: "Billing Group",
              sort: true,
            },
            {
              dataField: "status",
              text: "Status",
              sort: true,
              formatter: (cell, row) => {
                return <span className="text-default">{row.status}</span>;
              },
            },
            {
              dataField: "menu",
              text: "",
              sort: false,
              formatter: (cell, row) =>
                this.loadTableContextMenu(this.toggleModal, row),
            },
          ]}
          search
          exportCSV
        >
          {(props) => (
            <div className="py-4 table-responsive">
              <TableActionButtons {...props}>
                <ReactToPrint
                  trigger={() => (
                    <Button
                      color="primary"
                      size="sm"
                      className="buttons-copy buttons-html5"
                      id="print-tooltip"
                    >
                      Print
                    </Button>
                  )}
                  content={() => this.componentRef}
                />
              </TableActionButtons>
              <BootstrapTable
                size="sm"
                ref={(el) => (this.componentRef = el)}
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                id="react-bs-table"
                striped
                hover
                condensed
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    );
  }

  loadTableContextMenu(toggleModal, row) {
    return (
      <UncontrolledDropdown>
        <DropdownToggle
          className="btn-icon-only text-light h-25"
          color=""
          role="button"
          size="sm"
        >
          <i className="fas fa-ellipsis-v" />
        </DropdownToggle>
        <DropdownMenu
          className="dropdown-menu-arrow pt-0 overflow-hidden "
          right
        >
          <div className="px-3 py-2 mb-1 bg-primary">
            <h6 className="text-sm text-white m-0">{row.name}</h6>
          </div>
          <DropdownItem
            onClick={() => {
              this.copyOrganizationToState(row);
              this.setState({ currentOrganization: row });
              this.toggleModal("organizationDetailModal");
            }}
            className="text-default"
          >
            <i className="fas fa-eye" /> View Details
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              this.copyOrganizationToState(row);
              this.setState({ currentOrganization: row });
              this.toggleModal("newOrganizationModal");
            }}
            className="text-default"
          >
            <i className="fas fa-pencil-alt" /> Edit Details
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }

  newOrganizationModal = () => {
    const currentOrganization = this.state.currentOrganization;

    return (
      <Modal
        className="modal-dialog-centered"
        contentClassName="bg-secondary"
        isOpen={this.state.newOrganizationModal}
        toggle={() => this.toggleModal("newOrganizationModal")}
        backdrop="static"
        size="lg"
      >
        <div className="modal-header bg-primary">
          <h6
            className="modal-title text-secondary"
            id="modal-title-notification"
          >
            New Organization
          </h6>
          <button
            aria-label="Close"
            className="close text-secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("newOrganizationModal")}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <form
          onSubmit={(e) => {
            this.handleSubmit({
              e,
            });
          }}
        >
          <div className="modal-body">
            <Row>
              <Col>
                <Row>
                  <Col>
                    {this.renderStyledInput({
                      label: "Name",
                      mandatory: true,
                      type: "text",
                      name: "name",
                      placeholder: "Organization Name",
                      icon: "ni ni-building",
                    })}
                    {this.renderStyledInput({
                      label: "Contact Phone Number",
                      mandatory: true,
                      type: "text",
                      name: "contactPhoneNumber",
                      placeholder: "Contact Phone Number",
                      icon: "fas fa-phone",
                    })}
                    {this.renderStyledInput({
                      label: "Contact Email",
                      mandatory: true,
                      type: "text",
                      name: "contactEmail",
                      placeholder: "Contact Email",
                      icon: "ni ni-email-83",
                    })}
                    {this.renderStyledInput({
                      label: "Address",
                      mandatory: true,
                      type: "text",
                      name: "address",
                      placeholder: "Business Address",
                      icon: "ni ni-pin-3",
                    })}
                    {this.renderCheckbox({
                      mandatory: false,
                      name: "active",
                      label: "Make Active",
                      checked: this.state.data.active,
                      disabled: false,
                      iconClass: "text-muted ni ni-user-run",
                    })}
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>
                    {this.renderSelect2({
                      label: "Billing Group",
                      mandatory: true,
                      name: "billingGroup",
                      placeholder: "Select Billing Group",
                      icon: "ni ni-world-2",
                      options: this.state.billingGroupList,
                      classes: "mb-2",
                    })}
                    {this.renderSelect2({
                      label: "Billing Cycle",
                      mandatory: true,
                      name: "billingCycle",
                      placeholder: "Select Billing Cycle",
                      icon: "fas fa-file-invoice-dollar",
                      options: this.state.billingCycleList,
                      classes: "mb-2",
                    })}
                    {this.renderStyledInput({
                      label: "Credit Limit",
                      mandatory: true,
                      type: "text",
                      name: "creditLimit",
                      placeholder: "Credit Limit",
                      icon: "fas fa-money-bill-wave",
                    })}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="modal-footer bg-white">
            {this.renderButton(
              `${currentOrganization.id ? "Update" : "Create"} Organization`,
              "btn-primary",
              `${currentOrganization.id ? "Updating..." : "Creating..."}`
            )}
            <Button
              className="ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("newOrganizationModal")}
            >
              Close
            </Button>
          </div>
        </form>
      </Modal>
    );
  };

  organizationDetailModal = () => {
    const currentOrganization = this.state.currentOrganization;
    return (
      currentOrganization && (
        <Modal
          className="modal-dialog-centered"
          contentClassName="bg-secondary"
          isOpen={this.state.organizationDetailModal}
          toggle={() => this.toggleModal("organizationDetailModal")}
          backdrop="static"
        >
          <div className="modal-header bg-primary">
            <h6
              className="modal-title text-secondary"
              id="modal-title-notification"
            >
              {" "}
              Organization Details
            </h6>
            <button
              aria-label="Close"
              className="close text-secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("organizationDetailModal")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <p className="font-weight-bold p-0 m-0 text-primary">
              Organization Name
            </p>
            <p className="p-0 m-0">{currentOrganization.name}</p>
            <br />
            <p className="font-weight-bold p-0 m-0 text-primary">Description</p>
            <p className="p-0 m-0">{currentOrganization.description}</p>
            <br />
            <p className="font-weight-bold p-0 m-0 text-primary">
              Date Created
            </p>
            <p className="p-0 m-0">{currentOrganization.dateCreated}</p>
            <br />
            <p className="font-weight-bold p-0 m-0 text-primary">Created By</p>
            <p className="p-0 m-0">{currentOrganization.createdBy}</p>
            <br />
            <p className="font-weight-bold p-0 m-0 text-primary">Status</p>
            <p>{currentOrganization.status}</p>
          </div>
          <div className="modal-footer bg-white">
            <Button
              className="ml-auto"
              color="dark"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("organizationDetailModal")}
            >
              Close
            </Button>
          </div>
        </Modal>
      )
    );
  };
}

export default Organizations;
