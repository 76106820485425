import httpService from "services/httpService";

const baseApiEndPoint = process.env.REACT_APP_API_ENDPOINT;

const apiEndPoint = baseApiEndPoint + "/rate";

export async function updateShippingRate(model) {
  const { data } = await httpService.post(apiEndPoint, {
    ...model,
  });
  return data;
}

export async function updateShippingRateByMultiplier(model) {
  const { data } = await httpService.post(apiEndPoint + "/multiplier", {
    ...model,
  });
  return data;
}

export async function getShippingRate(shippingZoneId) {
  const { data } = await httpService.get(apiEndPoint + "/" + shippingZoneId);
  return data;
}

export default {
  updateShippingRate,
  updateShippingRateByMultiplier,
  getShippingRate,
};
