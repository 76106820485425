import React from "react";
import { Alert, Card, CardBody } from "reactstrap";
import Container from "reactstrap/lib/Container";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ValidationForm from "../../../validation/form";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import CardHeader from "reactstrap/lib/CardHeader";

import Joi from "joi-browser";
import Button from "reactstrap/lib/Button";
import {
  showLoadingRecordsMessage,
  showNoRecordsMessage,
  toastOptions,
} from "config/config";

// react plugin used to create datetimepicker
import CardTitle from "reactstrap/lib/CardTitle";
import { GLOBAL_PROPERTY } from "constants/constants";
import transactionsService from "services/transactionsService";
import bankAccountService from "services/bankAccountService";
import auth from "services/authService";
import { closePaymentModal, FlutterWaveButton } from "flutterwave-react-v3";
import { toast } from "react-toastify";

const paystackConfig = {
  public_key: "FLWPUBK-85c92ca0e03220367ec65735f62eaee8-X",
};

const FORM_VIRTUAL_ACCOUNT = 0;
const FORM_START = 1;
const FORM_RECIEVE_AMOUNT = 2;
const FORM_CONFIRM_PAYMENT = 3;
const FORM_BANK_TRANSFER = 4;

class FundWallet extends ValidationForm {
  state = {
    data: {},
    errors: {},
    formSubmitted: false,
    isLoading: false,
    paymentStage: FORM_VIRTUAL_ACCOUNT,
    loadingInflowAccount: true,
    paymentInProgress: false,
    inflowAccount: {},
  };

  activeButton = "";
  initializePayment = null;

  schema = {
    creditAmount: Joi.number().min(100).required().label("Credit Amount"),
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  // you can call this function anything
  handleSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    // console.log(reference);
  };

  async componentDidMount() {
    try {
      const inflowAccount = await bankAccountService.fetchInflowAccount("true");
      this.setState({ inflowAccount, loadingInflowAccount: false });
    } catch (error) {
      this.setState({ loadingInflowAccount: false });
    }
  }

  render() {
    let { isLoading } = this.state;
    let formComponent = <></>;
    if (this.state.paymentStage === FORM_VIRTUAL_ACCOUNT) {
      formComponent = this.displayVirtualBankAccount();
    } else if (this.state.paymentStage === FORM_START) {
      formComponent = this.start();
    } else if (this.state.paymentStage === FORM_RECIEVE_AMOUNT) {
      formComponent = this.receiveCreditAmount();
    } else if (this.state.paymentStage === FORM_CONFIRM_PAYMENT) {
      formComponent = this.confirmCredit();
    } else if (this.state.paymentStage === FORM_BANK_TRANSFER) {
      formComponent = this.bankTransfer();
    }
    return (
      <>
        <SimpleHeader
          name="Fund Wallet"
          parentName="Tables"
          description="Top up your wallet balance."
        />
        <Container className="mt--6" fluid>
          {formComponent}
        </Container>
      </>
    );
  }

  displayVirtualBankAccount() {
    if (this.state.loadingInflowAccount) {
      return showLoadingRecordsMessage(
        "Loading your virtual bank account. Please wait..."
      );
    }

    const bankAccount = this.state.inflowAccount;
    if (!bankAccount.accountNumber) {
      return showNoRecordsMessage(
        "Your virtual bank account is being created. Please try again a minute or two..."
      );
    }

    return (
      <>
        <Row className="mt-4">
          <Col className="offset-md-3 col-md-6">
            <Card className="bg-gradient-white mb-0 border border-primary rounded">
              <CardHeader className="pb-2 bg-primary text-white">
                <CardTitle className="mb-0 font-weight-normal text-white text-center">
                  <h3 className=" text-white">
                    Make a bank transfer to the bank details below and your
                    wallet will be automatically credited.
                  </h3>
                </CardTitle>
              </CardHeader>
              {/* <div className="text-center bg-danger p-2 text-white font-weight-bold">
                Processing Fee of &#8358; 100 applies. <br />
                (Remember to add &#8358; 100 to the transfer amount.)
              </div> */}
              <CardBody className="bg-white text-primary">
                <Row className="p-2">
                  <Col className="col-5">Bank Name:</Col>
                  <Col className="font-weight-bold text-right">
                    {bankAccount.bankName}
                  </Col>
                </Row>
                <Row className="p-2">
                  <Col className="col-5">Account Number:</Col>
                  <Col className="font-weight-bold text-right">
                    {bankAccount.accountNumber}
                  </Col>
                </Row>
                <Row className="p-2">
                  <Col className="col-5">Account Name:</Col>
                  <Col className="font-weight-bold text-right">
                    {bankAccount.accountName}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }

  start() {
    if (!GLOBAL_PROPERTY.inflowAccount) {
      this.setState({ paymentStage: FORM_RECIEVE_AMOUNT });
      return;
    }
    const bankAccount = GLOBAL_PROPERTY.inflowAccount;
    return (
      <>
        <div className="mb-4">
          <Row>
            <Col className="offset-md-3 col-md-6 text-center text-xl">
              How do you want to fund your wallet?
            </Col>
          </Row>
        </div>
        <Row>
          <Col className="offset-md-3 col-md-6">
            <Card className="bg-gradient-white mb-0 border border-primary rounded">
              <CardHeader className="pb-2 text-primary">
                <CardTitle className="text-lg mb-0 font-weight-bold text-primary text-center">
                  Fund Wallet With Account
                </CardTitle>
                <div className="text-center">
                  Make a bank transfer to the bank details below and your wallet
                  will be automatically credited.
                </div>
              </CardHeader>
              <CardBody className="bg-secondary text-primary">
                <Row className="p-2">
                  <Col className="col-5">Account Number:</Col>
                  <Col className="font-weight-bold text-right">
                    {bankAccount.accountNumber}
                  </Col>
                </Row>
                <Row className="p-2">
                  <Col className="col-5">Account Name:</Col>
                  <Col className="font-weight-bold text-right">
                    {bankAccount.accountName}
                  </Col>
                </Row>
                <Row className="p-2">
                  <Col className="col-5">Bank Name:</Col>
                  <Col className="font-weight-bold text-right">
                    {bankAccount.bankName}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="offset-md-3 col-md-6 text-center my-2">-OR-</Col>
        </Row>
        <Row className="mb-5">
          <Col className="offset-md-3 col-md-6">
            <Button
              color="primary"
              size="lg"
              block
              onClick={() =>
                this.setState({ paymentStage: FORM_RECIEVE_AMOUNT })
              }
            >
              <div className="text-lg">Fund Wallet With Card</div>
              <div className="text-sm">
                <em>(Powered by FlutterWave)</em>
              </div>
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  receiveCreditAmount() {
    return (
      <form
        role="form"
        onSubmit={(e) => {
          this.handleSubmit({
            e,
            submitFunction: this.doChoosePaymentOptionForm,
          });
        }}
      >
        <div className="mb-4">
          <Row>
            <Col className="offset-md-3 col-md-6 text-center text-xl">
              What is your credit amount?
            </Col>
          </Row>
        </div>
        <Row>
          <Col className="offset-md-4 col-md-4">
            {this.renderStyledInput({
              mandatory: true,
              type: "text",
              name: "creditAmount",
              placeholder: "Enter Credit Amount",
              currency: <>&#8358;</>,
              classes: "text-lg",
              // label: "Credit Amount",
            })}
          </Col>
        </Row>
        <Row className="mb-4">
          <Col className="offset-md-4 col-md-4">
            {this.renderButton(
              <>
                <div>Continue</div>
              </>,
              "btn-primary btn-block",
              "Continue"
            )}
          </Col>
        </Row>

        {GLOBAL_PROPERTY.inflowAccount ? (
          <Row className="mb-3">
            <Col className="offset-md-4 col-md-4">
              <Button
                type="text"
                color="danger"
                block
                onClick={() => this.cancel()}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </form>
    );
  }

  confirmCredit() {
    const componentProps = {
      ...paystackConfig,
      customer: {
        email: auth.getCurrentUserEmail(),
        // phonenumber: '07064586146',
        name: auth.getCurrentUserFullname(),
      },
      tx_ref: this.state.paymentReference,
      amount: this.state.data.creditAmount.replace(/\s/g, ""),
      currency: "NGN",
      customizations: {
        title: "Fund Wallet",
        description: "Fund wallet to ship!",
        logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
      },

      callback: (response) => {
        // console.log("************* > ", response);
        closePaymentModal(); // this will close the modal programmatically
        if (
          response.status === "successful" ||
          response.status === "completed"
        ) {
          toast.success("Payment Successful!", toastOptions);
          this.setState({
            data: { creditAmount: 0 },
            paymentStage: FORM_START,
          });
        }
      },
      onClose: () => {},
      // onSuccess: (reference) => this.handleSuccess(reference),
    };

    const payWithCardProps = {
      ...componentProps,
      payment_options: "card",
      text: (
        <>
          <div>
            Fund Wallet with{" "}
            <span className="font-weight-bolder font-italic">Card</span>
          </div>
        </>
      ),
    };

    const payWithTransferProps = {
      ...componentProps,
      payment_options: "banktransfer",
      text: (
        <>
          <div>
            Fund Wallet with{" "}
            <span className="font-weight-bolder font-italic">
              Bank Transfer
            </span>
          </div>
        </>
      ),
    };

    return (
      // <form
      //   onSubmit={(e) =>
      //     this.handleSubmit({ e, submitFunction: this.doBankTransferForm })
      //   }
      // >
      <>
        <div className="mb-3">
          <Row>
            <Col className="offset-md-3 col-md-6 text-center text-xl">
              Please confirm amount.
            </Col>
          </Row>
        </div>
        <Row>
          <Col className="offset-md-4 col-md-4 mb-2">
            {this.renderStyledInput({
              mandatory: true,
              type: "text",
              name: "creditAmount",
              placeholder: "Confirmation",
              currency: <>&#8358;</>,
              classes: "text-lg",
              // label: "Credit Amount",
              disabled: true,
            })}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col className="offset-md-4 col-md-4 mb-2">
            {/* <FlutterWaveButton
              className="btn btn-block btn-lg btn-outline-primary"
              {...payWithCardProps}
            /> */}
            {/* <FlutterWaveButton
              className="btn btn-block btn-lg btn-outline-primary"
              {...payWithTransferProps}
            /> */}
            {/* <PaystackConsumer {...componentProps}>
              {({ initializePayment }) => {
                return (
                  <Button
                    color="primary"
                    size="lg"
                    block
                    outline
                    onClick={() => {
                      initializePayment(this.handleSuccess);
                    }}
                  >
                    <div>Fund Wallet with Card</div>
                    <div className="text-sm">
                      <em>(Powered by PayStack)</em>
                    </div>
                  </Button>
                );
              }}
            </PaystackConsumer> */}
          </Col>
        </Row>
        {/* <Row>
          <Col className="offset-md-4 col-md-4 mb-4">
            {this.renderButton(
              <>
                <div>Pay With Account Transfer</div>
                <em>(offline payment)</em>
              </>,
              "btn-primary btn-block btn-outline-primary",
              "Creating..."
            )}
          </Col>
        </Row> */}
        <Row className="mb-3">
          <Col className="offset-md-4 col-md-4">
            <Button
              type="text"
              color="danger"
              size="lg"
              block
              onClick={() => this.cancel()}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </>
      // </form>
    );
  }

  bankTransfer() {
    return (
      <>
        <div className="mb-3">
          <Row>
            <Col className="offset-md-3 col-md-6 text-center text-xl">
              Bank Transfer Instruction
            </Col>
          </Row>
        </div>
        <Row>
          <Col className="offset-md-2 col-md-8 text-lg">
            <div className="bg-white">
              <div className="border pt-2">
                <p className="text-center mb-0">
                  Transfer the sum of &#8358; 4,500.00 to the account below.
                  <div className="font-weight-bold text-danger">
                    Ensure to use the code below as the narration/comment/memo
                    of your transfer.
                  </div>
                </p>
                <div className="display-2 text-center">XPAXMPP</div>
              </div>
              <Row className="mb-1 p-2 bg-primary text-white">
                <Col className="">Credit Amount</Col>
                <Col className="font-weight-bold">&#8358; 4,500.00</Col>
              </Row>
              <div className="border border-top-0">
                <Row className="mb-1 p-2">
                  <Col>Account Number</Col>
                  <Col className="font-weight-bold">0049453426</Col>
                </Row>
                <Row className="mb-1 p-2">
                  <Col>Account Name</Col>
                  <Col className="font-weight-bold">
                    WirePackage Tech. Limited
                  </Col>
                </Row>
                <Row className="mb-1 p-2">
                  <Col>Bank Name</Col>
                  <Col className="font-weight-bold">Guarantee Trust Bank</Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-4 offset-md-4 col-md-4">
          <Col>
            <Button
              block
              size="lg"
              color="primary"
              onClick={() => this.setState({ paymentStage: FORM_START })}
            >
              Close
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  cancel() {
    this.setState({ paymentStage: FORM_START, paymentReference: null });
  }

  doChoosePaymentOptionForm = async () => {
    this.setState({ formSubmitted: true });
    if (!this.state.paymentReference) {
      try {
        const paymentReference =
          await transactionsService.fetchPaymentReference(
            this.state.data.creditAmount
          );
        this.setState({ paymentReference });
      } catch (e) {
        this.setState({ formSubmitted: false });
        return;
      }
    }

    this.setState({ paymentStage: FORM_CONFIRM_PAYMENT, formSubmitted: false });
  };
  doBankTransferForm = () => {
    this.setState({ paymentStage: FORM_BANK_TRANSFER });
  };
}

export default FundWallet;
