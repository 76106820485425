import axios from "axios";
import { toast } from "react-toastify";
import { ACCESS_TOKEN, REFERRAL_CODE } from "constants/index";

// Strategy for refreshing tokens
// https://github.com/axios/axios/issues/2315

axios.interceptors.response.use(null, (error) => {
  // const expectedError =
  //   error.response &&
  //   error.response.status >= 400 &&
  //   error.response.status < 500;

  // if (!expectedError) {
  //   toast.error("Unexpected Error ");
  // }

  if (error.response) {
    // client received an error response (5xx, 4xx)
    if (error.response.status === 403 || error.response.status === 401) {
      localStorage.removeItem(ACCESS_TOKEN);
      if (window.location.pathname !== "/signin") {
        window.location = "/signin";
      }
      // toast.error("Please sign in with valid credentials.");
    } else if (error.response.status === 418) {
      //ignore the error: I AM A TEA POT error
    } else if (error.response.data) {
      let errorMessage = "";
      if (error.response.data.errors && error.response.data.errors.length > 0) {
        for (var i = 0; i < error.response.data.errors.length; i++) {
          errorMessage +=
            i + 1 + ". " + error.response.data.errors[i] + "<br/>";
        }
      } else {
        errorMessage =
          error.response.data.message || "An Unexpected Error occured!";
      }
      toast.error(errorMessage, {
        toastId: "httpService",
      });
    }
  } else if (error.request) {
    // client never received a response, or request never left
    toast.error(
      "Connectivity Error! The server can't be reached. Please check your connection.",
      {
        toastId: "httpService",
      }
    );
  } else {
    // anything else
    toast.error(
      "An error occured processing your last request! Please try again.",
      {
        toastId: "httpService",
      }
    );
  }

  return Promise.reject(error);
});

export function setJwt(jwt) {
  axios.defaults.headers.common["Authorization"] = jwt;
}

export function deleteJwt() {
  delete axios.defaults.headers.common["Authorization"];
}

export function getEndpointForPath(path) {
  return process.env.REACT_APP_API_ENDPOINT + "/" + path;
}

export function setReferralCode(referralCode) {
  axios.defaults.headers.common[REFERRAL_CODE] = referralCode;
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
  deleteJwt,
  getEndpointForPath,
  setReferralCode,
};
