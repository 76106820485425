import React from "react";
// react library for routing
import { Redirect, Route, Switch } from "react-router-dom";
// core components
import {
  adminRoutes,
  dashboardRoutes,
  otherAdminRoutes,
  otherDashboardRoutes,
} from "routes.js";

import authService from "services/authService";
import { loadingComponent } from "config/config";

import { LOGIN_ADMIN_URL, LOGIN_DASHBOARD_URL } from "constants/index";
import { GLOBAL_PROPERTY } from "constants/constants";
import userService from "services/userService";

//create components using React.lazy
const DashboardTheme = React.lazy(() =>
  import("components/theme/DashboardTheme")
);
const AdminNavbar = React.lazy(() =>
  import("components/Navbars/AdminNavbar.js")
);
const AdminFooter = React.lazy(() =>
  import("components/Footers/AdminFooter.js")
);
const Sidebar = React.lazy(() => import("components/Sidebar/Sidebar.js"));

class Admin extends React.Component {
  state = {
    sidenavOpen: true,
  };

  async componentDidMount() {
    const initParams = await userService.initialializeApp();

    if (initParams.transactionFee) {
      GLOBAL_PROPERTY.transactionFee = initParams.transactionFee;
    }

    if (initParams.bvn) {
      GLOBAL_PROPERTY.bvn = initParams.bvn;
    }

    if (initParams.dropOffAmountForInternational) {
      GLOBAL_PROPERTY.dropOffAmountForInternational =
        initParams.dropOffAmountForInternational;
    }
    if (initParams.dropOffAmountForLocal) {
      GLOBAL_PROPERTY.dropOffAmountForLocal = initParams.dropOffAmountForLocal;
    }
    if (initParams.insuranceList) {
      GLOBAL_PROPERTY.insuranceList = initParams.insuranceList;
    }

    if (initParams.userProfile) {
      GLOBAL_PROPERTY.userProfile = initParams.userProfile;
    }
  }

  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      // this.refs.mainContent.scrollTop = 0;
    }
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else if (prop.layout === "/base") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = (path) => {
    let routes;
    for (let j = 0; j < adminRoutes.length; j++) {
      routes = adminRoutes[j];
      for (let i = 0; i < routes.length; i++) {
        if (
          this.props.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return "Brand";
  };
  // toggles collapse between mini sidenav and normal
  toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    this.setState({
      sidenavOpen: !this.state.sidenavOpen,
    });
  };
  getNavbarTheme = () => {
    return this.props.location.pathname.indexOf(
      "admin/alternative-dashboard"
    ) === -1
      ? "dark"
      : "light";
  };

  render() {
    if (!authService.getCurrentUser()) {
      return this.authenticationRequired();
    } else if (authService.getCurrentUserRole() === "ADMIN") {
      if (authService.isDashboardView()) {
        return this.loadDashboardView();
      } else if (authService.isAdminView()) {
        return this.loadAdminView();
      } else {
        authService.switchViewToAdmin();
        return this.loadAdminView();
      }
    } else if (
      authService.getCurrentUserRole() === "MEMBER" ||
      authService.getCurrentUserRole() === "FRANCHISE" ||
      authService.getCurrentUserRole() === "MANAGER"
    ) {
      return this.loadDashboardView();
    }
  }

  authenticationRequired() {
    return (
      <Route
        render={() => (
          <Redirect
            to={{ pathname: "/signin", state: { from: this.props.location } }}
          />
        )}
      />
    );
  }

  loadAdminView() {
    const currentUserRole = authService.getCurrentUserRole();

    let routes = [];
    for (let j = 0; j < adminRoutes.length; j++) {
      if (adminRoutes[j].roles.includes(currentUserRole)) {
        routes.push(this.getRoutes(adminRoutes[j].routes));
      }
    }
    let otherRoutes = [];
    for (let j = 0; j < otherAdminRoutes.length; j++) {
      if (otherAdminRoutes[j].roles.includes(currentUserRole)) {
        otherRoutes.push(this.getRoutes(otherAdminRoutes[j].routes));
      }
    }

    return this.loadView(
      "Admin View",
      routes,
      adminRoutes,
      <Redirect from="*" to={LOGIN_ADMIN_URL} />,
      otherRoutes
    );
  }

  loadDashboardView() {
    const currentUserRole = authService.getCurrentUserRole();
    let routes = [];
    for (let j = 0; j < dashboardRoutes.length; j++) {
      if (dashboardRoutes[j].roles.includes(currentUserRole)) {
        routes.push(this.getRoutes(dashboardRoutes[j].routes));
      }
    }

    let otherRoutes = [];
    for (let j = 0; j < otherDashboardRoutes.length; j++) {
      if (otherDashboardRoutes[j].roles.includes(currentUserRole)) {
        otherRoutes.push(this.getRoutes(otherDashboardRoutes[j].routes));
      }
    }

    return this.loadView(
      "Dashboard View",
      routes,
      dashboardRoutes,
      <Redirect from="*" to={LOGIN_DASHBOARD_URL} />,
      otherRoutes
    );
  }

  loadView(viewText, mainViewRoutes, sideBarRoutes, defaultRoute, otherRoutes) {
    return (
      <React.Suspense fallback={<>{loadingComponent()}</>}>
        <DashboardTheme />
        <Sidebar
          {...this.props}
          routes={sideBarRoutes}
          toggleSidenav={this.toggleSidenav}
          sidenavOpen={this.state.sidenavOpen}
          logo={{
            innerLink: "/",
            imgSrc: require("assets/img/brand/wirepackage-colour.png"),
            imgAlt: "...",
          }}
        />
        <div
          className="main-content"
          ref="mainContent"
          onClick={this.closeSidenav}
        >
          <AdminNavbar
            {...this.props}
            theme={this.getNavbarTheme()}
            toggleSidenav={this.toggleSidenav}
            sidenavOpen={this.state.sidenavOpen}
            brandText={this.getBrandText(this.props.location.pathname)}
            viewText={viewText}
            newAccount={
              this.props.location.state && this.props.location.state.newAccount
            }
            signupMessage={
              this.props.location.state &&
              this.props.location.state.signupMessage
            }
          />
          <div className="min-vh-100">
            <Switch>
              {mainViewRoutes}
              {otherRoutes}
              {defaultRoute}
            </Switch>
          </div>
          <AdminFooter />
        </div>
        {this.state.sidenavOpen ? (
          <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
        ) : null}
      </React.Suspense>
    );
  }
}

export default Admin;
