/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
// react library for routing
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// bootstrap rtl for rtl support page
// import "assets/vendor/bootstrap-rtl/bootstrap-rtl.scss";
// plugins styles from node_modules
// import "react-notification-alert/dist/animate.css";
// import "react-perfect-scrollbar/dist/css/styles.css";
import "react-toastify/dist/ReactToastify.css";
// plugins styles downloaded
import "assets/vendor/select2/dist/css/select2.min.css";
import "assets/vendor/quill/dist/quill.core.css";
import "assets/vendor/nucleo/css/nucleo.css";
// import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "assets/css/reflection.css";
// core styles
// import "assets/scss/argon-dashboard-pro-react.scss?v1.1.0";
// import "assets/css/argon-design-system.css";
// import "assets/css/argon-dashboard-pro-react.css";
// import RTLLayout from "layouts/RTL.js";
// import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import IndexRoute from "views/pages/general/IndexRoute.js";
import OAuth2RedirectHandler from "oauth2/OAuth2RedirectHandler";
import VerifyAccount from "views/pages/admin/VerifyAccount.js";
import BrandedIndexRoute from "views/pages/branded/BrandedIndexRoute.js";

const domain = window.location.host.split(".");
let isDefaultPage = false;
let isDefaultTrackingPage = false;
let isbrandedTrackingPage = false;

let isTrackingPage = window.location.pathname.startsWith("/track/");

if (process.env.NODE_ENV === "production") {
  if (isTrackingPage) {
    if (domain.length === 2) {
      isDefaultTrackingPage = true;
    } else if (
      domain.length === 3 &&
      domain[0] === "www" &&
      domain[1] === "wirepackage" &&
      domain[2] === "com"
    ) {
      isDefaultTrackingPage = true;
    } else if (
      domain.length === 3 &&
      domain[0] != "www" &&
      domain[1] === "wirepackage" &&
      domain[2] === "com"
    ) {
      isbrandedTrackingPage = true;
    }
  } else {
    isDefaultPage = true;
  }
} else if (process.env.NODE_ENV === "staging") {
  if (isTrackingPage) {
    if (domain.length === 3) {
      isDefaultTrackingPage = true;
    } else if (
      domain.length === 4 &&
      domain[0] === "www" &&
      domain[1] === "wirepackage-portal" &&
      domain[2] === "onrender" &&
      domain[3] === "com"
    ) {
      isDefaultTrackingPage = true;
    } else if (
      domain.length === 4 &&
      domain[0] != "www" &&
      domain[1] === "wirepackage-portal" &&
      domain[2] === "onrender" &&
      domain[3] === "com"
    ) {
      isbrandedTrackingPage = true;
    }
  } else {
    isDefaultPage = true;
  }
} else {
  if (isTrackingPage) {
    if (domain.length === 1) {
      isDefaultTrackingPage = true;
    } else if (
      domain.length === 2 &&
      domain[0] === "www" &&
      domain[1] === "localhost:3000"
    ) {
      isDefaultTrackingPage = true;
    } else if (
      domain.length === 2 &&
      domain[0] != "www" &&
      domain[1] === "localhost:3000"
    ) {
      isbrandedTrackingPage = true;
    }
  } else {
    isDefaultPage = true;
  }
}

ReactDOM.render(
  <>
    <ToastContainer
      autoClose={false}
      position="top-center"
      autoClose={8000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      allowHtml={true}
      limit={2}
    />
    {/* {isDefaultPage ? defaultPages() : brandedPages(domain[0])} */}
    {getPagesToRender()}
  </>,
  document.getElementById("root")
);

function getPagesToRender() {
  if (isDefaultTrackingPage) {
    return brandedPages("track-something-unique123");
  } else if (isbrandedTrackingPage) {
    return brandedPages(domain[0]);
  } else if (isDefaultPage) {
    return defaultPages();
  } else {
    return defaultPages();
  }
}

function defaultPages() {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path={["/admin", "/base"]}
          render={(props) => <AdminLayout {...props} />}
        />
        {/* <Route path="/rtl" render={(props) => <RTLLayout {...props} />} /> */}
        {/* <Route path="/auth" render={(props) => <AuthLayout {...props} />} /> */}
        <Route path="/verify/:token" component={VerifyAccount} />
        <Route path="/oauth2/redirect" component={OAuth2RedirectHandler} />
        <Route path="/" render={(props) => <IndexRoute {...props} />} />
        {/* <Redirect from="*" to="/" /> */}
      </Switch>
    </BrowserRouter>
  );
}

function brandedPages(subdomain) {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/"
          render={(props) => (
            <BrandedIndexRoute
              {...props}
              subdomain={subdomain}
              isDefaultPage={isDefaultPage}
            />
          )}
        />
      </Switch>
    </BrowserRouter>
  );
}
