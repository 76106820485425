import React from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ValidationForm from "../../../validation/form";
import Joi from "joi-browser";
import brandedTrackingService from "services/brandedTrackingService";
import authService from "services/authService";
import { toast } from "react-toastify";
import { showLoadingRecordsMessage } from "config/config";
import utils from "util/CustomUtil";
import TrackShipment_1 from "../branded/TrackShipment_1";

const VALIDATING_BUSINESS = "1";
const BUSINESS_NOT_AVAILABLE = "2";
const LOADING_PRODUCTS = "3";
const FORM_READY = "4";
export default class BrandedTrackingSetup extends ValidationForm {
  state = {
    data: {},
    errors: {},
    tab: "tab-3",
    productList: [],
    productCheckboxSelectionState: [],
    selectedProductCount: 0,
    themeList: [],
    themeDetailList: [],
    selectedThemeProperties: null,
    showMoreSettings: false,
  };

  schema = {
    enableBrandedTracking: Joi.boolean().label("Make Active"),
    displayPhoneNumber: Joi.boolean().label("Make Active"),
    displayWhatsAppNumber: Joi.boolean().label("Make Active"),
    displayEmail: Joi.boolean().label("Make Active"),
    displayWebsiteLink: Joi.boolean(),
    displayAddress: Joi.boolean(),
    displaySocialMediaLinks: Joi.boolean(),
    displayWirePackageContact: Joi.boolean(),
    title1: Joi.string().max(25).required().label("Title 1"),
    title2: Joi.string().max(70).allow("").label("Title 2"),
    theme: Joi.object({
      label: Joi.string().required().label("Label"),
      value: Joi.number().min(0).required().label("Value"),
      id: Joi.number().min(1).required().label("Id"),
    })
      .required()
      .label("Theme"),
  };

  async componentDidMount() {
    try {
      if (authService.isBusinessAvailable()) {
        this.setState({
          loadStatus: LOADING_PRODUCTS,
        });
        const responses = await Promise.all([
          brandedTrackingService.fetchPreferences(),
          brandedTrackingService.fetchAllProductsForCurrentOrganization(),
          brandedTrackingService.fetchThemes(),
        ]);
        const preferences = responses[0];
        const productList = responses[1];
        const themeList = responses[2].trackingPageThemeSelection;
        const themeDetailList = responses[2].trackingPageThemeList;

        const data = this.state.data;
        data.displayPhoneNumber = preferences.displayPhoneNumber;
        data.displayWhatsAppNumber = preferences.displayWhatsAppNumber;
        data.displayEmail = preferences.displayEmail;
        data.displayWebsiteLink = preferences.displayWebsiteLink;
        data.displayAddress = preferences.displayAddress;
        data.displaySocialMediaLinks = preferences.displaySocialMediaLinks;
        data.displayWirePackageContact = preferences.displayWirePackageContact;

        // to activate branded tracking
        data.enableBrandedTracking = preferences.enableBrandedTracking;

        const productCheckboxSelectionState = [];
        let selectedProductCount = 0;
        productList.map((value, index) => {
          productCheckboxSelectionState[productCheckboxSelectionState.length] =
            value.selected;

          if (value.selected) {
            selectedProductCount++;
          }
        });

        this.setState({
          data,
          productList,
          productCheckboxSelectionState,
          selectedProductCount,
          themeList,
          themeDetailList,
          loadStatus: FORM_READY,
        });
      } else {
        this.setState({
          loadStatus: BUSINESS_NOT_AVAILABLE,
        });
      }
    } catch (ex) {
      this.setState({
        loadingProducts: false,
      });
    }
  }

  doSubmit = async () => {
    this.setState({ formSubmitted: true });
    let { data } = this.state;

    try {
      const preferences = {};
      preferences.displayPhoneNumber = data.displayPhoneNumber;
      preferences.displayWhatsAppNumber = data.displayWhatsAppNumber;
      preferences.displayEmail = data.displayEmail;
      preferences.displayWebsiteLink = data.displayWebsiteLink;
      preferences.displayAddress = data.displayAddress;
      preferences.displaySocialMediaLinks = data.displaySocialMediaLinks;
      preferences.displayFacebook = data.displayFacebook;
      preferences.displayTwitter = data.displayTwitter;
      preferences.displayWirePackageContact = data.displayWirePackageContact;

      const response = await brandedTrackingService.updatePreferences(
        preferences
      );
      toast.success(response.message);
      this.setState({
        formSubmitted: false,
      });
    } catch (ex) {
      this.setState({ formSubmitted: false });
    }
  };

  doSubmitSelectedProducts = async () => {
    try {
      this.setState({ formSubmitted: true });
      let { productCheckboxSelectionState, productList } = this.state;
      const selectedProducts = [];

      productCheckboxSelectionState.map((value, index) => {
        if (value == true) {
          selectedProducts[selectedProducts.length] = productList[index].id;
        }
      });

      const response =
        await brandedTrackingService.updateSelectedProductsForCurrentOrganization(
          selectedProducts
        );
      toast.success(response.message);
      this.setState({
        formSubmitted: false,
      });
    } catch (ex) {
      this.setState({ formSubmitted: false });
    }
  };

  render() {
    let recordViewComponent;
    if (
      this.state.loadStatus === LOADING_PRODUCTS ||
      this.state.loadStatus === VALIDATING_BUSINESS
    ) {
      recordViewComponent = showLoadingRecordsMessage("Loading Settings...");
    } else if (this.state.loadStatus === BUSINESS_NOT_AVAILABLE) {
      recordViewComponent = this.requestToCreateBusiness();
    } else {
      recordViewComponent = this.loadTabs();
    }

    return (
      <div>
        <SimpleHeader
          name="Branded Tracking Page"
          parentName="Tables"
          toggleModal={() => {
            this.setState({ showMoreSettings: true });
          }}
          newItemButtonLabel="Show Advance Settings"
          description="Select the theme that best represents your brand and preview your branded tracking page. When you are done, publish your setup."
        />
        <Container className="mt--6" fluid>
          {recordViewComponent}
        </Container>
      </div>
    );
  }

  requestToCreateBusiness() {
    return (
      <div className="card border border-primary" style={{ width: "18rem" }}>
        <div className="card-body">
          <p className="card-text">
            To setup your Branded Tracking Page, you need to create a business
            profile.
          </p>
          <Button
            color="primary"
            size="md"
            onClick={() =>
              this.props.history.push({
                pathname: "/base/business",
                search: "?t=n",
              })
            }
          >
            Create A New Business
          </Button>
        </div>
      </div>
    );
  }

  loadTabs() {
    let { tab } = this.state;

    return (
      <div className="bg-white border rounded border-white p-2">
        <div className="d-flex text-left ml-5 mb-3">
          <div>
            {this.renderSwitchButton({
              label:
                (this.state.data.enableBrandedTracking ? "Stop" : "Publish") +
                " Branded Tracking Page",
              mandatory: false,
              name: "enableBrandedTracking",
              placeholder: "Entry Count",
              checked: this.state.data.enableBrandedTracking,
              classes: "reflection-pointer",
              onchange: async (e) => {
                const data = this.state.data;
                try {
                  const response =
                    await brandedTrackingService.enableBrandedTracking(
                      data.enableBrandedTracking
                    );
                  toast.success(response.message);
                } catch (error) {}
              },
            })}
          </div>

          <span className="ml-auto">
            <Button
              size="sm"
              color="primary"
              type="button"
              onClick={() =>
                this.props.history.push("/base/branded-notification")
              }
            >
              Done, move to branded notifications.
            </Button>
          </span>
        </div>
        <hr className="m-0" />
        <div className="nav-wrapper">
          <Nav
            // className="nav-fill flex-column flex-md-row"
            className="nav-pills-primary nav-fill"
            pills
            role="tablist"
          >
            <NavItem className={!this.state.showMoreSettings && "d-none"}>
              <NavLink
                className={
                  "mb-sm-3 mb-md-0 reflection-pointer " +
                  (tab === "tab-1" ? "active" : "")
                }
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ tab: "tab-1" });
                }}
              >
                Preferences
              </NavLink>
            </NavItem>
            <NavItem className={!this.state.showMoreSettings && "d-none"}>
              <NavLink
                className={
                  "mb-sm-3 mb-md-0 reflection-pointer " +
                  (tab === "tab-2" ? "active" : "")
                }
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ tab: "tab-2" });
                }}
              >
                Product Selection
              </NavLink>
            </NavItem>
            <NavItem className={!this.state.showMoreSettings && "d-none"}>
              <NavLink
                className={
                  "mb-sm-3 mb-md-0 reflection-pointer shadow-lg " +
                  (tab === "tab-3" ? "active" : "")
                }
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ tab: "tab-3" });
                }}
              >
                Theme Selection
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <Card className="bg-transparent shadow-none mx--3">
          <CardBody className="bg-transparent pt-0">
            <TabContent id="myTabContent" activeTab={tab}>
              <TabPane tabId="tab-1" role="tabpanel">
                {tab === "tab-1" && this.generalSettings()}
              </TabPane>
              <TabPane tabId="tab-2" role="tabpanel">
                {tab === "tab-2" && this.productSelection()}
              </TabPane>
              <TabPane tabId="tab-3" role="tabpanel">
                {(tab === "tab-3" || !this.state.showMoreSettings) &&
                  this.themeSelection()}
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </div>
    );
  }

  themeSelection() {
    return (
      <Row>
        <Col className="col-sm-3">
          <form
            onSubmit={(e) => {
              this.handleSubmit({
                e,
                formItemNames: ["title1", "title2", "theme"],
                submitFunction: this.updateTheme,
              });
            }}
          >
            <div className="text-center font-weight-bold text-underline mb-2">
              Tracking Page Settings
            </div>
            {this.renderStyledInput({
              mandatory: true,
              label: "Title 1",
              type: "text",
              name: "title1",
              placeholder: "Title 1 Content",
            })}
            {this.renderStyledInput({
              label: "Title 2",
              type: "text",
              name: "title2",
              placeholder: "Title 2 Content",
            })}
            <div className="mb-2">
              {this.renderSelect2({
                mandatory: true,
                name: "theme",
                placeholder: "Select Theme",
                options: this.state.themeList,
                label: "Select Theme",
                classes: "mb-3",
                onchange: async (selectedTheme) => {
                  const selectedValue = selectedTheme.value;
                  this.setState({
                    selectedThemeProperties:
                      this.state.themeDetailList[selectedValue]
                        .trackingPageTemplateProperties,
                  });
                },
              })}
            </div>
            {this.renderButton("Save", "btn-primary", "Saving...")}
          </form>
        </Col>
        <Col className="col-sm-9">
          <div className="text-center font-weight-bold text-underline mb-2">
            Tracking Page Preview
          </div>
          <TrackShipment_1
            testPage={true}
            subdomain={authService.getOrganizationCode()}
            productList={this.state.productList}
            title1={this.state.data.title1}
            title2={this.state.data.title2}
            selectedThemeProperties={this.state.selectedThemeProperties}
            initPageProperties={this.initPageProperties}
          />
        </Col>
      </Row>
    );
  }

  initPageProperties = (title1, title2, theme) => {
    const data = this.state.data;
    data.title1 = title1;
    data.title2 = title2;
    data.theme = theme;
    console.log(theme);
    console.log(data.theme);
    this.setState({ data });
  };

  updateTheme = async () => {
    try {
      this.setState({ formSubmitted: true });

      const model = {};
      model.title1 = this.state.data.title1;
      model.title2 = this.state.data.title2;
      model.trackingPagetemplateId = this.state.data.theme.id;
      const response = await brandedTrackingService.updateTheme(model);
      toast.success(response.message);
      this.setState({ formSubmitted: false });
    } catch (error) {
      this.setState({ formSubmitted: false });
    }
  };

  productSelection() {
    return (
      <div>
        <Row>
          <Col className="col-6 pr-0">
            <div className="text-left text-primary mb-3">
              Select the products you want displayed on your branded tracking
              pages.
            </div>
          </Col>
          <Col className="text-right">
            <span className="badge badge-primary text-lg font-weight-normal bg-primary text-capitalize text-white border border-light text-right mb-2">
              Selected Product Count :{" "}
              <span className="font-weight-bolder">
                {this.state.selectedProductCount}
              </span>
            </span>
          </Col>
        </Row>

        {this.presentProductList()}
        <hr />
        <div className="text-right">
          <Button
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              this.doSubmitSelectedProducts();
            }}
            disabled={this.state.formSubmitted}
          >
            <span className={this.state.formSubmitted ? "" : "d-none"}>
              <Spinner type="border" size="sm"></Spinner> Saving Selection...
            </span>
            <span className={this.state.formSubmitted ? "d-none" : ""}>
              Save Selection
            </span>
          </Button>
        </div>
      </div>
    );
  }

  customCheckBox = (name, value, checked, label) => {
    return (
      <div className={`form-group`}>
        <div className="custom-control custom-control-alternative custom-checkbox">
          <input
            type="checkbox"
            id={name}
            name={name}
            className={`custom-control-input`}
            checked={checked}
            value={value}
            onChange={(event) => {
              const productCheckboxSelectionState = [
                ...this.state.productCheckboxSelectionState,
              ];
              productCheckboxSelectionState[event.currentTarget.name] =
                event.currentTarget.checked;

              let selectedProductCount = this.state.selectedProductCount;

              if (event.currentTarget.checked) {
                selectedProductCount++;
              } else {
                selectedProductCount--;
              }
              this.setState({
                productCheckboxSelectionState,
                selectedProductCount,
              });
            }}
          />
          <label className="custom-control-label" htmlFor={name}>
            <span className="">{label}</span>
          </label>
        </div>
      </div>
    );
  };

  generalSettings() {
    return (
      <form
        onSubmit={(e) => {
          this.handleSubmit({
            e,
            formItemNames: [
              "displayWirePackageContact",
              "displaySocialMediaLinks",
              "displayAddress",
              "displayWebsiteLink",
              "displayEmail",
              "displayWhatsAppNumber",
              "displayPhoneNumber",
            ],
          });
        }}
      >
        <div className="bg-lighter m--2 pt-4 px-4 pb-2 rounded">
          <Row>
            <Col className="col-12 col-md-6 pb-3">
              <span>
                What business contact information do you want displayed?
                <br />
              </span>
            </Col>
            <Col className="col-12 col-md-6 font-weight-bold">
              {this.renderCheckbox({
                mandatory: false,
                name: "displayPhoneNumber",
                label: "Display Primary Phone Number",
                checked: this.state.data.displayPhoneNumber,
                disabled: false,
              })}
              {this.renderCheckbox({
                mandatory: false,
                name: "displayWhatsAppNumber",
                label: "Display WhatsApp Phone Number",
                checked: this.state.data.displayWhatsAppNumber,
                disabled: false,
              })}
              {this.renderCheckbox({
                mandatory: false,
                name: "displayEmail",
                label: "Display Contact Email",
                checked: this.state.data.displayEmail,
                disabled: false,
              })}
              {this.renderCheckbox({
                mandatory: false,
                name: "displayWebsiteLink",
                label: "Display Website Link",
                checked: this.state.data.displayWebsiteLink,
                disabled: false,
              })}
              {this.renderCheckbox({
                mandatory: false,
                name: "displayAddress",
                label: "Display Physical Address Location",
                checked: this.state.data.displayAddress,
                disabled: false,
              })}
            </Col>
          </Row>
        </div>
        <hr />
        <div className="bg-lighter m--2 pt-4 px-4 pb-2 rounded">
          <Row>
            <Col className="col-12 col-md-6 pb-3">
              <span>Do you want your Social Media links displayed?</span>
            </Col>
            <Col className="col-12 col-md-6 font-weight-bold">
              {this.renderCheckbox({
                mandatory: false,
                name: "displaySocialMediaLinks",
                label: "Display Social Media Links",
                checked: this.state.data.displaySocialMediaLinks,
                disabled: false,
              })}
            </Col>
          </Row>
        </div>
        <hr />
        <div className=" bg-lighter m--2 pt-4 px-4 pb-2 rounded">
          <Row>
            <Col className="col-12 col-md-6 pb-3">
              <span>
                Do you want reciever's to contact WirePackage for shipment
                enquiries? This can take a burden off you.
              </span>
            </Col>
            <Col className="col-12 col-md-6 font-weight-bold">
              {this.renderCheckbox({
                mandatory: false,
                name: "displayWirePackageContact",
                label: "Display WirePackage Contacts on tracking page.",
                checked: this.state.data.displayWirePackageContact,
                disabled: false,
              })}
            </Col>
          </Row>
        </div>
        <hr />
        <div className="text-right">
          {this.renderButton(
            "Save Preferences",
            "btn-primary",
            "Saving Preferences"
          )}
        </div>
      </form>
    );
  }

  presentProductList() {
    const productDisplays = [];
    let product = null;
    let product2 = null;

    for (
      let index = 0;
      index < this.state.productList.length;
      index = index + 2
    ) {
      product = this.state.productList[index];

      if (index + 1 == this.state.productList.length) {
        productDisplays[productDisplays.length] = (
          <Row key={productDisplays.length}>
            <Col className="col-12 col-md-6 col-lg-6">
              <div className="rounded-lg border border-light bg-white p-4 shadow-lg mb-4">
                <Row>
                  <Col className="col-12 col-sm-12 col-md-12 col-lg-5">
                    <div style={{ height: 150, width: 150 }}>
                      <img
                        src={
                          product.imageURL
                            ? product.imageURL
                            : this.state.defaultImageURL
                        }
                        className="rounded float-center img-thumbnail img-fluid"
                        alt="Product Image"
                        style={{
                          maxHeight: "100%",
                          maxWidth: "100%",
                          objectFit: "contain",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          this.setState({
                            modalProductDetail: this.state.productList[index],
                          });
                          this.toggleModal("detailImageModal");
                        }}
                      />
                    </div>
                  </Col>
                  <Col className="col-12 col-sm-12 col-md-12 col-lg-7">
                    <span className="font-weight-bolder text-dark text-lg text-left">
                      {product.name}
                    </span>
                    <div> {product.description}</div>
                    <div>
                      {product.currency.value}{" "}
                      {utils.numberFormatter(product.price, 2)}
                    </div>
                    <br />
                    <div className="text-left">
                      {this.customCheckBox(
                        index,
                        product.id,
                        this.state.productCheckboxSelectionState[index],
                        "Display Product"
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        );
      } else {
        product2 = this.state.productList[index + 1];

        productDisplays[productDisplays.length] = (
          <Row key={productDisplays.length}>
            <Col className="col-12 col-md-6 col-lg-6">
              <div className="rounded-lg border border-light bg-white p-4 shadow-lg mb-4">
                <Row>
                  <Col className="col-12 col-sm-12 col-md-12 col-lg-5">
                    <div style={{ height: 150, width: 150 }}>
                      <img
                        src={
                          product.imageURL
                            ? product.imageURL
                            : this.state.defaultImageURL
                        }
                        className="rounded float-center img-thumbnail img-fluid"
                        alt="Product Image"
                        style={{
                          maxHeight: "100%",
                          maxWidth: "100%",
                          objectFit: "contain",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          this.setState({
                            modalProductDetail: this.state.productList[index],
                          });
                          this.toggleModal("detailImageModal");
                        }}
                      />
                    </div>
                  </Col>
                  <Col className="col-12 col-sm-12 col-md-12 col-lg-7">
                    <span className="font-weight-bolder text-dark text-lg text-left">
                      {product.name}
                    </span>
                    <div> {product.description}</div>
                    <div>
                      {product.currency.value}{" "}
                      {utils.numberFormatter(product.price, 2)}
                    </div>
                    <br />
                    <div className="text-left">
                      {this.customCheckBox(
                        index,
                        product.id,
                        this.state.productCheckboxSelectionState[index],
                        "Display Product"
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col className="col-12 col-sm-12 col-md-6 col-lg-6">
              <div className="rounded-lg border border-light bg-white p-4 shadow-lg mb-4">
                <Row>
                  <Col className="col-12 col-sm-12 col-md-12 col-lg-5">
                    <div style={{ height: 150, width: 150 }}>
                      <img
                        src={
                          product2.imageURL
                            ? product2.imageURL
                            : this.state.defaultImageURL
                        }
                        className="rounded float-center img-thumbnail img-fluid"
                        alt="Product Image"
                        style={{
                          maxHeight: "100%",
                          maxWidth: "100%",
                          objectFit: "contain",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          this.setState({
                            modalProductDetail:
                              this.state.productList[index + 1],
                          });
                          this.toggleModal("detailImageModal");
                        }}
                      />
                    </div>
                  </Col>
                  <Col className="col-12 col-sm-12 col-md-12 col-lg-7">
                    <span className="font-weight-bolder text-dark text-lg text-left">
                      {product2.name}
                    </span>
                    <div> {product2.description}</div>
                    <div>
                      {product2.currency.value}{" "}
                      {utils.numberFormatter(product2.price, 2)}
                    </div>
                    <br />
                    <div className="text-left">
                      {this.customCheckBox(
                        index + 1,
                        product2.id,
                        this.state.productCheckboxSelectionState[index + 1],
                        "Display Product"
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        );
      }
    }
    return productDisplays;
  }
}
