import React from "react";
// reactstrap components
import { Button, Container, Row } from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Joi from "joi-browser";
import ValidationForm from "../../../validation/form";
import { toast } from "react-toastify";
import { showLoadingRecordsMessage } from "config/config";
import Col from "reactstrap/lib/Col";
import shipmentService from "services/shipmentService";
import authService from "services/authService";
import Switch from "validation/switch";

const VALIDATING_BUSINESS = "1";
const BUSINESS_NOT_AVAILABLE = "2";
const LOADING_PRODUCTS = "3";
const FORM_READY = "4";

class BrandedNotification extends ValidationForm {
  state = {
    data: {},
    errors: {},
    formSubmitted: false,
    switchValue: false,
  };
  schema = {
    enableBrandedNotification: Joi.boolean().label("Branded Notification"),
  };

  async componentDidMount() {
    try {
      if (authService.isBusinessAvailable()) {
        this.setState({
          loadStatus: LOADING_PRODUCTS,
        });

        const responses = await Promise.all([
          shipmentService.fetchBrandedNotificationStatus(),
        ]);
        const preferences = responses[0];
        const data = this.state.data;
        // to activate branded tracking
        data.enableBrandedNotification = preferences.enableBrandedNotification;

        this.setState({
          data,
          loadStatus: FORM_READY,
        });
      } else {
        this.setState({
          loadStatus: BUSINESS_NOT_AVAILABLE,
        });
      }
    } catch (error) {}
  }

  render() {
    let recordViewComponent;
    if (
      this.state.loadStatus === LOADING_PRODUCTS ||
      this.state.loadStatus === VALIDATING_BUSINESS
    ) {
      recordViewComponent = showLoadingRecordsMessage("Loading products...");
    } else if (this.state.loadStatus === BUSINESS_NOT_AVAILABLE) {
      recordViewComponent = this.requestToCreateBusiness();
    } else {
      recordViewComponent = this.newBusinessModal();
    }

    return (
      <>
        <SimpleHeader
          name="Branded Notifications"
          parentName="Tables"
          description="Publish or stop branded notifications."
        />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">{recordViewComponent}</div>
          </Row>
        </Container>
      </>
    );
  }

  requestToCreateBusiness() {
    return (
      <div className="card border border-primary" style={{ width: "18rem" }}>
        <div className="card-body">
          <p className="card-text">
            To setup your Branded Notifications, you need to create a business
            profile.
          </p>
          <Button
            color="primary"
            size="md"
            onClick={() =>
              this.props.history.push({
                pathname: "/base/business",
                search: "?t=n",
              })
            }
          >
            Create A New Business
          </Button>
        </div>
      </div>
    );
  }

  newBusinessModal = () => {
    const currentBusiness = this.state.currentBusiness;

    return (
      <form
        onSubmit={(e) => {
          this.handleSubmit({
            e,
          });
        }}
      >
        <div className="rounded-lg border bg-white shadow-lg p-3">
          <Row>
            <Col>
              {/* <Switch
                isOn={this.state.switchValue}
                onColor="#EF476F"
                handleToggle={() =>
                  this.setState({ switchValue: !this.state.switchValue })
                }
              /> */}

              <div className="m--2 pt-4 px-4 pb-2 rounded">
                {this.renderSwitch({
                  label:
                    (this.state.data.enableBrandedNotification
                      ? "Stop"
                      : "Publish") + " Branded Notifications",
                  mandatory: false,
                  name: "enableBrandedNotification",
                  placeholder: "Entry Count",
                  checked: this.state.data.enableBrandedNotification,
                  // classes: "reflection-pointer",
                  onchange: async (e) => {
                    const data = this.state.data;
                    try {
                      const response =
                        await shipmentService.enableBrandedNotification(
                          data.enableBrandedNotification
                        );
                      toast.success(response.message);
                    } catch (error) {}
                  },
                })}
                {/* <div className="font-weight-bold text-decoration-underline mb-3">
                  <u>Shipment Configuration</u>
                </div> */}

                {/* <div className="ml-4">
                  {this.renderSwitchButton({
                    label:
                      (this.state.data.enableBrandedNotification
                        ? "Stop"
                        : "Publish") + " Branded Notifications",
                    mandatory: false,
                    name: "enableBrandedNotification",
                    placeholder: "Entry Count",
                    checked: this.state.data.enableBrandedNotification,
                    classes: "reflection-pointer",
                    onchange: async (e) => {
                      const data = this.state.data;
                      try {
                        const response =
                          await shipmentService.enableBrandedNotification(
                            data.enableBrandedNotification
                          );
                        toast.success(response.message);
                      } catch (error) {}
                    },
                  })}
                </div> */}
              </div>
            </Col>
          </Row>
        </div>
      </form>
    );
  };
}

export default BrandedNotification;
