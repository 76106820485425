import React from "react";
import ValidationForm from "../../../../validation/form";
import ImportShippingZone from "./ImportShippingZone";
import InternationalRates from "./ImportRates";

class Index extends ValidationForm {
  state = {
    showRatesSetup: false,
    currentZone: {},
  };

  render() {
    let currentView;
    if (this.state.showRatesSetup) {
      currentView = (
          <InternationalRates
              goBackAction={() =>
                  this.setState({
                    showRatesSetup: false,
                  })
              }
              currentZone={this.state.currentZone}
              billingGroup={this.state.billingGroup}
          />
      );
    } else {
      currentView = (
          <ImportShippingZone
              showRates={(currentZone, billingGroup) =>
                  this.setState({showRatesSetup: true, currentZone, billingGroup})
              }
          />
      );
    }

    return currentView;
  }
}

export default Index;
