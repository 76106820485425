import React from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
// reactstrap components
import {Button, Card, Container, Modal, Row} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import UncontrolledDropdown from "reactstrap/lib/UncontrolledDropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import Joi from "joi-browser";
import ValidationForm from "../../../../validation/form";
import {toast} from "react-toastify";
import TableActionButtons from "../TableActionButtons";
import {
  pagination,
  showCouldNotLoadRecordsMessage,
  showLoadingRecordsMessage,
  showNoRecordsMessage,
} from "config/config";
import {COUNTRY_LIST} from "constants/constants";
import shippingZoneService from "services/shippingZoneService";
import courierService from "services/courierService";
import geographyService from "services/geographyService";
import util from "util/CustomUtil";
import Col from "reactstrap/lib/Col";
import Spinner from "reactstrap/lib/Spinner";
import organizationService from "services/organizationService";

const GEOGRAPHY_LIST = [
  {
    value: "",
    label: "-ALL-",
  },
  {
    value: "country",
    label: "Country",
  },
  {
    value: "region",
    label: "Region",
  },
  {
    value: "continent",
    label: "Continent",
  },
];

const EXPORT_CLASS = "Export";

class ExportInternationalShippingZone extends ValidationForm {
  state = {
    data: {},
    errors: {},
    formSubmitted: false,
    loadingTableRecords: false,
    couldNotLoadTableRecords: false,
    newShippingZoneModal: false,
    shippingZoneDetailModal: false,
    shippingZoneList: [],
    courierList: [],
    billingGroupList: [],
    currentShippingZone: {},
    membersList: [],
    fieldIsProcessing: {},
    loadingDetailInProgress: false,
    deleteShippingZoneModal: false,
    deleteShippingZoneInProgress: false,
    signalsAvailableForEvent: false,
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  schema = {
    courier: Joi.object({
      value: Joi.string().required().label("Value"),
      label: Joi.string().required().label("Label"),
    })
      .required()
      .label("Courier"),
    country: Joi.object({
      value: Joi.string().required().label("Value"),
      label: Joi.string().required().label("Label"),
    })
      .required()
      .label("Origin Country"),
    geography: Joi.object({
      value: Joi.string().allow("").label("Value"),
      label: Joi.string().label("Label"),
    }).label("Geography"),
    description: Joi.string().max(100).required().label("Description"),
    courierCode: Joi.object({
      value: Joi.string().required().label("Value"),
      label: Joi.string().required().label("Label"),
    })
      .required()
      .label("Courier"),
    originCountryCode: Joi.object({
      value: Joi.string().required().label("Value"),
      label: Joi.string().required().label("Label"),
    })
      .required()
      .label("Origin Country"),
    geographyCode: Joi.object({
      value: Joi.string().required().label("Value"),
      label: Joi.string().required().label("Label"),
    })
      .required()
      .label("Geography"),
    billingGroup: Joi.object({
      value: Joi.number().required().label("Value"),
      label: Joi.string().required().label("Label"),
    }).label("Billing Group"),
    members: Joi.array()
      .min(1)
      .required()
      .items(
        Joi.object({
          value: Joi.string().alphanum().required().label("Value"),
          label: Joi.string().required().label("Label"),
        })
      )
      .label("Destination(s)"),
  };

  searchFormParam = ["courier", "country", "geography"];
  newFormParam = [
    "description",
    "courierCode",
    "originCountryCode",
    "geographyCode",
    "members",
  ];

  async componentDidMount() {
    try {
      const responses = await Promise.all([
        courierService.getAllCouriers(),
        organizationService.fetchBillingGroups(),
      ]);

      const courierList = util.transformToReactSelectModel(responses[0]);
      const billingGroupList = responses[1];

      this.setState({
        courierList,
        billingGroupList,
      });
    } catch (ex) {}
  }

  doSubmit = async () => {
    try {
      this.setState({ formSubmitted: true, loadingTableRecords: true });
      let shippingZoneList = [];

      if (this.state.data.geography.value === "") {
        shippingZoneList =
          await shippingZoneService.getInternationalShippingZones(
              EXPORT_CLASS,
              this.state.data.courier.value,
              this.state.data.country.value
          );
      } else {
        shippingZoneList =
          await shippingZoneService.getInternationalShippingZonesByGeo(
              EXPORT_CLASS,
              this.state.data.courier.value,
              this.state.data.country.value,
              this.state.data.geography.value
          );
      }

      this.setState({
        shippingZoneList,
        formSubmitted: false,
        loadingTableRecords: false,
      });
    } catch (ex) {
      //   toast.success(
      //     <div className="bg-success text-center p-5">Error Ni O</div>
      //   );
      this.setState({ formSubmitted: false, loadingTableRecords: false });
    }
  };

  render() {
    let recordViewComponent;
    if (this.state.loadingTableRecords) {
      recordViewComponent = showLoadingRecordsMessage();
    } else if (this.state.couldNotLoadTableRecords) {
      recordViewComponent = showCouldNotLoadRecordsMessage();
    } else {
      recordViewComponent =
        this.state.shippingZoneList.length > 0
          ? this.loadTable()
          : showNoRecordsMessage();
    }

    return (
      <>
        {this.newShippingZoneModal()}
        {this.shippingZoneDetailModal()}
        {this.deleteShippingZoneModal()}
        <SimpleHeader
            name="Export Shipping Zone Setup"
            parentName="Tables"
            description="Group countries into zones."
            toggleModal={() => {
              this.resetStateShippingZone();
              this.toggleModal("newShippingZoneModal");
            }}
            newItemButtonLabel="New Zone"
        />
        <Container className="mt--6" fluid>
          <form
              onSubmit={(e) =>
                  this.handleSubmit({e, formItemNames: this.searchFormParam})
              }
          >
            <Row>
              <Col md={3} sm={12}>
                {this.renderSelect2({
                  label: "Courier",
                  mandatory: true,
                  name: "courier",
                  placeholder: "Select Courier",
                  icon: "ni ni-world-2",
                  options: this.state.courierList,
                })}
              </Col>
              <Col md={3} sm={12}>
                {this.renderSelect2({
                  label: "Origin Country",
                  mandatory: true,
                  name: "country",
                  placeholder: "Select Country",
                  icon: "fas fa-flag",
                  options: [
                    {
                      value: "NG",
                      label: "Nigeria",
                    },
                    ...COUNTRY_LIST,
                  ],
                })}
              </Col>
              <Col md={3} sm={12}>
                {this.renderSelect2({
                  label: "Geography",
                  mandatory: true,
                  name: "geography",
                  placeholder: "Select Geography",
                  icon: "fas fa-flag",
                  options: GEOGRAPHY_LIST,
                })}
              </Col>
              <Col
                className="col-lg-2 align-self-center pt-4"
                xs={12}
                sm={3}
                md={2}
              >
                {this.renderButton(
                  "Search",
                  "btn-primary",
                  "Search",
                  this.searchFormParam
                )}
              </Col>
            </Row>
          </form>
          <br />
          <Row>
            <div className="col">{recordViewComponent}</div>
          </Row>
        </Container>
      </>
    );
  }

  updateActiveShippingZone(shippingZone, isUpdateRequest) {
    if (isUpdateRequest) {
      this.updateTableStateRecords(shippingZone.id, shippingZone);
      this.setState({
        formSubmitted: false,
        currentShippingZone: shippingZone,
      });
    } else {
      let { shippingZoneList } = this.state;
      shippingZone.index = shippingZoneList.length + 1;
      shippingZoneList = [...shippingZoneList, shippingZone];
      this.setState({
        formSubmitted: false,
        currentShippingZone: shippingZone,
        shippingZoneList,
      });
    }
  }

  resetStateShippingZone() {
    const { data } = this.state;
    data.description = "";
    data.courierCode = [];
    data.originCountryCode = [];
    data.geographyCode = [];
    data.members = [];

    this.setState({
      data,
      membersList: [],
      currentShippingZone: {},
      formSubmitted: false,
    });
  }

  copyShippingZoneToState(source) {
    const { data } = this.state;
    data.name = source.name;
    data.useValuesAsSuccessful = source.valuesRepresentSuccess;
    data.signalsAction = {
      value: source.signalsAbsentOnListAction,
      label: source.signalsAbsentOnListAction,
    };

    this.setState({ data, formSubmitted: false, errors: {} });
  }

  updateTableStateRecords(currentShippingZoneId, updateData) {
    let { shippingZoneList } = this.state;
    shippingZoneList = shippingZoneList.map((org) => {
      if (org.id === currentShippingZoneId) {
        updateData.index = org.index;
        Object.assign(org, updateData);
      }
      return org;
    });

    this.setState({ shippingZoneList });
  }

  deleteShippingZoneFromTable(shippingZoneId) {
    let { shippingZoneList } = this.state;
    shippingZoneList = shippingZoneList.filter(
      (shippingZone) => shippingZone.id !== shippingZoneId
    );
    this.setState({ shippingZoneList });
  }

  async deleteShippingZone() {
    try {
      this.setState({ deleteShippingZoneInProgress: true });
      const shippingZoneId = this.state.currentShippingZone.id;
      const response = await shippingZoneService.deleteShippingZone(
        parseInt(shippingZoneId)
      );
      toast.success(response.message);
      this.deleteShippingZoneFromTable(shippingZoneId);
      this.setState({
        deleteShippingZoneInProgress: false,
        deleteShippingZoneModal: false,
      });
    } catch (ex) {
      this.setState({ deleteShippingZoneInProgress: false });
    }
  }

  async loadDetails() {
    try {
      this.setState({ loadingDetailInProgress: true });
      const { currentShippingZone } = this.state;
      const response =
        await shippingZoneService.getInternationalShippingZonesDetail(
          currentShippingZone.id,
          currentShippingZone.geography
        );
      currentShippingZone.members = response;
      this.setState({
        currentShippingZone,
        loadingDetailInProgress: false,
      });
    } catch (ex) {
      this.setState({ loadingDetailInProgress: false });
    }
  }

  loadTable() {
    return (
      <Card>
        <ToolkitProvider
          data={this.state.shippingZoneList}
          keyField="index"
          columns={[
            {
              dataField: "index",
              text: "#",
              sort: true,
            },
            {
              dataField: "description",
              text: "Description",
              sort: true,
            },
            {
              dataField: "geography",
              text: "Geography",
              sort: true,
            },
            {
              dataField: "billingGroupName",
              text: "Billing Group",
              sort: true,
            },
            {
              dataField: "menu",
              text: "",
              sort: false,
              formatter: (cell, row) =>
                this.loadTableContextMenu(this.toggleModal, row),
            },
          ]}
          search
        >
          {(props) => (
            <div className="py-4 table-responsive">
              <TableActionButtons {...props}>
                <ReactToPrint
                  trigger={() => (
                    <Button
                      color="primary"
                      size="sm"
                      className="buttons-copy buttons-html5"
                      id="print-tooltip"
                    >
                      Print
                    </Button>
                  )}
                  content={() => this.componentRef}
                />
              </TableActionButtons>
              <BootstrapTable
                size="sm"
                ref={(el) => (this.componentRef = el)}
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                id="react-bs-table"
                striped
                hover
                condensed
                rowClasses="reflection-pointer"
                rowEvents={{
                  onClick: (e, row, rowIndex) => {
                    // this.setState({
                    //   currentShippingZone: row,
                    //   showShippingZoneDetailSetup: true,
                    // });
                  },
                }}
                // alert(signalsAvailableForEvent);
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    );
  }

  loadTableContextMenu(toggleModal, row) {
    return (
      <UncontrolledDropdown>
        <DropdownToggle
          className="btn-icon-only text-light h-25"
          color=""
          role="button"
          size="sm"
        >
          <i className="fas fa-ellipsis-v" />
        </DropdownToggle>
        <DropdownMenu
          className="dropdown-menu-arrow pt-0 overflow-hidden "
          right
        >
          <div className="px-3 py-2 mb-1 bg-primary">
            <h6 className="text-sm text-white m-0">{row.description}</h6>
          </div>
          <DropdownItem
            onClick={() => {
              this.copyShippingZoneToState(row);
              this.setState({ currentShippingZone: row }, () => {
                this.loadDetails();
                this.toggleModal("shippingZoneDetailModal");
              });
            }}
            className="text-default"
          >
            <i className="fas fa-eye" /> View Detail
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem
            onClick={() => {
              this.props.showRates(row);
            }}
            className="text-default"
          >
            <i className="fas fa-dollar-sign text-danger" /> Shipping Rates
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem
            onClick={() => {
              this.setState({ currentShippingZone: row });
              this.toggleModal("deleteShippingZoneModal");
            }}
            className="text-default"
          >
            <i className="fas fa-trash-alt text-danger" /> Delete
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }

  newShippingZoneModal = () => {
    const { currentShippingZone } = this.state;
    return (
      <Modal
        className="modal-dialog-centered"
        contentClassName=""
        isOpen={this.state.newShippingZoneModal}
        toggle={() => this.toggleModal("newShippingZoneModal")}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <div className="modal-header bg-primary">
          <h6
              className="modal-title text-secondary"
              id="modal-title-notification"
          >
            New Export Zone Configuration
          </h6>
          <button
              aria-label="Close"
              className="close text-secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("newShippingZoneModal")}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <form
          onSubmit={(e) => {
            this.handleSubmit({
              e,
              formItemNames: this.newFormParam,
              submitFunction: () => this.submitZoneRequest(),
            });
          }}
        >
          <div className="modal-body bg-secondary">
            <Row>
              <Col>
                {this.renderStyledInput({
                  label: "Description",
                  mandatory: true,
                  type: "text",
                  name: "description",
                  placeholder: "Description",
                  icon: "ni ni-circle-08",
                })}
              </Col>
            </Row>
            <Row>
              <Col>
                {this.renderSelect2({
                  label: "Courier",
                  mandatory: true,
                  name: "courierCode",
                  placeholder: "Select Courier",
                  icon: "ni ni-world-2",
                  options: this.state.courierList,
                  classes: "mb-2",
                })}
              </Col>
              <Col>
                {this.renderSelect2({
                  label: "Billing Group",
                  mandatory: false,
                  name: "billingGroup",
                  placeholder: "Select Billing Group",
                  icon: "ni ni-world-2",
                  options: this.state.billingGroupList,
                  classes: "mb-2",
                })}
              </Col>
            </Row>
            <Row>
              <Col>
                {this.renderSelect2({
                  label: "Origin Country",
                  mandatory: true,
                  name: "originCountryCode",
                  placeholder: "Select Country",
                  icon: "fas fa-flag",
                  options: [
                    {
                      value: "NG",
                      label: "Nigeria",
                    },
                    ...COUNTRY_LIST,
                  ],
                  classes: "mb-2",
                })}
              </Col>
              <Col>
                {this.renderSelect2({
                  label: "Geography",
                  mandatory: true,
                  name: "geographyCode",
                  placeholder: "Select Geography",
                  icon: "fas fa-flag",
                  options: GEOGRAPHY_LIST,
                  classes: "mb-2",
                  onchange: async (selectedGeography) => {
                    try {
                      const { fieldIsProcessing } = this.state;
                      fieldIsProcessing.members = true;
                      this.setState({
                        fieldIsProcessing,
                      });
                      let membersList = null;

                      const selectedValue =
                        selectedGeography.value.toLowerCase();
                      if (selectedValue === "country") {
                        membersList = COUNTRY_LIST;
                      } else if (selectedValue === "region") {
                        membersList = await geographyService.fetchRegions();
                      } else if (selectedValue === "continent") {
                        membersList = await geographyService.fetchContinents();
                      }

                      const { fieldIsProcessing: fieldIsProcessingUpdate } =
                        this.state;
                      fieldIsProcessingUpdate.members = false;
                      this.setState(
                        {
                          membersList,
                          fieldIsProcessing: fieldIsProcessingUpdate,
                        },
                        () => {
                          const { data } = this.state;
                          data.members = [];
                          this.setState({
                            data,
                          });
                        }
                      );
                    } catch (ex) {
                      const { fieldIsProcessing } = this.state;
                      fieldIsProcessing.members = false;
                      this.setState({
                        fieldIsProcessing,
                      });
                    }
                  },
                })}
              </Col>
            </Row>
            <Row>
              <Col>
                {this.renderSelect2({
                  label: "Destination(s)",
                  mandatory: true,
                  name: "members",
                  placeholder: "Select Destination(s)",
                  icon: "fas fa-flag",
                  options: this.state.membersList,
                  classes: "mb-2",
                  isMulti: true,
                })}
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            {this.renderButton(
              `${currentShippingZone.id ? "Update" : "Create"} Zone Config`,
              "btn-primary",
              `${currentShippingZone.id ? "Updating..." : "Creating..."}`,
              this.newFormParam
            )}
            <Button
              className="ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("newShippingZoneModal")}
            >
              Close
            </Button>
          </div>
        </form>
      </Modal>
    );
  };

  deleteShippingZoneModal() {
    return (
      <Modal
        className="modal-dialog-centered"
        isOpen={this.state.deleteShippingZoneModal}
        toggle={() => this.toggleModal("deleteShippingZoneModal")}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-notification">
            Delete Confirmation
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("deleteShippingZoneModal")}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body bg-danger text-white">
          <div className="py-3 text-center">
            <i className="fas fa-exclamation-triangle ni-3x" />
            <h4 className="heading mt-4 text-white">
              Are you sure you want to delete the Shipping Zone below? Doing so
              can not be undone.
            </h4>
            <br />
            <div>
              <div className="text-center text-underline display-5">
                Description
              </div>
              <div className="text-center display-4">
                {this.state.currentShippingZone.description}
              </div>
            </div>
            <div>
              <div className="text-center text-underline display-5">
                Geography
              </div>
              <div className="text-center display-4">
                {this.state.currentShippingZone.geography}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            color="danger"
            type="button"
            onClick={() => {
              this.deleteShippingZone();
            }}
            disabled={this.state.deleteShippingZoneInProgress}
          >
            <span
              className={
                this.state.deleteShippingZoneInProgress ? "" : "d-none"
              }
            >
              <Spinner color="" type="border" size="sm"></Spinner> Deleting...
            </span>
            <span
              className={
                this.state.deleteShippingZoneInProgress ? "d-none" : ""
              }
            >
              Delete Shipping Zone
            </span>
          </Button>
          <Button
            className="btn-secondary ml-auto"
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("deleteShippingZoneModal")}
          >
            Close
          </Button>
        </div>
      </Modal>
    );
  }

  shippingZoneDetailModal = () => {
    const { currentShippingZone, loadingDetailInProgress } = this.state;

    return (
      <Modal
        className="modal-dialog-centered"
        contentClassName=""
        isOpen={this.state.shippingZoneDetailModal}
        toggle={() => this.toggleModal("shippingZoneDetailModal")}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <div className="modal-header bg-primary">
          <h6
              className="modal-title text-secondary"
              id="modal-title-notification"
          >
            New Export Zone Configuration
          </h6>
          <button
              aria-label="Close"
              className="close text-secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("shippingZoneDetailModal")}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <div className="modal-body bg-secondary">
          {loadingDetailInProgress ? (
            showLoadingRecordsMessage()
          ) : (
            <>
              <div>
                <div className="text-center text-underline display-5">
                  Description
                </div>
                <div className="text-center display-4">
                  {currentShippingZone.description}
                </div>
              </div>
              <div>
                <div className="text-center text-underline display-5">
                  Geography
                </div>
                <div className="text-center display-4">
                  {currentShippingZone.geography}
                </div>
              </div>
              <div>
                <div className="text-center text-underline display-5">
                  Destination(s)
                </div>
                <div className="text-center display-4">
                  {currentShippingZone.members &&
                    currentShippingZone.members.map((sz) => sz.name + ", ")}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="modal-footer">
          {this.renderButton(
            `${currentShippingZone.id ? "Update" : "Create"} Zone Config`,
            "btn-primary",
            `${currentShippingZone.id ? "Updating..." : "Creating..."}`,
            this.newFormParam
          )}
          <Button
            className="ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("shippingZoneDetailModal")}
          >
            Close
          </Button>
        </div>
      </Modal>
    );
  };

  submitZoneRequest = async () => {
    this.setState({ formSubmitted: true });
    let { currentShippingZone, data } = this.state;
    try {
      const shippingZone = {};
      shippingZone.description = data.description;
      shippingZone.courierCode = data.courierCode.value;
      shippingZone.originCountryCode = data.originCountryCode.value;
      shippingZone.geographyCode = data.geographyCode.value;
      shippingZone.members = data.members;
      shippingZone.billingGroup = data.billingGroup.value;

      if (currentShippingZone.id) {
        shippingZone.id = currentShippingZone.id;
        const response =
          await shippingZoneService.updateInternationalShippingZone(
            shippingZone
          );
        toast.success(response.message);
        // use the value,label format before updating
        // shippingZone.courierCode = data.courierCode;
        // shippingZone.originCountryCode = data.originCountryCode;
        // shippingZone.geographyCode = data.geographyCode;
        // this.updateActiveShippingZone(shippingZone, true);
        const { data } = this.state;
        data.description = "";
        data.members = [];
        this.setState({
          data,
          currentShippingZone: {},
          formSubmitted: false,
        });
      } else {
        const response =
          await shippingZoneService.createInternationalShippingZone(
              EXPORT_CLASS,
              shippingZone
          );
        shippingZone.id = response.data.id;
        shippingZone.courierCode = data.courierCode;
        shippingZone.originCountryCode = data.originCountryCode;
        shippingZone.geography = data.geographyCode.value;
        shippingZone.billingGroup = data.billingGroup.value;
        this.updateActiveShippingZone(shippingZone, false);
        this.resetModal();
        // const { data } = this.state;
        // data.description = "";
        // data.members = [];
        // this.setState({
        //   data,
        //   currentShippingZone: {},
        //   formSubmitted: false,
        // });
        toast.success(response.message);
      }
    } catch (ex) {
      this.setState({ formSubmitted: false });
    }
  };

  resetModal() {
    const { data } = this.state;
    data.description = "";
    data.members = [];
    this.setState({
      data,
      currentShippingZone: {},
      formSubmitted: false,
    });
  }
}

export default ExportInternationalShippingZone;
