import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import { Col, Collapse, Container, Navbar, NavbarBrand, Row } from "reactstrap";

function BrandedNavbar(props) {
  const [collapseOpen, toggleCollapse] = React.useState(false);
  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById("dark-navbar-main"));
    // initialise
    headroom.init();
  });
  let navbarType = "",
    logoColor = "colour";
  switch (props.type) {
    case "dark":
      navbarType = "bg-default navbar-dark";
      break;
    case "transparent":
      navbarType = "navbar-transparent";
      break;
    case "primary":
      navbarType = "bg-primary navbar-dark";
      logoColor = "white";
      break;
    case "white":
      navbarType = "bg-white border-bottom";
      break;
    case "info":
      navbarType = "bg-info";
      break;
    case "secondary":
      navbarType = "bg-secondary border-bottom";
      break;
    case "branded":
      navbarType = "bg-white border-bottom";
      break;
    default:
      navbarType = "bg-default navbar-dark";
      break;
  }

  return (
    <>
      <Navbar
        className={
          props.border +
          " wirepackage-navbar-padding navbar-main headroom " +
          navbarType
        }
        expand="lg"
        id="dark-navbar-main"
      >
        <Container>
          <div className="h3 font-weight-bolder ml-3 d-block d-lg-none">
            {props.logoURL && props.logoURL !== "" && (
              <img
                alt={props.titleMessage}
                src={props.logoURL}
                style={{ height: "50px" }}
              ></img>
            )}
            {props.titleMessage}
            {/* <span className="font-weight-light">track</span> */}
          </div>

          {/* <NavbarBrand
            className="mr-lg-5 d-none d-lg-block"
            tag={Link}
            to={props.logoURL}
          >
            {props.logoURL && props.logoURL !== "" && (
              <img
                alt={props.titleMessage}
                src={props.logoURL}
                style={{ height: "50px" }}
              ></img>
            )}
          </NavbarBrand> */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => toggleCollapse(!collapseOpen)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <Collapse
            id="navbar_global"
            navbar
            toggler="#navbar_global"
            isOpen={collapseOpen}
          >
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  {/* {props.logoURL && props.logoURL !== "" && (
                    <img alt={props.titleMessage} src={props.logoURL}></img>
                  )} */}
                </Col>
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    onClick={() => toggleCollapse(!collapseOpen)}
                  >
                    <span></span>
                    <span></span>
                  </button>
                </Col>
              </Row>
            </div>
            <div className="text-center w-100 h3 font-weight-bolder">
              {props.logoURL && props.logoURL !== "" && (
                <img
                  alt={props.titleMessage}
                  src={props.logoURL}
                  style={{ height: "50px" }}
                ></img>
              )}{" "}
              {props.titleMessage}{" "}
              <span className="font-weight-light">track</span>
            </div>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

function loadHomeButton() {
  return (
    <h6 className="text-primary font-weight-bold mb-0">
      <a
        className="text-primary"
        href="https://www.facebook.com/wirepackage"
        target="_blank"
      >
        <i className="fab fa-facebook-square pr-2"></i>
      </a>{" "}
      <a
        className="text-primary"
        href="https://www.instagram.com/wirepackage"
        target="_blank"
      >
        <i className="fab fa-instagram pr-2"></i>
      </a>{" "}
      <i className="fab fa-twitter pr-2"></i>{" "}
      <i className="fab fa-linkedin pr-2"></i>{" "}
    </h6>
  );
}

BrandedNavbar.defaultProps = {
  type: "dark",
  isIndexPage: false,
  titleMessage: "",
  urlPath: "",
  logoURL: "",
};

BrandedNavbar.propTypes = {
  type: PropTypes.oneOf([
    "dark",
    "transparent",
    "primary",
    "white",
    "secondary",
    "branded",
  ]),
};

export default BrandedNavbar;
