import React from "react";

const Checkbox = ({
  name,
  label,
  error,
  iconClass,
  className,
  checked = false,
  mandatory,
  fieldIsProcessing,
  info,
  infoIcon,
  ...rest
}) => {
  return (
    <div className={`form-group ${className}`}>
      <div className="custom-control custom-control-alternative custom-checkbox">
        <input
          // autoFocus
          {...rest}
          id={name}
          name={name}
          className={`custom-control-input ${error ? "is-invalid" : ""}`}
          checked={checked}
        />
        <label className="custom-control-label" htmlFor={name}>
          <span className="">
            {mandatory && <span className="text-danger">*</span>} {label}
          </span>
          {iconClass ? <i className={iconClass} /> : ""} {fieldIsProcessing}
        </label>
        {info && <i class={infoIcon}>{info}</i>}
        {error ? (
          <div className="text-danger invalid-feedback">{error}</div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Checkbox;
