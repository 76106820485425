import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Col,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import * as Scroll from "react-scroll";
import auth from "services/authService";
import Button from "reactstrap/lib/Button";

function DemoNavbar(props) {
  const [collapseOpen, toggleCollapse] = React.useState(false);
  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById("dark-navbar-main"));
    // initialise
    headroom.init();
  });
  let navbarType = "",
    logoColor = "colour";
  switch (props.type) {
    case "dark":
      navbarType = "bg-default navbar-dark";
      break;
    case "transparent":
      navbarType = "navbar-transparent";
      break;
    case "primary":
      navbarType = "bg-primary navbar-dark";
      logoColor = "white";
      break;
    case "white":
      navbarType = "bg-white border-bottom";
      break;
    case "info":
      navbarType = "bg-info";
      break;
    case "secondary":
      navbarType = "bg-secondary border-bottom";
      break;
    case "branded":
      navbarType = "bg-white border-bottom";
      break;
    default:
      navbarType = "bg-default navbar-dark";
      break;
  }

  return (
    <>
      <Navbar
        className={
          props.border +
          " wirepackage-navbar-padding navbar-main headroom " +
          navbarType
        }
        expand="lg"
        id="dark-navbar-main"
      >
        <Container>
          <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
            <img
              alt="..."
              src={require("assets/img/brand/wirepackage-" +
                logoColor +
                ".png")}
            ></img>
          </NavbarBrand>
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => toggleCollapse(!collapseOpen)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <Collapse
            id="navbar_global"
            navbar
            toggler="#navbar_global"
            isOpen={collapseOpen}
          >
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="/index">
                    <img
                      alt="..."
                      src={require("assets/img/brand/wirepackage-" +
                        logoColor +
                        ".png")}
                    ></img>
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    onClick={() => toggleCollapse(!collapseOpen)}
                  >
                    <span></span>
                    <span></span>
                  </button>
                </Col>
              </Row>
            </div>
            <Nav
              className="navbar-nav-hover align-items-lg-center ml-lg-auto"
              navbar
            >
              <UncontrolledDropdown nav>
                <DropdownToggle nav>
                  <i className="ni ni-collection d-lg-none mr-1" />
                  <span className="nav-link-inner--text wirepackage-nav-link">
                    Developers
                  </span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>
                    <div className="d-none d-md-block">
                      <div className="font-weight-bolder">
                        API for All your shipping needs.
                      </div>
                      <div>Check Rates | Generate Labels | Track Shipments</div>
                    </div>
                    <div className="d-md-none">
                      <div className="font-weight-bold">
                        API for All your shipping needs.
                      </div>
                      <div>Check Rates | Generate Labels</div>
                      <div className="mb-1">Track Shipments</div>
                    </div>
                    <br />
                    <a
                      href="https://documenter.getpostman.com/view/2406333/2s93z9a2Un"
                      target="_blank"
                      className=""
                    >
                      Explore API Docs
                    </a>
                  </DropdownItem>
                  {/* <DropdownItem to="/branded-notification" tag={Link}>
                    Branded Notification
                  </DropdownItem> */}
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle nav>
                  <i className="ni ni-collection d-lg-none mr-1" />
                  <span className="nav-link-inner--text wirepackage-nav-link">
                    Products
                  </span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem to="/branded-tracking" tag={Link}>
                    Branded Tracking
                  </DropdownItem>
                  {/* <DropdownItem to="/branded-notification" tag={Link}>
                    Branded Notification
                  </DropdownItem> */}
                </DropdownMenu>
              </UncontrolledDropdown>

              {window.location.pathname === "/"
                ? loadLandingPageMenuItems()
                : loadOtherPagesMenuItems()}

              {/* <NavItem>
                <Link to="/rates" className="nav-link wirepackage-nav-link">
                  Check Rates
                </Link>
              </NavItem> */}
            </Nav>
          </Collapse>

          <div className="text-right">
            {props.isIndexPage && auth.getCurrentUser()
              ? loadHomeButton()
              : loadInitButtons()}
          </div>

          {/* <div className="text-right">
            {props.isIndexPage ? (
              auth.getCurrentUser() ? (
                loadHomeButton()
              ) : (
                loadInitButtons()
              )
            ) : props.titleMessage ? (
              <h4 className="text-primary p-0 m-0 mt-n1">
                {props.titleMessage}
              </h4>
            ) : (
              ""
            )}
          </div> */}
        </Container>
      </Navbar>
    </>
  );
}

function loadLandingPageMenuItems() {
  return (
    <>
      <NavItem>
        <Scroll.Link
          activeClass="active"
          to="whyChooseUs"
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
          className="nav-link wirepackage-nav-link"
        >
          Why Choose Us
        </Scroll.Link>
      </NavItem>
      {/* <NavItem>
        <Scroll.Link
          activeClass="active"
          to="testimonials"
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
          className="nav-link wirepackage-nav-link"
        >
          Testimonials
        </Scroll.Link>
      </NavItem> */}
      {/* <NavItem>
    <Scroll.Link
      activeClass="active"
      to="plansSection"
      spy={true}
      smooth={true}
      offset={0}
      duration={500}
      className="nav-link wirepackage-nav-link"
    >
      Plans
    </Scroll.Link>
  </NavItem> */}
      <NavItem>
        <Scroll.Link
          activeClass="active"
          to="faq"
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
          className="nav-link wirepackage-nav-link"
        >
          FAQ
        </Scroll.Link>
      </NavItem>

      <NavItem>
        <Scroll.Link
          activeClass="active"
          to="contact"
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
          className="nav-link wirepackage-nav-link"
        >
          Contact
        </Scroll.Link>
      </NavItem>
    </>
  );
}

function loadOtherPagesMenuItems() {
  return (
    <>
      <NavItem>
        <Link to="/#whyChooseUs" className="nav-link wirepackage-nav-link">
          Why Choose Us
        </Link>
      </NavItem>
      {/* <NavItem>
        <Link to="/#testimonials" className="nav-link wirepackage-nav-link">
          Testimonials
        </Link>
      </NavItem> */}
      <NavItem>
        <Link to="/#faq" className="nav-link wirepackage-nav-link">
          FAQ
        </Link>
      </NavItem>
      <NavItem>
        <Link to="/#contact" className="nav-link wirepackage-nav-link">
          Contact
        </Link>
      </NavItem>
    </>
  );
}

function loadInitButtons() {
  return (
    <>
      <div className="d-none d-sm-block">
        <Link to="/signin">
          <Button className="btn-icon mr-3" outline color="success">
            <span className="btn-inner--text text-capitalize">
              <strong>Sign In</strong>
            </span>
          </Button>
        </Link>
        <Link to="/register">
          <Button className="btn-icon" color="primary">
            <span className="btn-inner--text text-capitalize">
              <strong>Sign Up</strong>
            </span>
          </Button>
        </Link>
      </div>

      <div className="d-block d-sm-none">
        <Link to="/signin">
          <Button className="btn-icon" color="success">
            <span className="btn-inner--text text-capitalize">
              <strong>Sign In</strong>
            </span>
          </Button>
        </Link>
      </div>
    </>
  );
}

function loadHomeButton() {
  return (
    <>
      {/* <Button
        type="button"
        className="btn-icon mr-2 mb-3 mb-sm-0"
        color="primary"
        onClick={(e) => {
          e.preventDefault();
          window.location.replace(auth.resolveLandingPageUrl());
        }}
      >
        <span className="btn-inner--text text-capitalize">
          <strong>Dashboard</strong>
        </span>
      </Button> */}
      <Link to="/logout">
        <Button className="btn-icon mb-3 mb-sm-0" outline color="danger">
          <span className="btn-inner--text text-capitalize">
            <strong>Sign out</strong>
          </span>
        </Button>
      </Link>
    </>
  );
}

DemoNavbar.defaultProps = {
  type: "dark",
  isIndexPage: false,
  urlPath: "",
  logoUrl: "",
  businessName: "",
  brandColor: "",
  products: [],
};

DemoNavbar.propTypes = {
  type: PropTypes.oneOf([
    "dark",
    "transparent",
    "primary",
    "white",
    "secondary",
  ]),
};

export default DemoNavbar;
