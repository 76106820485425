import React from "react";
import PropTypes from "prop-types";

/**
 * @usage
 * <TimlineItem time={time} text={text} />
 */
function TimlineItem({ time, text, icon }) {
  return (
    <li>
      <i className="fa" />
      <div className="time-line-item">
        <span className="time">
          <i className="fa fa-clock-o"/>
        </span>
        <div className="time-line-header">
          <div className="font-weight-bold mb-1">{text}</div>
          <span className="text-muted font-weight-bold text-lowercase">
            {time}
          </span>
        </div>
      </div>
    </li>
  );
}

TimlineItem.defaultProps = {};

TimlineItem.propTypes = {
  time: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default TimlineItem;
