import React, { useEffect, useState } from "react";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { Alert, Button, Col, Container, Row } from "reactstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import utils from "util/CustomUtil";
import userService from "services/userService";
import { showLoadingRecordsMessage } from "config/config";

export default function Referrals() {
  const [referralLink, setReferralLink] = useState();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      setIsLoading(true);
      const refdata = await userService.fetchReferralStats();
      setData(refdata);
      setReferralLink(
        "http://wirepackage.com/register?ref=" + refdata.referralCode
      );
      setIsLoading(false);
    } catch (ex) {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <SimpleHeader
        name="Referral Program"
        parentName="Tables"
        color="wirepackage-text-success"
      />
      <Container className="mt--6" fluid>
        <Alert className="wirepackage-bg-success">
          <div className="display-4 mb-2">
            Give &#8358;1,000 &nbsp;and&nbsp; Get &#8358;1,000
          </div>
          <h3 className="text-white font-weight-bold">
            Everyone you refer gets &#8358;1,000 in credit over 30 days. You
            will also get &#8358;1,000 once they’ve shipped with us. There is no
            limit to the amount of credit you can earn through referrals.
          </h3>
        </Alert>
        {isLoading
          ? showLoadingRecordsMessage("Loading...")
          : loadDetails(data, referralLink)}
      </Container>
    </div>
  );
}

function loadDetails(data, referralLink) {
  if (!data) return;

  return (
    <>
      <Alert className="border shadow wirepackage-bg-success-light">
        <Row>
          <Col className="col-12 col-sm-12 col-md-6">
            <h2 className="wirepackage-text-table">Share your link</h2>
            <p className="wirepackage-text-table font-weight-normal">
              Copy your personal referral link and share it with your friends
              and followers.
            </p>
          </Col>
          <Col className="col-xs-12 col-sm-12 col-md-6 ">
            <div className="font-weight-bold text-uppercase mt-3 wirepackage-text-table">
              <u>Referral Link</u>
            </div>
            <div className="text-primary">
              <p className="wirepackage-text-table font-weight-normal">
                {referralLink}
                &nbsp; &nbsp;&nbsp;
                <CopyToClipboard
                  text={referralLink}
                  onCopy={() =>
                    toast.success("Referral link copied.", {
                      toastId: "refMsg",
                    })
                  }
                >
                  <Button
                    outline
                    color="primary"
                    type="button"
                    // onClick={() => this.toggleModal("regenerateTokenModal")}
                    size="sm"
                  >
                    Copy
                  </Button>
                </CopyToClipboard>
              </p>
            </div>
          </Col>
        </Row>
      </Alert>
      <Alert className="border shadow wirepackage-bg-success-light">
        <h2 className="wirepackage-text-table">Referral stats</h2>
        <div className="table-responsive">
          <table className="table table-striped table-success ">
            <tbody>
              <tr>
                <th>
                  <p className="font-weight-bold m-0 p-0">Clicks</p>
                </th>
                <td>
                  <p className="font-weight-normal m-0 p-0">
                    Number of times your link has been clicked.
                  </p>
                </td>
                <td>
                  <p className="font-weight-normal m-0 p-0">
                    {data.clickCount}
                  </p>
                </td>
              </tr>
              <tr>
                <th>
                  <p className="font-weight-bold m-0 p-0">Referrals</p>
                </th>
                <td>
                  <p className="font-weight-normal m-0 p-0">
                    People who have signed up using your link.
                  </p>
                </td>
                <td>
                  <p className="m-0 p-0 font-weight-normal ">
                    {data.countSignedUp}
                  </p>
                </td>
              </tr>
              <tr>
                <th>
                  <p className="font-weight-bold m-0 p-0">Pending</p>
                </th>
                <td>
                  <p className="font-weight-normal m-0 p-0">
                    Amount you stand to earn once your referrals have shipped
                    with us.
                  </p>
                </td>
                <td>
                  <p className="font-weight-normal m-0 p-0">
                    &#8358;{" "}
                    {utils.numberFormatter(Number(data.pendingAmount), 2)}
                  </p>
                </td>
              </tr>
              <tr>
                <th>
                  <p className="font-weight-normal font-weight-bold m-0 p-0">
                    Paid
                  </p>
                </th>
                <td>
                  <p className="font-weight-normal m-0 p-0">
                    Amount that has already been applied to your account.
                  </p>
                </td>
                <td>
                  <p className="font-weight-normal m-0 p-0">
                    &#8358; {utils.numberFormatter(Number(data.paidAmount), 2)}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Alert>
    </>
  );
}
