import React from "react";
import ValidationForm from "../../../../validation/form";
import ExportInternationalShippingZone from "./ExportInternationalShippingZone";
import InternationalRates from "./InternationalRates";

class Index extends ValidationForm {
  state = {
    showRatesSetup: false,
    currentZone: {},
    keyToForceExportRatesToRefresh: "k-0",
  };

  render() {
    let currentView;
    if (this.state.showRatesSetup) {
      currentView = (
        <InternationalRates
          key={this.state.keyToForceExportRatesToRefresh}
          goBackAction={() =>
            this.setState({
              showRatesSetup: false,
            })
          }
          forceRefresh={() =>
            this.setState({
              keyToForceExportRatesToRefresh: new Date().getMilliseconds(),
            })
          }
          currentZone={this.state.currentZone}
          billingGroup={this.state.billingGroup}
        />
      );
    } else {
      currentView = (
        <ExportInternationalShippingZone
          showRates={(currentZone, billingGroup) =>
            this.setState({ showRatesSetup: true, currentZone, billingGroup })
          }
        />
      );
    }

    return currentView;
  }
}

export default Index;
