import React from "react";

const TextArea = ({
  name,
  label,
  error,
  mandatory,
  fieldIsProcessing,
  ...rest
}) => {
  return (
    <div className="form-group">
      <label className="form-control-label" htmlFor={name}>
        {mandatory && <span className="text-danger">*</span>} {label}{" "}
        {fieldIsProcessing}
      </label>
      <textarea
        // autoFocus
        {...rest}
        id={name}
        name={name}
        className={`form-control ${error ? "is-invalid" : ""}`}
      />
      {error ? <div className="text-danger invalid-feedback">{error}</div> : ""}
    </div>
  );
};

export default TextArea;
