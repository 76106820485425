import React, { Component } from "react";
import {
  Card,
  CardBody,
  Container,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
// import ValidationForm from "validation/form";
import Profile from "./Profile";
import PasswordChange from "./PasswordChange";
import Row from "reactstrap/lib/Row";
import { GLOBAL_PROPERTY } from "constants/constants";
import userService from "services/userService";

class Index extends Component {
  state = {
    tab: "tab-profile",
    userProfile: {},
  };

  async componentDidMount() {
    let profile = {};
    if (GLOBAL_PROPERTY.userProfile) {
      this.setState({ userProfile: GLOBAL_PROPERTY.userProfile });
    } else {
      profile = await userService.fetchUserProfile();
      this.setState({ userProfile: profile });
    }
  }

  updateFullName(fullName) {
    const profile = this.state.userProfile;
    profile.fullName = fullName;
    this.setState({ userProfile: profile });
  }

  render() {
    let tab = this.state.tab;

    return (
      <div>
        <SimpleHeader
          name="Settings"
          parentName="Tables"
          // description="View and update settings."
        />
        <Container className="mt--6" fluid>
          <div>
            <h1>{this.state.userProfile.fullName}</h1>
            <h3 className="font-weight-normal">
              Member since: {this.state.userProfile.dateCreated}
            </h3>
          </div>
          <Row>
            <div className="col">
              <div className="nav-wrapper py-2 bg-white">
                <Nav className="nav-fill flex-column flex-md-row bg-white" tabs>
                  <NavItem className="bg-white">
                    <NavLink
                      className={
                        "mb-sm-3 mb-md-0 " +
                        (tab === "tab-profile" ? "active" : "")
                      }
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ tab: "tab-profile" });
                      }}
                    >
                      Profile
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={
                        "mb-sm-3 mb-md-0 " +
                        (tab === "tab-password" ? "active" : "")
                      }
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ tab: "tab-password" });
                      }}
                    >
                      Change Password
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
              <Card className="shadow min-vh-50">
                <CardBody className="min-vh-50 reflection-bg-secondary">
                  <TabContent id="myTabContent" activeTab={tab}>
                    <TabPane tabId="tab-profile" role="tabpanel">
                      {tab === "tab-profile" && (
                        <Profile
                          userProfile={this.state.userProfile}
                          updateFullName={(fullName) =>
                            this.updateFullName(fullName)
                          }
                        />
                      )}
                    </TabPane>
                    <TabPane tabId="tab-password" role="tabpanel">
                      {tab === "tab-password" && <PasswordChange />}
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Index;
