import axios from "axios";
import {toast} from "react-toastify";

// Strategy for refreshing tokens
// https://github.com/axios/axios/issues/2315
const instance = axios.create();
delete instance.defaults.headers.common["Authorization"];

instance.interceptors.response.use(null, (error) => {
  // const expectedError =
  //   error.response &&
  //   error.response.status >= 400 &&
  //   error.response.status < 500;

  // if (!expectedError) {
  //   toast.error("Unexpected Error ");
  // }

  if (error.response) {
  } else if (error.request) {
    // client never received a response, or request never left
    toast.error(
        "Connectivity Error! The server can't be reached. Please check your connection.",
        {
          toastId: "httpService",
        }
    );
  } else {
    // anything else
    toast.error(
      "An error occured processing your last request! Please try again.",
      {
        toastId: "httpService",
      }
    );
  }

  return Promise.reject(error);
});

export default {
  get: instance.get,
};
