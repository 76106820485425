import httpService from "services/httpService";

const baseApiEndPoint = process.env.REACT_APP_API_ENDPOINT;

const apiEndPoint = baseApiEndPoint + "/bank_account";

export async function fetchInflowAccount(hideErrorMessage) {
  const { data } = await httpService.get(
    apiEndPoint + "/inflow?hideError=" + hideErrorMessage
  );
  return data;
}

export async function fetchBankList() {
  const { data } = await httpService.get(apiEndPoint + "/banks");
  return data;
}

export async function fetchBvn() {
  const { data } = await httpService.get(apiEndPoint + "/bvn");
  return data;
}

export async function fetchLinkedBankAccounts() {
  const { data } = await httpService.get(apiEndPoint + "/linked");
  return data;
}

export async function fetchAccountName(bankCode, accountNumber) {
  const { data } = await httpService.get(
    apiEndPoint +
      "/name_enquiry?bankCode=" +
      bankCode +
      "&accountNumber=" +
      accountNumber
  );
  return data;
}

export async function verifyBvn(bvn) {
  const { data } = await httpService.get(
    apiEndPoint + "/bvn/verification?bvn=" + bvn
  );
  return data;
}

export async function verifyOtp(ref, otp, verificationType) {
  const { data } = await httpService.post(apiEndPoint + "/otp/verification", {
    ref,
    otp,
    verificationType,
  });
  return data;
}

export async function resendOtp(ref) {
  const { data } = await httpService.post(apiEndPoint + "/otp/resend", ref);
  return data;
}

export async function addBankAccount(ref) {
  const { data } = await httpService.post(apiEndPoint + "/add", ref);
  return data;
}

export async function generateOtpToAddAccount(ref) {
  const { data } = await httpService.get(
    apiEndPoint + "/add/generate_otp?ref=" + ref
  );
  return data;
}

export default {
  fetchInflowAccount,
  fetchBankList,
  fetchLinkedBankAccounts,
  fetchAccountName,
  verifyBvn,
  verifyOtp,
  resendOtp,
  generateOtpToAddAccount,
  addBankAccount,
};
