import httpService from "services/httpService";

const baseApiEndPoint = process.env.REACT_APP_API_ENDPOINT;

const apiEndPoint = baseApiEndPoint;

export async function fetchDangerousGoodsList() {
  const { data } = await httpService.get(
    apiEndPoint + "/config/dangerous_goods"
  );
  return data;
}

export default {
  fetchDangerousGoodsList,
};
