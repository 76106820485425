export const BRANDED_TRACKING_NUMBER =
  process.env.NODE_ENV === "production" ? "1000020120" : "1000025538";

export const ACCESS_TOKEN = "token";

export const OAUTH2_REDIRECT_URI =
  process.env.REACT_APP_PORTAL_ENDPOINT + "/oauth2/redirect";

export const GOOGLE_AUTH_URL =
  process.env.REACT_APP_API_ENDPOINT +
  "/oauth2/authorize/google?redirect_uri=" +
  OAUTH2_REDIRECT_URI;

export const GITHUB_AUTH_URL =
  process.env.REACT_APP_API_ENDPOINT +
  "/oauth2/authorize/github?redirect_uri=" +
  OAUTH2_REDIRECT_URI;

export const FACEBOOK_AUTH_URL =
  process.env.REACT_APP_API_ENDPOINT +
  "/oauth2/authorize/facebook?redirect_uri=" +
  OAUTH2_REDIRECT_URI;

export const LOGIN_VIEW = "view";

export const LOGIN_VIEW_DASHBOARD = "dashboard";

export const LOGIN_VIEW_ADMIN = "admin";

export const LOGIN_DASHBOARD_URL = "/base/home";

export const LOGIN_ADMIN_URL = "/admin/home";

export const PRODUCTS_SETUP_URL = "/base/products";

export const REFERRAL_CODE = "ref_code";
