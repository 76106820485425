import React from "react";
import { Card, CardBody, Alert } from "reactstrap";
import Container from "reactstrap/lib/Container";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ValidationForm from "../../../validation/form";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import Joi from "joi-browser";
import ButtonGroup from "reactstrap/lib/ButtonGroup";
import Button from "reactstrap/lib/Button";
import {
  showLoadingRecordsMessage,
  showNoRecordsMessage,
  toastOptions,
} from "config/config";

import CardTitle from "reactstrap/lib/CardTitle";
import { GLOBAL_PROPERTY } from "constants/constants";
import walletService from "services/walletService";
import bankAccountService from "services/bankAccountService";
import utils from "util/CustomUtil";
import { toast } from "react-toastify";
import Spinner from "reactstrap/lib/Spinner";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import TableActionButtons from "../admin/TableActionButtons";
import ReactToPrint from "react-to-print";
import BootstrapTable from "react-bootstrap-table-next";
import { pagination } from "config/config";
import Modal from "reactstrap/lib/Modal";

const FORM_START = 1;
const FORM_CONFIRM_PAYMENT = 2;
const FORM_BANK_TRANSFER = 3;

class Withdrawal extends ValidationForm {
  state = {
    data: {},
    errors: {},
    formSubmitted: false,
    isLoading: false,
    isLoadingBankAccounts: false,
    paymentStage: FORM_START,
    selectedAccount: { id: -1 },
    wallet: {},
    accountList: [],
    enterAmountModal: false,
    confirmWithdrawalModal: false,
  };

  activeButton = "";
  initializePayment = null;

  schema = {
    withdrawalAmount: Joi.number()
      .min(100)
      .required()
      .label("Withdrawal Amount"),
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  componentDidMount() {
    this.fetchBalances();
    this.fetchBankAccounts();
  }

  async fetchBalances() {
    try {
      this.setState({ isLoading: true });
      const wallet = await walletService.fetchWallet();
      this.setState({ isLoading: false, wallet });
    } catch (ex) {
      this.setState({ isLoading: false });
    }
  }

  async fetchBankAccounts() {
    try {
      this.setState({ isLoadingBankAccounts: true });
      const accountList = await walletService.fetchPayoutAccounts();
      this.setState({ accountList, isLoadingBankAccounts: false });
    } catch (ex) {
      this.setState({ isLoadingBankAccounts: false });
    }
  }

  render() {
    let formComponent = <></>;
    if (this.state.isLoading) {
      return showLoadingRecordsMessage("Loading... Please wait.");
    } else if (this.state.paymentStage === FORM_START) {
      formComponent = this.loadTable();
    } else if (this.state.paymentStage === FORM_CONFIRM_PAYMENT) {
      formComponent = this.confirmCredit();
    } else if (this.state.paymentStage === FORM_BANK_TRANSFER) {
      formComponent = this.confirmWithdrawalModal();
    }
    return (
      <>
        {this.enterAmountModal()}
        {this.confirmWithdrawalModal()}
        <SimpleHeader name="Withdrawal" parentName="Tables" />
        <Container className="mt--6" fluid>
          {this.state.isLoading
            ? showLoadingRecordsMessage("Loading Balance. Please wait...")
            : formComponent}
        </Container>
      </>
    );
  }

  start() {
    return (
      <form
        role="form"
        onSubmit={(e) => {
          this.handleSubmit({
            e,
            submitFunction: this.doChoosePaymentOptionForm,
          });
        }}
      >
        <div className="mb-4">
          <Row>
            <Col className="offset-md-3 col-md-6 text-center text-xl">
              How much do you want to withdraw?
            </Col>
          </Row>
        </div>
        <Row>
          <Col className="offset-md-4 col-md-4">
            <Card className="bg-gradient-primary">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle className="text-uppercase text-muted mb-0 text-white">
                      Cash <br className="d-none d-md-block d-lg-none" />
                      Balance
                    </CardTitle>
                    {this.loadBalance(
                      this.state.wallet,
                      "cashBalance",
                      "white"
                    )}
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                      <i className="ni ni-money-coins" />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col className="offset-md-4 col-md-4">
            {this.renderStyledInput({
              mandatory: true,
              type: "text",
              name: "withdrawalAmount",
              placeholder: "Enter withdrawal amount",
              currency: <>&#8358;</>,
              classes: "text-lg text-right",
              // label: "Credit Amount",
            })}
            <div className="px-2 text-lg">
              Transaction Fee:
              <b className="float-right">
                &#8358; {GLOBAL_PROPERTY.transactionFee}
              </b>
            </div>
          </Col>
        </Row>
        <Row className="mb-3 mt-4">
          <Col className="offset-md-4 col-md-4">
            {this.renderButton(
              <>
                <div>Continue</div>
              </>,
              "btn-primary btn-block",
              "Creating..."
            )}
          </Col>
        </Row>
      </form>
    );
  }

  confirmCredit() {
    let accountsComponent = [];
    this.state.accountList.map((account) => {
      accountsComponent[accountsComponent.length] = (
        <Button
          key={`act-${accountsComponent.length}`}
          className="mb-3"
          color={
            this.state.selectedAccount.id === account.id ? "primary" : "white"
          }
          onClick={() => this.setState({ selectedAccount: account })}
          active={this.state.selectedAccount.id === account.id}
        >
          <div className="text-left">
            <div className="border-bottom border-light">
              <Row>
                <Col className="text-left col-10">
                  <h2
                    className={`mb-0 ${
                      this.state.selectedAccount.id === account.id &&
                      "text-white"
                    }`}
                  >
                    <span>{account.bankName}</span>
                  </h2>
                </Col>
              </Row>
            </div>
            <h3>
              <span
                className={`text-muted ${
                  this.state.selectedAccount.id === account.id && "text-white"
                }`}
              >
                {account.accountName}
                <span className="float-right">{account.accountNumber}</span>
              </span>
            </h3>
          </div>
        </Button>
      );
    });

    return (
      <>
        <div className="mb-3">
          <Row>
            <Col className="offset-md-3 col-md-6 text-center text-xl">
              Which account should be credited?
            </Col>
          </Row>
        </div>

        {this.state.isLoadingBankAccounts ? (
          showLoadingRecordsMessage(
            "Loading your linked bank accounts. Please wait..."
          )
        ) : (
          <>
            <Row className="mb-3">
              <Col className="offset-md-3 col-md-6 mb-2">
                <div className="bg-light">
                  <ButtonGroup className="border rounded w-100" vertical>
                    {accountsComponent}
                  </ButtonGroup>
                </div>
              </Col>
            </Row>

            <Row className="mb-4 mt--2">
              <Col className="offset-md-3 col-md-6">
                <Button
                  type="text"
                  color="primary"
                  size="md"
                  block
                  outline
                  onClick={() => this.setState({ paymentStage: FORM_START })}
                >
                  ADD NEW ACCOUNT
                </Button>
              </Col>
            </Row>
          </>
        )}
        <Row className="mb-3">
          <Col className="offset-md-3 col-md-3">
            <Button
              type="text"
              color="primary"
              size="md"
              block
              onClick={() => this.setState({ paymentStage: FORM_START })}
            >
              Back
            </Button>
          </Col>
          <Col className="col-md-3">
            <Button
              type="text"
              color="primary"
              size="md"
              block
              onClick={() =>
                this.setState({ paymentStage: FORM_BANK_TRANSFER })
              }
              disabled={this.state.selectedAccount.id < 1}
            >
              Continue
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  confirmWithdrawalModal() {
    return (
      <Modal
        className="modal-dialog-centered"
        contentClassName=""
        isOpen={this.state.confirmWithdrawalModal}
        toggle={() => this.toggleModal("confirmWithdrawalModal")}
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <div className="modal-header bg-primary">
          <h6
            className="modal-title text-secondary"
            id="modal-title-notification"
          >
            Confirm Withdrawal Detail
          </h6>
          <button
            aria-label="Close"
            className="close text-secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("confirmWithdrawalModal")}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body bg-secondary">
          <Row>
            <Col className="text-lg">
              <div className="bg-white">
                <Row className="mb-1 p-2 bg-default text-white">
                  <Col className="">Withdrawal Amount</Col>
                  <Col className="font-weight-bold">
                    &#8358;{" "}
                    {utils.numberFormatter(
                      Number(this.state.data.withdrawalAmount),
                      2
                    )}
                  </Col>
                </Row>
                <Row className="mb-1 p-2 bg-default text-white">
                  <Col className="">Withdrawal Fee</Col>
                  <Col className="font-weight-bold">
                    &#8358; {GLOBAL_PROPERTY.transactionFee}
                  </Col>
                </Row>
                <div className="border border-top-0">
                  <Row className="mb-1 p-2">
                    <Col className="col-4">A/C Number</Col>
                    <Col className="font-weight-bold">
                      {this.state.selectedAccount.accountNumber}
                    </Col>
                  </Row>
                  <Row className="mb-1 p-2">
                    <Col className="col-4">A/C Name</Col>
                    <Col className="font-weight-bold">
                      {this.state.selectedAccount.accountName}
                    </Col>
                  </Row>
                  <Row className="mb-1 p-2 ">
                    <Col className="col-4">Bank</Col>
                    <Col className="font-weight-bold">
                      {this.state.selectedAccount.bankName}
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Button
                outline
                type="text"
                color="primary"
                size="md"
                block
                onClick={() => this.makeWithdrawal()}
                disabled={
                  this.state.selectedAccount.id < 1 || this.state.formSubmitted
                }
              >
                <span className={this.state.formSubmitted ? "" : "d-none"}>
                  <Spinner type="border" size="sm"></Spinner> Processing...
                </span>
                <span className={this.state.formSubmitted ? "d-none" : ""}>
                  Process Withdrawal
                </span>
              </Button>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            type="button"
            onClick={() => {
              this.toggleModal("confirmWithdrawalModal");
              this.toggleModal("enterAmountModal");
            }}
          >
            Back
          </Button>
          <Button
            className="ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("confirmWithdrawalModal")}
          >
            Close
          </Button>
        </div>
      </Modal>
    );
  }

  loadBalance(wallet, property, color) {
    return (
      <span className={`h1 font-weight-bold mb-0 text-${color}`}>
        {wallet ? (
          <>&#8358; {utils.numberFormatter(Number(wallet[property]), 2)}</>
        ) : (
          color === "primary" && (
            <Button
              type="text"
              color={color}
              size="sm"
              onClick={(e) => {
                e.preventDefault();
                this.fetchBalances();
              }}
              className="text-left"
            >
              retry
            </Button>
          )
        )}
      </span>
    );
  }

  loadTable() {
    if (this.state.wallet.cashBalance <= 0) {
      return showNoRecordsMessage(
        "You do not have any funds to withdraw. Your wallet balance is 0."
      );
    }

    return (
      <>
        <Alert color="default">
          <Row>
            <div className="col col-sm-4">
              <CardTitle className="text-uppercase text-muted mb-0 text-white">
                Cash <br className="d-none d-md-block d-lg-none" />
                Balance
              </CardTitle>
              {this.loadBalance(this.state.wallet, "cashBalance", "white")}
            </div>
            <Col className="col-auto col-sm-8">
              <h4 className="alert-heading">
                You can only withdraw funds into accounts that fund your wallet.
              </h4>
              <div className="alert-body">
                select account you want to withdraw into.
              </div>
            </Col>
          </Row>
        </Alert>
        <Card>
          <ToolkitProvider
            data={this.state.accountList}
            keyField="index"
            columns={[
              {
                dataField: "index",
                text: "#",
                sort: true,
              },
              {
                dataField: "bankName",
                text: "Bank",
                sort: true,
              },
              {
                dataField: "accountNumber",
                text: "Account Number",
                sort: true,
              },
              {
                dataField: "accountName",
                text: "Account Name",
                sort: true,
              },
              {
                dataField: "withdrawableAmount",
                text: <>Max. Receivable Amount (&#8358;)</>,
                sort: true,
                formatter: (cell, row) => {
                  return (
                    <span className="font-weight-bold text-default">
                      {row.withdrawableAmount}
                    </span>
                  );
                },
              },
            ]}
            search
          >
            {(props) => (
              <div className="py-4 table-responsive">
                <TableActionButtons {...props}>
                  <ReactToPrint
                    trigger={() => (
                      <Button
                        color="primary"
                        size="sm"
                        className="buttons-copy buttons-html5"
                        id="print-tooltip"
                      >
                        Print
                      </Button>
                    )}
                    content={() => this.componentRef}
                  />
                </TableActionButtons>
                <BootstrapTable
                  size="sm"
                  condensed
                  hover
                  ref={(el) => (this.componentRef = el)}
                  {...props.baseProps}
                  bootstrap4={true}
                  pagination={pagination}
                  bordered={false}
                  id="react-bs-table"
                  rowClasses="reflection-pointer"
                  rowEvents={{
                    onClick: (e, row, rowIndex) => {
                      this.setState({
                        selectedAccount: row,
                        enterAmountModal: true,
                      });
                    },
                  }}
                />
              </div>
            )}
          </ToolkitProvider>
        </Card>
      </>
    );
  }

  enterAmountModal = () => {
    const { selectedAccount } = this.state.selectedAccount;
    return (
      <Modal
        className="modal-dialog-centered"
        contentClassName=""
        isOpen={this.state.enterAmountModal}
        toggle={() => this.toggleModal("enterAmountModal")}
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <div className="modal-header bg-primary">
          <h6
            className="modal-title text-secondary"
            id="modal-title-notification"
          >
            Enter Withdrawal Amount
          </h6>
          <button
            aria-label="Close"
            className="close text-secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("enterAmountModal")}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <form
          onSubmit={(e) => {
            this.handleSubmit({
              e,
              formItemNames: this.enterAmountFormParam,
              submitFunction: () => {
                this.toggleModal("enterAmountModal");
                this.toggleModal("confirmWithdrawalModal");
              },
            });
          }}
        >
          <div className="modal-body bg-secondary">
            <div className="mb-4">
              <Row>
                <Col className="text-center text-xl">
                  How much do you want to withdraw?
                </Col>
              </Row>
            </div>
            <Row>
              <Col>
                {this.renderStyledInput({
                  mandatory: true,
                  type: "text",
                  name: "withdrawalAmount",
                  placeholder: "Enter withdrawal amount",
                  currency: <>&#8358;</>,
                  classes: "text-lg text-right",
                  // label: "Credit Amount",
                })}
                <div className="px-2 text-lg">
                  Transaction Fee:
                  <b className="float-right">
                    &#8358; {GLOBAL_PROPERTY.transactionFee}
                  </b>
                </div>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Card className="bg-gradient-primary">
                  <CardBody>
                    <Row>
                      <Col>
                        <div className="h2 font-weight-bold text-white">
                          {this.state.selectedAccount.bankName}
                        </div>
                      </Col>
                      <Col>
                        <div className="h3 font-weight-bold text-white text-right">
                          {this.state.selectedAccount.accountNumber}
                        </div>
                      </Col>
                    </Row>
                    <hr className="m-0 p-0" />
                    <Row>
                      <Col>
                        <div className="h3 font-weight-bold text-white">
                          {this.state.selectedAccount.accountName}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          <div className="modal-footer text-right">
            <Button
              className="float-left"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("enterAmountModal")}
            >
              Close
            </Button>

            {this.renderButton(
              "Continue",
              "btn-primary",
              "Continue",
              this.enterAmountFormParam
            )}
          </div>
        </form>
      </Modal>
    );
  };

  doChoosePaymentOptionForm = () => {
    this.setState({ paymentStage: FORM_CONFIRM_PAYMENT });
  };
  doBankTransferForm = () => {
    this.setState({ paymentStage: FORM_BANK_TRANSFER });
  };

  makeWithdrawal = async () => {
    try {
      this.setState({
        formSubmitted: true,
      });
      const response = await walletService.makeWithdrawal(
        this.state.data.withdrawalAmount,
        this.state.selectedAccount.id
      );
      toast.success(response.message, toastOptions);
      const cashBalance = response.data;
      const wallet = this.state.wallet;
      wallet.cashBalance = cashBalance;

      this.setState({
        enterAmountModal: false,
        confirmWithdrawalModal: false,
        formSubmitted: false,
      });
    } catch (e) {
      this.setState({
        formSubmitted: false,
      });
    }
  };
}

export default Withdrawal;
