import React, { Component } from "react";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ValidationForm from "../../../../validation/form";
import {
  Card,
  CardBody,
  Container,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Button,
} from "reactstrap";
import UserWallet from "./UserWallet";

export default class AccountManagement extends ValidationForm {
  state = {
    tab: "tab-wallet",
  };

  render() {
    let tab = this.state.tab;

    return (
      <div>
        <SimpleHeader name="User Account Management" parentName="Tables" />
        <Container className="mt--6" fluid>
          <div>
            <Button
              color="light"
              size="sm"
              onClick={() => this.props.controller.onReturnToUserList()}
            >
              <i class="fas fa-long-arrow-alt-left"></i> User List
            </Button>
            <h2 className="m-0 mt-2">
              {this.props.controller.selectedUser.firstname}
            </h2>
            <h3 className="font-weight-normal">
              Member since: {this.props.controller.selectedUser.dateCreated}
            </h3>
          </div>
          <Row>
            <div className="col">
              <div className="nav-wrapper py-2 bg-white">
                <Nav className="nav-fill flex-column flex-md-row bg-white" tabs>
                  <NavItem>
                    <NavLink
                      className={
                        "mb-sm-3 mb-md-0 " +
                        (tab === "tab-wallet" ? "active" : "")
                      }
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ tab: "tab-wallet" });
                      }}
                    >
                      Wallet
                    </NavLink>
                  </NavItem>
                  <NavItem className="bg-white">
                    <NavLink
                      className={
                        "mb-sm-3 mb-md-0 " +
                        (tab === "tab-profile" ? "active" : "")
                      }
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ tab: "tab-profile" });
                      }}
                    >
                      Profile
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
              <Card className="shadow min-vh-50">
                <CardBody className="min-vh-50 reflection-bg-secondary">
                  <TabContent id="myTabContent" activeTab={tab}>
                    <TabPane tabId="tab-profile" role="tabpanel">
                      {/* {tab === "tab-profile" && (
                        // <Profile
                        //   userProfile={this.props.selectedUser}
                        //   updateFullName={(fullName) =>
                        //     this.updateFullName(fullName)
                        //   }
                        // />
                      )} */}
                    </TabPane>
                    <TabPane tabId="tab-wallet" role="tabpanel">
                      {tab === "tab-wallet" && (
                        <UserWallet
                          selectedUser={this.props.controller.selectedUser}
                        />
                      )}
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}
