import React from "react";
import ValidationForm from "../../../validation/form";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import Joi from "joi-browser";
import Button from "reactstrap/lib/Button";
import { toast } from "react-toastify";
import { toastOptions } from "config/config";
import userService from "services/userService";
import utils from "util/CustomUtil";

class PasswordChange extends ValidationForm {
  state = {
    data: {},
    errors: {},
    formSubmitted: false,
  };

  schema = {
    currentPassword: Joi.string().min(8).required().label("Current Password"),
    newPassword: Joi.string().min(8).required().label("New Password"),
    newPasswordConfirmation: Joi.string()
      .min(8)
      .required()
      .label("Confirm New Password"),
  };

  doSubmit = async () => {
    const { currentPassword, newPassword, newPasswordConfirmation } =
      this.state.data;
    this.setState({
      formSubmitted: true,
    });

    try {
      if (!utils.passwordIsValid(newPassword)) {
        this.setState({
          errors: {
            newPassword:
              "Password must be a minimum of 8 characters long containing at least 1 uppercase, 1 lowercase, 1 special character and 1 number.",
          },
        });
        this.setState({
          formSubmitted: false,
        });
        return;
      }
      if (newPassword !== newPasswordConfirmation) {
        toast.error(
          "New Password and New Password Confirmation fields don't match"
        );
        this.setState({
          errors: {
            newPassword: "Does not match Confirmation field",
            newPasswordConfirmation: "Does not match New Password field",
          },
        });
        this.setState({
          formSubmitted: false,
        });
        return;
      }
      const response = await userService.changePassword(
        currentPassword,
        newPassword,
        newPasswordConfirmation
      );
      toast.success(response.message);
      this.setState({
        data: {
          currentPassword: "",
          newPassword: "",
          newPasswordConfirmation: "",
        },
      });
    } catch (ex) {}
    this.setState({
      formSubmitted: false,
    });
  };

  render() {
    return (
      <>
        <form
          role="form"
          onSubmit={(e) => {
            this.handleSubmit({
              e,
            });
          }}
        >
          <Row className="border-bottom">
            <Col className="col-12 col-md-4">
              <span className="font-weight-bold">Current Password</span>
            </Col>
            <Col className="col-12 col-md-4">
              {this.renderStyledInput({
                mandatory: true,
                type: "password",
                name: "currentPassword",
                placeholder: "Current Password",
                icon: "fas fa-lock",
              })}
            </Col>
          </Row>
          <Row className="border-bottom pt-3">
            <Col className="col-12 col-md-4">
              <span className="font-weight-bold">New Password</span>
            </Col>
            <Col className="col-12 col-md-4">
              {this.renderStyledInput({
                mandatory: true,
                type: "password",
                name: "newPassword",
                placeholder: "New Password",
                icon: "fas fa-lock",
              })}
            </Col>
          </Row>
          <Row className="border-bottom pt-3">
            <Col className="col-12 col-md-4">
              <span className="font-weight-bold">Confirm Password</span>
            </Col>
            <Col className="col-12 col-md-4">
              {this.renderStyledInput({
                mandatory: true,
                type: "password",
                name: "newPasswordConfirmation",
                placeholder: "Confirm New Password",
                icon: "fas fa-lock",
              })}
            </Col>
          </Row>
          <Row className="border-bottom pt-3">
            <Col className="col-12 col-md-4"></Col>
            <Col className="text-right col-12 col-md-4">
              {this.renderButton(
                "Update Password",
                "btn btn-primary btn-block",
                "Updating Password"
              )}
            </Col>
          </Row>
        </form>
      </>
    );
  }
}

export default PasswordChange;
