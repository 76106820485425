import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import Button from "reactstrap/lib/Button";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import Col from "reactstrap/lib/Col";
import ListGroupItem from "reactstrap/lib/ListGroupItem";
import Row from "reactstrap/lib/Row";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Alert from "reactstrap/lib/Alert";
import { showLoadingRecordsMessage } from "config/config";
import CardTitle from "reactstrap/lib/CardTitle";
import walletService from "services/walletService";
import utils from "util/CustomUtil";

export default function Home(props) {
  const [wallet, setWallet] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      setIsLoading(true);
      const wallet = await walletService.fetchWallet();
      setWallet(wallet);
      setIsLoading(false);
    } catch (ex) {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <SimpleHeader
        name="Welcome to WirePackage"
        parentName="Tables"
        // description="Welcome to WirePackage"
      />
      <Container className="mt--5" fluid>
        {isLoading
          ? showLoadingRecordsMessage("Loading Dashboard. Please wait...")
          : loadDashboardList(props, wallet, fetchData)}
      </Container>
    </div>
  );
}

function loadDashboardList(props, wallet, fetchData) {
  return (
    <>
      <Row>
        <div className="col-12">
          <div className="shadow">
            <Card className="bg-yellow">
              <CardBody className="border border-lighter rounded">
                <Row className="">
                  <Col>
                    <div className="">
                      <img
                        src="https://wp-ecommerce.fra1.digitaloceanspaces.com/testcloud/6-nUJYsDawfEXSNdY"
                        className="rounded rounded-lg border border-light float-center img-fluid "
                        alt=""
                        style={{
                          objectFit: "contain",
                        }}
                      />
                    </div>
                    <br />
                  </Col>
                  <Col className="col-12 col-md-8">
                    <h1>
                      Branded Tracking Page and Notifications{" "}
                      <span class="badge badge-white text-md text-danger border-danger">
                        <small>New</small>
                      </span>
                    </h1>
                    <p className="font-weight-normal text-justify">
                      If you own a business, you can now customize your tracking
                      page and notifications to fit your brand. It's time to
                      distinguish your brand and, boost customer retention and
                      revenue.
                    </p>
                    <div className="">
                      <a
                        className="font-weight-normal ml-1"
                        href="/branded-tracking"
                        target="_blank"
                      >
                        Click here to learn more...
                      </a>
                    </div>
                    <br /> <br />
                    <Button
                      size="sm"
                      color="primary"
                      type="button"
                      onClick={() => props.history.push("/base/business")}
                    >
                      Start Branded Customization
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </div>
      </Row>
      <Row>
        <div className="col-lg-4 offset-lg-2 col-md-5 offset-md-1 col-sm-6">
          <Card className="bg-gradient-white">
            <CardBody className="border border-light rounded">
              <Row>
                <div className="col">
                  <CardTitle className="text-uppercase mb-0 text-primary">
                    Wallet <br className="d-none d-md-block d-lg-none" />
                    Balance
                  </CardTitle>
                  {loadBalance(wallet, "cashBalance", fetchData, "primary")}
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                    <i className="ni ni-money-coins" />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
        <div className="col-lg-4 col-md-5 col-sm-6">
          <Card className="bg-gradient-white">
            <CardBody className="border border-light rounded">
              <Row>
                <div className="col">
                  <CardTitle className="text-uppercase mb-0 text-primary">
                    {wallet
                      ? wallet.organizationWallet
                        ? "Referral Bonus"
                        : "Cash Back Balance"
                      : " "}
                  </CardTitle>
                  {loadBalance(wallet, "cashBackBalance", fetchData, "primary")}
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                    <i className="fas fa-wallet" />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </Row>

      <Row>
        <div className="col-lg-4 offset-lg-2 col-md-5 offset-md-1 col-sm-6">
          <Button
            color="primary"
            type="button"
            size="lg"
            onClick={() => props.history.push("/base/shipment/book")}
            block
            className="mb-4"
          >
            <span className="text-lg">Book Shipment</span>
          </Button>
        </div>
        <div className="col-lg-4 col-md-5 col-sm-6">
          <Button
            color="primary"
            type="button"
            size="lg"
            onClick={() => props.history.push("/base/shipment/track")}
            block
            className="mb-4"
          >
            <span className="text-lg">Track Shipments</span>
          </Button>
        </div>
      </Row>
      <Row>
        <div className="col-lg-4 offset-lg-2 col-md-5 offset-md-1 col-sm-6">
          <Button
            color="primary"
            type="button"
            size="lg"
            onClick={() => props.history.push("/base/wallet/fund")}
            block
            className="mb-4"
          >
            <span className="text-lg">Fund Wallet</span>
          </Button>
        </div>
        <div className="col-lg-4 col-md-5 col-sm-6">
          <Button
            color="primary"
            type="button"
            size="lg"
            onClick={() => props.history.push("/base/referrals")}
            block
            className="mb-4"
          >
            <span className="text-lg">Referral Program</span>
          </Button>
        </div>
      </Row>

      <Row>
        <div className="col-lg-4 offset-lg-2 col-md-5 offset-md-1 col-sm-6"></div>
        <div className="col-lg-4 col-md-5 col-sm-6"></div>
      </Row>
    </>
  );
}

function loadBalance(wallet, property, fetchData, color) {
  const balance = wallet ? Number(wallet[property]) : 0;
  return (
    <span
      className={`h1 font-weight-bold mb-0 text-${
        balance >= 0 ? color : "danger"
      }`}
    >
      {wallet ? (
        <>&#8358; {utils.numberFormatter(balance, 2)}</>
      ) : (
        color === "primary" && (
          <Button
            type="text"
            color={color}
            size="sm"
            onClick={() => fetchData()}
            className="text-left"
          >
            retry
          </Button>
        )
      )}
    </span>
  );
}
function noDashboardsMessage() {
  return (
    <Alert color="default" className="text-center">
      <h4 className="alert-heading">No Configured Dashboards!</h4>
      <div className="alert-body">
        All your configured dashboards will show up here. Right now you have no
        configured dashboards.
      </div>
    </Alert>
  );
}

function loadTable(dashboardList, props) {
  const list = [];
  dashboardList.forEach((dashboard) => {
    list[list.length] = (
      <ListGroupItem className="px-0">
        <Row className="align-items-center">
          <div className="col ml--2">
            <a
              href="#pablo"
              className={`font-weight-bold text-${
                list.length % 2 === 0 ? "default" : "primary"
              }`}
              onClick={(e) => e.preventDefault()}
            >
              {dashboard.name}
            </a>
            <br />
            <span className="muted">{dashboard.reportCount} Reports</span>
          </div>
          <Col className="col-auto">
            <a
              role="Button"
              className={`btn btn-sm btn-${
                list.length % 2 === 0 ? "default" : "primary"
              }`}
              target="_blank"
              href=""
              onClick={(e) => {
                e.preventDefault();
                props.history.push(`/base/dashboard/${dashboard.id}`);
              }}
            >
              Launch
            </a>
          </Col>
        </Row>
      </ListGroupItem>
    );
  });
  return list;
}
