import React from "react";
// react component for creating dynamic tables
// reactstrap components
import { Button, Container, Modal, Row, Spinner } from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Joi from "joi-browser";
import ValidationForm from "../../../validation/form";
import { toast } from "react-toastify";
import businessService from "services/businessService";
// react plugin that prints a given react component
import {
  showCouldNotLoadRecordsMessage,
  showLoadingRecordsMessage,
} from "config/config";
import Col from "reactstrap/lib/Col";
import { NIGERIAN_STATES } from "constants/constants";
import { PRODUCTS_SETUP_URL } from "constants/index";
import Resizer from "react-image-file-resizer";
import authService from "services/authService";

class Business extends ValidationForm {
  state = {
    alert: null,
    newBusinessModal: false,
    businessDetailModal: false,
    currentBusiness: {},
    data: {},
    errors: {},
    formSubmitted: false,
    infoModal: false,
    fileProperties: {
      maxAllowedFileSize: 2097152,
      mimeTypes: ["image/gif", "image/jpg", "image/jpeg", "image/png"],
    },
    cacheFileName: Date.now().toString(),
    logoURL: null,
    defaultLogoURL: "https://cdn.wirepackage.com/production/default-logo.png",
    processingImage: false,
  };
  schema = {
    name: Joi.string().max(100).required().label("Name"),
    shortName: Joi.string()
      .min(2)
      .max(15)
      .required()
      // .regex(/^[a-z0-9]+$/i)
      // .error((errors) => {
      //   errors.forEach((err) => {
      //     switch (err.type) {
      //       case "any.empty":
      //         err.message = "Can not be empty";
      //         break;
      //       case "string.min":
      //         err.message = "Minimum of 5 characters required.";
      //         break;
      //       case "string.max":
      //         err.message = "Maximum of 25 characters required.";
      //         break;
      //       case "string.regex.base":
      //         err.message = "Alphanumeric characters (a-z, A-Z, 0-9) only.";
      //         break;
      //       case "any.required":
      //         err.message = '"Short Name" required.';
      //         break;
      //       default:
      //         err.message =
      //           "Minimum of 5 characters, Maximum of 25 characters: (a-z, A-Z, 0-9) only.";
      //         break;
      //     }
      //   });
      //   return errors;
      // })
      .label("Short Name"),
    contactPhoneNumber: Joi.string()
      .required()
      .label("Contact Phone Number")
      .regex(/^\+(?:[0-9]●?){6,14}[0-9]$|^[0]\d{10}$/g)
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = '"Contact Phone Number" should not be empty';
              break;
            default:
              err.message = '"Contact Phone Number" is invalid';
              break;
          }
        });
        return errors;
      }),
    whatsAppPhoneNumber: Joi.string()
      .allow("")
      .label("WhatsApp Phone Number")
      .regex(/^\+(?:[0-9]●?){6,14}[0-9]$|^[0]\d{10}$/g)
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = '"WhatsApp Phone Number" should not be empty';
              break;
            default:
              err.message = '"WhatsApp Phone Number" is invalid';
              break;
          }
        });
        return errors;
      }),
    contactEmail: Joi.string()
      .email({ tlds: { allow: true } })
      .max(50)
      .required()
      .label("Contact Email"),
    address: Joi.string().max(100).allow("").label("Address"),
    rcNumber: Joi.string().max(15).allow("").label("RC Number"),
    websiteURL: Joi.string().max(100).allow("").label("Website URL"),
    facebookPageURL: Joi.string().max(250).allow("").label("Facebook Page URL"),
    instagramPageURL: Joi.string()
      .max(250)
      .allow("")
      .label("Instagram Page URL"),
    twitterPageURL: Joi.string().max(250).allow("").label("Twitter Page URL"),
    linkedinPageURL: Joi.string().max(250).allow("").label("Linkedin Page URL"),
    notifyReceiver: Joi.boolean().label("Make Active"),
    businessState: Joi.object({
      value: Joi.string().required().label("Value"),
      label: Joi.string().required().label("Label"),
    }).label("State"),
    country: Joi.string().max(250).allow("").label("Country"),
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  async componentDidMount() {
    try {
      const search = this.props.location.search;
      if (search) {
        const ref = new URLSearchParams(search).get("t");
        if (ref && ref === "n") {
        } else if (
          authService.getCurrentOrganization() &&
          authService.getCurrentOrganization() > 2
        ) {
          const response = await businessService.fetchBusiness();
          const data = this.state.data;
          data.name = response.name;
          data.shortName = response.shortName;
          data.contactEmail = response.contactEmail;
          data.contactPhoneNumber = response.contactPhoneNumber;
          data.whatsAppPhoneNumber = response.whatsAppPhoneNumber;
          data.address = response.address;
          data.notifyReceiver = response.notifyReceiver;
          data.rcNumber = response.rcNumber;
          data.websiteURL = response.websiteURL;
          data.facebookPageURL = response.facebookPageURL;
          data.instagramPageURL = response.instagramPageURL;
          data.twitterPageURL = response.twitterPageURL;
          data.linkedinPageURL = response.linkedinPageURL;
          data.businessState = response.businessState;
          data.country = response.country;

          this.setState({
            data,
            currentBusiness: response,
            logoURL: response.logoURL,
          });
        }
      } else if (
        authService.getCurrentOrganization() &&
        authService.getCurrentOrganization() > 2
      ) {
        const response = await businessService.fetchBusiness();
        const data = this.state.data;
        data.name = response.name;
        data.shortName = response.shortName;
        data.contactEmail = response.contactEmail;
        data.contactPhoneNumber = response.contactPhoneNumber;
        data.whatsAppPhoneNumber = response.whatsAppPhoneNumber;
        data.address = response.address;
        data.notifyReceiver = response.notifyReceiver;
        data.rcNumber = response.rcNumber;
        data.websiteURL = response.websiteURL;
        data.facebookPageURL = response.facebookPageURL;
        data.instagramPageURL = response.instagramPageURL;
        data.twitterPageURL = response.twitterPageURL;
        data.linkedinPageURL = response.linkedinPageURL;
        data.businessState = response.businessStateLabelValue;
        data.country = response.country;

        this.setState({
          data,
          currentBusiness: response,
          logoURL: response.logoURL,
        });
      }
    } catch (ex) {
      this.setState({
        couldNotLoadTableRecords: true,
        loadingTableRecords: false,
      });
    }
  }

  doSubmit = async () => {
    this.setState({ formSubmitted: true });
    let { currentBusiness, data } = this.state;
    try {
      if (currentBusiness.id) {
        currentBusiness = {
          id: currentBusiness.id,
          ...data,
          cacheFileName: this.state.cacheFileName,
        };
        currentBusiness.country = "NG";
        if (data.businessState) {
          currentBusiness.businessState =
            currentBusiness.country + "_" + data.businessState.value;
        }
        const response = await businessService.update(currentBusiness);
        toast.success(response.message);
        this.setState({
          formSubmitted: false,
          currentBusiness,
        });
      } else {
        currentBusiness = { ...data, cacheFileName: this.state.cacheFileName };
        currentBusiness.country = "NG";
        if (data.businessState) {
          currentBusiness.businessState =
            currentBusiness.country + "_" + data.businessState.value;
        }
        const response = await businessService.createAndReload(currentBusiness);
        this.toggleModal("infoModal");
        this.setState({
          formSubmitted: false,
        });
      }
    } catch (ex) {
      this.setState({ formSubmitted: false });
    }
  };

  render() {
    let recordViewComponent;
    if (this.state.loadingTableRecords) {
      recordViewComponent = showLoadingRecordsMessage();
    } else if (this.state.couldNotLoadTableRecords) {
      recordViewComponent = showCouldNotLoadRecordsMessage();
    } else {
      recordViewComponent = this.newBusinessModal();
    }

    return (
      <>
        {this.infoModal()}
        <SimpleHeader
          name="Business Profile"
          parentName="Tables"
          description="Setting up a business profile is the first step in creating a branded experience."
        />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">{recordViewComponent}</div>
          </Row>
        </Container>
      </>
    );
  }

  newBusinessModal = () => {
    const currentBusiness = this.state.currentBusiness;

    return (
      <form
        onSubmit={(e) => {
          this.handleSubmit({
            e,
          });
        }}
      >
        <div className="rounded-lg border bg-white shadow-lg p-3">
          <div className="text-center mb-3">
            <div className="mb-2">
              <img
                src={
                  this.state.logoURL
                    ? this.state.logoURL
                    : this.state.defaultLogoURL
                }
                className="rounded float-center img-thumbnail img-fluid"
                alt="Business logo"
                style={{ maxHeight: 150 }}
              />
            </div>
            <label className="btn btn-primary btn-sm">
              <span className={this.state.processingImage ? "" : "d-none"}>
                <Spinner type="border" size="sm"></Spinner>
              </span>
              &nbsp; Upload Logo
              <input
                type="file"
                name="file"
                className="d-none"
                onChange={(event) => {
                  this.uploadLogoChangeHandler(
                    event,
                    this.state.currentBusiness.id || 0
                  );
                }}
              />
            </label>
          </div>
          <div className=" bg-lighter m--2 pt-4 px-4 pb-2 rounded">
            <div className="font-weight-bold text-decoration-underline mb-3">
              <u>Core Profile (mandatory)</u>
            </div>
            <Row>
              <Col className="col-12 col-sm-6">
                {this.renderStyledInput({
                  label: "Name",
                  mandatory: true,
                  type: "text",
                  name: "name",
                  placeholder: "Business Name",
                  icon: "ni ni-building",
                })}
              </Col>
              <Col className="col-12 col-sm-6">
                {this.renderStyledInput({
                  label: "Short Name (for your Tracking Page)",
                  mandatory: true,
                  type: "text",
                  name: "shortName",
                  placeholder: "Business Short Name",
                  icon: "ni ni-building",
                })}
              </Col>
            </Row>
            <Row>
              <Col className="col-12 col-sm-6">
                {this.renderStyledInput({
                  label: "Contact Phone Number",
                  mandatory: true,
                  type: "text",
                  name: "contactPhoneNumber",
                  placeholder: "Contact Phone Number",
                  icon: "fas fa-phone",
                })}
              </Col>
              <Col>
                {this.renderStyledInput({
                  label: "Contact Email",
                  mandatory: true,
                  type: "text",
                  name: "contactEmail",
                  placeholder: "Contact Email",
                  icon: "ni ni-email-83",
                })}
              </Col>
            </Row>
          </div>
          <hr />
          <div className="bg-lighter m--2 pt-4 px-4 pb-2  rounded">
            <div className="font-weight-bold text-decoration-underline mb-3">
              <u>Basic Profile (optional)</u>
            </div>
            <Row>
              <Col className="col-12 col-sm-6">
                {this.renderStyledInput({
                  label: "WhatsApp Phone Number",
                  mandatory: false,
                  type: "text",
                  name: "whatsAppPhoneNumber",
                  placeholder: "Optional",
                  icon: "fab fa-whatsapp",
                })}
              </Col>
              <Col>
                {this.renderStyledInput({
                  label: "Business Address",
                  mandatory: false,
                  type: "text",
                  name: "address",
                  placeholder: "Optional",
                  icon: "ni ni-pin-3",
                })}
              </Col>
            </Row>
            <Row>
              <Col className="col-12 col-sm-6">
                {this.renderSelect2({
                  label: "State",
                  mandatory: false,
                  name: "businessState",
                  options: NIGERIAN_STATES,
                  placeholder: "Select State (Optional)",
                  icon: "ni ni-world-2",
                  classes: "mb-3",
                })}
              </Col>
              <Col>
                {this.renderStyledInput({
                  label: "Country",
                  mandatory: false,
                  type: "text",
                  name: "country",
                  placeholder: "Nigeria",
                  icon: "fas fa-flag",
                  disabled: true,
                })}
                {/* {this.renderSelect2({
                  label: "Country",
                  mandatory: true,
                  name: "senderCountry",
                  options: COUNTRY_LIST,
                  placeholder: "Select Country",
                  icon: "fas fa-flag",
                })} */}
              </Col>
            </Row>
            <Row>
              <Col className="col-12 col-sm-6">
                {this.renderStyledInput({
                  label: "RC Number",
                  mandatory: false,
                  type: "text",
                  name: "rcNumber",
                  placeholder: "Optional",
                  icon: "fas fa-registered",
                })}
              </Col>
              <Col>
                {this.renderStyledInput({
                  label: "Website URL",
                  mandatory: false,
                  type: "text",
                  name: "websiteURL",
                  placeholder: "Optional",
                  icon: "fab fa-internet-explorer",
                })}
              </Col>
            </Row>
          </div>

          <hr />
          <div className="bg-lighter m--2 pt-4 px-4 pb-2  rounded">
            <div className="font-weight-bold text-decoration-underline mb-3">
              <u>Social Media Profile (optional)</u>
            </div>
            <Row>
              <Col className="col-12 col-sm-6">
                {this.renderStyledInput({
                  label: "Facebook Page URL",
                  mandatory: false,
                  type: "text",
                  name: "facebookPageURL",
                  placeholder: "Optional",
                  icon: "fab fa-facebook",
                })}
              </Col>
              <Col>
                {this.renderStyledInput({
                  label: "Instagram Page URL",
                  mandatory: false,
                  type: "text",
                  name: "instagramPageURL",
                  placeholder: "Optional",
                  icon: "fab fa-instagram",
                })}
              </Col>
            </Row>
            <Row>
              <Col className="col-12 col-sm-6">
                {this.renderStyledInput({
                  label: "Twitter Page URL",
                  mandatory: false,
                  type: "text",
                  name: "twitterPageURL",
                  placeholder: "Optional",
                  icon: "fab fa-twitter",
                })}
              </Col>
              <Col className="col-12 col-sm-6">
                {this.renderStyledInput({
                  label: "Linkedin Page URL",
                  mandatory: false,
                  type: "text",
                  name: "linkedinPageURL",
                  placeholder: "Optional",
                  icon: "fab fa-linkedin",
                })}
              </Col>
            </Row>
          </div>
          <hr />

          <Row>
            <Col>
              <div className=" bg-lighter m--2 pt-4 px-4 pb-2 rounded">
                <div className="font-weight-bold text-decoration-underline mb-3">
                  <u>Shipment Configuration</u>
                </div>

                {this.renderCheckbox({
                  mandatory: false,
                  name: "notifyReceiver",
                  label:
                    "Also Send Notification (SMS and Email) to Receiver After Shipment is Booked.",
                  checked: this.state.data.notifyReceiver,
                  disabled: false,
                })}
              </div>
            </Col>
          </Row>
          <hr />
          <div className="text-right">
            {this.renderButton(
              `${currentBusiness.id ? "Update" : "Create"} Business Profile`,
              "btn-primary",
              `${currentBusiness.id ? "Updating..." : "Creating..."}`
            )}
          </div>
        </div>
      </form>
    );
  };

  infoModal = () => {
    return (
      <Modal
        className="modal-dialog-centered"
        contentClassName="bg-secondary"
        isOpen={this.state.infoModal}
        toggle={() => {
          window.location = PRODUCTS_SETUP_URL;
          this.toggleModal("infoModal");
        }}
        backdrop="static"
        size="sm"
      >
        <div className="modal-header reflection-bg-secondary p-3">
          <h5
            className="modal-title text-default"
            id="modal-title-notification"
          >
            New Business Created
          </h5>
        </div>
        <div className="modal-body p-3 text-justify bg-success text-default">
          Your new business has been successfully created. <br />
          <br />
          Your sign in profile will be switched to your new business account.
          <br />
          <br />
          Business Name: <b>{this.state.data.name}</b>
        </div>
        <div className="modal-footer reflection-bg-secondary">
          <Button
            size="sm"
            className="ml-auto"
            color="success"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              window.location = PRODUCTS_SETUP_URL;
              this.toggleModal("infoModal");
            }}
          >
            I got it!
          </Button>
        </div>
      </Modal>
    );
  };

  async uploadLogoChangeHandler(event, businessId) {
    if (!event.target.files[0]) return;

    if (
      !this.isValidSize(event, this.state.fileProperties.maxAllowedFileSize)
    ) {
      toast.error(
        "Allowed file size (" +
          this.state.fileProperties.maxAllowedFileSize +
          " bytes) exceeded"
      );
      return;
    }

    if (!this.isValidMimeType(event, this.state.fileProperties.mimeTypes)) {
      toast.error("Upload file type is unsupported.");
      return;
    }

    this.setState({ processingImage: true });
    let file = event.target.files[0];

    try {
      let resizedFile = await this.resizeFile(
        file,
        "file",
        this.getImageType(file.type)
      );
      const formData = new FormData();
      formData.append("file", resizedFile);
      const data = await businessService.uploadLogo(
        this.state.cacheFileName,
        businessId,
        formData
      );

      let resizedImageUri = await this.resizeFile(
        file,
        "base64",
        this.getImageType(file.type)
      );
      this.setState({ logoURL: resizedImageUri });

      this.setState({ processingImage: false });
    } catch (err) {
      console.log(err);
    }
  }

  getImageType(fileType) {
    if (fileType.includes("jpg") || fileType.includes("jpeg")) {
      return "JPEG";
    } else if (fileType.includes("png")) {
      return "PNG";
    }
  }

  resizeFile = (file, outputType, fileType) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        150,
        150,
        fileType,
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        outputType
      );
    });

  isValidMimeType(event, acceptedTypes) {
    //getting file object
    let file = event.target.files[0];
    let isValid = false;
    // compare file type find doesn't matach
    acceptedTypes.map((type) => {
      if (file.type === type) {
        isValid = true;
      }
    });
    if (isValid) {
      return true;
    }
    event.target.value = null;
    return false;
  }

  isValidSize(event, size) {
    //getting file object
    const file = event.target.files[0];

    if (file && file.size <= size) return true;

    event.target.value = null;
    return false;
  }
}

export default Business;
