import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
// import { PaystackConsumer } from "react-paystack";
import {
  pagination,
  renderFormPanel,
  showLoadingRecordsMessage,
  toastOptions,
} from "config/config";
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import Container from "reactstrap/lib/Container";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ValidationForm from "../../../validation/form";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";

import Joi from "joi-browser";
import ButtonGroup from "reactstrap/lib/ButtonGroup";
import Button from "reactstrap/lib/Button";
import TableActionButtons from "../admin/TableActionButtons";
import Modal from "reactstrap/lib/Modal";
import {
  COUNTRY_LIST,
  GLOBAL_PROPERTY,
  NIGERIAN_STATES,
  ORIGIN_COUNTRIES,
} from "constants/constants";
import shipmentService from "services/shipmentService";
import locationService from "services/locationService";
import walletService from "services/walletService";
import systemConfigService from "services/systemConfigService";
import Alert from "reactstrap/lib/Alert";
import auth from "services/authService";
import utils from "util/CustomUtil";
import Spinner from "reactstrap/lib/Spinner";
import { toast } from "react-toastify";
import ReactDatetime from "react-datetime";
import FormGroup from "reactstrap/lib/FormGroup";
import { closePaymentModal, FlutterWaveButton } from "flutterwave-react-v3";
import CompleteBooking from "./bookshipment/CompleteBooking";
import ErrorBoundary from "ErrorBoundary";

const paystackConfig = {
  public_key: "FLWPUBK-85c92ca0e03220367ec65735f62eaee8-X",
};

const UK_INVOICE_AMOUNT_LIMIT = 150000;

// you can call this function anything
const handleClose = () => {
  // implementation for  whatever you want to do when the Paystack dialog closed.
};
const dateFormat = "YYYY-MM-DD";

class BookShipment extends ValidationForm {
  state = {
    data: {
      usePreferredPickupDate: false,
    },
    errors: {},
    shipment: null,
    formSubmitted: false,
    fieldIsProcessing: {},
    tab: "tab-0",
    packageType: "nonDocument",
    insuranceType: "Premium",
    payWithWalletModal: false,
    dropOffLocationListModal: false,
    dropOffLocationList: [
      {
        index: 1,
        state: "Lagos",
        city: "Surulere",
        address:
          "WirePackage Office, Number 9, Bode Thomas Street, Surulere, Lagos.",
      },
    ],
    itemList: [],
    packageWeight: 0,
    senderCountryList: [...ORIGIN_COUNTRIES],
    senderStateList: [],
    recipientCountryList: [
      {
        value: "NG",
        label: "Nigeria",
      },
      ...COUNTRY_LIST,
    ],
    recipientStateList: [],
    recipientCityList: [],
    itemCategoryList: [],
    nonFoodCategoryList: [],
    foodCategoryList: [],
    submitShipmentInProgress: false,
    fetchingWalletBalance: false,
    fetchedWalletBalance: false,
    fetchedWalletBalanceType: null,
    wallet: null,
    payWithWalletBalanceInProgress: false,
    paymentCompleted: false,
    preferredPickupDate: "",
    hidePreferredPickupDateField: true,
    showRecipientCity: false,
    senderCityList: [],
    showSenderCity: true,
    originAddressList: [],
    destinationAddressList: [],
    showDimensions: true,
    expressBoxList: [],
    displayExpressBoxes: false,
    selectedExpressBox: {},
    showGiftCode: false,
    showDiscountInfo: false,
    applyingGiftCode: false,
    discountInfo: {},
    totalPaymentAmount: null,
    moveToCompleteBooking: false,
    incompleteBookings: [],
    completeBookingShipmentId: 0,
    hideIncompleteBookingList: false,
    hidePackageDimension: true,
    packageList: [],
    currentPackageIndex: 0,
    shipmentWeight: 0,
    packageValue: 0,
    shipmentValue: 0,
    showAddItemsInstruction: false,
    currentItem: null,
    currentPackage: null,
    currentItemIndex: -1,
    hasFetchedDetail: false,
    importDutyInformationModal: false,
    checkedInvoiceValueLimit: false,
    totalInvoiceAmount: 0,
    addNewItemModal: false,
    itemCategoryTootltip: false,
    preferredCourier: null,
    courierRates: null,
    signatureServiceFee: null,
    shippingRateResponse: null,
    dangerousGoodsList: [],
    platformInformationList: [],
  };

  itemCounter = 0;
  packageCounter = 0;

  schema = {
    saveOriginAddress: Joi.boolean().label("Save Address"),
    originAddressList: Joi.object({
      value: Joi.string().required().label("Value"),
      label: Joi.string().required().label("Label"),
    })
      .required()
      .label("Origin Address List"),
    senderFullname: Joi.string().min(5).max(20).required().label("Fullname"),
    senderEmail: Joi.string()
      .email({ tlds: { allow: true } })
      .required()
      .label("Email"),
    senderPhoneNumber: Joi.string()
      .required()
      .label("Phone Number")
      .regex(/^\+(?:[0-9]●?){6,14}[0-9]$|^[0]\d{10}$/g)
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = '"Phone Number" should not be empty';
              break;
            default:
              err.message = '"Phone Number" is invalid';
              break;
          }
        });
        return errors;
      }),
    senderAddress: Joi.string().max(73).required().label("Address"),
    senderCountry: Joi.object({
      value: Joi.string().required().label("Value"),
      label: Joi.string().required().label("Label"),
    })
      .required()
      .label("Country"),
    senderState: Joi.object({
      value: Joi.string().required().label("Value"),
      label: Joi.string().required().label("Label"),
    })
      .required()
      .label("State"),
    senderCity: Joi.object({
      value: Joi.string().required().label("Value"),
      label: Joi.string().required().label("Label"),
    }).label("City"),
    senderPostalCode: Joi.string().required().label("Postal Code"),
    saveRecipientAddress: Joi.boolean().label("Save Address"),
    destinationAddressList: Joi.object({
      value: Joi.string().required().label("Value"),
      label: Joi.string().required().label("Label"),
    })
      .required()
      .label("Destination Address List"),
    recipientFullname: Joi.string().min(3).max(35).required().label("Fullname"),
    recipientEmail: Joi.string()
      .required()
      .email({ tlds: { allow: true } })
      .label("Email"),
    recipientPhoneNumber: Joi.string()
      .required()
      .label("Phone Number")
      .regex(/^\+(?:[(]|[0-9]|[)]●?){6,20}[0-9]$|^[0]\d{9,11}$/g)
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = '"Phone Number" should not be empty';
              break;
            default:
              err.message = '"Phone Number" is invalid';
              break;
          }
        });
        return errors;
      }),
    recipientAddress: Joi.string().max(90).required().label("Address"),
    recipientCountry: Joi.object({
      value: Joi.string().required().label("Value"),
      label: Joi.string().required().label("Label"),
    })
      .required()
      .label("Country"),
    recipientState: Joi.object({
      value: Joi.string().required().label("Value"),
      label: Joi.string().required().label("Label"),
    })
      .required()
      .label("State"),
    recipientCity: Joi.object({
      value: Joi.string().required().label("Value"),
      label: Joi.string().required().label("Label"),
    }).label("City"),
    recipientPostalCode: Joi.string().max(10).required().label("Postal Code"),
    itemCategory: Joi.object({
      value: Joi.number().min(1).required().label("Value"),
      label: Joi.string().required().label("Label"),
    })
      .required()
      .label("Item Category"),
    itemDescription: Joi.string().max(105).required().label("Item Description"),
    itemQuantity: Joi.number()
      .integer()
      .min(1)
      .required()
      .label("Item Quantity"),
    usePreferredPickupDate: Joi.boolean()
      .required()
      .label("Preferred Pickup Date"),
    itemValue: Joi.number().min(200).required().label("Item Value"),
    itemWeight: Joi.number()
      .min(0.1)
      .max(300)
      .required()
      .label("Package Weight"),
    length: Joi.number()
      .min(0.1)
      .required()
      .default(0)
      .allow("")
      .label("Length"),
    width: Joi.number().min(0.1).required().default(0).allow("").label("Width"),
    height: Joi.number()
      .min(0.1)
      .required()
      .default(0)
      .allow("")
      .label("Height"),
    giftCode: Joi.string().min(2).max(30).required().label("Code"),
    signatureRequired: Joi.boolean().label("Signature Required"),
    insuranceRequired: Joi.boolean().label("Insurance Required"),
    pickupRequired: Joi.boolean().label("Pickup Required"),
  };

  senderAndRecipientFormItems = [
    "senderFullname",
    "senderEmail",
    "senderPhoneNumber",
    "senderAddress",
    "senderCountry",
    "senderState",
    "senderCity",
    "recipientFullname",
    "recipientEmail",
    "recipientPhoneNumber",
    "recipientAddress",
    "recipientCountry",
    "recipientState",
    "recipientCity",
    "recipientPostalCode",
  ];

  addItemFormItems = [
    "itemCategory",
    "itemDescription",
    "itemQuantity",
    "itemValue",
  ];

  addPackageFormItems = ["itemWeight"];

  originAddresses = [];
  destinationAddresses = [];

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  handleSuccess = async (reference) => {
    this.setState({ paymentCompleted: true });

    try {
      await shipmentService.paymentInitiated(this.state.shipment.id);
    } catch (e) {}

    // console.log(reference);
  };

  async componentDidMount() {
    this.setState({ hasFetchedDetail: false });

    const incompleteBookings = await shipmentService.loadIncompleteBookings();

    this.setState({ incompleteBookings });
    await this.updateAddressList();

    //load item category list
    const resp = await shipmentService.fetchItemCategories();

    const itemCategoryList = [...resp];

    try {
      const signatureServiceFee =
        await shipmentService.getSignatureServiceFee();

      this.setState({
        signatureServiceFee:
          signatureServiceFee.data < 0 ? null : signatureServiceFee.data,
      });

      const dangerousGoodsList = (
        await systemConfigService.fetchDangerousGoodsList()
      ).map((word) => word.toUpperCase());

      this.setState({
        dangerousGoodsList,
      });
    } catch (ex) {}

    if (this.isAdmin()) {
      this.setState({
        displayExpressBoxes: true,
        itemCategoryList,
      });
      try {
        const expressBoxList = await shipmentService.fetchShippingBoxes();
        this.setState({ expressBoxList });
      } catch (ex) {}
    } else {
      this.setState({ itemCategoryList });
    }
  }

  async updateAddressList() {
    try {
      const addressList = await shipmentService.fetchAddressList();

      const originAddressList = [];
      this.originAddresses = [];
      addressList.originAddressList.map((add) => {
        this.originAddresses[add.id + ""] = add;
        originAddressList[originAddressList.length] = {
          value: add.id + "",
          label:
            add.fullname +
            " (" +
            add.cityName +
            ", " +
            add.stateName +
            ", " +
            add.country +
            ")",
        };
      });

      const destinationAddressList = [];
      this.destinationAddresses = [];
      addressList.destinationAddressList.map((add) => {
        this.destinationAddresses[add.id + ""] = add;
        destinationAddressList[destinationAddressList.length] = {
          value: add.id + "",
          label:
            add.fullname + " (" + add.stateName + ", " + add.countryName + ")",
        };
      });

      const data = this.state.data;
      data.saveOriginAddress = false;
      data.saveRecipientAddress = false;

      this.setState({
        originAddressList,
        destinationAddressList,
        data,
      });

      // const nonFoodCategoryList = await locationService.fetchLocation(
      //   "NonFoodCategories"
      // );
      // this.setState({ nonFoodCategoryList });
      // if (this.state.packageType === "nonDocument") {
      //   this.setState({ itemCategoryList: nonFoodCategoryList });
      // }
    } catch (ex) {}
  }

  async loadShipmentDetail(id) {
    const detail = await shipmentService.fetchShipmentDetail(id);
    this.setState({ hasFetchedDetail: true, currentDetail: detail });

    this.editShipment();
  }

  render() {
    let { isLoading } = this.state;

    if (this.props.editShipmentId && !this.state.hasFetchedDetail) {
      this.loadShipmentDetail(this.props.editShipmentId);
      return (
        <>
          <SimpleHeader
            name={`${this.state.currentDetail ? "Edit" : "Book New"} Shipment`}
            parentName="Tables"
            description={
              this.state.moveToCompleteBooking
                ? "Complete shipment booking."
                : `${
                    this.state.currentDetail
                      ? "Edit shipment detail."
                      : "Provide your shipment's detail."
                  }`
            }
          />
          <Container className="mt--6" fluid>
            {showLoadingRecordsMessage("Loading shipment...")}
          </Container>
        </>
      );
    } else {
      return (
        <>
          {this.addNewItemModal()}
          {this.selectExpressBoxModal()}
          {this.dropOffLocationListModal()}
          {this.state.shipment && this.payWithWalletModal()}
          {this.importDutyInformationModal()}
          <SimpleHeader
            name={`${this.state.currentDetail ? "Edit" : "Book New"} Shipment`}
            parentName="Tables"
            description={
              this.state.moveToCompleteBooking
                ? "Complete shipment booking."
                : "Provide your shipment's detail."
            }
          />
          <Container className="mt--6" fluid>
            {this.state.paymentCompleted
              ? this.paymentCompleted()
              : this.state.moveToCompleteBooking
              ? this.moveToCompleteBooking()
              : this.loadTabs()}
          </Container>
        </>
      );
    }
  }

  moveToCompleteBooking() {
    return (
      <CompleteBooking
        shipment={this.state.shipment}
        returnAction={() => this.resetForm()}
        returnMessage_1="Return to Book New Shipment"
        returnMessage_2="Book New Shipment"
        shipmentId={this.state.completeBookingShipmentId}
      />
    );
  }

  loadIncompleteShipments() {
    if (
      this.state.incompleteBookings.length === 0 ||
      this.props.editShipmentId
    ) {
      return;
    }

    return (
      <Alert className="border shadow pb-0 px-3 bg-white border-light">
        <h4 className="wirepackage-text-table text-danger">
          Shipment booking to{" "}
          {this.state.incompleteBookings.length > 1
            ? "some receivers have"
            : "a receiver has"}{" "}
          not yet been completed.{" "}
          <Button
            outline
            color="secondary"
            size="sm"
            onClick={() => {
              this.toggleModal("hideIncompleteBookingList");
            }}
            className="text-underline"
          >
            {this.state.hideIncompleteBookingList ? "Show List" : "Hide List"}
          </Button>
        </h4>
        <div
          className={`${
            this.state.hideIncompleteBookingList && "d-none"
          } table-responsive`}
        >
          <table className="table table-striped table-light table-transparent table-hover table-sm">
            <tbody>
              {this.state.incompleteBookings.map((shipment, index) => {
                return (
                  <tr key={`incomplete-${shipment.id}`}>
                    <th>
                      <h4 className="wirepackage-text-table  m-0 p-0">
                        {shipment.recipient}
                      </h4>
                    </th>
                    <td className="d-none d-md-block">
                      <h4 className="font-weight-normal m-0 p-0">
                        {shipment.destinationState},{" "}
                        {shipment.destinationCountry}
                      </h4>
                    </td>
                    <td>
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => {
                          this.setState({
                            moveToCompleteBooking: true,
                            completeBookingShipmentId: shipment.id,
                          });
                        }}
                      >
                        Continue
                      </Button>
                      <Button
                        outline
                        color="danger"
                        size="sm"
                        onClick={async () => {
                          await shipmentService.cancelBooking(shipment.id);
                          const incompleteBookings =
                            this.state.incompleteBookings;
                          incompleteBookings.splice(index, 1);
                          this.setState({ incompleteBookings });
                        }}
                      >
                        Ignore
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Alert>
    );
  }

  loadTabs() {
    let { tab } = this.state;

    return (
      <div>
        {this.loadIncompleteShipments()}
        <Col className="p-0">
          <div className="nav-wrapper">
            <Nav
              // className="nav-fill flex-column flex-md-row"
              className="nav-pills-primary nav-fill"
              pills
              role="tablist"
            >
              <NavItem>
                <NavLink
                  className={
                    "mb-sm-3 mb-md-0 reflection-pointer-default " +
                    (tab === "tab-0" ? "active" : "")
                  }
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    // this.setState({ tab: "tab-0" });
                  }}
                >
                  <span className="badge badge-light text-sm">1</span> Origin
                  &amp; Destination
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    "mb-sm-3 mb-md-0 reflection-pointer-default " +
                    (tab === "tab-1" ? "active" : "")
                  }
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    // this.setState({ tab: "tab-1" });
                  }}
                >
                  <span className="badge badge-light text-sm">2</span> Shipment
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    "mb-sm-3 mb-md-0 reflection-pointer-default " +
                    (tab === "tab-2" ? "active" : "")
                  }
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    // this.setState({ tab: "tab-2" });
                  }}
                >
                  <span className="badge badge-light text-sm">3</span> Others
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    "mb-sm-3 mb-md-0 reflection-pointer-default " +
                    (tab === "tab-3" ? "active" : "")
                  }
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    // this.setState({ tab: "tab-3" });
                  }}
                >
                  <span className="badge badge-light text-sm">4</span> Payment
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <Card className="bg-transparent shadow-none">
            <CardBody className="bg-transparent p-0">
              <TabContent id="myTabContent" activeTab={tab}>
                <TabPane tabId="tab-0" role="tabpanel">
                  {tab === "tab-0" && this.packageLocations()}
                </TabPane>
                <TabPane tabId="tab-1" role="tabpanel">
                  {tab === "tab-1" && this.packageItems()}
                </TabPane>
                <TabPane tabId="tab-2" role="tabpanel">
                  {tab === "tab-2" && this.insurance()}
                </TabPane>
                <TabPane tabId="tab-3" role="tabpanel">
                  {tab === "tab-3" && this.displayCourierOptions()}
                </TabPane>
                <TabPane tabId="tab-4" role="tabpanel">
                  {tab === "tab-4" && this.confirmShipment()}
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </div>
    );
  }

  packageLocations() {
    return (
      <>
        <form
          onSubmit={(e) => {
            this.handleSubmit({
              e,
              submitFunction: () => this.nextTab("tab-1"),
              formItemNames: this.senderAndRecipientFormItems,
            });
          }}
        >
          <Row>
            <Col lg="6">
              {renderFormPanel({
                title: "Origin",
                content: (
                  <>
                    <Row>
                      <Col md="12">
                        <div class="d-flex">
                          <div class="flex-grow-1">
                            <div
                              className={
                                this.state.originAddressList.length > 0
                                  ? "mb-3"
                                  : "d-none mb-3"
                              }
                            >
                              {this.renderSelect2({
                                mandatory: true,
                                type: "text",
                                name: "originAddressList",
                                placeholder: "Select from Saved Addresses",
                                icon: "fas fa-address-book",
                                options: this.state.originAddressList,
                                onchange: async (selectedItem) => {
                                  this.initOriginDetails(
                                    this.originAddresses[selectedItem.value]
                                  );
                                },
                              })}
                            </div>
                          </div>
                          {/* <div class="text-right pl-1">
                            <h1 class="display-4">
                              <i class="fas fa-edit text-gray wirepackage-icon"></i>
                            </h1>
                          </div> */}
                        </div>
                      </Col>
                    </Row>
                    <h2>
                      <span className="badge badge-primary">
                        Sender's Contact Detail
                      </span>
                    </h2>
                    <Row>
                      <Col md="6">
                        {this.renderStyledInput({
                          mandatory: true,
                          type: "text",
                          name: "senderFullname",
                          placeholder: "Fullname (Required)",
                          icon: "ni ni-circle-08",
                        })}
                      </Col>
                      <Col md="6">
                        {this.renderStyledInput({
                          mandatory: true,
                          type: "tel",
                          name: "senderPhoneNumber",
                          placeholder: "Reachable Phone Number(Required)",
                          icon: "fas fa-phone",
                        })}
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        {this.renderStyledInput({
                          mandatory: true,
                          type: "email",
                          name: "senderEmail",
                          placeholder: "Email (Required)",
                          icon: "ni ni-email-83",
                        })}
                      </Col>
                    </Row>
                    <h2 className="mt-2">
                      <span className="badge badge-primary">
                        Sender's Address
                      </span>
                    </h2>
                    <Row>
                      <Col md="12">
                        {this.renderStyledInput({
                          mandatory: true,
                          type: "text",
                          name: "senderAddress",
                          placeholder: "Enter Address (Required)",
                          icon: "ni ni-pin-3",
                        })}
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" className="mb-3">
                        {this.renderSelect2({
                          mandatory: false,
                          name: "senderCountry",
                          options: this.state.senderCountryList,
                          placeholder: "Select Country",
                          icon: "fas fa-flag",
                          onchange: async (selectedItem) => {
                            try {
                              const { fieldIsProcessing, data } = this.state;
                              fieldIsProcessing.senderState = true;

                              let showSenderCity = true;
                              if (selectedItem.value === "NG") {
                                showSenderCity = true;
                                data.senderCity = null;
                                data.senderPostalCode = "0000";
                              } else {
                                showSenderCity = false;
                                data.senderPostalCode = "";
                              }

                              data.senderState = {
                                label: "Loading...",
                                value: "Loading...",
                              };
                              data.recipientCountry = {
                                label: "Loading...",
                                value: "Loading...",
                              };
                              data.recipientState = null;

                              data.senderCountry = selectedItem;
                              this.setState({
                                fieldIsProcessing,
                                showSenderCity,
                                data,
                                recipientStateList: [],
                              });

                              let senderStateList = [],
                                recipientCountryList = [];
                              if (selectedItem.value === "NG") {
                                senderStateList = [...NIGERIAN_STATES];
                                recipientCountryList = [
                                  {
                                    value: "NG",
                                    label: "Nigeria",
                                  },
                                  ...COUNTRY_LIST,
                                ];
                              } else {
                                senderStateList =
                                  await locationService.fetchLocation(
                                    selectedItem.value
                                  );
                                recipientCountryList = [
                                  {
                                    value: "NG",
                                    label: "Nigeria",
                                  },
                                ];
                              }

                              const {
                                fieldIsProcessing: fieldIsProcessingUpdate,
                                data: datas,
                              } = this.state;
                              datas.senderState = null;
                              fieldIsProcessingUpdate.senderState = false;
                              data.recipientCountry = null;
                              fieldIsProcessingUpdate.recipientCountry = false;

                              this.setState({
                                fieldIsProcessing: fieldIsProcessingUpdate,
                                senderStateList,
                                recipientCountryList,
                                data: datas,
                              });
                            } catch (ex) {
                              console.log(ex);
                            }
                          },
                        })}
                      </Col>
                      <Col md="6" className="mb-3">
                        {this.renderSelect2({
                          mandatory: false,
                          name: "senderState",
                          options: this.state.senderStateList,
                          placeholder: "Select State",
                          icon: "ni ni-world-2",
                          onchange: async (selectedItem) => {
                            try {
                              const { fieldIsProcessing, data } = this.state;

                              if (data.senderCountry.value !== "NG") {
                                data.senderCity = selectedItem;
                                data.senderState = selectedItem;
                                this.setState({
                                  data,
                                });
                                return;
                              }

                              fieldIsProcessing.senderCity = true;

                              this.setState({
                                fieldIsProcessing,
                              });

                              const senderCityList =
                                await locationService.fetchLocation(
                                  data.senderCountry.value +
                                    "_" +
                                    selectedItem.value
                                );
                              const {
                                fieldIsProcessing: fieldIsProcessingUpdate,
                                data: datas,
                              } = this.state;
                              datas.senderCity = null;
                              datas.senderState = selectedItem;
                              fieldIsProcessingUpdate.senderCity = false;

                              this.setState({
                                fieldIsProcessing: fieldIsProcessingUpdate,
                                senderCityList,
                                data: datas,
                              });
                            } catch (ex) {
                              console.log(ex);
                            }
                          },
                        })}
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <div
                          className={
                            this.state.showSenderCity ? "mb-3" : "d-none mb-3"
                          }
                        >
                          {this.renderSelect2({
                            mandatory: true,
                            name: "senderCity",
                            placeholder: "Select City",
                            icon: "fas fa-city",
                            options: this.state.senderCityList,
                          })}
                        </div>
                      </Col>
                      {/* <Col md="6">
                     <div className={
                      !this.state.showSenderCity ? "" : "d-none"
                    }>
                      {this.renderStyledInput({
                        mandatory: true,
                        type: "text",
                        name: "senderPostalCode",
                        placeholder: "Postal Code",
                        icon: "ni ni-email-83",
                      })}
                    </Col> */}
                    </Row>
                    <Row>
                      <Col md="12">
                        <hr className="mt-0 mb-3" />
                        {this.renderCheckbox({
                          mandatory: false,
                          name: "saveOriginAddress",
                          label: "Save origin detail to address book.",
                          checked: this.state.data.saveOriginAddress,
                          disabled: false,
                          iconClass: "fas fa-address-book",
                        })}
                      </Col>
                    </Row>
                  </>
                ),
              })}
            </Col>
            <Col lg="6">
              {renderFormPanel({
                title: "Destination",
                content: (
                  <>
                    <Row>
                      <Col md="12">
                        <div
                          className={
                            this.state.destinationAddressList.length > 0
                              ? "mb-3"
                              : "d-none mb-3"
                          }
                        >
                          {this.renderSelect2({
                            mandatory: true,
                            type: "text",
                            name: "destinationAddressList",
                            placeholder: "Select from Saved Addresses",
                            icon: "fas fa-address-book",
                            options: this.state.destinationAddressList,
                            onchange: async (selectedItem) => {
                              this.initDestinationDetails(
                                this.destinationAddresses[selectedItem.value]
                              );
                            },
                          })}
                        </div>
                      </Col>
                    </Row>
                    <h2>
                      <span className="badge badge-primary">
                        Recipient's Contact Detail
                      </span>
                    </h2>
                    <Row>
                      <Col md="6">
                        {this.renderStyledInput({
                          mandatory: true,
                          type: "text",
                          name: "recipientFullname",
                          placeholder: "Fullname (Required)",
                          icon: "ni ni-circle-08",
                        })}
                      </Col>
                      <Col md="6">
                        {this.renderStyledInput({
                          mandatory: true,
                          type: "tel",
                          name: "recipientPhoneNumber",
                          placeholder: "Reachable Phone Number(Required)",
                          icon: "fas fa-phone",
                        })}
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        {this.renderStyledInput({
                          mandatory: true,
                          type: "email",
                          name: "recipientEmail",
                          placeholder: "Email (Required)",
                          icon: "ni ni-email-83",
                        })}
                      </Col>
                    </Row>
                    <h2 className="mt-2">
                      <span className="badge badge-primary">
                        Delivery Address
                      </span>
                    </h2>
                    <Row>
                      <Col md="12">
                        {this.renderStyledInput({
                          mandatory: true,
                          type: "text",
                          name: "recipientAddress",
                          placeholder: "Enter Address + City (Required)",
                          icon: "ni ni-pin-3",
                          options: this.state.recipientCityList,
                        })}

                        {/* <GooglePlacesInput
                          name="recipientAddress"
                          value={this.state.data.recipientAddress}
                          onChange={this.handleChange}
                          error={this.state.errors.recipientAddress}
                          placeholder="Address"
                          onPlaceSelected={(place) => {
                            let houseNumber = "";
                            console.log(place);
                            const data = this.state.data;
                            place.address_components.map((loc) => {
                              if (loc.types.includes("neighborhood")) {
                                data.recipientCity = loc.long_name;
                              } else if (
                                loc.types.includes(
                                  "administrative_area_level_1"
                                )
                              ) {
                                data.recipientState = loc.long_name;
                              } else if (loc.types.includes("country")) {
                                data.recipientCountry = loc.long_name;
                              } else if (loc.types.includes("postal_code")) {
                                data.recipientPostalCode = loc.long_name;
                              } else if (
                                loc.types.includes(
                                  "administrative_area_level_3"
                                ) ||
                                loc.types.includes("locality") ||
                                loc.types.includes("sublocality") ||
                                loc.types.includes("postal_town")
                              ) {
                                data.cityName = loc.long_name;
                              }
                            });

                            if (place.formatted_address) {
                              data.recipientAddress = place.formatted_address;
                            }
                            this.setState({ data });
                          }}
                        /> */}
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" className="mb-3">
                        {this.renderSelect2({
                          name: "recipientCountry",
                          options: this.state.recipientCountryList,
                          placeholder: "Select Country",
                          icon: "fas fa-flag",
                          onchange: async (selectedItem) => {
                            try {
                              const { fieldIsProcessing, data } = this.state;
                              fieldIsProcessing.recipientState = true;

                              let showRecipientCity = true;
                              if (selectedItem.value === "NG") {
                                showRecipientCity = true;
                                data.recipientCity = null;
                                data.recipientPostalCode = "000000";
                              } else {
                                showRecipientCity = false;
                                data.recipientPostalCode = "";
                              }

                              data.recipientState = {
                                label: "Loading...",
                                value: "Loading...",
                              };

                              data.recipientCountry = selectedItem;
                              this.setState({
                                fieldIsProcessing,
                                showRecipientCity,
                                data,
                              });

                              const recipientStateList =
                                await locationService.fetchLocation(
                                  selectedItem.value
                                );
                              const {
                                fieldIsProcessing: fieldIsProcessingUpdate,
                                data: datas,
                              } = this.state;
                              datas.recipientState = null;
                              fieldIsProcessingUpdate.recipientState = false;

                              this.setState({
                                fieldIsProcessing: fieldIsProcessingUpdate,
                                recipientStateList,
                                data: datas,
                              });
                            } catch (ex) {
                              console.log(ex);
                            }
                          },
                        })}
                      </Col>
                      <Col md="6" className="mb-3">
                        {this.renderSelect2({
                          mandatory: true,
                          name: "recipientState",
                          placeholder: "Select State",
                          icon: "ni ni-world-2",
                          options: this.state.recipientStateList,
                          onchange: async (selectedItem) => {
                            try {
                              const { fieldIsProcessing, data } = this.state;

                              if (data.recipientCountry.value !== "NG") {
                                data.recipientCity = selectedItem;
                                data.recipientState = selectedItem;
                                this.setState({
                                  data,
                                });
                                return;
                              }

                              fieldIsProcessing.recipientCity = true;

                              this.setState({
                                fieldIsProcessing,
                              });

                              const recipientCityList =
                                await locationService.fetchLocation(
                                  data.recipientCountry.value +
                                    "_" +
                                    selectedItem.value
                                );
                              const {
                                fieldIsProcessing: fieldIsProcessingUpdate,
                                data: datas,
                              } = this.state;
                              datas.recipientCity = null;
                              datas.recipientState = selectedItem;
                              fieldIsProcessingUpdate.recipientCity = false;

                              this.setState({
                                fieldIsProcessing: fieldIsProcessingUpdate,
                                recipientCityList,
                                data: datas,
                              });
                            } catch (ex) {
                              console.log(ex);
                            }
                          },
                        })}
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <div
                          className={
                            this.state.showRecipientCity
                              ? "mb-3"
                              : "d-none mb-3"
                          }
                        >
                          {this.renderSelect2({
                            mandatory: true,
                            type: "text",
                            name: "recipientCity",
                            placeholder: "Select City",
                            icon: "fas fa-city",
                            options: this.state.recipientCityList,
                          })}
                        </div>
                      </Col>
                      <Col md="6">
                        <div
                          className={
                            !this.state.showRecipientCity ? "" : "d-none"
                          }
                        >
                          {this.renderStyledInput({
                            mandatory: true,
                            type: "text",
                            name: "recipientPostalCode",
                            placeholder: "Postal Code (Required)",
                            icon: "ni ni-email-83",
                          })}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <hr className="mt-0 mb-3" />

                        {this.renderCheckbox({
                          mandatory: false,
                          name: "saveRecipientAddress",
                          label: "Save destination detail to address book.",
                          checked: this.state.data.saveRecipientAddress,
                          disabled: false,
                          iconClass: "fas fa-address-book",
                        })}
                      </Col>
                    </Row>
                  </>
                ),
              })}
            </Col>
          </Row>
          <div className="text-right border-top pt-2">
            {this.renderButton(
              "Next",
              `btn-${this.state.currentDetail ? "warning" : "primary"}`,
              "Next",
              this.senderAndRecipientFormItems
            )}
          </div>
        </form>
      </>
    );
  }

  async initOriginDetails(address) {
    try {
      const { fieldIsProcessing, data } = this.state;
      fieldIsProcessing.senderState = true;

      data.senderFullname = address.fullname;
      data.senderPhoneNumber = address.phoneNumber;
      data.senderEmail = address.email;
      data.senderAddress = address.address;
      data.senderCountry = {
        value: address.country,
        label: address.countryName,
      };

      data.recipientState = null;
      data.recipientCountry = null;
      let showSenderCity = true,
        senderStateList = [],
        recipientCountryList = [],
        recipientStateList = [];
      if (address.country === "NG") {
        showSenderCity = true;
        data.senderCity = {
          value: address.city,
          label: address.cityName,
        };
        data.senderPostalCode = "0000";

        senderStateList = [...NIGERIAN_STATES];
        recipientCountryList = [
          {
            value: "NG",
            label: "Nigeria",
          },
          ...COUNTRY_LIST,
        ];
      } else {
        showSenderCity = false;
        data.senderCity = "";
        data.senderPostalCode = "";

        senderStateList = await locationService.fetchLocation(address.country);
        recipientCountryList = [
          {
            value: "NG",
            label: "Nigeria",
          },
        ];
        recipientStateList = [...NIGERIAN_STATES];
      }

      data.senderState = {
        value: "Loading...",
        label: "Loading...",
      };

      this.setState({
        fieldIsProcessing,
        showSenderCity,
        data,
        senderStateList,
        recipientCountryList,
        recipientStateList,
      });

      // const senderStateList =
      //   await locationService.fetchLocation(
      //     selectedItem.value
      //   );
      const senderCityList = await locationService.fetchLocation(
        address.country + "_" + address.state
      );
      const { fieldIsProcessing: fieldIsProcessingUpdate, data: datas } =
        this.state;
      datas.senderState = {
        value: address.state,
        label: address.stateName,
      };
      fieldIsProcessingUpdate.senderState = false;

      this.setState({
        fieldIsProcessing: fieldIsProcessingUpdate,
        // senderStateList,
        senderCityList,
        data: datas,
      });
    } catch (ex) {
      console.log(ex);
    }
  }

  async initDestinationDetails(address) {
    try {
      let { fieldIsProcessing, data } = this.state;
      fieldIsProcessing.recipientState = true;

      data.recipientFullname = address.fullname;
      data.recipientPhoneNumber = address.phoneNumber;
      data.recipientEmail = address.email;
      data.recipientAddress = address.address;
      data.recipientCountry = {
        value: address.country,
        label: address.countryName,
      };
      data.recipientPostalCode = address.postalCode;

      let recipientCityList = [];

      data.recipientState = {
        value: "Loading...",
        label: "Loading...",
      };

      this.setState({
        fieldIsProcessing,
        showRecipientCity: address.country === "NG",
        data,
      });

      // const recipientStateList =
      //   await locationService.fetchLocation(
      //     selectedItem.value
      //   );

      const recipientStateList = await locationService.fetchLocation(
        address.country
      );

      const { fieldIsProcessing: fieldIsProcessingUpdate, data: datas } =
        this.state;

      datas.recipientState = {
        value: address.state,
        label: address.stateName,
      };
      if (address.country === "NG") {
        recipientCityList = await locationService.fetchLocation(
          address.country + "_" + address.state
        );

        data.recipientCity = {
          value: address.city,
          label: address.cityName,
        };
        data.recipientPostalCode = "0000";
      } else {
        data.recipientCity = datas.recipientState;
      }

      fieldIsProcessingUpdate.recipientState = false;

      this.setState({
        fieldIsProcessing: fieldIsProcessingUpdate,
        recipientStateList,
        recipientCityList,
        datas,
      });
    } catch (ex) {
      console.log(ex);
    }
  }

  packageItems() {
    return (
      <>
        <Row>
          <Col className="col-lg-6 col-md-12 col-sm-12 col-12">
            <Row className="no-gutter">
              <Col>
                {renderFormPanel({
                  title: (
                    <h4>
                      <em>
                        {"Package (Box) " +
                          (this.state.currentPackage
                            ? this.state.currentPackage.index + 1 + " - UPDATE"
                            : this.state.packageList.length + 1)}{" "}
                        - Invoice/Customs Declaration
                      </em>
                    </h4>
                  ),
                  content: (
                    <>
                      <div className="font-weight-bold text-danger">Items</div>
                      <hr className="my-3" />

                      {this.loadItemsTable()}

                      <hr className="my-3" />
                      <div className="pb-0">
                        <Row>
                          <Col className="col-8 col-sm-6 col-md-6 mb-2">
                            <div className="mb-2 mt-1 wirepackage-label text-dark">
                              <span className="text-danger">*</span> Package
                              Weight (Kg):
                            </div>
                          </Col>
                          <Col className="col-4 col-sm-6 col-md-6">
                            {this.renderInput({
                              mandatory: true,
                              name: "itemWeight",
                              type: "text",
                              placeholder: "Enter Package Weight",
                              containerClass: "mb-0",
                              isSmall: true,
                            })}
                          </Col>
                        </Row>
                      </div>
                      {/* {this.state.hidePackageDimension && (
                        <>
                          <Button
                            color="secondary"
                            size="sm"
                            onClick={() => {
                              this.toggleModal("hidePackageDimension");
                            }}
                            className="text-underline"
                          >
                            Apply package dimension
                          </Button>
                        </>
                      )}
                      {!this.state.hidePackageDimension && (
                        <div className="reflection-bg-secondary p-3 mb-3">
                          <h3 className="text-primary">
                            <span className="text-danger">(Optional)</span>{" "}
                            Package dimension.{" "}
                            <Button
                              outline
                              color="default"
                              size="sm"
                              onClick={() => {
                                this.toggleModal("hidePackageDimension");
                                let { data } = this.state;
                                data.length = "";
                                data.width = "";
                                data.height = "";
                                this.setState({ data });
                              }}
                              className="text-underline"
                            >
                              cancel
                            </Button>
                          </h3>
                          <div
                            className={`${
                              this.state.displayExpressBoxes ? "" : "d-none"
                            }`}
                          >
                            <div className="text-left">
                              <Button
                                size="sm"
                                color="primary"
                                className="my-1"
                                outline
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState({
                                    showDimensions: false,
                                    selectExpressBoxModal: true,
                                  });
                                }}
                              >
                                Select Express Box
                              </Button>
                              <Button
                                size="sm"
                                color="primary"
                                className="my-1"
                                outline
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState({
                                    selectedExpressBox: {},
                                    showDimensions: true,
                                  });
                                }}
                              >
                                Enter Dimensions
                              </Button>
                            </div>
                            <hr className="my-1" />
                          </div>

                          <div
                            className={`pt-2 ${
                              this.state.selectedExpressBox.id ? "" : "d-none"
                            }`}
                          >
                            <p>
                              Package will be shipped in a{" "}
                              <b>
                                {this.state.selectedExpressBox.weight}kg Express
                                Box
                                <Button
                                  className="btn-icon my-1 ml-2 mb-sm-0"
                                  size="sm"
                                  color="default"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                      selectedExpressBox: {},
                                      showDimensions: true,
                                    });
                                  }}
                                >
                                  <span className="btn-inner--text text-capitalize">
                                    <strong>clear</strong>
                                  </span>
                                </Button>
                              </b>
                            </p>
                          </div>

                          <div
                            className={`pt-2 ${
                              this.state.showDimensions ? "" : "d-none"
                            }`}
                          >
                            <Row className="no-gutter">
                              <Col className="col-4">
                                {this.renderThresholdInput(
                                  false,
                                  "length",
                                  "Length (CM)",
                                  "text",
                                  "Optional"
                                )}
                              </Col>
                              <Col className="col-4">
                                {this.renderThresholdInput(
                                  false,
                                  "width",
                                  "Width (CM)",
                                  "text",
                                  "Optional"
                                )}
                              </Col>
                              <Col className="col-4">
                                {this.renderThresholdInput(
                                  false,
                                  "height",
                                  "Height (CM)",
                                  "text",
                                  "Optional"
                                )}
                              </Col>
                            </Row>
                          </div>
                        </div>
                      )} */}

                      {this.addPackageDetailsButtons()}
                    </>
                  ),
                })}
              </Col>
            </Row>
          </Col>
          <Col>
            {this.state.packageList.length > 0 && (
              <Row className="no-gutter">
                <Col>
                  {renderFormPanel({
                    title: (
                      <>
                        Your Shipment{" "}
                        <span className="text-muted float-right">
                          {utils.numberFormatter(
                            Number(this.state.shipmentWeight),
                            2
                          ) + " KG"}
                        </span>
                      </>
                    ),
                    content: this.loadShipmentTable(false),
                  })}
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <div className="text-right border-top pt-2">
          <form
            onSubmit={(e) => {
              this.handleSubmit({
                e,
                submitFunction: () => this.nextTab("tab-2"),
                formItemNames: [],
              });
            }}
          >
            <Button
              color={`${this.state.currentDetail ? "warning" : "primary"}`}
              onClick={(e) => {
                e.preventDefault();
                this.nextTab("tab-0");
              }}
              className="float-left"
            >
              Previous
            </Button>
            {this.renderButton(
              "Next",
              `btn-${this.state.currentDetail ? "warning" : "primary"}`,
              "Next",
              null,
              this.state.packageList.length === 0
            )}
          </form>
        </div>
      </>
    );
  }

  addPackageDetailsButtons() {
    return (
      this.state.itemList.length !== 0 &&
      this.state.data.itemWeight > 0 &&
      !this.state.errors.itemWeight && (
        <div>
          <hr className="my-3" />
          <Alert
            color="white"
            className="text-danger"
            closeClassName="bg-light"
            isOpen={this.state.showAddItemsInstruction}
            toggle={() => this.toggleModal("showAddItemsInstruction")}
          >
            Please add at least one item to your package.
          </Alert>

          <Row>
            <Col className="col-12 col-sm-6 mb-3">
              <form
                onSubmit={(e) => {
                  this.handleSubmit({
                    e,
                    submitFunction: () => this.addPackage(true),
                    formItemNames: this.addPackageFormItems,
                  });
                }}
              >
                {this.renderButton(
                  `${
                    this.state.currentPackage
                      ? "Update Package"
                      : "Add Another Package"
                  }`,
                  `${
                    this.state.currentPackage
                      ? "btn-info btn-lg btn-block"
                      : "btn-success btn-lg btn-block"
                  }`,
                  `${
                    this.state.currentPackage ? "Update" : "Add Another"
                  } Package`,
                  this.addPackageFormItems
                )}
              </form>
            </Col>
            <Col className="col-12 col-sm-6">
              {!this.state.currentPackage && (
                <form
                  onSubmit={(e) => {
                    this.handleSubmit({
                      e,
                      submitFunction: () => this.doneWithPackages(),
                      formItemNames: this.addPackageFormItems,
                    });
                  }}
                >
                  {this.renderButton(
                    "Done with Package!",
                    "btn-success btn-lg btn-block",
                    "Add Item",
                    this.addPackageFormItems
                  )}
                </form>
              )}
              {this.state.currentPackage && (
                <Button
                  size="lg"
                  color="warning"
                  block
                  onClick={() => {
                    const { data } = this.state;
                    data.itemDescription = "";
                    data.itemQuantity = "";
                    data.itemWeight = "";
                    data.itemValue = "";

                    this.setState({
                      data,
                      itemList: [],
                      packageValue: 0,
                      packageWeight: 0,
                      currentItem: null,
                      currentItemIndex: null,
                      currentPackage: null,
                      currentPackageIndex: null,
                    });
                  }}
                >
                  Cancel Update
                </Button>
              )}
            </Col>
          </Row>
        </div>
      )
    );
  }
  loadInsuranceOptions() {
    let opts = [];

    if (GLOBAL_PROPERTY.insuranceList) {
      GLOBAL_PROPERTY.insuranceList.map((item) => {
        opts[opts.length] = (
          <>
            {this.renderSwitch({
              label: (
                <p className="m-0 p-0 mt--1 font-weight-bold">{item.name}</p>
              ),
              mandatory: false,
              name: "insuranceRequired",
              checked: this.state.data.insuranceRequired,
            })}
            <div className="text-dark font-weight-light mt-2">
              {item.description}
            </div>
          </>
        );
      });
    }

    return opts;

    // let opts = [];

    // if (GLOBAL_PROPERTY.insuranceList) {
    //   GLOBAL_PROPERTY.insuranceList.map((item) => {
    //     opts[opts.length] = (
    //       <Button
    //         key={`${"insurance" - opts.length}`}
    //         className={`ml-0 ${
    //           opts.length !== GLOBAL_PROPERTY.insuranceList.length - 1
    //             ? "mb-3"
    //             : ""
    //         }`}
    //         color={this.state.insuranceType === item.code ? "primary" : "white"}
    //         onClick={() => this.setState({ insuranceType: item.code })}
    //         active={this.state.insuranceType === item.code}
    //       >
    //         <div key={`insurdiv-${item.code}`} className="text-left">
    //           <div
    //             key={`insurdiv1-${item.code}`}
    //             className="border-bottom border-light"
    //           >
    //             <Row>
    //               <Col className="text-left col-10">
    //                 <h2
    //                   className={`mb-0 ${
    //                     this.state.insuranceType === item.code && "text-white"
    //                   }`}
    //                 >
    //                   <span>{item.name}</span>
    //                 </h2>
    //               </Col>
    //               <Col className="text-lg text-right">
    //                 <i className="fas fa-medal" />
    //               </Col>
    //             </Row>
    //           </div>
    //           <span
    //             className={`text-muted text-justify ${
    //               this.state.insuranceType === item.code && "text-white"
    //             }`}
    //           >
    //             {item.description}
    //           </span>
    //         </div>
    //       </Button>
    //     );
    //   });
    // }

    // return opts;
  }

  loadDropOffAmount() {
    if (
      this.state.data.senderCountry.value ===
      this.state.data.recipientCountry.value
    ) {
      return GLOBAL_PROPERTY.dropOffAmountForLocal;
    } else {
      return GLOBAL_PROPERTY.dropOffAmountForInternational;
    }
  }

  insurance() {
    if (this.state.submitShipmentInProgress) {
      return showLoadingRecordsMessage(
        "Submitting your booking request. Please wait..."
      );
    }

    const dropOffAmount = this.loadDropOffAmount();

    return (
      <>
        <div class="text-center display-4 font-weight-normal mb-3">
          Select service options
        </div>
        <Row className="justify-content-center">
          <Col className="col-auto">
            {renderFormPanel({
              title: (
                <>
                  Insurance Service{" "}
                  <span className="text-danger">(recommended)</span>
                </>
              ),
              content: (
                <>
                  {/* <ButtonGroup className="border rounded" vertical>
                  {this.loadInsuranceOptions()}
                </ButtonGroup> */}

                  <div className="border border-bottom-1 mx--3 px-3 mb--3 pt-1 pb-2 reflection-bg-secondary text-dark">
                    {this.loadInsuranceOptions()}
                  </div>
                </>
              ),
            })}
          </Col>
          {this.state.signatureServiceFee && (
            <Col className="col-auto">
              {renderFormPanel({
                infoIcon: {
                  id: "sigserv-id",
                  message: (
                    <>
                      The courier will obtain a signature from someone at the
                      delivery address or from somebody nearby, such as a
                      neighbor or building manager.
                      <br />
                      <br />
                      Requiring a signature confirmation can give peace of mind.
                    </>
                  ),
                },
                title: "Signature Assurance (optional)",
                content: (
                  <>
                    <div className="border border-bottom-1 mx--3 px-3 mb--3 pt-1 pb-2 reflection-bg-secondary text-dark">
                      {this.renderSwitch({
                        label: (
                          <p className="m-0 p-0 mt--1 font-weight-bold">
                            Require a signature for delivery (someone must sign)
                          </p>
                        ),
                        mandatory: false,
                        name: "signatureRequired",
                        placeholder: "Entry Count",
                        checked: this.state.data.signatureRequired,
                      })}
                      <div className="text-dark font-weight-light mt-2">
                        <span className="font-weight-normal">
                          Additional Cost: &#8358;{" "}
                          {utils.numberFormatter(
                            Number(this.state.signatureServiceFee),
                            2
                          )}
                        </span>
                        <br />
                        The cost comes from the carrier and it will be included
                        in your final payment amount.
                        {/* We recommend using this service for your high-value shipments and other important packages. When you select UPS Signature Required service, UPS will not deliver the shipment without obtaining a signature (or electronic acknowledgement). It's worth noting, however, that the signature obtained by the driver doesn't necessarily have to be the receiver listed on the package. */}
                      </div>
                    </div>
                  </>
                ),
              })}
            </Col>
          )}

          <Col className="col-auto">
            {renderFormPanel({
              title: <>Pickup Service (optional)</>,
              content: (
                <div className="border border-bottom-1 mx--3 px-3 mb--3 pt-1 pb-2 reflection-bg-secondary text-dark">
                  {this.renderSwitch({
                    label: (
                      <p className="m-0 p-0 mt--1 font-weight-bold">
                        Pick up my package (charges apply)
                      </p>
                    ),
                    mandatory: false,
                    name: "pickupRequired",
                    checked: this.state.data.pickupRequired,
                  })}
                  <div className="text-dark font-weight-light mt-2">
                    You want us to pick up your package (the sender's address
                    will be the pickup location).
                  </div>
                </div>
              ),
            })}
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="col-auto">
            <div
              className={this.state.data.pickupRequired ? "visible" : "d-none"}
            >
              {renderFormPanel({
                title: "When should we pick up your package?",
                content: (
                  <>
                    <Row className="mt-2 mb-4">
                      <Col>
                        {this.renderRadio(
                          false,
                          "usePreferredPickupDate_0",
                          "usePreferredPickupDate",
                          false,
                          <p className="font-weight-bold">
                            I want my package picked up as quickly as possible.
                          </p>,
                          false,
                          () => {
                            const { data } = this.state;
                            data.eventId = 0;
                            this.setState({
                              data,
                              hidePreferredPickupDateField: true,
                            });
                          }
                        )}
                      </Col>
                    </Row>
                    <Row className="no-gutter">
                      <Col className="col-auto">
                        {this.renderRadio(
                          false,
                          "usePreferredPickupDate_1",
                          "usePreferredPickupDate",
                          true,
                          <p className="font-weight-bold">
                            I want to choose a preferred pick up date.
                          </p>,
                          false,
                          () => {
                            const { data } = this.state;
                            data.eventId = "";
                            this.setState({
                              data,
                              hidePreferredPickupDateField: false,
                            });
                          }
                        )}

                        {/* <div
                        className={
                          this.state.hidePreferredPickupDateField
                            ? "invisible mt--4"
                            : "d-none"
                        }
                      >
                        <span className="text-danger font-weight-bold d-lg-none">
                          This is not a gurranteed pickup date, but we will
                          ensure your pickup request is given high priority on
                          this date.
                        </span>
                        <span className="text-danger font-weight-bold d-none d-lg-block">
                          This is not a gurranteed pickup date, but we will
                          ensure your pickup request is given high
                          <br />
                          priority on this date.
                        </span>
                      </div> */}
                        <FormGroup
                          className={
                            this.state.hidePreferredPickupDateField
                              ? "d-none mb-0 mt-2"
                              : "text-center mb-0 mt-2"
                          }
                        >
                          <ReactDatetime
                            dateFormat="dddd, MMM D, YYYY"
                            inputProps={{
                              placeholder:
                                "Click here to select a preferred pickup date.",
                              className: "form-control text-center",
                            }}
                            closeOnSelect={true}
                            value={this.state.preferredPickupDate}
                            timeFormat={false}
                            onChange={(currentDate) => {
                              const day = currentDate._isAMomentObject
                                ? currentDate
                                : "";
                              this.setState({
                                preferredPickupDate: day,
                              });
                            }}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              classes += this.isValidDate(currentDate)
                                ? " text-primary"
                                : " rdtOld";

                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            isValidDate={(current) => this.isValidDate(current)}
                          />
                          {/* <span className="text-danger font-weight-bold d-lg-none">
                          This is not a gurranteed pickup date, but we will
                          ensure your pickup request is given high priority on
                          this date.
                        </span>
                        <span className="text-danger font-weight-bold d-none d-lg-block">
                          This is not a gurranteed pickup date, but we will
                          ensure your pickup request is given high
                          <br />
                          priority on this date.
                        </span> */}
                        </FormGroup>
                      </Col>
                    </Row>
                  </>
                ),
              })}
            </div>
          </Col>
        </Row>
        <div className="text-right border-top pt-2">
          <form
            onSubmit={(e) => {
              this.handleSubmit({
                e,
                submitFunction: () => this.fetchCourierRates(),
                formItemNames: [],
              });
            }}
          >
            <Button
              color={`${this.state.currentDetail ? "warning" : "primary"}`}
              onClick={(e) => {
                e.preventDefault();
                this.nextTab("tab-1");
              }}
              className="float-left"
            >
              Previous
            </Button>
            {this.renderButton(
              "Next",
              `btn-${this.state.currentDetail ? "warning" : "primary"}`,
              "Next",
              null
            )}
          </form>
        </div>
      </>
    );
  }

  async fetchCourierRates() {
    this.setState({ formSubmitted: true });
    const shipment = this.createShipmentSubmissionModel();

    try {
      const shippingRateResponse = await shipmentService.getRate(shipment);
      const courierRates = shippingRateResponse.courierRates;
      const platformInformationList =
        shippingRateResponse.platformInformationList;

      if (courierRates.length === 1) {
        // no need to ask user to choose, just use as default
        this.setState(
          {
            preferredCourier: courierRates[0].courier,
            courierRates,
            platformInformationList,
          },
          () => {
            this.submitShipment();
          }
        );
      } else {
        this.setState(
          {
            courierRates,
            shippingRateResponse,
            platformInformationList,
          },
          () => {
            this.nextTab("tab-3");
          }
        );
      }
    } catch (ex) {
      console.log(ex);
      this.nextTab("tab-2");
    }
    this.setState({ formSubmitted: false });
  }

  loadCourierOptions() {
    let opts = [];

    if (this.state.courierRates) {
      this.state.courierRates.map((rate) => {
        opts[opts.length] = (
          <Button
            key={`${"rates" - opts.length}`}
            className={`ml-0 ${
              opts.length !== this.state.courierRates.length - 1 ? "mb-3" : ""
            }`}
            color={
              this.state.preferredCourier === rate.courier ? "primary" : "white"
            }
            onClick={() => this.setState({ preferredCourier: rate.courier })}
            active={this.state.preferredCourier === rate.courier}
          >
            <div key={`insurdiv-${rate.courier}`} className="text-left">
              <div
                key={`insurdiv1-${rate.courier}`}
                className="border-bottom border-light"
              >
                <Row>
                  <Col className="col-12">
                    <span
                      className={`text-primary font-weight-bolder text-justify h2 ${
                        this.state.preferredCourier === rate.courier &&
                        "text-white"
                      }`}
                    >
                      {rate.courier}
                    </span>
                  </Col>
                </Row>
              </div>
              <Row>
                <Col className="text-left col-6">
                  <div
                    className={`display-4 mb-0 text-lowercase ${
                      this.state.preferredCourier === rate.courier &&
                      "text-white"
                    }`}
                  >
                    {rate.deliveryTimeline}
                  </div>
                </Col>

                <Col className="text-right col-6">
                  <div className="display-4">
                    &#8358;{" "}
                    {utils.numberFormatter(Number(rate.shippingRate), 2)}
                  </div>
                </Col>
              </Row>
            </div>
          </Button>
        );
      });
    }

    return opts;
  }

  shippingRatePartnerSubjectHeader() {
    if (this.state.shippingRateResponse.partnerGroupName) {
      return (
        <h3>
          <div class="wirepackage-text-bright-gold bg-dark px-3 py-1 text-center">
            <i class="fas fa-medal fa-flip-vertical"></i>&nbsp;&nbsp;
            {this.state.shippingRateResponse.partnerGroupName} Rate
          </div>
        </h3>
      );
    }
  }

  displayCourierOptions() {
    return (
      <Row className="justify-content-center">
        <Col className="col-12 col-md-9 col-lg-8">
          {renderFormPanel({
            title: "Choose Your Preferred Delivery Service",
            content: (
              <>
                {this.shippingRatePartnerSubjectHeader()}
                {this.state.platformInformationList &&
                  this.loadServiceSelectionInformation()}
                <ButtonGroup className="border rounded w-100" vertical>
                  {this.loadCourierOptions()}
                </ButtonGroup>
                <div className="text-right border-top pt-2">
                  <form
                    onSubmit={(e) => {
                      this.handleSubmit({
                        e,
                        submitFunction: () => this.submitShipment(),
                        formItemNames: [],
                      });
                    }}
                  >
                    <Button
                      color={`${
                        this.state.currentDetail ? "warning" : "primary"
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        this.nextTab("tab-2");
                      }}
                      className="float-left"
                    >
                      Previous
                    </Button>
                    {this.renderButton(
                      "Next",
                      `btn-${this.state.currentDetail ? "warning" : "primary"}`,
                      "Next",
                      null,
                      this.state.preferredCourier === null,
                      this.state.submitShipmentInProgress
                    )}
                  </form>
                </div>
              </>
            ),
          })}
        </Col>
      </Row>
    );
  }

  loadServiceSelectionInformation() {
    let platformInformationList = null;

    if (
      !this.state.platformInformationList ||
      this.state.platformInformationList.length == 0
    ) {
      return platformInformationList;
    }

    return (
      <div className="p-3 mb-4 bg-warning fade show border border-light text-white font-weight-bold">
        {this.state.platformInformationList.map((info) => {
          return (
            <div className="mb-2">
              <i class="fas fa-info-circle"></i> {info.detail}
            </div>
          );
        })}
      </div>
    );
  }

  loadItemConfirmationTable(items) {
    return (
      <Card className="mb-0">
        <ToolkitProvider
          data={items}
          keyField="index"
          columns={[
            {
              dataField: "description",
              text: "Desc",
              sort: true,
            },
            {
              dataField: "quantity",
              text: "Qty",
              sort: true,
            },
            {
              dataField: "value",
              text: <div className="text-right">Unit Price (&#8358;) </div>,
              sort: true,
              formatter: (cell, row) => {
                return (
                  <div className="text-right">
                    {utils.numberFormatter(Number(row.value), 2)}
                  </div>
                );
              },
            },
          ]}
          search
        >
          {(props) => (
            <div className="py-4 table-responsive">
              <BootstrapTable
                size="sm"
                ref={(el) => (this.componentRef = el)}
                {...props.baseProps}
                bootstrap4={true}
                // pagination={pagination}
                bordered={false}
                id="react-bs-table"
                striped
                hover
                condensed
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    );
  }

  isValidDate(current) {
    return (
      current.isSameOrAfter(new Date(), "day") &&
      current.day() !== 0 &&
      current.day() !== 6
    );
  }

  confirmShipment() {
    // const componentProps = {
    //   ...paystackConfig,
    //   email: auth.getCurrentUserEmail(),
    //   metadata: {
    //     name: auth.getCurrentUserFullname(),
    //     // phone: "08062078951",
    //   },
    //   reference: this.state.shipment.transactionReference,
    //   amount: this.state.shipment.paymentAmount * 100,
    //   onSuccess: (reference) => this.handleSuccess(reference),
    //   onClose: handleClose,
    // };

    const componentProps = {
      ...paystackConfig,
      customer: {
        email: auth.getCurrentUserEmail(),
        // phonenumber: '07064586146',
        name: auth.getCurrentUserFullname(),
      },
      tx_ref: this.state.shipment.transactionReference,
      amount: this.state.totalPaymentAmount,
      currency: "NGN",
      customizations: {
        title: "Book Shipment",
        description: "Please pay for your shipment.",
        logo: "https://cdn.wirepackage.com/files/logo%20bright.png",
      },

      callback: (response) => {
        // console.log("************* > ", response);
        closePaymentModal(); // this will close the modal programmatically
        if (
          response.status === "successful" ||
          response.status === "completed"
        ) {
          toast.success("Payment Completed", toastOptions);
          this.handleSuccess(response);
        }
      },
      onClose: () => {},
      // onSuccess: (reference) => this.handleSuccess(reference),
    };

    const payWithCardProps = {
      ...componentProps,
      payment_options: "card",
      text: (
        <>
          <div>Pay with Card</div>
          <div className="text-sm">
            <em>(Powered by Flutterwave)</em>
          </div>
        </>
      ),
    };

    return (
      <>
        <Row>
          <Col className="col-12 col-lg-6">
            {renderFormPanel({
              title: "Origin Info",
              content: (
                <>
                  <Row>
                    <Col className="col-12 col-md-6 mb-2 font-weight-bold h2">
                      {this.state.shipment.origin.fullname}
                    </Col>
                    <Col className="col-12 col-md-6 mb-2 font-weight-bold">
                      <div className="d-none d-md-block text-right">
                        {this.state.shipment.origin.phoneNumber}
                      </div>
                      <div className="d-block d-md-none">
                        {this.state.shipment.origin.phoneNumber}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-muted text-justify">
                      {this.state.shipment.origin.address},{" "}
                      {this.state.shipment.origin.cityName}
                    </Col>
                  </Row>
                </>
              ),
              bgColor: "bg-white",
              supplimentaryTitle: this.state.shipment.origin.state.replace(
                "_",
                "-"
              ),
            })}
          </Col>
          <Col>
            {renderFormPanel({
              title: "Destination Info",
              content: (
                <>
                  <Row>
                    <Col className="col-12 col-md-6 mb-2 font-weight-bold h2">
                      {this.state.shipment.destination.fullname}
                    </Col>
                    <Col className="col-12 col-md-6 mb-2 font-weight-bold">
                      <div className="d-none d-md-block text-right">
                        {this.state.shipment.destination.phoneNumber}
                      </div>
                      <div className="d-block d-md-none">
                        {" "}
                        {this.state.shipment.destination.phoneNumber}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-muted text-justify">
                      {this.state.shipment.destination.address},{" "}
                      {this.state.shipment.destination.cityName},{" "}
                      {this.state.shipment.destination.postalCode}
                    </Col>
                  </Row>
                </>
              ),
              bgColor: "bg-white",
              supplimentaryTitle: this.state.shipment.destination.state.replace(
                "_",
                "-"
              ),
            })}
          </Col>
        </Row>
        <Row>
          <Col>
            {renderFormPanel({
              title: "Item(s)",
              content: (
                <>
                  {this.state.shipment.items.map((item, index) => {
                    return (
                      <>
                        <div className="d-none d-md-block">
                          <span className="font-weight-bold pr-3">Package</span>
                          {this.state.shipment.items.length > 1 && (
                            <span className="font-weight-bold pr-3">
                              {index + 1} -
                            </span>
                          )}
                          <span className="pull-right pr-3">
                            Weight: {item.weight} Kg,
                          </span>
                          <span className="pull-right">
                            Value: &#8358;
                            {utils.numberFormatter(Number(item.value), 2)}
                          </span>
                        </div>

                        <div className="d-block d-md-none">
                          <span className="font-weight-bold pr-3">Package</span>
                          {this.state.shipment.items.length > 1 && (
                            <span className="font-weight-bold pr-3">
                              {index + 1} -
                            </span>
                          )}
                          <span className="pull-right pr-3">
                            W: {item.weight} Kg,
                          </span>
                          <span className="pull-right">
                            V: &#8358;
                            {utils.numberFormatter(Number(item.value), 2)}
                          </span>
                        </div>
                        {this.loadItemConfirmationTable(item.itemUnits)}
                      </>
                    );
                  })}

                  {this.state.shipment.shipmentDimension && (
                    <>
                      <div className="border-bottom border-light pb-3 mb-3" />
                      <Row>
                        <Col className="col-12 col-md-4">
                          Shipment Dimension:
                        </Col>
                        <Col>
                          <div className="font-weight-bold">
                            {this.state.shipment.shipmentDimension[0]} X{" "}
                            {this.state.shipment.shipmentDimension[1]} X{" "}
                            {this.state.shipment.shipmentDimension[2]}
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              ),
              bgColor: "bg-white",
            })}
          </Col>
        </Row>
        <Row>
          <Col>
            {renderFormPanel({
              title: "Others",
              content: (
                <>
                  <Row>
                    <Col className="col-6 col-sm-4 mb-2">
                      <div className="text-muted">Service Type</div>
                      <div className="font-weight-bold h2">
                        {this.state.shipment.courierRates[0].serviceType}
                      </div>
                    </Col>
                    <Col className="col-6 col-sm-4 mb-2">
                      <div className="text-muted">Insurance Type</div>
                      <div className="font-weight-bold h2">
                        {this.state.shipment.insurance}
                      </div>
                    </Col>
                    <Col className="col-6 col-sm-4 mb-2">
                      <div className="text-muted">
                        Delivery Signature Required
                      </div>
                      <div className="font-weight-bold h2">
                        {this.state.shipment.signatureRequired ? "YES" : "NO"}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-6 col-sm-4 mb-2">
                      <div className="text-muted">Pickup Required</div>
                      <div className="font-weight-bold h2">
                        {this.state.shipment.pickup ? "YES" : "NO"}
                      </div>
                    </Col>
                    {this.state.shipment.preferredPickupDateDesc && (
                      <Col className="col-6 col-sm-4 mb-2">
                        <div className="text-muted">Preferred Pickup Date</div>
                        <div className="font-weight-bold h2">
                          {this.state.shipment.preferredPickupDateDesc}
                        </div>
                      </Col>
                    )}
                  </Row>
                </>
              ),
              bgColor: "bg-white",
            })}
          </Col>
        </Row>
        <Row>
          <Col>
            {renderFormPanel({
              title: "Payment Summary",
              content: (
                <>
                  {parseFloat(this.state.shipment.billableWeight) >
                    parseFloat(this.state.shipment.grossWeight) && (
                    <>
                      <Row>
                        <Col className="mb-2">
                          <div className="text-muted">
                            Shipment Actual Weight
                          </div>
                          <div className="font-weight-bold h2">
                            {this.state.shipment.grossWeight} Kg
                          </div>
                        </Col>
                        <Col className="mb-2">
                          <div className="text-muted">
                            Shipment Dimensional Weight
                          </div>
                          <div className="font-weight-bold h2">
                            {this.state.shipment.billableWeight} Kg
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-2">
                          <div className="text-primary font-weight-bold">
                            Billable Weight
                          </div>
                          <div className="font-weight-bold h2">
                            {this.state.shipment.billableWeight} Kg
                          </div>
                        </Col>
                        <Col className="mb-2"></Col>
                      </Row>
                    </>
                  )}

                  {parseFloat(this.state.shipment.billableWeight) ===
                    parseFloat(this.state.shipment.grossWeight) && (
                    <>
                      <Row>
                        <Col className="mb-2"></Col>
                        <Col className="mb-2">
                          <div className="text-primary font-weight-bold">
                            Billable Weight
                          </div>
                          <div className="font-weight-bold h2">
                            {this.state.shipment.billableWeight} Kg
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}

                  <hr className="my-2" />
                  <Row>
                    <Col className="col-6 col-md-4 mb-2">
                      <div className="text-muted">
                        Shipping Rate
                        <span class="bg-dark px-1 rounded ">
                          <i class="fas fa-medal fa-flip-vertical wirepackage-text-bright-gold"></i>
                        </span>
                      </div>
                      <div className="font-weight-bold h2">
                        &#8358;{" "}
                        {utils.numberFormatter(
                          Number(this.state.shipment.shippingRate),
                          2
                        )}
                      </div>
                    </Col>
                    <Col className="col-6 col-md-4 mb-2">
                      <div className="text-muted">Insurance Fee</div>
                      <div className="font-weight-bold h2">
                        {(!this.state.shipment.insuranceFee ||
                          Number(this.state.shipment.insuranceFee) == 0) && (
                          <>--</>
                        )}
                        {this.state.shipment.insuranceFee &&
                          Number(this.state.shipment.insuranceFee) != 0 && (
                            <>
                              &#8358;{" "}
                              {utils.numberFormatter(
                                Number(this.state.shipment.insuranceFee),
                                2
                              )}
                            </>
                          )}
                      </div>
                    </Col>
                    <Col className="col-6 col-md-4 mb-2">
                      <div className="text-muted">Signature Assurance</div>
                      <div className="font-weight-bold h2">
                        {(!this.state.shipment.signatureServiceFee ||
                          Number(this.state.shipment.signatureServiceFee) ==
                            0) && <>--</>}
                        {this.state.shipment.signatureServiceFee &&
                          Number(this.state.shipment.signatureServiceFee) !=
                            0 && (
                            <>
                              &#8358;{" "}
                              {utils.numberFormatter(
                                Number(this.state.shipment.signatureServiceFee),
                                2
                              )}
                            </>
                          )}
                      </div>
                    </Col>
                    <Col className="d-block d-md-none col-6 mb-2">
                      <div className="text-muted">Shipping Materials</div>
                      <div className="font-weight-bold h2">
                        {(!this.state.shipment.shippingBoxFee ||
                          Number(this.state.shipment.shippingBoxFee) === 0) && (
                          <>--</>
                        )}
                        {this.state.shipment.shippingBoxFee &&
                          Number(this.state.shipment.shippingBoxFee) > 0 && (
                            <>
                              &#8358;{" "}
                              {utils.numberFormatter(
                                Number(this.state.shipment.shippingBoxFee),
                                2
                              )}
                            </>
                          )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-none d-md-block col-md-4 mb-2">
                      <div className="text-muted">Shipping Materials</div>
                      <div className="font-weight-bold h2">
                        {(!this.state.shipment.shippingBoxFee ||
                          Number(this.state.shipment.shippingBoxFee) === 0) && (
                          <>--</>
                        )}
                        {this.state.shipment.shippingBoxFee &&
                          Number(this.state.shipment.shippingBoxFee) > 0 && (
                            <>
                              &#8358;{" "}
                              {utils.numberFormatter(
                                Number(this.state.shipment.shippingBoxFee),
                                2
                              )}
                            </>
                          )}
                      </div>
                    </Col>
                    <Col className="col-6 col-md-4 mb-2">
                      <div
                        className={`${
                          this.state.shipment.additionalDocumentsFee &&
                          Number(this.state.shipment.additionalDocumentsFee) !==
                            0
                            ? ""
                            : "d-none"
                        } no-gutter`}
                      >
                        <div className="text-muted">
                          Food Clearance Document(s)
                        </div>
                        <div className="font-weight-bold h2">
                          &#8358;{" "}
                          {utils.numberFormatter(
                            Number(this.state.shipment.additionalDocumentsFee),
                            2
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col className="mb-2">
                      <div
                        className={`${
                          this.state.showDiscountInfo ? "" : "d-none"
                        }`}
                      >
                        <div className="text-muted">
                          {this.state.discountInfo.discountRate} Discount
                        </div>
                        <div className="font-weight-bold h2 text-danger">
                          &#8358;{" - "}
                          {utils.numberFormatter(
                            Number(this.state.discountInfo.discountAmount),
                            2
                          )}
                        </div>
                      </div>
                      <Button
                        className={`${this.state.showGiftCode ? "d-none" : ""}`}
                        size="sm"
                        outline
                        color="default"
                        onClick={() => {
                          this.toggleModal("showGiftCode");
                        }}
                      >
                        {this.state.showDiscountInfo
                          ? "Change Gift Code"
                          : "Use Gift Code"}
                      </Button>
                      <form
                        onSubmit={(e) => {
                          this.handleSubmit({
                            e,
                            submitFunction: () => this.applyGiftCode(),
                            formItemNames: ["giftCode"],
                          });
                        }}
                      >
                        <Row
                          className={`${
                            this.state.showGiftCode ? "" : "d-none"
                          } no-gutter`}
                        >
                          <Col className="col-12 ">
                            {this.renderStyledInput({
                              mandatory: true,
                              type: "text",
                              name: "giftCode",
                              placeholder: "Enter Gift Code",
                              size: "sm",
                              label: "Enter Gift Code",
                            })}
                          </Col>
                          <Col className="col-12 d-flex align-items-center">
                            {this.renderSimpleButton({
                              label: "Apply Gift Code",
                              classs: "btn-primary btn-sm",
                              spinnerLabel: "Applying Code",
                              formItemNames: ["giftCode"],
                              busy: this.state.applyingGiftCode,
                              onclick: () => {
                                this.applyGiftCode();
                              },
                            })}
                          </Col>
                        </Row>
                      </form>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-6 col-md-4 mb-2">
                      <div className="text-dark font-weight-bold mb-2 text-underline">
                        Total Payment Amount
                      </div>
                      <div
                        className={`${
                          this.state.showDiscountInfo ? "" : "d-none"
                        }`}
                      >
                        <div className="text-danger text-sm mt--1 mb-2">
                          (discount applied)
                        </div>

                        <span className="font-weight-bold h2 p-2 bg-dark text-white">
                          &#8358;{" "}
                          {utils.numberFormatter(
                            Number(
                              this.state.discountInfo.discountedPaymentAmount
                            ),
                            2
                          )}
                        </span>
                      </div>
                      <div
                        className={`${
                          this.state.showDiscountInfo ? "d-none" : ""
                        } mb-2`}
                      >
                        <span className="font-weight-bold h2 p-2 bg-dark text-white">
                          &#8358;{" "}
                          {utils.numberFormatter(
                            Number(this.state.totalPaymentAmount),
                            2
                          )}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </>
              ),
              bgColor: "bg-white",
            })}
          </Col>
        </Row>
        <Row>
          <Col>
            {renderFormPanel({
              title: "How do you want to pay?",
              content: (
                <>
                  <Row>
                    <Col className="col-12 col-md-4">
                      <div className="text-center mb-3">
                        <Button
                          color={`${
                            this.state.currentDetail ? "warning" : "primary"
                          }`}
                          size="lg"
                          block
                          onClick={() => {
                            this.fetchWalletBalance("cash");
                            this.toggleModal("payWithWalletModal");
                          }}
                          className="mb-1 mr-1"
                        >
                          <div>Pay With Wallet</div>
                          <div className="text-sm">
                            <em>(Powered by WirePackage)</em>
                          </div>
                        </Button>
                      </div>
                    </Col>
                    {/* <Col className="col-12 col-md-4">
                      <div className="text-center mb-3">
                        <Button
                          color={`${
                            this.state.currentDetail ? "warning" : "primary"
                          }`}
                          size="lg"
                          block
                          onClick={() => {
                            this.fetchWalletBalance("cash_back");
                            this.toggleModal("payWithWalletModal");
                          }}
                          className="mb-1 mr-1"
                        >
                          <div>Pay With Cash Back</div>
                          <div className="text-sm">
                            <em>(Powered by WirePackage)</em>
                          </div>
                        </Button>
                      </div>
                    </Col> */}
                    <Col className="col-12 col-md-4">
                      <ErrorBoundary>
                        <FlutterWaveButton
                          className={`btn btn-block btn-lg btn-${
                            this.state.currentDetail ? "warning" : "primary"
                          }`}
                          {...payWithCardProps}
                        />
                      </ErrorBoundary>
                      {/* <PaystackConsumer {...componentProps}>
                      {({ initializePayment }) => (
                        <Button
                          color="primary"
                          size="lg"
                          block
                          outline
                          onClick={() =>
                            initializePayment(this.handleSuccess, handleClose)
                          }
                        >
                          <div>Pay with Card</div>
                          <div className="text-sm">
                            <em>(Powered by PayStack)</em>
                          </div>
                        </Button>
                      )}
                    </PaystackConsumer> */}
                    </Col>
                  </Row>
                </>
              ),
              bgColor: "bg-white",
            })}
          </Col>
        </Row>
        <div className="text-left border-top pt-2">
          <Button
            color={`${this.state.currentDetail ? "warning" : "primary"}`}
            onClick={(e) => {
              e.preventDefault();
              if (this.state.courierRates.length === 1) {
                this.nextTab("tab-2");
              } else {
                this.nextTab("tab-3");
              }
            }}
            className="float-left"
          >
            Previous
          </Button>
        </div>
      </>
    );
  }

  async applyGiftCode() {
    this.setState({ applyingGiftCode: true });
    try {
      const discountInfo = await shipmentService.applyDiscount(
        this.state.data.giftCode,
        this.state.shipment.id
      );
      this.setState({
        discountInfo,
        totalPaymentAmount: discountInfo.discountedPaymentAmount,
        applyingGiftCode: false,
        showDiscountInfo: true,
        showGiftCode: false,
      });
    } catch (ex) {
      this.setState({ applyingGiftCode: false });
    }
  }

  loadItemsTable() {
    if (this.state.itemList.length === 0) {
      return this.emptyCartMessage();
    }

    const tableColumnEvents = {
      onClick: (e, column, columnIndex, row, rowIndex) => {
        const data = this.state.data;
        data.itemDescription = row.description;
        data.itemQuantity = row.quantity + "";
        data.itemValue = row.value + "";
        data.itemCategory = row.categorySelection;
        this.setState(
          { currentItem: row, data, currentItemIndex: rowIndex },
          () => this.toggleModal("addNewItemModal")
        );
      },
    };

    return (
      <>
        <Card className="mb-0 mx--3 mt--3">
          <ToolkitProvider
            data={this.state.itemList}
            keyField="index"
            columns={[
              {
                dataField: "description",
                text: "Item",
                sort: true,
                classes: (cell, row, rowIndex, colIndex) => {
                  return `${
                    this.state.currentItem &&
                    this.state.currentItem.index == row.index
                      ? "bg-info font-weight-bold"
                      : ""
                  } text-center px-1 text-wrap`;
                },
                events: tableColumnEvents,
                headerClasses: "th-lg",
              },
              {
                dataField: "quantity",
                text: "Quantity",
                headerFormatter: (cell, row) => (
                  <>
                    <span className="d-none d-sm-block">Quantity</span>
                    <span className="d-block d-sm-none">Qty</span>
                  </>
                ),
                sort: true,
                classes: (cell, row, rowIndex, colIndex) => {
                  return `${
                    this.state.currentItem &&
                    this.state.currentItem.index == row.index
                      ? "bg-info font-weight-bold"
                      : ""
                  } text-center px-1`;
                },
                events: tableColumnEvents,
              },
              {
                dataField: "value",
                text: "Unit Price",
                headerFormatter: (cell, row) => (
                  <>
                    <span className="d-none d-sm-block">
                      Unit Price (&#8358;)
                    </span>
                    <span className="d-block d-sm-none">Unit P.(&#8358;)</span>
                  </>
                ),
                sort: true,
                formatter: (cell, row) => {
                  return utils.numberFormatter(Number(row.value), 2);
                },
                classes: (cell, row, rowIndex, colIndex) => {
                  return `${
                    this.state.currentItem &&
                    this.state.currentItem.index == row.index
                      ? "bg-info font-weight-bold"
                      : ""
                  } text-center px-1`;
                },
                events: tableColumnEvents,
              },
              {
                dataField: "menu",
                text: "",
                sort: false,
                formatter: (cell, row) => this.loadTableContextMenu(row),
                classes: (cell, row, rowIndex, colIndex) => {
                  return `${
                    this.state.currentItem &&
                    this.state.currentItem.index == row.index
                      ? "bg-info font-weight-bold"
                      : ""
                  } text-wrap px-1 align-middle`;
                },
              },
            ]}
          >
            {(props) => (
              <>
                <div className="table-responsive">
                  <BootstrapTable
                    size="sm"
                    ref={(el) => (this.componentRef = el)}
                    {...props.baseProps}
                    bootstrap4={true}
                    // pagination={pagination}
                    bordered={false}
                    id="react-bs-table"
                    striped
                    hover
                    condensed
                    classes="mb-0"
                    rowClasses="reflection-pointer"
                  />
                </div>
              </>
            )}
          </ToolkitProvider>
        </Card>
        {this.state.itemList.length !== 0 && (
          <div className="text-center pt-2">
            <Button
              size="sm"
              color="success"
              onClick={() => this.toggleModal("addNewItemModal")}
            >
              + Add Another Item
            </Button>

            <div className="text-center mt-3 mb-2 mt-1 wirepackage-label">
              Package Value:{" "}
              <span className="text-dark wirepackage-label">
                &#8358;{" "}
                {utils.numberFormatter(Number(this.state.packageValue), 2)}
              </span>
            </div>
          </div>
        )}
      </>
    );
  }

  addNewItemButton() {
    return (
      <Button
        size="sm"
        color="success"
        onClick={() => this.toggleModal("addNewItemModal")}
      >
        {this.state.itemList.length === 0
          ? "+ Add New Item"
          : "+ Add Another Item"}
      </Button>
    );
  }

  loadTableContextMenu(row) {
    return (
      <>
        {/* <Button
          onClick={() => {
            // this.copyServiceToState(row);
            // this.setState({ currentField: row });
            this.toggleModal("newConstraintModal");
          }}
          className="btn-danger btn-sm mb-1 mr-1"
        >
          <i className="fas fa-trash-alt" />
        </Button> */}
        <i
          className="fas fa-times text-danger mr-2 float-right reflection-pointer"
          onClick={() => {
            const packageValue =
              this.state.packageValue - Number(row.totalValue);
            let { itemList } = this.state;
            itemList = itemList.filter((item) => item.index !== row.index);
            this.setState({ itemList, packageValue });
            if (
              this.state.currentItem &&
              this.state.currentItem.index === row.index
            ) {
              let data = this.state.data;
              data.itemDescription = "";
              data.itemQuantity = "";
              data.itemValue = "";

              this.setState({ currentItem: null, data });
            }
          }}
        />
      </>
    );
  }

  loadShipmentTable(showInfo) {
    const tableColumnEvents = {
      onClick: (e, column, columnIndex, row, rowIndex) => {
        const data = this.state.data;
        data.itemDescription = "";
        data.itemQuantity = "";
        data.itemValue = "";
        data.itemWeight = row.weight;

        this.setState({
          currentPackage: row,
          itemList: row.itemUnits,
          packageValue: row.value,
          data,
          currentPackageIndex: rowIndex,
        });
      },
    };

    return (
      <Card className="mb--3 ml--3 mr--3 mt--3">
        <ToolkitProvider
          data={this.state.packageList}
          keyField="index"
          columns={[
            {
              dataField: "index",
              text: "Package",
              sort: true,
              classes: (cell, row, rowIndex, colIndex) => {
                return `${
                  this.state.currentPackage &&
                  this.state.currentPackage.index == row.index
                    ? "bg-info font-weight-bold"
                    : ""
                } text-wrap px-2`;
              },
              formatter: (cell, row, rowIndex) => {
                return <b>{"Package " + (row.index + 1)}</b>;
              },
              events: tableColumnEvents,
            },
            {
              dataField: "value",
              text: "Value ",
              headerFormatter: (cell, row) => <>Value (&#8358;)</>,
              sort: true,
              formatter: (cell, row) => {
                return utils.numberFormatter(Number(row.value), 2);
              },
              classes: (cell, row, rowIndex, colIndex) => {
                return `${
                  this.state.currentPackage &&
                  this.state.currentPackage.index == row.index
                    ? "bg-info font-weight-bold"
                    : ""
                } text-center px-1`;
              },
              events: tableColumnEvents,
            },
            {
              dataField: "weight",
              text: "Weight (Kg)",
              sort: true,
              classes: (cell, row, rowIndex, colIndex) => {
                return `${
                  this.state.currentPackage &&
                  this.state.currentPackage.index == row.index
                    ? "bg-info font-weight-bold"
                    : ""
                } text-center px-1`;
              },
              events: tableColumnEvents,
            },
            {
              dataField: "menu",
              text: "",
              sort: false,
              formatter: (cell, row) => this.loadShipmentTableContextMenu(row),
              classes: (cell, row, rowIndex, colIndex) => {
                return `${
                  this.state.currentPackage &&
                  this.state.currentPackage.index == row.index
                    ? "bg-info font-weight-bold"
                    : ""
                } text-wrap px-1 align-middle`;
              },
            },
          ]}
        >
          {(props) => (
            <div className="table-responsive">
              <BootstrapTable
                size="sm"
                ref={(el) => (this.componentRef = el)}
                {...props.baseProps}
                bootstrap4={true}
                // pagination={pagination}
                bordered={false}
                id="react-bs-table"
                striped
                hover
                condensed
                classes="mb-0"
                rowClasses="reflection-pointer"
              />
            </div>
          )}
        </ToolkitProvider>
        <div className="m-2">
          <span className="m-1 text-sm">
            Total Shipment Value: &#8358;{" "}
            <b>{utils.numberFormatter(Number(this.state.shipmentValue), 2)}</b>
          </span>
          <br />
          <span className="m-1 text-sm">
            Total Shipment Weight:{" "}
            <b>
              {utils.numberFormatter(Number(this.state.shipmentWeight), 2)} Kg
            </b>
          </span>
        </div>
        {showInfo && this.emptyCartMessage()}
      </Card>
    );
  }

  loadShipmentTableContextMenu(row) {
    return (
      <>
        <i
          className="fas fa-times text-danger mr-2 float-right reflection-pointer"
          onClick={() => {
            const shipmentWeight =
              this.state.shipmentWeight - Number(row.weight);
            const shipmentValue = this.state.shipmentValue - Number(row.value);
            let { packageList } = this.state;
            packageList = packageList.filter(
              (item) => item.index !== row.index
            );
            this.setState({
              shipmentWeight,
              packageList,
              shipmentValue,
              currentPackage: null,
              currentPackageIndex: null,
            });
          }}
        />
      </>
    );
  }

  dropOffLocationListModal = () => {
    return (
      <Modal
        className="modal-dialog-centered"
        contentClassName="bg-secondary"
        isOpen={this.state.dropOffLocationListModal}
        toggle={() => this.toggleModal("dropOffLocationListModal")}
        backdrop="static"
      >
        <div className="modal-header bg-primary">
          <h6
            className="modal-title text-secondary"
            id="modal-title-notification"
          >
            Package Drop Off Locations
          </h6>
          <button
            aria-label="Close"
            className="close text-secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("dropOffLocationListModal")}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body p-2">
          <Card className="m-0">
            <ToolkitProvider
              data={this.state.dropOffLocationList}
              keyField="index"
              columns={[
                {
                  dataField: "state",
                  text: "state",
                  sort: true,
                },
                {
                  dataField: "city",
                  text: "City",
                  sort: true,
                },
                {
                  dataField: "address",
                  text: "Address",
                  sort: true,
                  classes: (cell, row, rowIndex, colIndex) => {
                    return "text-wrap";
                  },
                },
              ]}
              search
            >
              {(props) => (
                <div className="py-4">
                  <TableActionButtons
                    hideExportButtons={true}
                    {...props}
                  ></TableActionButtons>
                  <BootstrapTable
                    size="sm"
                    ref={(el) => (this.componentRef = el)}
                    {...props.baseProps}
                    bootstrap4={true}
                    pagination={pagination}
                    bordered={false}
                    id="react-bs-table"
                    striped
                    hover
                    condensed
                  />
                </div>
              )}
            </ToolkitProvider>
          </Card>
        </div>
        <div className="modal-footer">
          <Button
            className="ml-auto"
            color="dark"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("dropOffLocationListModal")}
          >
            Close
          </Button>
        </div>
      </Modal>
    );
  };

  emptyCartMessage() {
    return (
      <Alert color="default">
        Add all the items contained in your package one after the other. Click
        on the button below to start adding items:
        <br /> <br />
        {this.addNewItemButton()}
      </Alert>
    );
  }

  nextTab = (tab) => {
    this.scrollToTheTop();
    this.setState({ tab: tab });
  };

  createShipmentSubmissionModel() {
    let {
      data,
      insuranceType,
      preferredPickupDate,
      packageList,
      shipmentValue,
      shipmentWeight,
    } = this.state;

    const shipment = {
      items: packageList,
      insurance: this.state.data.insuranceRequired
        ? GLOBAL_PROPERTY.insuranceList[0].code
        : "Standard",
      pickup: this.state.data.pickupRequired,
      signatureRequired: data.signatureRequired,
      origin: {
        fullname: data.senderFullname,
        phoneNumber: data.senderPhoneNumber,
        email: data.senderEmail,
        address: data.senderAddress,
        country: data.senderCountry.value,
        state: data.senderCountry.value + "_" + data.senderState.value,
        city: data.senderCity.value,
        cityName: data.senderCity.label,
        saveInAddressBook: data.saveOriginAddress,
      },
      destination: {
        fullname: data.recipientFullname,
        phoneNumber: data.recipientPhoneNumber,
        email: data.recipientEmail,
        address: data.recipientAddress,
        country: data.recipientCountry.value,
        state: data.recipientCountry.value + "_" + data.recipientState.value,
        city: data.recipientCity.value,
        postalCode: data.recipientPostalCode,
        cityName: data.recipientCity.label,
        saveInAddressBook: data.saveRecipientAddress,
      },
      preferredPickupDate:
        data.usePreferredPickupDate && preferredPickupDate
          ? preferredPickupDate.format(dateFormat)
          : null,
      preferredPickupDateDesc:
        data.usePreferredPickupDate && preferredPickupDate
          ? preferredPickupDate.format("dddd, MMM D, YYYY")
          : null,
      transactionReference: this.state.shipment
        ? this.state.shipment.transactionReference
        : null,
      id: this.state.shipment ? this.state.shipment.id : 0,
      value: shipmentValue,
      weight: shipmentWeight,
      editShipmentId: this.state.currentDetail
        ? this.state.currentDetail.id
        : null,
    };

    return shipment;
  }

  submitShipment = async () => {
    this.setState({ submitShipmentInProgress: true });
    const data = this.state.data;
    const shipment = this.createShipmentSubmissionModel();

    shipment.preferredCourier = this.state.preferredCourier;

    try {
      const shipmentResponse = await shipmentService.bookShipment(shipment);
      let totalInvoiceAmount = 0;

      shipmentResponse.items.map((it) => {
        totalInvoiceAmount = totalInvoiceAmount + Number(it.value);
      });

      if (shipmentResponse.insurance == "Premium") {
        totalInvoiceAmount =
          totalInvoiceAmount + Number(shipmentResponse.insuranceFee);
      }

      this.setState(
        {
          shipment: shipmentResponse,
          totalPaymentAmount: Number(shipmentResponse.paymentAmount),
          discountInfo: {},
          applyingGiftCode: false,
          showDiscountInfo: false,
          showGiftCode: false,
          totalInvoiceAmount,
        },
        () => {
          this.setState({
            importDutyInformationModal:
              data.recipientCountry.value === "GB" &&
              totalInvoiceAmount > UK_INVOICE_AMOUNT_LIMIT,
          });
        }
      );
      this.nextTab("tab-4");
    } catch (ex) {}
    this.setState({ submitShipmentInProgress: false });
  };

  addItem = () => {
    let { itemList, data, currentItemIndex } = this.state;
    if (
      data.itemDescription === "" ||
      data.itemQuantity === "" ||
      data.itemValue === "" ||
      !data.itemCategory ||
      data.itemCategory.value === ""
    ) {
      return false;
    }

    const itemValue = data.itemValue.replace(/\s/g, "");
    const itemQuantity = data.itemQuantity.replace(/\s/g, "");
    const totalValue = Number(data.itemQuantity) * Number(data.itemValue);

    let packageValue = 0;
    if (this.state.currentItem) {
      // update item action
      let item = itemList[currentItemIndex];
      console.log(item);
      packageValue = this.state.packageValue - item.totalValue + totalValue;
      const newItem = {
        index: item.index,
        description: data.itemDescription,
        quantity: itemQuantity,
        value: itemValue,
        category: data.itemCategory.value,
        categorySelection: data.itemCategory,
        packageType: this.state.packageType,
        totalValue,
      };
      itemList[currentItemIndex] = newItem;
    } else {
      // add item action
      let item = {
        index: this.itemCounter++,
        description: data.itemDescription,
        quantity: itemQuantity,
        value: itemValue,
        category: data.itemCategory.value,
        categorySelection: data.itemCategory,
        packageType: this.state.packageType,
        totalValue,
      };
      packageValue = this.state.packageValue + totalValue;
      itemList = [...itemList, item];
    }

    data.itemDescription = "";
    data.itemQuantity = "";
    data.itemValue = "";
    data.itemCategory = null;

    this.setState(
      {
        itemList,
        data,
        selectedExpressBox: {},
        showDimensions: true,
        packageType: "nonDocument",
        packageValue,
        currentItem: null,
      },
      () => {
        if (this.state.currentPackage) {
          this.addPackage(false);
        }
      }
    );
    return true;
  };

  doneWithPackages = () => {
    if (this.addPackage(true)) {
      this.nextTab("tab-2");
    }
  };

  addPackage = (reset) => {
    let {
      itemList,
      selectedExpressBox,
      data,
      packageList,
      packageValue,
      currentPackage,
      currentPackageIndex,
      checkedInvoiceValueLimit,
    } = this.state;

    if (itemList.length === 0) {
      // no item added to package
      this.setState({ showAddItemsInstruction: true });
      return false;
    }

    let newPackage = {
      itemUnits: itemList,
      weight: data.itemWeight.replace(/\s/g, ""),
      value: packageValue,
      packageDimension: [
        data.length ? data.length.replace(/\s/g, "") : data.length,
        data.width ? data.width.replace(/\s/g, "") : data.width,
        data.height ? data.height.replace(/\s/g, "") : data.height,
      ],
      expressBoxId: selectedExpressBox.id ? selectedExpressBox.id : 0,
      expressBoxWeight: selectedExpressBox.weight
        ? selectedExpressBox.weight
        : 0,
    };

    let packageWeight = Number(data.itemWeight);
    let shipmentWeight = 0;
    let shipmentValue = 0;

    if (currentPackage && packageList.length != 0) {
      // update package
      let packageObject = packageList[currentPackageIndex];
      newPackage.index = packageObject.index;
      packageList[currentPackageIndex] = newPackage;
      packageList = [...packageList];
      shipmentWeight =
        this.state.shipmentWeight - packageObject.weight + packageWeight;
      shipmentValue =
        this.state.shipmentValue - packageObject.value + packageValue;

      console.log(newPackage);
      console.log("packageValue: " + packageValue);
    } else {
      // new package
      newPackage.index = this.packageCounter++;
      packageList = [...packageList, newPackage];
      shipmentWeight = this.state.shipmentWeight + packageWeight;
      shipmentValue = this.state.shipmentValue + packageValue;
    }

    data.itemDescription = "";
    data.itemQuantity = "";
    data.itemValue = "";
    let showImportDutyInformationModal = false;

    if (
      data.recipientCountry.value === "GB" &&
      shipmentValue > UK_INVOICE_AMOUNT_LIMIT
    ) {
      if (!checkedInvoiceValueLimit) {
        checkedInvoiceValueLimit = true;
        showImportDutyInformationModal = true;
      } else {
        showImportDutyInformationModal = false;
      }
    } else {
      checkedInvoiceValueLimit = false;
      showImportDutyInformationModal = false;
    }

    if (reset) {
      packageValue = 0;
      data.itemWeight = "";
      data.length = "";
      data.width = "";
      data.height = "";
      this.setState({
        packageList,
        data,
        selectedExpressBox: {},
        showDimensions: true,
        packageType: "nonDocument",
        shipmentWeight,
        packageValue,
        shipmentValue,
        totalInvoiceAmount: shipmentValue,
        itemList: [],
        currentItem: null,
        currentPackage: null,
        selectedItem: null,
        importDutyInformationModal: showImportDutyInformationModal,
        checkedInvoiceValueLimit,
      });
    } else {
      this.setState({
        packageList,
        data,
        selectedExpressBox: {},
        showDimensions: true,
        packageType: "nonDocument",
        shipmentWeight,
        packageValue,
        shipmentValue,
        totalInvoiceAmount: shipmentValue,
        currentItem: null,
        importDutyInformationModal: showImportDutyInformationModal,
        checkedInvoiceValueLimit,
      });
    }
    return true;
  };

  payWithWalletModal = () => {
    return (
      <Modal
        className="modal-dialog-centered"
        contentClassName="bg-secondary"
        isOpen={this.state.payWithWalletModal}
        toggle={() => this.toggleModal("payWithWalletModal")}
        backdrop="static"
      >
        <div className="modal-header bg-primary">
          <h6
            className="modal-title text-secondary"
            id="modal-title-notification"
          >
            {this.state.fetchedWalletBalanceType === "cash"
              ? "Pay With Wallet Balance"
              : this.state.fetchedWalletBalanceType === "cash_back"
              ? "Pay With Cash Back Balance"
              : ""}
          </h6>
          <button
            aria-label="Close"
            className="close text-secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("payWithWalletModal")}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        {this.displayWalletView()}
      </Modal>
    );
  };

  displayWalletView() {
    if (this.state.fetchingWalletBalance) {
      return (
        <div className="my-5">
          {showLoadingRecordsMessage("Fetching your wallet balance...")}
        </div>
      );
    } else if (this.state.fetchedWalletBalance) {
      let balance = 0,
        balanceTitle = "",
        errorMessage = "",
        isOrganizationWallet = false,
        creditLimit = 0;
      let paymentAmount = Number(this.state.totalPaymentAmount);
      if (this.state.fetchedWalletBalanceType === "cash") {
        balance = Number(this.state.wallet.cashBalance);
        balanceTitle = "Wallet Balance";
        isOrganizationWallet = this.state.wallet.organizationWallet;
        creditLimit = this.state.wallet.creditLimit;
        errorMessage = (
          <Alert color="danger" className="text-center">
            <div className="alert-body">
              Insufficient Wallet balance! Please fund your wallet and retry or
              use another payment method.
            </div>
          </Alert>
        );
      } else if (this.state.fetchedWalletBalanceType === "cash_back") {
        balance = Number(this.state.wallet.cashBackBalance);
        balanceTitle = "Cash Back Balance";
        errorMessage = (
          <Alert color="danger" className="text-center">
            <div className="alert-body">
              Insufficient Cash Back balance. Please use another payment method.
            </div>
          </Alert>
        );
      } else {
        return (
          <>
            <div className="modal-body p-2 text-center">
              Unknown balance type selected.
            </div>
          </>
        );
      }

      return (
        <>
          <div className="modal-body p-2 text-center">
            <span className="text-muted">{balanceTitle}</span>
            <div className="font-weight-bold h1">
              &#8358; {utils.numberFormatter(balance, 2)}
            </div>
            {(balance > 0 && balance >= paymentAmount) ||
            (isOrganizationWallet && creditLimit >= paymentAmount - balance) ? (
              <div className="mt-2">
                Book your shipment by clicking on the button below.
              </div>
            ) : (
              <div className="mt-2">{errorMessage}</div>
            )}
          </div>
          {(balance > 0 && balance >= paymentAmount) ||
          (isOrganizationWallet && creditLimit >= paymentAmount - balance) ? (
            <div className="modal-footer text-center">
              <Button
                block
                className="ml-auto"
                color="success"
                type="button"
                onClick={() => {
                  this.payWithBalance();
                }}
                disabled={this.state.payWithWalletBalanceInProgress}
              >
                <span
                  className={
                    this.state.payWithWalletBalanceInProgress ? "" : "d-none"
                  }
                >
                  <Spinner type="border" size="sm"></Spinner> Making payment...
                </span>
                <span
                  className={
                    this.state.payWithWalletBalanceInProgress ? "d-none" : ""
                  }
                >
                  Pay &#8358; {utils.numberFormatter(paymentAmount, 2)}
                </span>
              </Button>
            </div>
          ) : (
            <div className="modal-footer text-center">
              <Button
                block
                className="ml-auto"
                color="primary"
                type="button"
                onClick={() => this.toggleModal("payWithWalletModal")}
              >
                Close
              </Button>
            </div>
          )}
        </>
      );
    } else {
      return (
        <>
          <div className="modal-body p-2 text-center">
            Could not load your wallet balance. Please check your network
            connectivity.
          </div>
        </>
      );
    }
  }

  fetchWalletBalance = async (fetchedWalletBalanceType) => {
    this.setState({
      fetchedWalletBalanceType,
      fetchingWalletBalance: true,
      fetchedWalletBalance: false,
    });
    try {
      const wallet = await walletService.fetchWallet();
      this.setState({
        wallet,
        fetchingWalletBalance: false,
        fetchedWalletBalance: true,
      });
    } catch (e) {
      this.setState({ fetchingWalletBalance: false });
    }
  };

  payWithBalance = async () => {
    this.setState({ payWithWalletBalanceInProgress: true });
    let response = null;
    try {
      if (this.state.fetchedWalletBalanceType === "cash") {
        response = await shipmentService.payWithWallet(
          this.state.shipment.transactionReference
        );
      } else if (this.state.fetchedWalletBalanceType === "cash_back") {
        response = await shipmentService.payWithCashBack(
          this.state.shipment.transactionReference
        );
      } else {
        return;
      }

      toast.success(response.message);
      this.setState({
        paymentCompleted: true,
        payWithWalletBalanceInProgress: false,
        currentDetail: null,
      });
      this.toggleModal("payWithWalletModal");
    } catch (e) {
      this.setState({
        payWithWalletBalanceInProgress: false,
      });
    }
  };

  paymentCompleted() {
    this.scrollToTheTop();

    return (
      <div className="text-center">
        <h1>Thank you for shipping with WirePackage!</h1>
        <br />
        <h2>Tracking Number: {this.state.shipment.trackingNumber}</h2>
        <br />
        <h2>Waybill will be sent to your email shortly.</h2>
        <br />
        <br />
        <Button
          className="ml-auto"
          color="primary"
          type="button"
          onClick={async () => {
            this.resetForm();
            await this.updateAddressList();
          }}
        >
          Book Another Shipment
        </Button>
      </div>
    );
  }

  resetForm() {
    const { data } = this.state;
    data.itemDescription = "";
    data.itemQuantity = "";
    data.itemWeight = "";
    data.itemValue = "";
    data.itemCategory = null;
    data.recipientFullname = "";
    data.recipientPhoneNumber = "";
    data.recipientEmail = "";
    data.recipientAddress = "";
    data.recipientPostalCode = "";
    data.recipientCountry = null;
    data.recipientState = null;
    data.signatureRequired = false;
    data.insuranceRequired = false;

    this.setState({
      data,
      shipment: null,
      tab: "tab-0",
      paymentCompleted: false,
      moveToCompleteBooking: false,
      itemList: [],
      currentItem: null,
      currentItemIndex: null,
      packageList: [],
      packageValue: 0,
      packageWeight: 0,
      currentPackage: null,
      currentPackageIndex: null,
      shipmentValue: 0,
      shipmentWeight: 0,
      currentDetail: null,
      checkedInvoiceValueLimit: false,
      showImportDutyInformationModal: false,
    });
  }

  scrollToTheTop() {
    //scroll up window
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  addNewItemModal = () => {
    return (
      <Modal
        className="modal-dialog-centered"
        contentClassName="bg-secondary"
        isOpen={this.state.addNewItemModal}
        toggle={() => this.toggleModal("addNewItemModal")}
        backdrop="static"
      >
        <form
          onSubmit={(e) => {
            this.handleSubmit({
              e,
              submitFunction: () => {
                this.addItem();
                this.toggleModal("addNewItemModal");
              },
              formItemNames: this.addItemFormItems,
            });
          }}
        >
          <div className="modal-header bg-primary">
            <h6
              className="modal-title text-secondary"
              id="modal-title-notification"
            >
              {this.state.currentItem ? "Edit Item" : "New Item"}
            </h6>
            <button
              aria-label="Close"
              className="close text-secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                this.toggleModal("addNewItemModal");
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body p-2">
            <div className="text-center text-white bg-gradient-danger mb-4 font-weight-bold">
              Enter only one (1) item.
            </div>
            {/* <Row
                            className={`${
                                this.state.displayExpressBoxes ? "" : "d-none"
                            }`}
                        >
                          <Col className="col-12">
                          <span className="mr-2 mb-2">
                            What type of item(s) is in this package?
                          </span>
                            <ButtonGroup className="border rounded mt-2">
                            <Button
                              size="sm"
                              color={
                                this.state.packageType === "nonDocument"
                                  ? "primary"
                                  : "white"
                              }
                              onClick={async () => {
                                this.setState({
                                  packageType: "nonDocument",
                                  // itemCategoryList:
                                  //   this.state.nonFoodCategoryList,
                                });
                              }}
                              active={this.state.packageType === "nonDocument"}
                            >
                              Non-Document Item(s)
                            </Button>
                            <Button
                              size="sm"
                              color={
                                this.state.packageType === "document"
                                  ? "primary"
                                  : "white"
                              }
                              onClick={() =>
                                this.setState({
                                  packageType: "document",
                                  // itemCategoryList: this.state.foodCategoryList,
                                })
                              }
                              active={this.state.packageType === "document"}
                            >
                              Document Only Item(s)
                            </Button>
                          </ButtonGroup>
                        </Col>
                      </Row> */}
            {/* <Row>
                        <Col>
                          {this.renderSelect2({
                            mandatory: true,
                            name: "itemCategory",
                            placeholder: "Select Item Category",
                            icon: "ni ni-world-2",
                            options: this.state.itemCategoryList,
                            label: "Item Category",
                            classes: "mb-4",
                          })}
                        </Col>
                      </Row> */}
            <Row className="no-gutter">
              <Col className="col-12">
                {this.renderInput({
                  mandatory: true,
                  name: "itemDescription",
                  label: "Item Description",
                  type: "text",
                  placeholder: "e.g Men's shirt made of cotton",
                  isSmall: false,
                  disallowedTexts: {
                    message: [
                      "Dangerous Good (*&*) detected.",
                      ' "*&*" cannot be shipped!',
                    ],
                    words: this.state.dangerousGoodsList,
                  },
                })}
              </Col>
            </Row>
            <Row className="no-gutter">
              <Col className="col-12">
                {this.renderSelect2({
                  mandatory: true,
                  name: "itemCategory",
                  placeholder: "Select Item Category",
                  icon: "ni ni-world-2",
                  options: this.state.itemCategoryList,
                  label: "Item Category",
                  classes: "mb-4",
                  tooltipConfig: {
                    iconClass: "fas fa-question-circle",
                    id: "itemCategoryTootltip",
                    message:
                      "To avoid shipment delays, you need to help Customs better understand your item by selecting the category that best describes it. It will also help customs clear your package ahead of its arrival.",
                    tooltipOpen: this.state.itemCategoryTootltip,
                    toggle: this.toggleModal,
                  },
                })}
              </Col>
            </Row>
            <Row className="no-gutter">
              <Col className="col-6 col-md-6">
                {this.renderInput({
                  mandatory: true,
                  name: "itemQuantity",
                  label: "Quantity",
                  type: "text",
                  placeholder: "Item Quantity",
                  isSmall: false,
                })}
              </Col>
              <Col className="col-6 col-md-6 pl-0">
                {this.renderInput({
                  mandatory: true,
                  name: "itemValue",
                  label: <>Unit Price (&#8358;)</>,
                  type: "text",
                  placeholder: "Cost for one item",
                  isSmall: false,
                })}
              </Col>
            </Row>
            <hr className="my-0" />
          </div>
          <div className="modal-footer">
            {!this.state.currentItem && (
              <Button
                className="ml-auto"
                color="dark"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  const data = this.state.data;
                  data.itemDescription = "";
                  data.itemQuantity = "";
                  data.itemValue = "";
                  data.itemCategory = null;
                  this.setState({ data });
                  this.toggleModal("addNewItemModal");
                }}
              >
                Cancel
              </Button>
            )}

            {this.renderButton(
              `${
                this.state.currentItem ? "Update Item" : "Add Item to Package"
              }`,
              `${this.state.currentItem ? "btn-info" : "btn-success"}`,
              `${this.state.currentItem ? "Update" : "Add Another"} Item`,
              this.addItemFormItems
            )}
            {this.state.currentItem && (
              <Button
                color="warning"
                onClick={() => {
                  let { data } = this.state;
                  data.itemDescription = "";
                  data.itemQuantity = "";
                  data.itemValue = "";
                  data.itemCategory = null;

                  this.setState({
                    currentItem: null,
                    data,
                  });
                }}
              >
                Cancel Update
              </Button>
            )}
          </div>
        </form>
      </Modal>
    );
  };

  selectExpressBoxModal = () => {
    return (
      <Modal
        className="modal-dialog-centered"
        contentClassName="bg-secondary"
        isOpen={this.state.selectExpressBoxModal}
        toggle={() => this.toggleModal("selectExpressBoxModal")}
        backdrop="static"
      >
        <div className="modal-header bg-primary">
          <h6
            className="modal-title text-secondary"
            id="modal-title-notification"
          >
            Select An Express Box
          </h6>
          <button
            aria-label="Close"
            className="close text-secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              this.toggleModal("selectExpressBoxModal");
            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body p-2">
          <ButtonGroup className="border rounded" vertical>
            {this.loadExpressBoxesOptions()}
          </ButtonGroup>
          <hr className="my-0" />
        </div>
        <div className="modal-footer">
          <Button
            className="ml-auto"
            color="dark"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("selectExpressBoxModal")}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    );
  };

  loadExpressBoxesOptions() {
    let opts = [];

    this.state.expressBoxList.map((item) => {
      opts[opts.length] = (
        <Button
          key={`${"insurance" - opts.length}`}
          className={`ml-0 ${
            opts.length !== this.state.expressBoxList.length - 1 ? "mb-3" : ""
          }`}
          color={
            this.state.selectedExpressBox.id === item.id ? "primary" : "white"
          }
          onClick={() => {
            this.setState({ selectedExpressBox: item });
            this.toggleModal("selectExpressBoxModal");
          }}
          active={this.state.selectedExpressBox.id === item.id}
        >
          <span
            className={`text-justify ${
              this.state.selectedExpressBox.id === item.id && "text-white"
            }`}
          >
            {item.weight}KG Express Box
          </span>
        </Button>
      );
    });

    return opts;
  }

  editShipment() {
    let { currentDetail } = this.state;

    this.initOriginDetails(currentDetail.origin);
    this.initDestinationDetails(currentDetail.destination);

    let packageValue = 0;
    let itemList = [];
    let packageList = [];
    let shipmentValue = 0,
      shipmentWeight = 0;
    currentDetail.items.map((it) => {
      it.itemUnits.map((itu) => {
        const totalValue = Number(itu.quantity) * Number(itu.value);
        packageValue = packageValue + totalValue;
        itemList[itemList.length] = {
          index: itemList.length,
          description: itu.description,
          quantity: Number(itu.quantity),
          value: itu.value,
          category: itu.category,
          categorySelection: {
            label: itu.categoryName,
            value: itu.category,
          },
          packageType: "nonDocument",
          totalValue,
        };
        shipmentValue = shipmentValue + totalValue;
      });
      packageList[packageList.length] = {
        index: packageList.length,
        itemUnits: itemList,
        weight: it.weight,
        value: packageValue,
        packageDimension: [
          it.packageDimension ? it.packageDimension[0] : 0,
          it.packageDimension ? it.packageDimension[1] : 0,
          it.packageDimension ? it.packageDimension[2] : 0,
        ],
        expressBoxId: it.expressBoxId ? it.expressBoxId : 0,
        expressBoxWeight: 0,
      };
      shipmentWeight = shipmentWeight + Number(it.weight);
      itemList = [];
      packageValue = 0;
      this.packageCounter++;
    });

    let { data } = this.state;

    data.signatureRequired = currentDetail.signatureRequired;
    data.insuranceRequired = currentDetail.insurance === "Premium";
    data.pickupRequired = currentDetail.pickup;

    this.setState({
      data,
      insuranceType: currentDetail.insurance,
      shipmentValue,
      shipmentWeight,
      itemList,
      packageValue,
      packageList,
      preferredCourier: currentDetail.courier,
    });

    // const shipment = {
    //   items: packageList,
    //   insurance: insuranceType,
    //   pickup: packageArrivalType === "pickup",
    //   origin: {
    //     fullname: data.senderFullname,
    //     phoneNumber: data.senderPhoneNumber,
    //     email: data.senderEmail,
    //     address: data.senderAddress,
    //     country: data.senderCountry.value,
    //     state: data.senderCountry.value + "_" + data.senderState.value,
    //     city: data.senderCity.value,
    //     cityName: data.senderCity.label,
    //     saveInAddressBook: data.saveOriginAddress,
    //   },
    //   destination: {
    //     fullname: data.recipientFullname,
    //     phoneNumber: data.recipientPhoneNumber,
    //     email: data.recipientEmail,
    //     address: data.recipientAddress,
    //     country: data.recipientCountry.value,
    //     state: data.recipientCountry.value + "_" + data.recipientState.value,
    //     city: data.recipientCity.value,
    //     postalCode: data.recipientPostalCode,
    //     cityName: data.recipientCity.label,
    //     saveInAddressBook: data.saveRecipientAddress,
    //   },
    //   preferredPickupDate:
    //     data.usePreferredPickupDate && preferredPickupDate
    //       ? preferredPickupDate.format(dateFormat)
    //       : null,
    //   preferredPickupDateDesc:
    //     data.usePreferredPickupDate && preferredPickupDate
    //       ? preferredPickupDate.format("dddd, MMM D, YYYY")
    //       : null,
    //   transactionReference: this.state.shipment
    //     ? this.state.shipment.transactionReference
    //     : null,
    //   id: this.state.shipment ? this.state.shipment.id : 0,
    //   value: shipmentValue,
    //   weight: shipmentWeight,
    // };
  }

  importDutyInformationModal = () => {
    const { totalInvoiceAmount } = this.state;
    return (
      <Modal
        className="modal-dialog-centered"
        contentClassName="bg-secondary"
        isOpen={this.state.importDutyInformationModal}
        toggle={() => this.toggleModal("importDutyInformationModal")}
        backdrop="static"
        size="md"
      >
        <div className="modal-header bg-danger">
          <h6 className="modal-title text-white" id="modal-title-notification">
            Import Duty Information
          </h6>
          <button
            aria-label="Close"
            className="close text-secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("importDutyInformationModal")}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="text-danger font-weight-bold">
            <em>Likely Import Duty Payment</em>
          </div>
          <br />
          <div className="text-black">
            When shipping to the UK, the receiver may be requested to pay import
            duty when the invoice amount (insurance amount inclusive) exceeds{" "}
            <b>&#8358; {utils.numberFormatter(UK_INVOICE_AMOUNT_LIMIT, 0)}</b>.
          </div>
          <br />
          <div className="text-black">
            Your current invoice amount is{" "}
            <b>&#8358; {utils.numberFormatter(totalInvoiceAmount, 2)}</b>.
          </div>
        </div>
        <div className="modal-footer bg-white">
          <Button
            className="ml-auto"
            color="dark"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("importDutyInformationModal")}
          >
            Okay, I got this.
          </Button>
        </div>
      </Modal>
    );
  };

  isAdmin() {
    return (
      auth.getCurrentUserRole() === "ROOT" ||
      auth.getCurrentUserRole() === "ADMIN" ||
      auth.getCurrentUserRole() === "MANAGER"
    );
  }
}

export default BookShipment;
