import httpService from "services/httpService";

const baseApiEndPoint = process.env.REACT_APP_API_ENDPOINT;

const apiEndPoint = baseApiEndPoint + "/shippingZone/local";

export async function createLocalShippingZone(model) {
  const { data } = await httpService.post(apiEndPoint, {
    ...model,
  });
  return data;
}

export async function updateLocalShippingZone(model) {
  const { data } = await httpService.put(apiEndPoint + "/" + model.id, {
    ...model,
  });
  return data;
}

export async function getLocalShippingZones(courierCode, originCountryCode) {
  const { data } = await httpService.get(
    apiEndPoint + "/" + courierCode + "/" + originCountryCode
  );
  return data;
}

export async function getLocalShippingZonesDetail(shippingZoneId) {
  const { data } = await httpService.get(
    apiEndPoint + "/detail/" + shippingZoneId
  );
  return data;
}

export async function deleteShippingZone(shippingZoneId) {
  const { data } = await httpService.delete(apiEndPoint + "/" + shippingZoneId);
  return data;
}

export default {
  getLocalShippingZones,
  createLocalShippingZone,
  updateLocalShippingZone,
  getLocalShippingZonesDetail,
  deleteShippingZone,
};
