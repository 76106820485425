import httpService from "services/httpService";
import authService from "services/authService";

const baseApiEndPoint = process.env.REACT_APP_API_ENDPOINT;

const apiEndPoint = baseApiEndPoint + "/business";

export async function update(model) {
  const { data } = await httpService.put(apiEndPoint + "/" + model.id, {
    ...model,
  });
  return data;
}

export async function create(model) {
  const { data } = await httpService.post(apiEndPoint, {
    ...model,
  });
  return data;
}

export async function fetchBusiness() {
  const { data } = await httpService.get(apiEndPoint + "/");
  return data;
}

export async function fetchUserBusiness(userId) {
  const { data } = await httpService.get(apiEndPoint + "/" + userId);
  return data;
}

export async function fetchAll(queryString = "") {
  queryString = queryString ? `?condition=${encodeURI(queryString)}` : "";
  const { data } = await httpService.get(apiEndPoint + "/all" + queryString);
  return data;
}

export async function createAndReload(model) {
  const { data, headers } = await httpService.post(apiEndPoint + "/reload", {
    ...model,
  });

  authService.updateToken(headers.authorization);
  return data;
}

export async function uploadLogo(cacheFileName, businessId, formData) {
  const { data } = await httpService.post(
    apiEndPoint + "/logo/business/" + businessId + "/" + cacheFileName,
    formData
  );
  return data;
}

export async function replaceLogo(logoId, formData) {
  const { data } = await httpService.put(
    apiEndPoint + "/logo/" + logoId,
    formData
  );
  return data;
}

export default {
  create,
  update,
  fetchBusiness,
  fetchUserBusiness,
  fetchAll,
  createAndReload,
  uploadLogo,
  replaceLogo,
};
