import React from "react";
// react component for creating dynamic tables
// reactstrap components
import { Button, Container, Modal, Row, Spinner } from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Joi from "joi-browser";
import ValidationForm from "../../../validation/form";
import { toast } from "react-toastify";
import productService from "services/productService";
// react plugin that prints a given react component
import {
  showCouldNotLoadRecordsMessage,
  showLoadingRecordsMessage,
} from "config/config";
import Col from "reactstrap/lib/Col";
import Resizer from "react-image-file-resizer";
import auth from "services/authService";
import utils from "util/CustomUtil";
// import LazyLoad from "react-lazyload";

const VALIDATING_BUSINESS = "1";
const BUSINESS_NOT_AVAILABLE = "2";
const LOADING_PRODUCTS = "3";
const ERROR_LOADING_PRODUCTS = "4";
const FORM_READY = "5";

class Products extends ValidationForm {
  state = {
    alert: null,
    newBusinessModal: false,
    businessDetailModal: false,
    currentProduct: {},
    productList: [],
    data: {},
    errors: {},
    formSubmitted: false,
    loadingProducts: true,
    detailImageModal: false,
    fileProperties: {
      maxAllowedFileSize: 2097152,
      mimeTypes: ["image/gif", "image/jpg", "image/jpeg", "image/png"],
    },
    cacheFileName: Date.now().toString(),
    imageURL: null,
    defaultImageURL:
      "https://cdn.wirepackage.com/production/default-product.png",
    modalProductDetail: null,
    processingImage: false,
    showNewProductForm: false,
    productCategoryGroupList: [],
    currencyList: [],
    confirmDeleteModal: false,
    deleteProduct: null,
    forceProductListReresh: Date.now().toString(),
    loadStatus: VALIDATING_BUSINESS,
  };

  defaultCurrency = { label: "NGN", value: "NGN" };
  schema = {
    name: Joi.string().max(50).required().label("Name"),
    description: Joi.string().max(100).allow("").label("Description"),
    productCategoryGroup: Joi.object({
      value: Joi.number().min(1).required().label("Value"),
      label: Joi.string().required().label("Label"),
    })
      .required()
      .label("Product Category"),
    currency: Joi.object({
      value: Joi.string().required().label("Value"),
      label: Joi.string().required().label("Label"),
    })
      .required()
      .label("Currency"),
    price: Joi.number().min(1).required().label("Price"),
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  async componentDidMount() {
    try {
      if (auth.isBusinessAvailable()) {
        this.setState({
          loadStatus: LOADING_PRODUCTS,
        });

        const responses = await Promise.all([
          productService.fetchAllForCurrentOrganization(),
          productService.fetchCategoryGroups(),
          productService.fetchCurrencyList(),
        ]);
        const productList = responses[0];
        const productCategoryGroupList = responses[1];
        const currencyList = responses[2];

        const data = this.state.data;
        data.currency = this.defaultCurrency;

        this.setState({
          productList,
          productCategoryGroupList,
          currencyList,
          data,
          loadStatus: FORM_READY,
          showNewProductForm: productList.length === 0,
        });
      } else {
        this.setState({
          loadStatus: BUSINESS_NOT_AVAILABLE,
        });
      }
    } catch (ex) {
      this.setState({
        loadStatus: ERROR_LOADING_PRODUCTS,
      });
    }
  }

  doSubmit = async () => {
    if (!this.state.imageURL) {
      toast.error("You must upload the product's image before saving.");
      return;
    }

    this.setState({ formSubmitted: true });
    let { currentProduct, data, productList } = this.state;
    try {
      if (currentProduct.id) {
        currentProduct = {
          id: currentProduct.id,
          ...data,
          cacheFileName: this.state.cacheFileName,
        };
        currentProduct.productCategoryGroupId = data.productCategoryGroup.value;

        const response = await productService.update(currentProduct);
        console.log(response.data);
        productList[currentProduct.arrayIndex] = response.data;
        const updateProductList = [...productList];
        toast.success(response.message);
        this.setState({
          formSubmitted: false,
          currentProduct: response.data,
          productList: updateProductList,
          forceProductListReresh: Date.now().toString(),
        });
      } else {
        currentProduct = { ...data, cacheFileName: this.state.cacheFileName };
        currentProduct.productCategoryGroupId = data.productCategoryGroup.value;

        const response = await productService.create(currentProduct);
        toast.success(response.message);

        data.name = "";
        data.description = "";
        data.price = "";
        productList.unshift(response.data);
        this.setState({
          formSubmitted: false,
          cacheFileName: Date.now().toString(),
          imageURL: null,
          data,
          productList,
        });
      }
    } catch (ex) {
      this.setState({ formSubmitted: false });
    }
  };

  render() {
    let recordViewComponent;
    if (
      this.state.loadStatus === LOADING_PRODUCTS ||
      this.state.loadStatus === VALIDATING_BUSINESS
    ) {
      recordViewComponent = showLoadingRecordsMessage("Loading products...");
    } else if (this.state.loadStatus === BUSINESS_NOT_AVAILABLE) {
      recordViewComponent = this.requestToCreateBusiness();
    } else if (this.state.loadStatus === ERROR_LOADING_PRODUCTS) {
      recordViewComponent = showCouldNotLoadRecordsMessage(
        "An error occured while loading products. Please check your connectivity..."
      );
    } else {
      recordViewComponent = this.newBusinessModal();
    }

    return (
      <>
        {this.detailImageModal()}
        {this.confirmDeleteModal()}
        <SimpleHeader
          name="My Products"
          parentName="Tables"
          description="Setup products that will appear on your branded tracking page and branded notifications."
        />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">{recordViewComponent}</div>
          </Row>
        </Container>
      </>
    );
  }

  resetProductForm() {
    const data = this.state.data;
    data.name = "";
    data.description = "";
    data.price = "";
    data.productCategoryGroup = null;
    data.currency = this.defaultCurrency;

    this.setState({
      data,
      imageURL: null,
      currentProduct: {},
      cacheFileName: Date.now().toString(),
    });
  }

  updateCurrentProduct(product) {
    const data = this.state.data;
    data.currency = product.currency;
    data.productCategoryGroup = product.productCategoryGroup;
    data.name = product.name;
    data.description = product.description;
    data.price = product.price;

    this.setState({
      currentProduct: product,
      data,
      imageURL: product.imageURL,
    });
  }

  requestToCreateBusiness() {
    return (
      <div className="card border border-primary" style={{ width: "18rem" }}>
        <div className="card-body">
          <p className="card-text">
            To setup products, you need to create a business profile.
          </p>
          <Button
            color="primary"
            size="md"
            onClick={() =>
              this.props.history.push({
                pathname: "/base/business",
                search: "?t=n",
              })
            }
          >
            Create A New Business
          </Button>
        </div>
      </div>
    );
  }

  presentProductList() {
    const productDisplays = [];
    let product = null;
    let product2 = null;

    for (
      let index = 0;
      index < this.state.productList.length;
      index = index + 2
    ) {
      product = this.state.productList[index];

      if (index + 1 === this.state.productList.length) {
        productDisplays[productDisplays.length] = (
          <Row key={productDisplays.length}>
            <Col className="col-12 col-md-6 col-lg-6">
              <div className="rounded-lg border border-light bg-white p-4 shadow-lg mb-4">
                <Row>
                  <Col className="col-12 col-sm-12 col-md-12 col-lg-5">
                    <div style={{ height: 150, width: 150 }}>
                      {/* <LazyLoad height={150}> */}
                      <img
                        src={
                          product.imageURL
                            ? product.imageURL
                            : this.state.defaultImageURL
                        }
                        className="rounded float-center img-thumbnail img-fluid"
                        alt="Product Image"
                        style={{
                          objectFit: "contain",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          this.setState({
                            modalProductDetail: this.state.productList[index],
                          });
                          this.toggleModal("detailImageModal");
                        }}
                      />
                      {/* </LazyLoad>*/}
                    </div>
                  </Col>
                  <Col className="col-12 col-sm-12 col-md-12 col-lg-7">
                    <span className="font-weight-bolder text-dark text-lg text-left">
                      {product.name}
                    </span>
                    <div> {product.description}</div>
                    <div>
                      {product.currency.value}{" "}
                      {utils.numberFormatter(product.price, 2)}
                    </div>
                    <br />
                    <div className="text-left">
                      <Button
                        outline
                        size="sm"
                        color="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          this.updateCurrentProduct({
                            ...this.state.productList[index],
                            arrayIndex: index,
                          });
                          this.setState({ showNewProductForm: true });
                          this.toggleModal("editPhoneNumber");
                        }}
                      >
                        Edit
                      </Button>

                      <Button
                        outline
                        size="sm"
                        color="danger"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            deleteProduct: {
                              ...this.state.productList[index],
                              arrayIndex: index,
                            },
                          });
                          this.toggleModal("confirmDeleteModal");
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        );
      } else {
        product2 = this.state.productList[index + 1];

        productDisplays[productDisplays.length] = (
          <Row key={productDisplays.length}>
            <Col className="col-12 col-md-6 col-lg-6">
              <div className="rounded-lg border border-light bg-white p-4 shadow-lg mb-4">
                <Row>
                  <Col className="col-12 col-sm-12 col-md-12 col-lg-5">
                    <div style={{ height: 150, width: 150 }}>
                      {/* <LazyLoad height={150}> */}
                      <img
                        src={
                          product.imageURL
                            ? product.imageURL
                            : this.state.defaultImageURL
                        }
                        className="rounded float-center img-thumbnail img-fluid"
                        alt="Product Image"
                        style={{
                          objectFit: "contain",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          this.setState({
                            modalProductDetail: this.state.productList[index],
                          });
                          this.toggleModal("detailImageModal");
                        }}
                      />
                      {/* </LazyLoad>*/}
                    </div>
                  </Col>
                  <Col className="col-12 col-sm-12 col-md-12 col-lg-7">
                    <span className="font-weight-bolder text-dark text-lg text-left">
                      {product.name}
                    </span>
                    <div> {product.description}</div>
                    <div>
                      {product.currency.value}{" "}
                      {utils.numberFormatter(product.price, 2)}
                    </div>
                    <br />
                    <div className="text-left">
                      <Button
                        outline
                        size="sm"
                        color="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          this.updateCurrentProduct({
                            ...this.state.productList[index],
                            arrayIndex: index,
                          });
                          this.setState({ showNewProductForm: true });
                          this.toggleModal("editPhoneNumber");
                        }}
                      >
                        Edit
                      </Button>

                      <Button
                        outline
                        size="sm"
                        color="danger"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            deleteProduct: {
                              ...this.state.productList[index],
                              arrayIndex: index,
                            },
                          });
                          this.toggleModal("confirmDeleteModal");
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col className="col-12 col-sm-12 col-md-6 col-lg-6">
              <div className="rounded-lg border border-light bg-white p-4 shadow-lg mb-4">
                <Row>
                  <Col className="col-12 col-sm-12 col-md-12 col-lg-5">
                    <div style={{ height: 150, width: 150 }}>
                      {/* <LazyLoad height={150}> */}{" "}
                      <img
                        src={
                          product2.imageURL
                            ? product2.imageURL
                            : this.state.defaultImageURL
                        }
                        className="rounded float-center img-thumbnail img-fluid"
                        alt="Product Image"
                        style={{
                          objectFit: "contain",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          this.setState({
                            modalProductDetail:
                              this.state.productList[index + 1],
                          });
                          this.toggleModal("detailImageModal");
                        }}
                      />
                      {/* </LazyLoad>*/}
                    </div>
                  </Col>
                  <Col className="col-12 col-sm-12 col-md-12 col-lg-7">
                    <span className="font-weight-bolder text-dark text-lg text-left">
                      {product2.name}
                    </span>
                    <div> {product2.description}</div>
                    <div>
                      {product2.currency.value}{" "}
                      {utils.numberFormatter(product2.price, 2)}
                    </div>
                    <br />
                    <div className="text-left">
                      <Button
                        outline
                        size="sm"
                        color="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          this.updateCurrentProduct({
                            ...this.state.productList[index + 1],
                            arrayIndex: index + 1,
                          });
                          this.setState({ showNewProductForm: true });
                          this.toggleModal("editPhoneNumber");
                        }}
                      >
                        Edit
                      </Button>

                      <Button
                        outline
                        size="sm"
                        color="danger"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            deleteProduct: {
                              ...this.state.productList[index + 1],
                              arrayIndex: index + 1,
                            },
                          });
                          this.toggleModal("confirmDeleteModal");
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        );
      }
    }
    return productDisplays;
  }

  newBusinessModal = () => {
    const currentProduct = this.state.currentProduct;

    return (
      <form
        onSubmit={(e) => {
          this.handleSubmit({
            e,
          });
        }}
      >
        <div
          className={`rounded-lg border border-light bg-white shadow-lg py-3 px-2 ${
            this.state.showNewProductForm ? "" : "d-none"
          }`}
        >
          <Row>
            <Col className="col-12 col-sm-12 col-md-6">
              <div className="bg-lighter mt--2 mb-2 pt-4 px-4 pb-2 rounded">
                <div className="font-weight-bold text-decoration-underline mb-3">
                  <u>
                    {this.state.currentProduct.id ? "Update" : "New"} Product
                  </u>
                </div>
                {this.renderStyledInput({
                  label: "Product Name",
                  mandatory: true,
                  type: "text",
                  name: "name",
                  placeholder: "Product Name",
                  icon: "ni ni-building",
                })}
                {this.renderStyledInput({
                  label: "Product Description",
                  mandatory: false,
                  type: "text",
                  name: "description",
                  placeholder: "Optional",
                  icon: "fas fa-phone",
                })}
                <div className="mb-2">
                  {this.renderSelect2({
                    mandatory: true,
                    name: "productCategoryGroup",
                    placeholder: "Select Category",
                    icon: "ni ni-world-2",
                    options: this.state.productCategoryGroupList,
                    label: "Product Category",
                    classes: "",
                  })}
                </div>
                <Row>
                  <Col className="col-12 col-sm-6">
                    <div className="mb-2">
                      {this.renderSelect2({
                        mandatory: true,
                        name: "currency",
                        placeholder: "Select Currency",
                        icon: "ni ni-world-2",
                        options: this.state.currencyList,
                        label: "Currency",
                        classes: "",
                      })}
                    </div>
                  </Col>
                  <Col className="col-12 col-sm-6">
                    {this.renderStyledInput({
                      label: "Price",
                      mandatory: true,
                      type: "text",
                      name: "price",
                      placeholder: "Optional",
                      icon: "ni ni-pin-3",
                    })}
                  </Col>
                </Row>
              </div>
            </Col>
            <Col className="col-12 col-sm-12 col-md-6">
              <div>
                <div className="d-flex justify-content-center">
                  <div
                    className="mb-2 d-flex justify-content-center"
                    style={{ height: 300, width: 300 }}
                  >
                    <img
                      src={
                        this.state.imageURL
                          ? this.state.imageURL
                          : this.state.defaultImageURL
                      }
                      className="rounded float-center img-fluid"
                      alt="Product Image"
                      style={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <label className="btn btn-primary btn-sm">
                    <span
                      className={this.state.processingImage ? "" : "d-none"}
                    >
                      <Spinner type="border" size="sm"></Spinner>
                    </span>
                    &nbsp; Upload Product Image
                    <input
                      type="file"
                      name="file"
                      className="d-none"
                      onChange={(event) => {
                        this.uploadLogoChangeHandler(event);
                      }}
                    />
                  </label>
                </div>
              </div>
              <div className="text-center mt-5">
                {this.state.currentProduct && this.state.currentProduct.id && (
                  <Button
                    color="light"
                    onClick={(e) => {
                      e.preventDefault();
                      this.resetProductForm();
                    }}
                  >
                    Cancel
                  </Button>
                )}
                {this.renderButton(
                  `${
                    this.currentProduct && this.currentProduct.id
                      ? "Update"
                      : "Save"
                  } Product`,
                  "btn-primary",
                  `${
                    this.currentProduct && this.currentProduct.id
                      ? "Updating..."
                      : "Saving..."
                  }`
                )}
              </div>
            </Col>
          </Row>
        </div>
        <br />
        <div className="d-flex">
          <span className="badge badge-primary text-lg font-weight-normal bg-white text-capitalize text-dark border border-light mr-5">
            Product Count:{" "}
            <span className="font-weight-bolder">
              {this.state.productList.length}
            </span>
          </span>
          <span
            className={`mr-5 ${this.state.showNewProductForm ? "d-none" : ""}`}
          >
            <Button
              size="sm"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                this.toggleModal("showNewProductForm");
              }}
            >
              Add New Product
            </Button>
          </span>
          <span
            className={`ml-auto ${
              this.state.productList.length > 0 ? "" : "d-none"
            }`}
          >
            <Button
              size="sm"
              color="primary"
              type="button"
              onClick={() => this.props.history.push("/base/branded-tracking")}
            >
              Done creating products, next.
            </Button>
          </span>
        </div>
        <br />
        {/* <div className="d-flex flex-wrap"></div> */}
        <div key={this.stateforceProductListReresh}>
          {this.presentProductList()}
        </div>
      </form>
    );
  };

  detailImageModal = () => {
    const modalProductDetail = this.state.modalProductDetail;
    return (
      <Modal
        className="modal-dialog-centered"
        contentClassName="bg-secondary"
        isOpen={this.state.detailImageModal}
        toggle={() => {
          this.toggleModal("detailImageModal");
        }}
        backdrop="static"
        size="lg"
      >
        <div
          className="modal-body p-3 text-justify bg-white text-default d-flex justify-content-center bg-transparents"
          // style={
          //   {
          //     // height: 500,
          //     // width: 500,
          //     // objectFit: "contain",
          //   }
          // }
        >
          <img
            src={
              modalProductDetail
                ? modalProductDetail.imageURL
                : this.state.defaultImageURL
            }
            className="rounded float-center img-fluid"
            alt="Product Image"
            style={{
              maxHeight: "100%",
              maxWidth: "100%",
              // objectFit: "contain",
            }}
          />
        </div>
        <div className="modal-footer reflection-bg-secondary">
          {modalProductDetail && (
            <>
              <span className="font-weight-bolder text-dark text-lg text-left">
                {modalProductDetail.name}
              </span>{" "}
              ||
              <div> {modalProductDetail.description}</div> ||
              <div>
                {modalProductDetail.currency.value}{" "}
                {utils.numberFormatter(modalProductDetail.price, 2)}
              </div>
            </>
          )}
          <Button
            // size="sm"
            className="ml-auto"
            color="primary"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              this.toggleModal("detailImageModal");
            }}
          >
            Close
          </Button>
        </div>
      </Modal>
    );
  };

  confirmDeleteModal = () => {
    const product = this.state.deleteProduct;
    return (
      <Modal
        className="modal-dialog-centered"
        contentClassName="bg-secondary"
        isOpen={this.state.confirmDeleteModal}
        toggle={() => this.toggleModal("confirmDeleteModal")}
        backdrop="static"
      >
        <div className="modal-header bg-danger px-3 pt-3 pb-1 m-0">
          <h6
            className="modal-title text-secondary"
            id="modal-title-notification"
          >
            Product Delete Confirmation
          </h6>
          <button
            aria-label="Close"
            className="close text-secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              this.toggleModal("confirmDeleteModal");
            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body p-2">
          <div className="text-center mb-4 text-lg">
            Confirm you want to delete this product?
          </div>
          {product && (
            <div className="text-center">
              <div className="d-flex justify-content-center">
                <div className="mb-2" style={{ height: 150, width: 150 }}>
                  <img
                    src={
                      product.imageURL
                        ? product.imageURL
                        : this.state.defaultImageURL
                    }
                    className="rounded float-center img-thumbnail img-fluid"
                    alt="Product Image"
                    style={{
                      maxHeight: "100%",
                      maxWidth: "100%",
                      objectFit: "contain",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
              <div className="font-weight-bold text-dark text-lg">
                {product.name}
              </div>
              <div className=" text-lg"> {product.description}</div>
              <div className=" text-lg">
                {product.currency.value}{" "}
                {utils.numberFormatter(product.price, 2)}
              </div>
            </div>
          )}
        </div>
        <div className="modal-footer bg-white">
          <Button
            className="ml-auto"
            color="dark"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("confirmDeleteModal")}
          >
            Cancel
          </Button>
          <Button
            color="danger"
            data-dismiss="modal"
            type="button"
            onClick={async () => {
              const response = await productService.deleteProduct(
                this.state.deleteProduct.id
              );
              toast.success(response.message);
              const productList = this.state.productList;
              productList.splice(this.state.deleteProduct.arrayIndex, 1);
              this.setState({ productList });

              this.toggleModal("confirmDeleteModal");
            }}
          >
            Yes, Delete Product
          </Button>
        </div>
      </Modal>
    );
  };

  async uploadLogoChangeHandler(event) {
    if (!event.target.files[0]) return;

    if (
      !this.isValidSize(event, this.state.fileProperties.maxAllowedFileSize)
    ) {
      toast.error(
        "Allowed file size (" +
          this.state.fileProperties.maxAllowedFileSize +
          " bytes) exceeded"
      );
      return;
    }

    if (!this.isValidMimeType(event, this.state.fileProperties.mimeTypes)) {
      toast.error("Upload file type is unsupported.");
      return;
    }

    this.setState({ processingImage: true });
    let file = event.target.files[0];

    try {
      const formData = new FormData();
      if (file.type.includes("gif")) {
        formData.append("file", file, file.name);
      } else {
        let resizedFile = await this.resizeFile(
          file,
          "file",
          this.getImageType(file.type)
        );
        formData.append("file", resizedFile, file.name);
      }
      let data = null;
      if (this.state.currentProduct && this.state.currentProduct.id) {
        data = await productService.replaceImage(
          this.state.cacheFileName,
          formData
        );
      } else {
        data = await productService.uploadNewImage(
          this.state.cacheFileName,
          formData
        );
      }

      if (file.type.includes("gif")) {
        this.setState({ imageURL: URL.createObjectURL(file) });
      } else {
        let resizedImageUri = await this.resizeFile(
          file,
          "base64",
          this.getImageType(file.type)
        );
        this.setState({ imageURL: resizedImageUri });
      }

      this.setState({ processingImage: false });
    } catch (err) {
      console.log(err);
    }
  }

  getImageType(fileType) {
    if (fileType.includes("jpg") || fileType.includes("jpeg")) {
      return "JPEG";
    } else if (fileType.includes("png")) {
      return "PNG";
    } else if (fileType.includes("gif")) {
      return "GIF";
    }
  }

  resizeFile = (file, outputType, fileType) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1080,
        1080,
        fileType,
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        outputType
      );
    });

  isValidMimeType(event, acceptedTypes) {
    //getting file object
    let file = event.target.files[0];
    let isValid = false;
    // compare file type find doesn't matach
    acceptedTypes.map((type) => {
      if (file.type === type) {
        isValid = true;
      }
    });
    if (isValid) {
      return true;
    }
    event.target.value = null;
    return false;
  }

  isValidSize(event, size) {
    //getting file object
    const file = event.target.files[0];

    if (file && file.size <= size) return true;

    event.target.value = null;
    return false;
  }
}

export default Products;
