import httpService from "services/httpService";

const baseApiEndPoint = process.env.REACT_APP_API_ENDPOINT;

const apiEndPoint = baseApiEndPoint + "/wallet";

export async function fetchWallet() {
  const { data } = await httpService.get(apiEndPoint + "/");
  return data;
}

export async function fetchUserWallet(userId) {
  const { data } = await httpService.get(
    apiEndPoint + "/advanced/user/" + userId
  );
  return data;
}

export async function fetchWalletList(queryString) {
  queryString = queryString ? `?condition=${encodeURI(queryString)}` : "";
  const { data } = await httpService.get(apiEndPoint + "/all" + queryString);
  return data;
}

export async function makeWithdrawal(amount, creditAccountId) {
  const { data } = await httpService.post(apiEndPoint + "/withdraw", {
    amount,
    creditAccountId,
  });
  return data;
}

export async function fetchPayoutAccounts() {
  const { data } = await httpService.get(apiEndPoint + "/payoutAccounts/NGN");
  return data;
}

export async function performOperation(operationDetail) {
  const { data } = await httpService.post(
    apiEndPoint + "/advanced/wallet_operation",
    { ...operationDetail }
  );
  return data;
}

export default {
  fetchWallet,
  fetchUserWallet,
  fetchWalletList,
  makeWithdrawal,
  fetchPayoutAccounts,
  performOperation,
};
