import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {
  pagination,
  showCouldNotLoadRecordsMessage,
  showLoadingRecordsMessage,
  showNoRecordsMessage,
} from "config/config";
import { Card, FormGroup } from "reactstrap";
import Container from "reactstrap/lib/Container";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ValidationForm from "../../../validation/form";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import Joi from "joi-browser";
import Button from "reactstrap/lib/Button";
import ReactToPrint from "react-to-print";
import TableActionButtons from "../admin/TableActionButtons";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
import transactionsService from "services/transactionsService";
import utils from "util/CustomUtil";

const dateFormat = "YYYY-MM-DD";
class TransactionHistory extends ValidationForm {
  state = {
    data: {},
    errors: {},
    formSubmitted: true,
    isLoading: false,
    searchTriggered: false,
    currentHistory: null,
    startDate: "",
    endDate: "",
    history: [],
    historyModal: false,
  };

  schema = {
    trackingNumber: Joi.string().max(20).required().label("Tracking Number"),
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  handleReactDatetimeChange = (who, date) => {
    if (
      this.state.startDate &&
      who === "endDate" &&
      new Date(this.state.startDate._d + "") > new Date(date._d + "")
    ) {
      this.setState({
        startDate: date,
        endDate: null,
      });
    } else if (
      this.state.endDate &&
      who === "startDate" &&
      new Date(this.state.endDate._d + "") < new Date(date._d + "")
    ) {
      this.setState({
        startDate: date,
        endDate: date,
      });
    } else {
      this.setState({
        [who]: date,
      });
    }
  };
  // this function adds on the day tag of the date picker
  // middle-date className which means that this day will have no border radius
  // start-date className which means that this day will only have left border radius
  // end-date className which means that this day will only have right border radius
  // this way, the selected dates will look nice and will only be rounded at the ends
  getClassNameReactDatetimeDays = (date) => {
    if (this.state.startDate && this.state.endDate) {
    }
    if (
      this.state.startDate &&
      this.state.endDate &&
      this.state.startDate._d + "" !== this.state.endDate._d + ""
    ) {
      if (
        new Date(this.state.endDate._d + "") > new Date(date._d + "") &&
        new Date(this.state.startDate._d + "") < new Date(date._d + "")
      ) {
        return " middle-date";
      }
      if (this.state.endDate._d + "" === date._d + "") {
        return " end-date";
      }
      if (this.state.startDate._d + "" === date._d + "") {
        return " start-date";
      }
    }
    return "";
  };

  async componentDidMount() {
    try {
      this.setState({ formSubmitted: true, searchTriggered: true });
      const history = await transactionsService.fetchRecentTransactionHistory();
      this.setState({ formSubmitted: false, history });
    } catch (e) {
      this.setState({ formSubmitted: false });
    }
  }

  render() {
    let { isLoading } = this.state;
    let recordViewComponent;
    if (this.state.formSubmitted) {
      recordViewComponent = showLoadingRecordsMessage(
        "Loading transaction history... Please wait."
      );
    } else if (this.state.couldNotLoadTableRecords) {
      recordViewComponent = showCouldNotLoadRecordsMessage(
        "Could not loading transaction history. Please try again."
      );
    } else {
      recordViewComponent =
        this.state.history.length > 0
          ? this.loadTable()
          : showNoRecordsMessage(
              this.state.searchTriggered
                ? "No records found! You may need to modify the search date."
                : "Your search results will show up here!"
            );
    }

    return (
      <>
        <SimpleHeader
          name="Transaction History"
          parentName="Tables"
          description="View list of transactions on your wallet."
        />
        <Container className="mt--6" fluid>
          <form onSubmit={this.handleSubmit}>
            <Row>
              <Col xs={12} sm={4} className="offset-md-1">
                <label className=" form-control-label">Start date</label>
                <FormGroup>
                  <ReactDatetime
                    inputProps={{
                      placeholder: "Date Picker Here",
                    }}
                    value={this.state.startDate}
                    timeFormat={false}
                    onChange={(e) =>
                      this.handleReactDatetimeChange("startDate", e)
                    }
                    renderDay={(props, currentDate, selectedDate) => {
                      let classes = props.className;
                      classes +=
                        this.getClassNameReactDatetimeDays(currentDate);
                      return (
                        <td {...props} className={classes}>
                          {currentDate.date()}
                        </td>
                      );
                    }}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={4}>
                <FormGroup>
                  <label className=" form-control-label">End date</label>
                  <ReactDatetime
                    inputProps={{
                      placeholder: "Date Picker Here",
                    }}
                    value={this.state.endDate}
                    timeFormat={false}
                    onChange={(e) =>
                      this.handleReactDatetimeChange("endDate", e)
                    }
                    renderDay={(props, currentDate, selectedDate) => {
                      let classes = props.className;
                      classes +=
                        this.getClassNameReactDatetimeDays(currentDate);
                      return (
                        <td {...props} className={classes}>
                          {currentDate.date()}
                        </td>
                      );
                    }}
                  />
                </FormGroup>
              </Col>
              <Col
                className="col-lg-2 align-self-center pt-2"
                xs={12}
                sm={3}
                md={2}
              >
                <Button
                  size="md"
                  color="primary"
                  block
                  disabled={
                    !this.state.startDate ||
                    !this.state.endDate ||
                    this.state.formSubmitted
                  }
                  onClick={async (e) => {
                    e.preventDefault();
                    this.setState({
                      searchTriggered: true,
                      formSubmitted: true,
                      history: [],
                    });
                    try {
                      const history =
                        await transactionsService.fetchTransactionHistory(
                          this.state.startDate.format(dateFormat),
                          this.state.endDate.format(dateFormat)
                        );
                      this.setState({ formSubmitted: false, history });
                    } catch (e) {
                      this.setState({ formSubmitted: false });
                    }
                  }}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </form>
          <Row>
            <div className="col mt-4">{recordViewComponent}</div>
          </Row>
        </Container>
      </>
    );
  }

  handleSubmit() {}

  loadTable() {
    return (
      <Card>
        <ToolkitProvider
          data={this.state.history}
          keyField="index"
          columns={[
            {
              dataField: "index",
              text: "#",
              sort: true,
            },
            {
              dataField: "paymentDate",
              text: "Date",
              sort: true,
            },
            {
              dataField: "type",
              text: "Type",
              sort: true,
              formatter: (cell, row) => {
                return row.type.replace(/_/g, "");
              },
            },
            {
              dataField: "comment",
              text: "Comment",
              sort: true,
            },
            {
              dataField: "amount",
              text: <>Amount (&#8358;)</>,
              sort: true,
              formatter: (cell, row) => {
                return (
                  <span
                    className={`font-weight-bold ${
                      row.isCredit ? " reflection-text-success" : "text-danger"
                    }`}
                  >
                    {row.isCredit ? "+" : "-"}
                    {utils.numberFormatter(row.amount, 2)}
                  </span>
                );
              },
            },
          ]}
          search
        >
          {(props) => (
            <div className="py-4 table-responsive">
              <TableActionButtons {...props}>
                <ReactToPrint
                  trigger={() => (
                    <Button
                      color="primary"
                      size="sm"
                      className="buttons-copy buttons-html5"
                      id="print-tooltip"
                    >
                      Print
                    </Button>
                  )}
                  content={() => this.componentRef}
                />
              </TableActionButtons>
              <BootstrapTable
                size="sm"
                condensed
                hover
                ref={(el) => (this.componentRef = el)}
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                id="react-bs-table"
                rowClasses="reflection-pointer"
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    );
  }
}

export default TransactionHistory;
