import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {
  pagination,
  showCouldNotLoadRecordsMessage,
  showLoadingRecordsMessage,
  showNoRecordsMessage,
  renderFormPanel,
  renderFieldGrouping,
} from "config/config";
import {
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Modal,
  Spinner,
} from "reactstrap";
import Container from "reactstrap/lib/Container";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ValidationForm from "../../../../validation/form";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import Joi from "joi-browser";
import Button from "reactstrap/lib/Button";
import ReactToPrint from "react-to-print";
import TableActionButtons from "../../admin/TableActionButtons";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
import transactionsService from "services/transactionsService";
import utils from "util/CustomUtil";
import walletService from "services/walletService";
import { toast } from "react-toastify";
import shipmentService from "services/shipmentService";
import Timeline from "views/pages/member/timeline/Timeline";

class UserWallet extends ValidationForm {
  state = {
    data: {},
    errors: {},
    formSubmitted: true,
    isLoading: false,
    history: [],
    creditConfirmationModal: false,
    debitConfirmationModal: false,
    walletOperationInProgress: false,
    walletOperation: null,
    trackingModal: false,
    fetchingTrackingInfo: false,
    trackingDetail: null,
    historyModal: false,
    currentHistory: null,
    fetchingDetail: false,
  };

  schema = {
    amount: Joi.number().min(0.1).max(1000000).required().label("Amount"),
    comment: Joi.string().min(2).max(100).required().label("Comment"),
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  componentDidMount() {
    this.refreshPageView();
  }

  async refreshPageView() {
    try {
      this.setState({ formSubmitted: true });
      const history =
        await transactionsService.fetchUserRecentTransactionHistory(
          this.props.selectedUser.id
        );
      const wallet = await walletService.fetchUserWallet(
        this.props.selectedUser.id
      );

      this.setState({ formSubmitted: false, history, wallet });
    } catch (e) {
      this.setState({ formSubmitted: false });
    }
  }

  render() {
    let { isLoading } = this.state;
    let recordViewComponent;
    if (this.state.formSubmitted) {
      recordViewComponent = showLoadingRecordsMessage(
        "Loading transaction history... Please wait."
      );
    } else if (this.state.couldNotLoadTableRecords) {
      recordViewComponent = showCouldNotLoadRecordsMessage(
        "Could not loading transaction history. Please try again."
      );
    } else {
      recordViewComponent =
        this.state.history.length > 0
          ? this.loadTable()
          : showNoRecordsMessage("No records found!");
    }

    return (
      <>
        {this.creditConfirmationModal()}
        {this.debitConfirmationModal()}
        {this.historyModal()}
        {this.trackingModal()}
        <Row>
          <Col className="col-12 col-sm-6 col-md-4">
            {this.loadBalanceView(
              this.loadBalance(
                this.state.wallet ? this.state.wallet.cashBalance : -1,
                "white"
              ),
              "Wallet Balance",
              "bg-gradient-primary",
              "text-white"
            )}
            {this.loadBalanceView(
              this.loadBalance(
                this.state.wallet ? this.state.wallet.cashBackBalance : -1,
                "white"
              ),
              "Cashback Balance",
              "bg-gradient-default",
              "text-white"
            )}
          </Col>
          <Col className="col-12 col-sm-6 col-md-4">
            <div>
              <form
                onSubmit={(e) => {
                  this.handleSubmit({
                    e,
                    submitFunction: () => this.handleDebitOrCreditRequest(),
                    formItemNames: ["amount", "comment"],
                  });
                }}
              >
                <div>
                  {this.renderInput({
                    mandatory: true,
                    type: "text",
                    name: "amount",
                    placeholder: "Enter Amount",
                    isSmall: true,
                  })}
                  {this.renderInput({
                    mandatory: true,
                    type: "text",
                    name: "comment",
                    placeholder: "Enter Comment",
                    isSmall: true,
                  })}
                </div>
                <div className="text-right">
                  {this.renderSimpleButton({
                    label: "Debit",
                    classs: "btn-danger btn-sm",
                    spinnerLabel: "Debit",
                    formItemNames: ["amount", "comment"],
                    busy: this.state.walletOperationInProgress,
                    onclick: () => {
                      this.setState({ walletOperation: "DEBIT" }, () =>
                        this.handleDebitOrCreditRequest()
                      );
                    },
                  })}
                  -OR-
                  {this.renderSimpleButton({
                    label: "Credit",
                    classs: "btn-success btn-sm",
                    spinnerLabel: "Credit",
                    formItemNames: ["amount", "comment"],
                    busy: this.state.walletOperationInProgress,
                    onclick: () => {
                      this.setState({ walletOperation: "CREDIT" }, () =>
                        this.handleDebitOrCreditRequest()
                      );
                    },
                  })}
                </div>
              </form>
            </div>
          </Col>
        </Row>
        <br />
        {recordViewComponent}
      </>
    );
  }

  handleDebitOrCreditRequest() {
    const { walletOperation } = this.state;

    if (walletOperation === "CREDIT") {
      this.toggleModal("creditConfirmationModal");
    } else if (walletOperation === "DEBIT") {
      this.toggleModal("debitConfirmationModal");
    } else {
      alert("Unknown Operation: " + walletOperation);
    }
  }

  async performOperation() {
    const { amount, comment } = this.state.data;
    const { walletOperation } = this.state;

    if (walletOperation != "CREDIT" && walletOperation != "DEBIT") {
      alert("Unknown Operation: " + walletOperation);
      return;
    }

    this.setState({ walletOperationInProgress: true });
    try {
      const response = await walletService.performOperation({
        amount,
        comment,
        walletUserId: this.props.selectedUser.id,
        walletId: this.state.wallet.id,
        isCredit: walletOperation === "CREDIT" ? true : false,
      });
      toast.success(response.message);
      const { data } = this.state;
      data.amount = "";
      data.comment = "";
      this.setState({ walletOperationInProgress: false, data });
      this.refreshPageView();
      this.toggleModal(
        walletOperation === "CREDIT"
          ? "creditConfirmationModal"
          : "debitConfirmationModal"
      );
    } catch (ex) {
      this.setState({ walletOperationInProgress: false });
    }
  }

  loadTable() {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        if (row.shipmentId) {
          this.setState({ fetchingDetail: true });
          this.loadShipmentDetail(row.shipmentId);
          this.toggleModal("historyModal");
        }
      },
    };

    return (
      <Card>
        <ToolkitProvider
          data={this.state.history}
          keyField="index"
          columns={[
            {
              dataField: "index",
              text: "#",
              sort: true,
            },
            {
              dataField: "paymentDate",
              text: "Date",
              sort: true,
            },
            {
              dataField: "type",
              text: "Type",
              sort: true,
              formatter: (cell, row) => {
                return row.type.replace(/_/g, "");
              },
            },
            {
              dataField: "comment",
              text: "Comment",
              sort: true,
            },
            {
              dataField: "amount",
              text: <>Amount (&#8358;)</>,
              sort: true,
              formatter: (cell, row) => {
                return (
                  <span
                    className={`font-weight-bold ${
                      row.isCredit ? " reflection-text-success" : "text-danger"
                    }`}
                  >
                    {row.isCredit ? "+" : "-"}
                    {utils.numberFormatter(row.amount, 2)}
                  </span>
                );
              },
            },
          ]}
          search
        >
          {(props) => (
            <div className="py-4 table-responsive">
              <TableActionButtons {...props}>
                <ReactToPrint
                  trigger={() => (
                    <Button
                      color="primary"
                      size="sm"
                      className="buttons-copy buttons-html5"
                      id="print-tooltip"
                    >
                      Print
                    </Button>
                  )}
                  content={() => this.componentRef}
                />
              </TableActionButtons>
              <BootstrapTable
                size="sm"
                condensed
                hover
                ref={(el) => (this.componentRef = el)}
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                id="react-bs-table"
                rowClasses="reflection-pointer"
                rowEvents={rowEvents}
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    );
  }

  loadBalanceView(balance, desc, bgColor, textColor) {
    return (
      <div className={`${bgColor} border border-light rounded h5 p-2`}>
        <div className={`${textColor} text-uppercase`}>{desc}</div>
        {balance}
      </div>
    );
  }

  loadBalance(balance, color) {
    return (
      <span className={`h3 font-weight-bold mb-0 text-${color}`}>
        &#8358;{" "}
        {balance === -1 ? "loading..." : utils.numberFormatter(balance, 2)}
      </span>
    );
  }

  creditConfirmationModal = () => {
    const { amount, comment } = this.state.data;
    return (
      <Modal
        className="modal-dialog-centered"
        contentClassName="bg-secondary"
        isOpen={this.state.creditConfirmationModal}
        toggle={() => this.toggleModal("creditConfirmationModal")}
        backdrop="static"
        size="md"
      >
        <div className="modal-header bg-default">
          <h6 className="modal-title text-white" id="modal-title-notification">
            Wallet CREDIT Confirmation
          </h6>
          <button
            aria-label="Close"
            className="close text-secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("creditConfirmationModal")}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="text-black">
            Please confirm you want to <b>CREDIT</b> the wallet below:
          </div>
          <br /> <br />
          <Row>
            <Col className="col-6">
              <span className="text-underline text-default">Wallet Owner:</span>
              <div className="font-weight-bold text-primary h2">
                {this.props.selectedUser.firstname}
              </div>
              {this.props.selectedUser.username}
            </Col>
            <Col className="col-6">
              <span className="text-underline text-default">
                <b>CREDIT Amount:</b>
              </span>
              <div className="font-weight-bold text-primary h2">
                &#8358; {utils.numberFormatter(amount, 2)}
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col className="col-12">
              <span className="text-underline text-default">Reason:</span>
              <div className="font-weight-bold text-primary h2">{comment}</div>
            </Col>
          </Row>
        </div>
        <div className="modal-footer bg-white">
          <Button
            className="ml-auto"
            color="dark"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("creditConfirmationModal")}
          >
            Close
          </Button>
          <Button
            color="danger"
            type="button"
            onClick={async () => {
              this.performOperation();
            }}
            disabled={this.state.walletOperationInProgress}
          >
            <span
              className={this.state.walletOperationInProgress ? "" : "d-none"}
            >
              <Spinner type="border" size="sm"></Spinner> Crediting Wallet...
            </span>
            <span
              className={this.state.walletOperationInProgress ? "d-none" : ""}
            >
              Yes, <b>CREDIT</b> Wallet &#8358;{" "}
              {utils.numberFormatter(amount, 2)}
            </span>
          </Button>
        </div>
      </Modal>
    );
  };

  debitConfirmationModal = () => {
    const { amount, comment } = this.state.data;
    return (
      <Modal
        className="modal-dialog-centered"
        contentClassName="bg-secondary"
        isOpen={this.state.debitConfirmationModal}
        toggle={() => this.toggleModal("debitConfirmationModal")}
        backdrop="static"
        size="md"
      >
        <div className="modal-header bg-danger">
          <h6 className="modal-title text-white" id="modal-title-notification">
            Wallet DEBIT Confirmation
          </h6>
          <button
            aria-label="Close"
            className="close text-secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("debitConfirmationModal")}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="text-black">
            Please confirm you want to <b>DEBIT</b> the wallet below:
          </div>
          <br /> <br />
          <Row>
            <Col className="col-6">
              <span className="text-underline text-default">Wallet Owner:</span>
              <div className="font-weight-bold text-primary h2 mb-0">
                {this.props.selectedUser.firstname}
              </div>
              {this.props.selectedUser.username}
            </Col>
            <Col className="col-6">
              <span className="text-underline text-default">
                <b>DEBIT Amount:</b>
              </span>
              <div className="font-weight-bold text-primary h2">
                &#8358; {utils.numberFormatter(amount, 2)}
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col className="col-12">
              <span className="text-underline text-default">Reason:</span>
              <div className="font-weight-bold text-primary h2">{comment}</div>
            </Col>
          </Row>
        </div>
        <div className="modal-footer bg-white">
          <Button
            className="ml-auto"
            color="dark"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("debitConfirmationModal")}
          >
            Close
          </Button>
          <Button
            color="danger"
            type="button"
            onClick={async () => {
              this.performOperation();
            }}
            disabled={this.state.walletOperationInProgress}
          >
            <span
              className={this.state.walletOperationInProgress ? "" : "d-none"}
            >
              <Spinner type="border" size="sm"></Spinner> Debiting Wallet...
            </span>
            <span
              className={this.state.walletOperationInProgress ? "d-none" : ""}
            >
              Yes, <b>DEBIT</b> Wallet &#8358;{" "}
              {utils.numberFormatter(amount, 2)}
            </span>
          </Button>
        </div>
      </Modal>
    );
  };

  historyModal = () => {
    const currentHistory = this.state.currentHistory;
    return (
      <Modal
        className="modal-dialog-centered"
        contentClassName="bg-secondary"
        isOpen={this.state.historyModal}
        toggle={() => this.toggleModal("historyModal")}
        backdrop="static"
        size="lg"
      >
        <div className="modal-header bg-primary">
          <h6
            className="modal-title text-secondary"
            id="modal-title-notification"
          >
            Shipment Detail
          </h6>
          <button
            aria-label="Close"
            className="close text-secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("historyModal")}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          {this.state.fetchingDetail
            ? showLoadingRecordsMessage(
                "Loading shipment detail... Please wait."
              )
            : currentHistory && (
                <>
                  <div className="text-right">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={async () => {
                        const currentHistory = this.state.currentHistory;
                        this.loadTrackingDetail(currentHistory);
                        this.toggleModal("trackingModal");
                        this.toggleModal("historyModal");
                      }}
                    >
                      <i class="fas fa-exchange-alt"></i> show tracking history
                    </Button>
                  </div>
                  <br />
                  <Row>
                    <Col className="col-12 col-lg-6">
                      {renderFormPanel({
                        title: "Sender",
                        content: (
                          <>
                            <Row>
                              <Col className="mb-2">
                                <div className="text-muted">Fullname</div>
                                <div className="font-weight-bold">
                                  {currentHistory.origin.fullname}
                                </div>
                              </Col>
                              <Col className="mb-2">
                                <div className="text-muted">Phone No:</div>
                                <div className="font-weight-bold">
                                  {currentHistory.origin.phoneNumber}
                                </div>
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              <Col>
                                <div className="text-muted">Email:</div>
                                <div className="font-weight-bold">
                                  {currentHistory.origin.email}
                                </div>
                              </Col>
                            </Row>
                            <div className="border-bottom border-light pb-2 mb-3" />
                            <Row>
                              <Col className="mb-2">
                                <div className="text-muted">Address:</div>
                                <div className="font-weight-bold">
                                  {currentHistory.origin.address}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mb-2">
                                <div className="text-muted">City</div>
                                <div className="font-weight-bold">
                                  {currentHistory.origin.cityName}
                                </div>
                              </Col>
                              <Col className="mb-2">
                                <div className="text-muted">State:</div>
                                <div className="font-weight-bold">
                                  {currentHistory.origin.stateName}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mb-2">
                                <div className="text-muted">Country</div>
                                <div className="font-weight-bold">
                                  {currentHistory.origin.countryName}
                                </div>
                              </Col>
                              <Col className="mb-2">
                                <div className="text-muted">Postal Code:</div>
                                <div className="font-weight-bold">
                                  {currentHistory.origin.postalCode}
                                </div>
                              </Col>
                            </Row>
                          </>
                        ),
                        bgColor: "bg-white",
                      })}
                    </Col>
                    <Col className="col-12 col-lg-6">
                      {renderFormPanel({
                        title: "Recipient",
                        content: (
                          <>
                            <Row>
                              <Col className="mb-2">
                                <div className="text-muted">Fullname</div>
                                <div className="font-weight-bold">
                                  {currentHistory.destination.fullname}
                                </div>
                              </Col>
                              <Col className="mb-2">
                                <div className="text-muted">Phone No:</div>
                                <div className="font-weight-bold">
                                  {currentHistory.destination.phoneNumber}
                                </div>
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              <Col>
                                <div className="text-muted">Email:</div>
                                <div className="font-weight-bold">
                                  {currentHistory.destination.email}
                                </div>
                              </Col>
                            </Row>
                            <div className="border-bottom border-light pb-2 mb-3" />
                            <Row>
                              <Col className="mb-2">
                                <div className="text-muted">Address:</div>
                                <div className="font-weight-bold">
                                  {currentHistory.destination.address}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mb-2">
                                <div className="text-muted">City</div>
                                <div className="font-weight-bold">
                                  {currentHistory.destination.cityName}
                                </div>
                              </Col>
                              <Col className="mb-2">
                                <div className="text-muted">State:</div>
                                <div className="font-weight-bold">
                                  {currentHistory.destination.stateName}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mb-2">
                                <div className="text-muted">Country</div>
                                <div className="font-weight-bold">
                                  {currentHistory.destination.countryName}
                                </div>
                              </Col>
                              <Col className="mb-2">
                                <div className="text-muted">Postal Code:</div>
                                <div className="font-weight-bold">
                                  {currentHistory.destination.postalCode}
                                </div>
                              </Col>
                            </Row>
                          </>
                        ),
                        bgColor: "bg-white",
                      })}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {renderFormPanel({
                        title: "Item(s)",
                        content: (
                          <>
                            {this.state.currentHistory.items.map(
                              (item, index) => {
                                return (
                                  <>
                                    <div className="d-none d-md-block">
                                      <span className="font-weight-bold pr-3">
                                        Package
                                      </span>
                                      {this.state.currentHistory.items.length >
                                        1 && (
                                        <span className="font-weight-bold pr-3">
                                          {index + 1} -
                                        </span>
                                      )}
                                      <span className="pull-right pr-3">
                                        Weight: {item.weight} Kg,
                                      </span>
                                      <span className="pull-right">
                                        Value: &#8358;
                                        {utils.numberFormatter(
                                          Number(item.value),
                                          2
                                        )}
                                      </span>
                                    </div>

                                    <div className="d-block d-md-none">
                                      <span className="font-weight-bold pr-3">
                                        Package
                                      </span>
                                      {this.state.currentHistory.items.length >
                                        1 && (
                                        <span className="font-weight-bold pr-3">
                                          {index + 1} -
                                        </span>
                                      )}
                                      <span className="pull-right pr-3">
                                        W: {item.weight} Kg,
                                      </span>
                                      <span className="pull-right">
                                        V: &#8358;
                                        {utils.numberFormatter(
                                          Number(item.value),
                                          2
                                        )}
                                      </span>
                                    </div>
                                    {this.loadItemConfirmationTable(
                                      item.itemUnits
                                    )}
                                  </>
                                );
                              }
                            )}

                            {currentHistory.shipmentDimension && (
                              <>
                                <div className="border-bottom border-light pb-3 mb-3" />
                                <Row>
                                  <Col className="col-12 col-md-4">
                                    Shipment Dimension:
                                  </Col>
                                  <Col>
                                    <div className="font-weight-bold">
                                      {currentHistory.shipmentDimension[0]} X{" "}
                                      {currentHistory.shipmentDimension[1]} X{" "}
                                      {currentHistory.shipmentDimension[2]}
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            )}
                          </>
                        ),
                        bgColor: "bg-white",
                      })}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {renderFormPanel({
                        title: "Others",
                        content: (
                          <>
                            <Row>
                              <Col className="mb-2">
                                <div className="text-muted">Insurance Type</div>
                                <div className="font-weight-bold">
                                  {currentHistory.insurance}
                                </div>
                              </Col>
                              <Col className="mb-2">
                                <div className="text-muted">
                                  How will your package get to the hub?
                                </div>
                                <div className="font-weight-bold">
                                  {currentHistory.pickupText}
                                </div>
                              </Col>
                            </Row>
                          </>
                        ),
                        bgColor: "bg-white",
                      })}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {renderFormPanel({
                        title: "Payment",
                        content: (
                          <>
                            <Row>
                              <Col className="mb-2">
                                <div className="text-muted">Gross Weight</div>
                                <div className="font-weight-bold">
                                  {utils.numberFormatter(
                                    Number(currentHistory.grossWeight),
                                    2
                                  )}{" "}
                                  Kg
                                </div>
                              </Col>
                              <Col className="mb-2">
                                <div className="text-muted">Payment Amount</div>
                                <div className="font-weight-bold">
                                  &#8358;{" "}
                                  {utils.numberFormatter(
                                    Number(currentHistory.paymentAmount),
                                    2
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mb-2">
                                <div className="text-muted">Status</div>
                                <div className="font-weight-bold text-uppercase">
                                  {currentHistory.status}
                                </div>
                                <br />
                                {currentHistory.status.toUpperCase() ===
                                  "AWAITING_PAYMENT_CONFIRMATION" && (
                                  <>
                                    <Button
                                      color="primary"
                                      size="sm"
                                      onClick={() => {
                                        this.setState({
                                          moveToCompleteBooking: true,
                                          completeBookingShipmentId:
                                            currentHistory.id,
                                        });
                                      }}
                                    >
                                      Complete Booking
                                    </Button>
                                    <Button
                                      outline
                                      color="danger"
                                      size="sm"
                                      onClick={async () => {
                                        await shipmentService.cancelBooking(
                                          currentHistory.id
                                        );
                                        // const incompleteBookings =
                                        //   this.state.incompleteBookings;
                                        // incompleteBookings.splice(index, 1);
                                        // this.setState({ incompleteBookings });
                                      }}
                                    >
                                      Ignore
                                    </Button>
                                  </>
                                )}
                              </Col>
                            </Row>
                          </>
                        ),
                        bgColor: "bg-white",
                      })}
                    </Col>
                  </Row>
                </>
              )}
        </div>
        <div className="modal-footer">
          <Button
            className="ml-auto"
            color="dark"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("historyModal")}
          >
            Close
          </Button>
        </div>
      </Modal>
    );
  };

  trackingModal = () => {
    const trackingDetail = this.state.trackingDetail;
    return (
      <Modal
        className="modal-dialog-centered"
        contentClassName="bg-secondary"
        isOpen={this.state.trackingModal}
        toggle={() => this.toggleModal("trackingModal")}
        backdrop="static"
        size="lg"
      >
        <div className="modal-header bg-primary">
          <h6
            className="modal-title text-secondary"
            id="modal-title-notification"
          >
            Tracking Information
          </h6>

          <button
            aria-label="Close"
            className="close text-secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("trackingModal")}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          {this.state.fetchingTrackingInfo
            ? showLoadingRecordsMessage("Loading... Please wait.")
            : trackingDetail && (
                <>
                  <div className="text-right">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={async () => {
                        const currentHistory = this.state.currentHistory;
                        this.loadShipmentDetail(trackingDetail.id);
                        this.toggleModal("historyModal");
                        this.toggleModal("trackingModal");
                      }}
                    >
                      <i class="fas fa-exchange-alt"></i> show details
                    </Button>
                  </div>
                  <br />
                  {true &&
                    renderFieldGrouping(
                      null,
                      <div className="pl-2 py-2">
                        <div className="bg-white p-2 rounded mb-2">
                          <Row>
                            <Col className="col-5">
                              {this.deliveryText()}
                              <h5 className="text-primary">
                                {this.deliveryDay()}
                              </h5>
                            </Col>
                            <Col className="col-2">{this.deliveryIcon()}</Col>
                            <Col className="text-right">
                              Route
                              <h5 className="text-primary">
                                {trackingDetail.fromLocation}{" "}
                                <span className="fas fa-arrow-right" />{" "}
                                {trackingDetail.toLocation}
                              </h5>
                            </Col>
                          </Row>
                        </div>
                        <Timeline
                          items={trackingDetail.trackingList}
                          format="hh:mm a"
                        />
                      </div>,
                      "bg-light"
                    )}
                </>
              )}
        </div>
        <div className="modal-footer">
          <Button
            className="ml-auto"
            color="dark"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("trackingModal")}
          >
            Close
          </Button>
        </div>
      </Modal>
    );
  };

  async loadShipmentDetail(id) {
    const detail = await shipmentService.fetchShipmentDetail(id);
    this.setState({ fetchingDetail: false, currentHistory: detail });
  }

  loadItemConfirmationTable(items) {
    return (
      <Card className="mb-0">
        <ToolkitProvider
          data={items}
          keyField="index"
          columns={[
            {
              dataField: "index",
              text: "#",
              sort: true,
              classes: "d-none d-md-block",
              headerClasses: "d-none d-md-block",
            },
            {
              dataField: "description",
              text: "Desc",
              sort: true,
            },
            {
              dataField: "quantity",
              text: "Qty",
              sort: true,
            },
            {
              dataField: "value",
              text: <div className="text-right">Unit Price (&#8358;) </div>,
              sort: true,
              formatter: (cell, row) => {
                return (
                  <div className="text-right">
                    {utils.numberFormatter(Number(row.value), 2)}
                  </div>
                );
              },
            },
          ]}
          search
        >
          {(props) => (
            <div className="py-4 table-responsive">
              <BootstrapTable
                size="sm"
                ref={(el) => (this.componentRef = el)}
                {...props.baseProps}
                bootstrap4={true}
                // pagination={pagination}
                bordered={false}
                id="react-bs-table"
                striped
                hover
                condensed
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    );
  }

  async loadTrackingDetail(shipmentDetail) {
    this.setState({ fetchingTrackingInfo: true });
    const trackingDetail = await shipmentService.trackShipment(
      shipmentDetail.trackingNumber
    );
    trackingDetail.id = shipmentDetail.id;
    this.setState({
      fetchingTrackingInfo: false,
      trackingDetail,
    });
  }

  deliveryText() {
    if (this.state.trackingDetail.delivered) {
      return "Date Delivered";
    } else if (this.state.trackingDetail.deliveryDay) {
      return "Estimated Delivery Date";
    }
  }

  deliveryDay() {
    if (this.state.trackingDetail.deliveryDay) {
      return this.state.trackingDetail.deliveryDay;
    }
  }

  deliveryIcon() {
    if (this.state.trackingDetail.delivered) {
      return (
        <span class="text-success">
          <i class="fas fa-check-circle fa-2xl"></i>
        </span>
      );
    } else if (this.state.trackingDetail.deliveryDay) {
      return <Spinner type="grow" className="text-primary"></Spinner>;
    }
  }
}

export default UserWallet;
