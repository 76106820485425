/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import { Button, Col, Container, Row } from "reactstrap";

class TimelineHeader extends React.Component {
  render() {
    return (
      <>
        <div className="header header-dark pb-6 content__title content__title--calendar">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center pb-1 pt-3">
                <Col lg="8" md="8" sm="8" xs="8">
                  <h2
                    className={`${
                      this.props.color ? this.props.color : "text-primary"
                    }`}
                  >
                    {this.props.name}
                  </h2>
                </Col>

                {this.props.newItemButtonLabel && (
                  <Col className="mt-0 text-right">
                    {this.props.additionalButton}
                    <Button
                      className="btn-primary"
                      color="primary"
                      size="sm"
                      onClick={() => this.props.toggleModal()}
                    >
                      {this.props.newItemButtonLabel}
                    </Button>
                  </Col>
                )}
              </Row>
              <hr className="mt-0 mb-1" />
              {this.props.goBack && this.backButton(this.props.goBack)}
              {this.props.description && (
                <p className="text-black d-inline-block">
                  {this.props.description}
                </p>
              )}
            </div>
          </Container>
        </div>
      </>
    );
  }

  backButton(goBack) {
    return (
      <Button className="btn btn-link" size="sm" onClick={goBack.action}>
        <i className="fas fa-undo" />
      </Button>
    );
  }
}

TimelineHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default TimelineHeader;
