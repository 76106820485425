import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// Core Components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import { Link } from "react-router-dom";

function Error404() {
  React.useEffect(() => {
    document.body.classList.add("error-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("error-page");
    };
  });
  return (
    <>
      <DemoNavbar />
      <div className="wrapper">
        <div className="page-header error-page">
          <div
            className="page-header-image"
            style={{
              backgroundImage: "url(" + require("assets/img/ill/404.png") + ")",
              opacity: 0.2,
            }}
          ></div>
          <Container className="reflection-bg- mt-lg">
            <Row>
              <Col className="text-center" md="12">
                <h3 className="text-default">
                  Ooooops! Looks like you got lost.
                </h3>
                <h1 className="title text-default">404</h1>
                <h3>The page you are looking for has not shipped!</h3>
                <Link to="/">
                  <span className="bg-primary text-white p-1 px-3 rounded">
                    back to home page
                  </span>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Error404;
