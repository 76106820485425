import React from "react";
import Container from "reactstrap/lib/Container";
import ValidationForm from "../../../validation/form";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import Joi from "joi-browser";
import { renderFieldGrouping, showLoadingRecordsMessage } from "config/config";
import Timeline from "views/pages/member/timeline/Timeline";
import shipmentService from "services/shipmentService";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import Alert from "reactstrap/lib/Alert";
import { Card, CardBody } from "reactstrap";

class TrackShipment extends ValidationForm {
  state = {
    data: {},
    errors: {},
    formSubmitted: false,
    isLoading: false,
    trackingDetail: {},
  };

  schema = {
    trackingNumber: Joi.number().min(1).required().label("Tracking Number"),
  };

  componentDidMount() {
    const data = this.state.data;
    const trackingDetail = this.props.trackingDetail;
    if (trackingDetail) {
      this.setState({
        trackingDetail,
        formSubmitted: false,
      });
    } else {
      const trackingNumber = this.props.match.params.trackingNumber;
      if (trackingNumber) {
        data.trackingNumber = this.props.match.params.trackingNumber;
        this.setState({ data });
        this.doSubmit();
      }
    }
  }

  render() {
    let { isLoading } = this.state;

    return (
      <>
        <DemoNavbar type="white" titleMessage="Track Shipment" />

        {/* <SimpleHeader
          name="Track Shipment"
          parentName="Tables"
          description="Track your shipments using the tracking numbers."
        /> */}
        <Container fluid>
          <br />
          <br />
          <br />
          <br />
          <div className="min-vh-100">
            <Row>
              <Col className="offset-xs-1 col-xs-8 offset-sm-1 col-sm-9 offset-md-1 col-md-9">
                {this.state.formSubmitted ? (
                  <>
                    <br />
                    {showLoadingRecordsMessage(
                      "Tracking your shipment... Please wait."
                    )}
                  </>
                ) : !this.state.trackingDetail.trackingList ||
                  this.state.trackingDetail.trackingList.length === 0 ? (
                  <Alert color="default" className="text-center">
                    No tracking information.
                  </Alert>
                ) : (
                  renderFieldGrouping(
                    null,
                    <div className="pl-2 py-2">
                      <div className="bg-white p-2 rounded mb-2">
                        <Row>
                          <Col>
                            {this.deliveryText()}
                            <h5 className="text-primary">
                              {this.deliveryDay()}
                            </h5>
                          </Col>
                          <Col className="text-right">
                            Route
                            <h5 className="text-primary">
                              {this.state.trackingDetail.origin.country}{" "}
                              <span className="fas fa-arrow-right" />{" "}
                              {
                                this.state.trackingDetail.destination
                                  .countryName
                              }
                            </h5>
                          </Col>
                        </Row>
                        {this.state.trackingDetail.accessPointAddress && (
                          <Row>
                            <Col>
                              <Card className="bg-primary text-white m-0 p-0">
                                <CardBody className="border border-lighter rounded text-center p-2 m-0">
                                  <div>
                                    <h6 className="p-0 m-0 text-underline text-white">
                                      Pick up your package at this address (
                                      <em>an ID is required to pickup</em>
                                      ):
                                    </h6>
                                    <span className="text-md font-weight-bold">
                                      {
                                        this.state.trackingDetail
                                          .accessPointAddress.address
                                      }
                                      {this.state.trackingDetail
                                        .accessPointAddress.city
                                        ? ", " +
                                          this.state.trackingDetail
                                            .accessPointAddress.city
                                        : ""}
                                      {this.state.trackingDetail
                                        .accessPointAddress.country
                                        ? ", " +
                                          this.state.trackingDetail
                                            .accessPointAddress.country
                                        : ""}
                                      {this.state.trackingDetail
                                        .accessPointAddress.postalCode
                                        ? ". " +
                                          this.state.trackingDetail
                                            .accessPointAddress.postalCode
                                        : ""}
                                    </span>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        )}
                      </div>
                      <Timeline
                        items={this.state.trackingDetail.trackingList}
                        format="hh:mm a"
                      />
                      <div className="text-dark text-sm text-center">
                        <a
                          href="https://cdn.wirepackage.com/files/terms.html"
                          target="_blank"
                          className="text-primary"
                        >
                          Terms
                        </a>{" "}
                        and{" "}
                        <a
                          href="https://cdn.wirepackage.com/files/privacy-policy.html"
                          target="_blank"
                          className="text-primary"
                        >
                          Privacy Policy
                        </a>
                      </div>
                    </div>,
                    "bg-light"
                  )
                )}
              </Col>
            </Row>
          </div>
        </Container>
        <DemoFooter />
      </>
    );
  }

  deliveryText() {
    if (this.state.trackingDetail.delivered) {
      return "Date Delivered";
    } else if (this.state.trackingDetail.deliveryDay) {
      return "Estimated Delivery Date";
    }
  }

  deliveryDay() {
    if (this.state.trackingDetail.deliveryDay) {
      return this.state.trackingDetail.deliveryDay;
    }
  }

  statusText() {
    if (this.state.trackingDetail.delivered) {
      return "Package Delivered";
    } else if (this.state.trackingDetail.deliveryDay) {
      return "Your package is on the way.";
    }
  }

  async doSubmit() {
    this.setState({
      formSubmitted: true,
      trackingDetail: {},
    });
    try {
      const trackingDetail = await shipmentService.trackShipment(
        this.state.data.trackingNumber
      );
      this.setState({
        trackingDetail,
        formSubmitted: false,
      });
    } catch (e) {
      this.setState({
        formSubmitted: false,
      });
    }
  }
}

export default TrackShipment;
