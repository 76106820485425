import React from "react";
import paginationFactory from "react-bootstrap-table2-paginator";
import Spinner from "reactstrap/lib/Spinner";
// reactstrap components
import { Badge } from "reactstrap";
import Card from "reactstrap/lib/Card";
import CardHeader from "reactstrap/lib/CardHeader";
import CardBody from "reactstrap/lib/CardBody";
import TooltipIcon from "components/TooltipIcon";

export const toastOptions = {
  autoClose: 5000,
  position: "top-right",
  hideProgressBar: true,
};

export const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

export const showLoadingRecordsMessage = (message) => {
  return (
    <div className="text-center align-middle">
      <Badge color="default">
        <Spinner type="border" size="sm"></Spinner>{" "}
        <span className="ml-1">
          {message || "Loading records. Please wait..."}
        </span>
      </Badge>
    </div>
  );
};

export const showCouldNotLoadRecordsMessage = (message) => {
  return (
    <div className="text-center align-middle">
      <Badge color="danger">
        <i className="fas fa-exclamation" />{" "}
        <span className="ml-1">{message || "Could not load records..."}</span>
      </Badge>
    </div>
  );
};

export const showNoRecordsMessage = (message) => {
  return (
    <div className="text-center align-middle">
      <Badge color="default">
        <i className="fas fa-exclamation" />{" "}
        <span className="ml-1">{message || "No records available."}</span>
      </Badge>
    </div>
  );
};

export const renderFieldGrouping = (title, fields, classs, titleClass) => {
  return (
    <div
      className={`border rounded-sm mb-4 px-2 pt-2 ${
        classs ? classs : "bg-secondary"
      }`}
    >
      {title && (
        <h2>
          <span
            className={`badge text-white ${
              titleClass ? titleClass : "bg-primary"
            }`}
          >
            {title}
          </span>
        </h2>
      )}
      {fields}
    </div>
  );
};

export const renderFieldGroupingForHomePage = (
  title,
  fields,
  classs,
  titleClass
) => {
  return (
    <div
      className={`border rounded-sm mb-4 px-2 pt-2 ${
        classs ? classs : "bg-secondary"
      }`}
    >
      {title && (
        <h3>
          <span
            className={`badge text-white ${
              titleClass ? titleClass : "bg-primary"
            }`}
          >
            {title}
          </span>
        </h3>
      )}
      {fields}
    </div>
  );
};

export const loadingComponent = () => {
  return (
    <div className="d-flex vh-100 justify-content-center">
      <div className="align-self-center bg-secondary">
        <Spinner color="primary" type="border" size="xl"></Spinner>
      </div>
    </div>
  );
};

export const renderFormPanel = ({
  title,
  content,
  bgColor,
  supplimentaryTitle,
  infoIcon,
}) => {
  return (
    <div className="card-wrapper">
      <Card className="border border-light">
        <CardHeader className="px-3 py-2">
          {/* <span className="badge badge-primary mb-0">{title}</span> */}
          <h2 className="mb-0 text-primary">
            {title} {infoIcon && <TooltipIcon {...infoIcon} />}
          </h2>
          {supplimentaryTitle && (
            <p className="float-right text-muted h3 mb-0">
              {supplimentaryTitle}
            </p>
          )}
        </CardHeader>
        <CardBody
          className={`${bgColor ? bgColor : "bg-light"}  p-3 text-default`}
        >
          {content}
        </CardBody>
      </Card>
    </div>
  );
};

export default {
  toastOptions,
  pagination,
  showLoadingRecordsMessage,
  showCouldNotLoadRecordsMessage,
  showNoRecordsMessage,
  renderFieldGrouping,
  renderFieldGroupingForHomePage,
  loadingComponent,
  renderFormPanel,
};
