import React from "react";
import Container from "reactstrap/lib/Container";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ValidationForm from "../../../validation/form";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import Joi from "joi-browser";
import { renderFieldGrouping, showLoadingRecordsMessage } from "config/config";
import Timeline from "./timeline/Timeline";
import shipmentService from "services/shipmentService";
import Alert from "reactstrap/lib/Alert";

class TrackShipment extends ValidationForm {
  state = {
    data: {},
    errors: {},
    formSubmitted: false,
    isLoading: false,
    trackingDetail: {},
  };

  schema = {
    trackingNumber: Joi.number().min(1).required().label("Tracking Number"),
  };

  render() {
    let { isLoading } = this.state;

    return (
      <>
        <SimpleHeader
          name="Track Shipment"
          parentName="Tables"
          description="Track your shipments using the tracking numbers."
        />
        <Container className="mt--6" fluid>
          {/* <div className="min-vh-100"> */}
          <form
            role="form"
            onSubmit={(e) => {
              this.handleSubmit({ e });
            }}
          >
            <Row>
              <Col className="xs-12 col-xs-8 offset-sm-2 col-sm-5 col-md-5">
                {this.renderStyledInput({
                  mandatory: true,
                  type: "text",
                  name: "trackingNumber",
                  placeholder: "Tracking Number",
                  icon: "fas fa-search-location",
                  classes: "text-lg",
                })}
              </Col>
              <Col>
                {this.renderButton(
                  "Track Shipment",
                  "btn-primary btn-lg",
                  "Tracking..."
                )}
              </Col>
            </Row>
          </form>
          <Row>
            <Col className="offset-xs-1 col-xs-8 offset-sm-1 col-sm-9 offset-md-2 col-md-7">
              {this.state.formSubmitted ? (
                <>
                  <br />
                  {showLoadingRecordsMessage(
                    "Tracking your shipment... Please wait."
                  )}
                </>
              ) : !this.state.trackingDetail.trackingList ||
                this.state.trackingDetail.trackingList.length === 0 ? (
                <Alert color="default" className="text-center">
                  Your tracking detail will show up here.
                </Alert>
              ) : (
                renderFieldGrouping(
                  null,
                  <div className="pl-2 py-2">
                    <div className="bg-white p-2 rounded mb-2">
                      <Row>
                        <Col>
                          {this.deliveryText()}
                          <h5 className="text-primary">{this.deliveryDay()}</h5>
                        </Col>
                        <Col className="text-right">
                          Route
                          <h5 className="text-primary">
                            {this.state.trackingDetail.origin.country}{" "}
                            <span className="fas fa-arrow-right" />{" "}
                            {this.state.trackingDetail.destination.countryName}
                          </h5>
                        </Col>
                      </Row>
                    </div>
                    <Timeline
                      items={this.state.trackingDetail.trackingList}
                      format="hh:mm a"
                    />
                  </div>,
                  "bg-light"
                )
              )}
            </Col>
          </Row>
          {/* </div> */}
        </Container>
      </>
    );
  }

  deliveryText() {
    if (this.state.trackingDetail.delivered) {
      return "Date Delivered";
    } else if (this.state.trackingDetail.deliveryDay) {
      return "Estimated Delivery Date";
    }
  }

  deliveryDay() {
    if (this.state.trackingDetail.deliveryDay) {
      return this.state.trackingDetail.deliveryDay;
    }
  }

  async doSubmit() {
    this.setState({
      formSubmitted: true,
      trackingDetail: {},
    });
    try {
      const trackingDetail = await shipmentService.trackShipment(
        this.state.data.trackingNumber
      );
      this.setState({
        trackingDetail,
        formSubmitted: false,
      });
    } catch (e) {
      this.setState({
        formSubmitted: false,
      });
    }
  }
}

export default TrackShipment;
