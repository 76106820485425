import httpService from "services/httpService";

const baseApiEndPoint = process.env.REACT_APP_API_ENDPOINT;

const apiEndPoint = baseApiEndPoint + "/shippingZone";

export async function createInternationalShippingZone(ExportOrImport, model) {
  const { data } = await httpService.post(
    apiEndPoint + "/international/" + ExportOrImport,
    {
      ...model,
    }
  );
  return data;
}

export async function updateInternationalShippingZone(model) {
  const { data } = await httpService.put(
    apiEndPoint + "/international/" + model.id,
    {
      ...model,
    }
  );
  return data;
}

export async function getInternationalShippingZones(
  ExportOrImport,
  courierCode,
  originCountryCode
) {
  const { data } = await httpService.get(
    apiEndPoint +
      "/international/" +
      ExportOrImport +
      "/" +
      courierCode +
      "/" +
      originCountryCode
  );
  return data;
}

export async function getInternationalShippingZonesByGeo(
  ExportOrImport,
  courierCode,
  originCountryCode,
  geographyCode
) {
  const { data } = await httpService.get(
    apiEndPoint +
      "/international/" +
      ExportOrImport +
      "/" +
      courierCode +
      "/" +
      originCountryCode +
      "/" +
      geographyCode
  );
  return data;
}

export async function getInternationalShippingZonesDetail(
  shippingZoneId,
  geographyCode
) {
  const { data } = await httpService.get(
    apiEndPoint +
      "/international/detail/" +
      shippingZoneId +
      "/" +
      geographyCode
  );
  return data;
}

export async function deleteShippingZone(shippingZoneId) {
  const { data } = await httpService.delete(
    apiEndPoint + "/international/" + shippingZoneId
  );
  return data;
}

export default {
  getInternationalShippingZones,
  getInternationalShippingZonesByGeo,
  createInternationalShippingZone,
  updateInternationalShippingZone,
  getInternationalShippingZonesDetail,
  deleteShippingZone,
};
