import React from "react";
import { Col, Row } from "reactstrap";

const Switch = ({
  onColor = "wirepackage-bg-success",
  label = "Insurance",
  mandatory = true,
  fieldIsProcessing = false,
  checked,
  name,
  ...rest
}) => {
  return (
    <>
      <div className="d-flex flex-row">
        <div className="align-self-center mr-2">
          <input
            className="react-switch-checkbox"
            id={name}
            type="checkbox"
            name={name}
            checked={checked}
            {...rest}
          />
          <label
            className={` mt--3 react-switch-label ${checked && onColor}`}
            htmlFor={name}
          >
            <span className={`react-switch-button`} />
          </label>
        </div>
        <div className="align-self-center">
          {label && (
            <span
              // className=""
              htmlFor={name}
              // style={{
              //   width: "400px",
              //   position: "absolute",
              //   marginLeft: "60px",
              // }}
            >
              {mandatory && <span className="text-danger">*</span>} {label}
              {fieldIsProcessing}
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default Switch;
