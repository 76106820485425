import React from "react";
import { UncontrolledTooltip } from "reactstrap";

function TooltipIcon(props) {
  return (
    <>
      <i class="fas fa-info-circle" id={props.id}></i>

      <UncontrolledTooltip
        innerClassName="bg-dark w-100"
        delay={0}
        placement="auto"
        target={props.id}
      >
        <div className="text-left  w-100">{props.message}</div>
      </UncontrolledTooltip>
    </>
  );
}

export default TooltipIcon;