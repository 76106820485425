import httpService from "services/httpService";

const baseApiEndPoint = process.env.REACT_APP_API_ENDPOINT;

const apiEndPoint = baseApiEndPoint + "/geography";

export async function fetchRegions() {
  const { data } = await httpService.get(apiEndPoint + "/region/all");
  return data;
}

export async function fetchContinents() {
  const { data } = await httpService.get(apiEndPoint + "/continent/all");
  return data;
}

export async function fetchCountryRegions(countryCode) {
  const { data } = await httpService.get(
    apiEndPoint + "/region/country/" + countryCode
  );
  return data;
}

export async function fetchStateRegions(countryCode, stateCode) {
  const { data } = await httpService.get(
    apiEndPoint + "/region/country/" + countryCode + "/state/" + stateCode
  );
  return data;
}

export default {
  fetchRegions,
  fetchContinents,
  fetchCountryRegions,
  fetchStateRegions,
};
