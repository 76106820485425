import httpService from "./httpService";
import jwtDecode from "jwt-decode";
import {
  ACCESS_TOKEN,
  LOGIN_ADMIN_URL,
  LOGIN_DASHBOARD_URL,
  LOGIN_VIEW,
  LOGIN_VIEW_ADMIN,
  LOGIN_VIEW_DASHBOARD,
  REFERRAL_CODE,
} from "constants/index";

const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;

httpService.setJwt(getJwt());

export async function ping(component) {
  await httpService.post(apiEndPoint + "/ping");
  component.tokenIsValid();
}

export async function completeSignUp(component, phoneNumber, referralCode) {
  await httpService.post(apiEndPoint + "/completeSignUp", {
    phoneNumber,
    referralCode,
  });
  component.tokenIsValid();
}

export async function login(username, password) {
  const { data, headers } = await httpService.post(apiEndPoint + "/login", {
    username,
    password,
  });
  updateToken(headers.authorization);

  window.location.replace(resolveLandingPageUrl());
  return data;
}

export async function signUp(userDetails) {
  const { data } = await httpService.post(apiEndPoint + "/account", {
    ...userDetails,
  });
  return data;
}

export async function verifyAccount(signUpToken) {
  const { data, headers } = await httpService.get(
    apiEndPoint + "/account?token=" + encodeURI(signUpToken)
  );
  updateToken(headers.authorization);
  return data;
}

export function resolveLandingPageUrl() {
  if (getCurrentUserRole() === "ADMIN") {
    if (isDashboardView()) {
      return LOGIN_DASHBOARD_URL;
    } else if (isAdminView()) {
      return LOGIN_ADMIN_URL;
    } else {
      switchViewToAdmin();
      return LOGIN_ADMIN_URL;
    }
  } else if (
    getCurrentUserRole() === "MEMBER" ||
    getCurrentUserRole() === "FRANCHISE" ||
    getCurrentUserRole() === "MANAGER"
  ) {
    return LOGIN_DASHBOARD_URL;
  }
}

export async function resetPassword(username) {
  const { data } = await httpService.put(apiEndPoint + "/resetPassword", {
    username,
  });
  return data;
}

export function loginWithJwt(jwt) {
  localStorage.setItem(ACCESS_TOKEN, jwt);
}

export function logout() {
  httpService.deleteJwt();
  localStorage.removeItem(ACCESS_TOKEN);
}

export function getJwt() {
  return localStorage.getItem(ACCESS_TOKEN);
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(ACCESS_TOKEN);
    return jwtDecode(jwt);
  } catch (error) {
    return null;
  }
}

export function getCurrentUserRole() {
  try {
    const jwt = localStorage.getItem(ACCESS_TOKEN);
    return jwtDecode(jwt).role.replace("ROLE_", "");
  } catch (error) {
    return null;
  }
}

export function getCurrentUserEmail() {
  try {
    const jwt = localStorage.getItem(ACCESS_TOKEN);
    return jwtDecode(jwt).sub;
  } catch (error) {
    return null;
  }
}

export function getCurrentUserFullname() {
  try {
    const jwt = localStorage.getItem(ACCESS_TOKEN);
    const user = jwtDecode(jwt);
    return user.firstname + " " + user.lastname;
  } catch (error) {
    return null;
  }
}

export function getCurrentOrganization() {
  try {
    const jwt = localStorage.getItem(ACCESS_TOKEN);
    return jwtDecode(jwt).organization;
  } catch (error) {
    return null;
  }
}

export function updateToken(newToken) {
  localStorage.setItem(ACCESS_TOKEN, newToken);
  httpService.setJwt(newToken);
}

export async function fetchUserOrganizations() {
  const { data } = await httpService.get(
    apiEndPoint + "/account/organizations"
  );
  return data;
}

export async function switchToOrganization(organizationId) {
  const { data, headers } = await httpService.put(
    apiEndPoint + "/switch/" + organizationId
  );
  updateToken(headers.authorization);
  return data;
}

export function switchViewToDashboard() {
  localStorage.setItem(LOGIN_VIEW, LOGIN_VIEW_DASHBOARD);
}

export function switchViewToAdmin() {
  localStorage.setItem(LOGIN_VIEW, LOGIN_VIEW_ADMIN);
}

export function isDashboardView() {
  return localStorage.getItem(LOGIN_VIEW) === LOGIN_VIEW_DASHBOARD;
}

export function isAdminView() {
  return localStorage.getItem(LOGIN_VIEW) === LOGIN_VIEW_ADMIN;
}

export function getOrganizationCode() {
  try {
    const jwt = localStorage.getItem(ACCESS_TOKEN);
    return jwtDecode(jwt).subdomain;
  } catch (error) {
    return null;
  }
}

export function isBusinessAvailable() {
  try {
    const jwt = localStorage.getItem(ACCESS_TOKEN);
    const decoded = jwtDecode(jwt);
    return decoded.subdomain && decoded.organization > 2;
  } catch (error) {
    return null;
  }
}

export function updateReferralCode(referralCode) {
  localStorage.setItem(REFERRAL_CODE, referralCode);
  httpService.setReferralCode(referralCode);
}

export function getReferralCode() {
  return localStorage.getItem(REFERRAL_CODE);
}

export function updateReferralCodeHeader() {
  const refcode = getReferralCode();
  if (refcode) {
    httpService.setReferralCode(refcode);
  }
}

export default {
  login,
  signUp,
  completeSignUp,
  verifyAccount,
  loginWithJwt,
  logout,
  getCurrentOrganization,
  getCurrentUser,
  getCurrentUserRole,
  getCurrentUserEmail,
  getCurrentUserFullname,
  getJwt,
  resetPassword,
  updateToken,
  ping,
  fetchUserOrganizations,
  switchToOrganization,
  switchViewToDashboard,
  switchViewToAdmin,
  isDashboardView,
  isAdminView,
  resolveLandingPageUrl,
  getOrganizationCode,
  isBusinessAvailable,
  updateReferralCode,
  getReferralCode,
  updateReferralCodeHeader,
};
