import React from "react";

const Radio = ({
  id,
  name,
  label,
  error,
  className,
  mandatory,
  fieldIsProcessing,
  ...rest
}) => {
  return (
    <div>
      <div className="custom-control custom-radio">
        <input
          id={id}
          name={name}
          type="radio"
          className={`custom-control-input ${error ? "is-invalid" : ""}`}
          {...rest}
        />
        <label className="custom-control-label font-weight-bold" htmlFor={id}>
          {mandatory && <span className="text-danger">*</span>} {label}{" "}
          {fieldIsProcessing}
        </label>
      </div>
      {error ? <div className="text-danger invalid-feedback">{error}</div> : ""}
    </div>
  );
};

export default Radio;
