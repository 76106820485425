import httpService from "services/httpService";

const baseApiEndPoint = process.env.REACT_APP_API_ENDPOINT;

const apiEndPoint = baseApiEndPoint + "/organization";

export async function fetch(id) {
  const { data } = await httpService.get(apiEndPoint + "/" + id);
  return data;
}

export async function update(model) {
  const { data } = await httpService.put(apiEndPoint + "/" + model.id, {
    ...model,
  });
  return data;
}

export async function create(model) {
  const { data } = await httpService.post(apiEndPoint, {
    ...model,
  });
  return data;
}

export async function fetchOrganization() {
  const { data } = await httpService.get(apiEndPoint + "/");
  return data;
}

export async function fetchUserOrganization(userId) {
  const { data } = await httpService.get(apiEndPoint + "/" + userId);
  return data;
}

export async function fetchAll(queryString = "") {
  queryString = queryString ? `?condition=${encodeURI(queryString)}` : "";
  const { data } = await httpService.get(apiEndPoint + "/all" + queryString);
  return data;
}

export async function makeWithdrawal(amount, creditAccountId) {
  const { data } = await httpService.post(apiEndPoint + "/withdraw", {
    amount,
    creditAccountId,
  });
  return data;
}

export async function fetchBillingGroups() {
  const { data } = await httpService.get(apiEndPoint + "/init/billingGroups");
  return data;
}

export default {
  create,
  update,
  fetchOrganization,
  fetchUserOrganization,
  fetchAll,
  makeWithdrawal,
  fetchBillingGroups,
};
