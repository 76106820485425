import httpService from "services/httpService";

const baseApiEndPoint = process.env.REACT_APP_API_ENDPOINT;

const apiEndPoint = baseApiEndPoint + "/hub";

export async function fetchShipmentHistory(startDate, endDate, category) {
  const { data } = await httpService.get(
    apiEndPoint +
      "/history?startDate=" +
      startDate +
      "&endDate=" +
      endDate +
      "&category=" +
      category
  );
  return data;
}

export async function fetchShipmentHistoryRecentRecord() {
  const { data } = await httpService.get(apiEndPoint + "/history/recent");
  return data;
}

export async function shipmentReceived(shipmentId) {
  const { data } = await httpService.put(
    apiEndPoint + "/shipment/" + shipmentId + "/receive"
  );
  return data;
}

export default {
  fetchShipmentHistory,
  fetchShipmentHistoryRecentRecord,
  shipmentReceived,
};
