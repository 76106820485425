import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {
  pagination,
  renderFormPanel,
  showCouldNotLoadRecordsMessage,
  showLoadingRecordsMessage,
  showNoRecordsMessage,
} from "config/config";
import { Card, FormGroup } from "reactstrap";
import Container from "reactstrap/lib/Container";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ValidationForm from "../../../validation/form";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import Button from "reactstrap/lib/Button";
import ReactToPrint from "react-to-print";
import TableActionButtons from "../admin/TableActionButtons";
import Modal from "reactstrap/lib/Modal";
import Joi from "joi-browser";
import { toast } from "react-toastify";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
import DropdownItem from "reactstrap/lib/DropdownItem";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import UncontrolledDropdown from "reactstrap/lib/UncontrolledDropdown";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import hubService from "services/hubService";
import shipmentService from "services/shipmentService";
import utils from "util/CustomUtil";
import CompleteBooking from "./bookshipment/CompleteBooking";

const dateFormat = "YYYY-MM-DD";

const SEARCH_CATEGORY_LIST = [
  {
    value: "region",
    label: "My Region",
  },
  {
    value: "hub",
    label: "My Hub",
  },
];

class HubShippingHistory extends ValidationForm {
  state = {
    data: {},
    errors: {},
    formSubmitted: true,
    isLoading: false,
    startDate: "",
    endDate: "",
    history: [],
    historyModal: false,
    currentHistory: null,
    searchTriggered: false,
    fetchingDetail: false,
    moveToCompleteBooking: false,
    completeBookingShipmentId: 0,
  };
  schema = {
    searchCategory: Joi.object({
      value: Joi.string().required().label("Value"),
      label: Joi.string().required().label("Label"),
    })
      .required()
      .label("Geography"),
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };
  // this function adds on the day tag of the date picker
  // middle-date className which means that this day will have no border radius
  // start-date className which means that this day will only have left border radius
  // end-date className which means that this day will only have right border radius

  handleReactDatetimeChange = (who, date) => {
    if (
      this.state.startDate &&
      who === "endDate" &&
      new Date(this.state.startDate._d + "") > new Date(date._d + "")
    ) {
      this.setState({
        startDate: date,
        endDate: null,
      });
    } else if (
      this.state.endDate &&
      who === "startDate" &&
      new Date(this.state.endDate._d + "") < new Date(date._d + "")
    ) {
      this.setState({
        startDate: date,
        endDate: date,
      });
    } else {
      this.setState({
        [who]: date,
      });
    }
  };

  // this way, the selected dates will look nice and will only be rounded at the ends
  getClassNameReactDatetimeDays = (date) => {
    if (
      this.state.startDate &&
      this.state.endDate &&
      this.state.startDate._d + "" !== this.state.endDate._d + ""
    ) {
      if (
        new Date(this.state.endDate._d + "") > new Date(date._d + "") &&
        new Date(this.state.startDate._d + "") < new Date(date._d + "")
      ) {
        return " middle-date";
      }
      if (this.state.endDate._d + "" === date._d + "") {
        return " end-date";
      }
      if (this.state.startDate._d + "" === date._d + "") {
        return " start-date";
      }
    }
    return "";
  };

  async componentDidMount() {
    try {
      this.setState({ formSubmitted: true, searchTriggered: true });
      const history = await hubService.fetchShipmentHistoryRecentRecord();
      this.setState({ formSubmitted: false, history });
    } catch (ex) {
      this.setState({ formSubmitted: false });
    }
  }

  render() {
    let { isLoading } = this.state;
    let recordViewComponent;
    if (this.state.formSubmitted) {
      recordViewComponent = showLoadingRecordsMessage(
        "Loading your past shipments... Please wait!"
      );
    } else if (this.state.couldNotLoadTableRecords) {
      recordViewComponent = showCouldNotLoadRecordsMessage(
        "Could not load shipping history. Please try again."
      );
    } else {
      recordViewComponent =
        this.state.history.length > 0
          ? this.loadTable()
          : showNoRecordsMessage(
              this.state.searchTriggered
                ? "No records found! You may need to modify the search date."
                : "Your search results will show up here!"
            );
    }

    return (
      <>
        {this.historyModal()}
        <SimpleHeader
          name="Shipments"
          parentName="Tables"
          description="View all shipments."
        />
        <Container className="mt--6" fluid>
          {this.state.moveToCompleteBooking ? (
            this.moveToCompleteBooking()
          ) : (
            <>
              <form onSubmit={this.handleSubmit}>
                <Row>
                  <Col xs={12} sm={3} className="offset-md-1">
                    <label className=" form-control-label">Start date</label>
                    <FormGroup>
                      <ReactDatetime
                        dateFormat="DD/MM/YYYY"
                        inputProps={{
                          placeholder: "Date Picker Here",
                        }}
                        value={this.state.startDate}
                        timeFormat={false}
                        onChange={(e) =>
                          this.handleReactDatetimeChange("startDate", e)
                        }
                        renderDay={(props, currentDate, selectedDate) => {
                          let classes = props.className;
                          classes +=
                            this.getClassNameReactDatetimeDays(currentDate);
                          return (
                            <td {...props} className={classes}>
                              {currentDate.date()}
                            </td>
                          );
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={3}>
                    <FormGroup>
                      <label className=" form-control-label">End date</label>
                      <ReactDatetime
                        dateFormat="DD/MM/YYYY"
                        inputProps={{
                          placeholder: "Date Picker Here",
                        }}
                        value={this.state.endDate}
                        timeFormat={false}
                        onChange={(e) =>
                          this.handleReactDatetimeChange("endDate", e)
                        }
                        renderDay={(props, currentDate, selectedDate) => {
                          let classes = props.className;
                          classes +=
                            this.getClassNameReactDatetimeDays(currentDate);
                          return (
                            <td {...props} className={classes}>
                              {currentDate.date()}
                            </td>
                          );
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={3}>
                    {this.renderSelect2({
                      label: "Category",
                      mandatory: true,
                      name: "searchCategory",
                      placeholder: "Select Category",
                      icon: "fas fa-location",
                      options: SEARCH_CATEGORY_LIST,
                      classes: "mb-2",
                    })}
                  </Col>
                  <Col
                    className="col-lg-2 align-self-center pt-2"
                    xs={12}
                    sm={3}
                    md={2}
                  >
                    <Button
                      size="md"
                      color="primary"
                      block
                      disabled={
                        !this.state.data.searchCategory ||
                        !this.state.startDate ||
                        !this.state.endDate ||
                        this.state.formSubmitted
                      }
                      onClick={async (e) => {
                        e.preventDefault();
                        this.setState({
                          searchTriggered: true,
                          formSubmitted: true,
                          history: [],
                        });
                        const history = await hubService.fetchShipmentHistory(
                          this.state.startDate.format(dateFormat),
                          this.state.endDate.format(dateFormat),
                          this.state.data.searchCategory.value
                        );
                        this.setState({ formSubmitted: false, history });
                      }}
                    >
                      Search
                    </Button>
                  </Col>
                </Row>
              </form>
              <Row>
                <div className="col mt-4">{recordViewComponent}</div>
              </Row>
            </>
          )}
        </Container>
      </>
    );
  }

  handleSubmit() {}

  moveToCompleteBooking() {
    return (
      <CompleteBooking
        shipment={this.state.shipment}
        returnAction={() => {
          this.setState({ moveToCompleteBooking: false });
        }}
        returnMessage_1="Return to Shipping History"
        returnMessage_2="Return to Shipping History"
        shipmentId={this.state.completeBookingShipmentId}
      />
    );
  }

  loadTable() {
    return (
      <Card>
        <ToolkitProvider
          data={this.state.history}
          keyField="index"
          columns={[
            {
              dataField: "index",
              text: "#",
              sort: true,
            },
            {
              dataField: "requestDate",
              text: "Date",
              sort: true,
            },
            {
              dataField: "trackingNumber",
              text: "Tracking No.",
              sort: true,
            },
            {
              dataField: "sender",
              text: "Sender",
              sort: true,
            },
            {
              dataField: "recipient",
              text: "Recipient",
              sort: true,
              formatter: (cell, row) => {
                return (
                  <>
                    {row.recipient}
                    <br />
                    <b className="text-primary">{row.destinationCountry}</b>
                  </>
                );
              },
              filterValue: (cell, row) => {
                return row.recipient + " " + row.destinationCountry;
              },
            },
            {
              dataField: "status",
              text: "Status",
              sort: true,
              formatter: (cell, row) => {
                return (
                  <>
                    {(row.status.toUpperCase() !== "BOOKED" ||
                      (row.status.toUpperCase() === "BOOKED" &&
                        row.hub > 0)) && (
                      <span className="text-default">{row.status}</span>
                    )}
                    {row.status.toUpperCase() === "BOOKED" && row.hub == 0 && (
                      <>
                        <Button
                          color="primary"
                          size="sm"
                          onClick={() => {
                            this.setState({ fetchingDetail: true });
                            this.loadShipmentDetail(row.id);
                            this.toggleModal("historyModal");
                          }}
                        >
                          View Detail
                        </Button>
                        {/* <Button
                          outline
                          color="primary"
                          size="sm"
                          onClick={async () => {
                            await hubService.shipmentReceived(row.id);
                            // const incompleteBookings =
                            //   this.state.incompleteBookings;
                            // incompleteBookings.splice(index, 1);
                            // this.setState({ incompleteBookings });
                          }}
                        >
                          Receive Shipment
                        </Button> */}
                      </>
                    )}
                  </>
                );
              },
            },
            {
              dataField: "menu",
              text: "",
              sort: false,
              formatter: (cell, row) =>
                this.loadTableContextMenu(this.toggleModal, row),
              classes: (cell, row, rowIndex, colIndex) => "px-0",
            },
          ]}
          search
        >
          {(props) => (
            <div className="py-4 table-responsive">
              <TableActionButtons {...props}>
                <ReactToPrint
                  trigger={() => (
                    <Button
                      color="primary"
                      size="sm"
                      className="buttons-copy buttons-html5"
                      id="print-tooltip"
                    >
                      Print
                    </Button>
                  )}
                  content={() => this.componentRef}
                />
              </TableActionButtons>
              <BootstrapTable
                size="sm"
                condensed
                hover
                ref={(el) => (this.componentRef = el)}
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                id="react-bs-table"
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    );
  }

  historyModal = () => {
    const currentHistory = this.state.currentHistory;
    return (
      <Modal
        className="modal-dialog-centered"
        contentClassName="bg-secondary"
        isOpen={this.state.historyModal}
        toggle={() => this.toggleModal("historyModal")}
        backdrop="static"
        size="lg"
      >
        <div className="modal-header bg-primary">
          <h6
            className="modal-title text-secondary"
            id="modal-title-notification"
          >
            Past Shipment Detail
          </h6>
          <button
            aria-label="Close"
            className="close text-secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("historyModal")}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          {this.state.fetchingDetail
            ? showLoadingRecordsMessage(
                "Loading shipment detail... Please wait."
              )
            : currentHistory && (
                <>
                  <Row>
                    <Col className="col-12 col-sm-12 col-md-6">
                      {renderFormPanel({
                        title: "Sender",
                        content: (
                          <>
                            <Row>
                              <Col className="col-12 col-sm-12 col-md-6 mb-2">
                                <div className="text-muted">Fullname</div>
                                <div className="font-weight-bold">
                                  {currentHistory.origin.fullname}
                                </div>
                              </Col>
                              <Col className="col-12 col-sm-12 col-md-6 mb-2">
                                <div className="text-muted">Phone No:</div>
                                <div className="font-weight-bold">
                                  {currentHistory.origin.phoneNumber}
                                </div>
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              <Col>
                                <div className="text-muted">Email:</div>
                                <div className="font-weight-bold">
                                  {currentHistory.origin.email}
                                </div>
                              </Col>
                            </Row>
                            <div className="border-bottom border-light pb-2 mb-3" />
                            <Row>
                              <Col className="mb-2">
                                <div className="text-muted">Address:</div>
                                <div className="font-weight-bold">
                                  {currentHistory.origin.address}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mb-2">
                                <div className="text-muted">City</div>
                                <div className="font-weight-bold">
                                  {currentHistory.origin.cityName}
                                </div>
                              </Col>
                              <Col className="mb-2">
                                <div className="text-muted">State:</div>
                                <div className="font-weight-bold">
                                  {currentHistory.origin.stateName}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mb-2">
                                <div className="text-muted">Country</div>
                                <div className="font-weight-bold">
                                  {currentHistory.origin.countryName}
                                </div>
                              </Col>
                              <Col className="mb-2">
                                <div className="text-muted">Postal Code:</div>
                                <div className="font-weight-bold">
                                  {currentHistory.origin.postalCode}
                                </div>
                              </Col>
                            </Row>
                          </>
                        ),
                        bgColor: "bg-white",
                      })}
                    </Col>
                    <Col>
                      {renderFormPanel({
                        title: "Recipient",
                        content: (
                          <>
                            <Row>
                              <Col className="col-12 col-sm-12 col-md-6 mb-2">
                                <div className="text-muted">Fullname</div>
                                <div className="font-weight-bold">
                                  {currentHistory.destination.fullname}
                                </div>
                              </Col>
                              <Col className="col-12 col-sm-12 col-md-6 mb-2">
                                <div className="text-muted">Phone No:</div>
                                <div className="font-weight-bold">
                                  {currentHistory.destination.phoneNumber}
                                </div>
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              <Col>
                                <div className="text-muted">Email:</div>
                                <div className="font-weight-bold">
                                  {currentHistory.destination.email}
                                </div>
                              </Col>
                            </Row>
                            <div className="border-bottom border-light pb-2 mb-3" />
                            <Row>
                              <Col className="mb-2">
                                <div className="text-muted">Address:</div>
                                <div className="font-weight-bold">
                                  {currentHistory.destination.address}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mb-2">
                                <div className="text-muted">City</div>
                                <div className="font-weight-bold">
                                  {currentHistory.destination.cityName}
                                </div>
                              </Col>
                              <Col className="mb-2">
                                <div className="text-muted">State:</div>
                                <div className="font-weight-bold">
                                  {currentHistory.destination.stateName}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mb-2">
                                <div className="text-muted">Country</div>
                                <div className="font-weight-bold">
                                  {currentHistory.destination.countryName}
                                </div>
                              </Col>
                              <Col className="mb-2">
                                <div className="text-muted">Postal Code:</div>
                                <div className="font-weight-bold">
                                  {currentHistory.destination.postalCode}
                                </div>
                              </Col>
                            </Row>
                          </>
                        ),
                        bgColor: "bg-white",
                      })}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-none d-md-block">
                      {renderFormPanel({
                        title: "Item(s)",
                        content: (
                          <>
                            {this.state.currentHistory.items.map(
                              (item, index) => {
                                return (
                                  <>
                                    <div>
                                      <span className="font-weight-bold pr-3">
                                        Package
                                      </span>
                                      {this.state.currentHistory.items.length >
                                        1 && (
                                        <span className="font-weight-bold pr-3">
                                          {index + 1} -
                                        </span>
                                      )}
                                      <span className="pull-right pr-3">
                                        Weight: {item.weight} Kg,
                                      </span>
                                      <span className="pull-right">
                                        Value: &#8358;
                                        {utils.numberFormatter(
                                          Number(item.value),
                                          2
                                        )}
                                      </span>
                                    </div>
                                    {this.loadItemConfirmationTable(
                                      item.itemUnits
                                    )}
                                  </>
                                );
                              }
                            )}

                            {currentHistory.shipmentDimension && (
                              <>
                                <div className="border-bottom border-light pb-3 mb-3" />
                                <Row>
                                  <Col className="col-12 col-md-4">
                                    Shipment Dimension:
                                  </Col>
                                  <Col>
                                    <div className="font-weight-bold">
                                      {currentHistory.shipmentDimension[0]} X{" "}
                                      {currentHistory.shipmentDimension[1]} X{" "}
                                      {currentHistory.shipmentDimension[2]}
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            )}
                          </>
                        ),
                        bgColor: "bg-white",
                      })}
                    </Col>
                    <Col className="d-block d-md-none">
                      {renderFormPanel({
                        title: "Item(s)",
                        content: (
                          <>
                            {currentHistory.items.map((item) => {
                              return (
                                <>
                                  <Row>
                                    <Col className="mb-2">
                                      <div className="text-muted">Category</div>
                                      <div className="font-weight-bold">
                                        {item.categoryName}
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col className="mb-2">
                                      <div className="text-muted">
                                        Description
                                      </div>
                                      <div className="font-weight-bold">
                                        {item.description}
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col className="mb-2">
                                      <div className="text-muted">Quantity</div>
                                      <div className="font-weight-bold">
                                        {utils.numberFormatter(
                                          Number(item.quantity),
                                          2
                                        )}
                                      </div>
                                    </Col>
                                    <Col className="mb-2">
                                      <div className="text-muted">Weight</div>
                                      <div className="font-weight-bold">
                                        {utils.numberFormatter(
                                          Number(item.weight),
                                          2
                                        )}{" "}
                                        Kg
                                      </div>
                                    </Col>
                                    <Col className="mb-2">
                                      <div className="text-muted">Value</div>
                                      <div className="font-weight-bold">
                                        &#8358;{" "}
                                        {utils.numberFormatter(
                                          Number(item.value),
                                          2
                                        )}{" "}
                                        Kg
                                      </div>
                                    </Col>
                                  </Row>
                                  <div className="border-bottom border-light pb-3 mb-3" />
                                </>
                              );
                            })}
                            {currentHistory.shipmentDimension && (
                              <Row>
                                <Col className="col-12 col-md-4">
                                  Shipment Dimension:
                                </Col>
                                <Col>
                                  <div className="font-weight-bold">
                                    {currentHistory.shipmentDimension[0]} X{" "}
                                    {currentHistory.shipmentDimension[1]} X{" "}
                                    {currentHistory.shipmentDimension[2]}
                                  </div>
                                </Col>
                              </Row>
                            )}
                          </>
                        ),
                      })}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {renderFormPanel({
                        title: "Others",
                        content: (
                          <>
                            <Row>
                              <Col className="mb-2">
                                <div className="text-muted">Insurance Type</div>
                                <div className="font-weight-bold">
                                  {currentHistory.insurance}
                                </div>
                              </Col>
                              <Col className="mb-2">
                                <div className="text-muted">
                                  How will your package get to the hub?
                                </div>
                                <div className="font-weight-bold">
                                  {currentHistory.pickupText}
                                </div>
                              </Col>
                            </Row>
                          </>
                        ),
                        bgColor: "bg-white",
                      })}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {renderFormPanel({
                        title: "Payment",
                        content: (
                          <>
                            <Row>
                              <Col className="mb-2">
                                <div className="text-muted">Gross Weight</div>
                                <div className="font-weight-bold">
                                  {utils.numberFormatter(
                                    Number(currentHistory.grossWeight),
                                    2
                                  )}{" "}
                                  Kg
                                </div>
                              </Col>
                              <Col className="mb-2">
                                <div className="text-muted">Payment Amount</div>
                                <div className="font-weight-bold">
                                  &#8358;{" "}
                                  {utils.numberFormatter(
                                    Number(currentHistory.paymentAmount),
                                    2
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mb-2">
                                <div className="text-muted">Status</div>
                                <div className="font-weight-bold text-uppercase">
                                  {currentHistory.status}
                                </div>
                                <br />
                                {currentHistory.status.toUpperCase() ===
                                  "BOOKED" &&
                                  currentHistory.hub == 0 && (
                                    <>
                                      <Button
                                        color="primary"
                                        size="sm"
                                        onClick={async () => {
                                          await hubService.shipmentReceived(
                                            currentHistory.id
                                          );
                                          toast.success(
                                            "Successfully Received!"
                                          );
                                          // const incompleteBookings =
                                          //   this.state.incompleteBookings;
                                          // incompleteBookings.splice(index, 1);
                                          // this.setState({ incompleteBookings });
                                        }}
                                      >
                                        Receive Shipment
                                      </Button>
                                    </>
                                  )}
                              </Col>
                            </Row>
                          </>
                        ),
                        bgColor: "bg-white",
                      })}
                    </Col>
                  </Row>
                </>
              )}
        </div>
        <div className="modal-footer">
          <Button
            className="ml-auto"
            color="dark"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("historyModal")}
          >
            Close
          </Button>
        </div>
      </Modal>
    );
  };

  loadItemConfirmationTable(items) {
    return (
      <Card className="mb-0">
        <ToolkitProvider
          data={items}
          keyField="index"
          columns={[
            {
              dataField: "index",
              text: "#",
              sort: true,
            },
            {
              dataField: "description",
              text: "Desc",
              sort: true,
            },
            {
              dataField: "quantity",
              text: "Qty",
              sort: true,
            },
            {
              dataField: "value",
              text: <div className="text-right">Unit Price (&#8358;) </div>,
              sort: true,
              formatter: (cell, row) => {
                return (
                  <div className="text-right">
                    {utils.numberFormatter(Number(row.value), 2)}
                  </div>
                );
              },
            },
          ]}
          search
        >
          {(props) => (
            <div className="py-4 table-responsive">
              <BootstrapTable
                size="sm"
                ref={(el) => (this.componentRef = el)}
                {...props.baseProps}
                bootstrap4={true}
                // pagination={pagination}
                bordered={false}
                id="react-bs-table"
                striped
                hover
                condensed
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    );
  }

  loadTableContextMenu(toggleModal, row) {
    return (
      <UncontrolledDropdown>
        <DropdownToggle
          className="btn-icon-only text-primary h-25 mx-0"
          color=""
          role="button"
          size="sm"
        >
          <i className="fas fa-ellipsis-v" />
        </DropdownToggle>
        <DropdownMenu
          className="dropdown-menu-arrow pt-0 overflow-hidden "
          right
        >
          <div className="px-3 py-2 mb-1 bg-gradient-primary">
            <h6 className="text-sm text-white m-0">{row.trackingNumber}</h6>
          </div>
          <DropdownItem
            onClick={() => {
              this.setState({ fetchingDetail: true });
              this.loadShipmentDetail(row.id);
              this.toggleModal("historyModal");
            }}
            className="text-default"
          >
            <i className="fas fa-microscope text-primary" /> Detail
          </DropdownItem>
          {/* <DropdownItem
            onClick={() => {
              this.setState({ fetchingDetail: true });
              this.loadShipmentDetail(row.id);
              this.toggleModal("historyModal");
            }}
            className="text-default"
          >
            <i className="fas fa-microscope text-success" /> Edit
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              this.setState({ fetchingDetail: true });
              this.loadShipmentDetail(row.id);
              this.toggleModal("historyModal");
            }}
            className="text-default"
          >
            <i className="fas fa-close text-danger" /> Attempt Void
          </DropdownItem> */}
          {/* <DropdownItem
            onClick={() => {
              this.toggleModal("historyModal");
            }}
            className="text-default"
          >
            <i className="ni ni-pin-3 text-primary" /> Tracking History
          </DropdownItem> */}
          {/* <DropdownItem
            onClick={() => {
              this.resetStateDatabase();
              this.setState({ currentDatabase: row });
            }}
            className="text-default"
          >
            <i className="fas fa-download text-primary" /> Download Waybill
          </DropdownItem> */}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }

  async loadShipmentDetail(id) {
    const detail = await shipmentService.fetchShipmentDetail(id);
    this.setState({ fetchingDetail: false, currentHistory: detail });
  }
}

export default HubShippingHistory;
