import httpService from "services/httpService";

const baseApiEndPoint = process.env.REACT_APP_API_ENDPOINT;

export async function getAllCouriers() {
  const { data } = await httpService.get(
    baseApiEndPoint + "/courier/international/all"
  );
  return data;
}

export async function getAllCityCouriers() {
  const { data } = await httpService.get(baseApiEndPoint + "/courier/city/all");
  return data;
}

export default {
  getAllCouriers,
  getAllCityCouriers,
};
