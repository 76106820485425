import React from "react";
import ValidationForm from "../../../../validation/form";
import CityShippingZone from "./cityShippingZone";
import CityRates from "./cityRates";

class Index extends ValidationForm {
  state = {
    showRatesSetup: false,
    currentZone: {},
  };

  render() {
    let currentView;
    if (this.state.showRatesSetup) {
      currentView = (
        <CityRates
          goBackAction={() =>
            this.setState({
              showRatesSetup: false,
            })
          }
          currentZone={this.state.currentZone}
        />
      );
    } else {
      currentView = (
        <CityShippingZone
          showRates={(currentZone) =>
            this.setState({ showRatesSetup: true, currentZone })
          }
        />
      );
    }

    return currentView;
  }
}

export default Index;
