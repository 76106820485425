import React, { Component } from "react";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
import { UncontrolledTooltip } from "reactstrap";

const animatedComponents = makeAnimated();

const Control = ({ children, ...props }) => {
  const { emoji } = props.selectProps;
  const style = { padding: 10, paddingTop: 12, color: "#ced4da" };

  return (
    <components.Control {...props}>
      <span style={style}>{emoji}</span>
      {children}
    </components.Control>
  );
};
const DropdownIndicator = (
  props: ElementConfig<typeof components.DropdownIndicator>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <span></span>
    </components.DropdownIndicator>
  );
};

type State = {};

export default class CustomControl extends Component<*, State> {
  state = { isFocused: false };

  render() {
    return (
      <>
        {this.props.label && (
          <>
            <label className="form-control-label" htmlFor={this.props.name}>
              {this.props.mandatory && <span className="text-danger">*</span>}{" "}
              {this.props.label} {this.props.fieldIsProcessing}
            </label>
            {this.props.tooltipConfig && (
              <>
                {" "}
                <UncontrolledTooltip
                  placement="auto"
                  target={this.props.tooltipConfig.id}
                >
                  {this.props.tooltipConfig.message}
                </UncontrolledTooltip>
                <i
                  class={this.props.tooltipConfig.iconClass}
                  id={this.props.tooltipConfig.id}
                ></i>
              </>
            )}
          </>
        )}
        {this.props.isCreatableSelect ? (
          <CreatableSelect
            emoji={this.props.icon && <i className={this.props.icon}></i>}
            components={animatedComponents}
            {...this.props}
            size={5}
          />
        ) : (
          <Select
            className="border-1 border-dark"
            onFocus={() => this.setState({ isFocused: true })}
            onBlur={() => this.setState({ isFocused: false })}
            emoji={
              this.props.icon && (
                <i
                  className={`${this.props.icon} ${
                    this.state.isFocused
                      ? "reflection-select-focused"
                      : "reflection-select-blured"
                  }`}
                ></i>
              )
            }
            components={{ Control, DropdownIndicator }}
            {...this.props}
            size={5}
            theme={(theme) => ({
              ...theme,
              borderRadius: 4,
              colors: {
                ...theme.colors,
                // primary25: "blue",
                // primary: "#edeeee",
                // neutral10: "black",
              },
            })}
          />
        )}
        {this.props.error && (
          <div className="text-danger text-sm">{this.props.error}</div>
        )}
      </>
    );
  }
}
