import React, { Component } from "react";

import { ButtonGroup, Container, Row, Col } from "reactstrap";
import { Search, CSVExport } from "react-bootstrap-table2-toolkit";

import { toast } from "react-toastify";
import { toastOptions } from "config/config";

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

export default class TableActionButtons extends Component {
  state = {};
  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  copyToClipboardAsTable = (el) => {
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand("copy");
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand("Copy");
    }
    toast.success("Copied to clipboard!", toastOptions);
  };

  render() {
    return (
      <div>
        <Container fluid>
          <Row>
            {!this.props.hideExportButtons && (
              <Col xs={6} sm={6}>
                <ButtonGroup>
                  {/* <Button
                  className="buttons-copy buttons-html5"
                  color="primary"
                  size="sm"
                  id="copy-tooltip"
                  onClick={() =>
                    this.copyToClipboardAsTable(
                      document.getElementById("react-bs-table")
                    )
                  }
                >
                  <span>Copy</span>
                </Button> */}
                  <ExportCSVButton
                    className="btn-default btn-sm"
                    {...this.props.csvProps}
                  >
                    Export CSV
                  </ExportCSVButton>
                  {this.props.children}
                </ButtonGroup>

                {/* <UncontrolledTooltip placement="top" target="copy-tooltip">
                This will copy to your clipboard the visible rows of the table.
              </UncontrolledTooltip> */}
              </Col>
            )}

            <Col
              className={`${
                this.props.hideExportButtons
                  ? "col-xs-6 col-sm-6"
                  : "col-xs-6 col-sm-6"
              }`}
            >
              {this.props.replaceSearch ? (
                this.props.replaceSearch
              ) : (
                <div
                  id="datatable-basic_filter"
                  className="dataTables_filter px-4 pb-1 float-right"
                >
                  <label>
                    Search:
                    <SearchBar
                      className="form-control-sm"
                      placeholder=""
                      {...this.props.searchProps}
                    />
                  </label>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
