import React, { Component } from "react";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";
import Joi from "joi-browser";
import ValidationForm from "../../../../validation/form";
import Col from "reactstrap/lib/Col";
import Button from "reactstrap/lib/Button";
import shippingRateService from "services/shippingRateService";
import { toast } from "react-toastify";
import { showNoRecordsMessage, showLoadingRecordsMessage } from "config/config";

class LocalRates extends ValidationForm {
  state = {
    data: {},
    errors: {},
    formSubmitted: false,
    loadingDetailInProgress: false,
    entries: [],
    // forceRefresh: new Date().getMilliseconds(),
    update: false,
    generateRange: false,
    activeEntryCount: 0,
  };

  schema = {
    entryCount: Joi.number()
      .integer()
      .min(1)
      .max(50)
      .required()
      .label("Entry Count"),
    increment: Joi.number().min(0.5).max(300).required().label("Increment"),
    startWeight: Joi.number().min(0).max(300).required().label("Start Weight"),
  };

  generateEntryFormParam = ["entryCount", "increment"];

  ratesFormParam = [];

  async componentDidMount() {
    try {
      this.setState({ loadingDetailInProgress: true });
      const response = await shippingRateService.getShippingRate(
        this.props.currentZone.id
      );
      this.initEntryFields(response);
      this.setState({ loadingDetailInProgress: false });
    } catch (ex) {
      this.setState({ loadingDetailInProgress: false });
    }
  }

  render() {
    let recordViewComponent;
    if (this.state.loadingDetailInProgress) {
      recordViewComponent = showLoadingRecordsMessage(
        "Loading existing rates. Please wait..."
      );
    } else {
      recordViewComponent =
        this.state.entries.length === 0
          ? showNoRecordsMessage("No rates available!")
          : null;
    }

    return (
      <>
        <SimpleHeader
          name="Local Zones / Rates"
          parentName="Tables"
          description={
            <span className="h2 mb-2">
              {this.props.currentZone.description}
              <em className="font-weight-normal">
                -{this.props.currentZone.geography}
              </em>
            </span>
          }
          toggleModal={() => {
            this.resetStateApp();
            this.toggleModal("newAppModal");
          }}
          goBack={{
            label: "Local Zones",
            action: this.props.goBackAction,
          }}
        />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              {this.entryFactoryButton()}
              <form
                role="form"
                onSubmit={(e) =>
                  this.handleSubmit({
                    e,
                    formItemNames: this.ratesFormParam,
                    submitFunction: () => {},
                  })
                }
              >
                {this.state.entries}
              </form>

              {this.state.activeEntryCount > 0 &&
                this.renderSimpleButton({
                  label: "Save Rate(s)",
                  classs: "btn-primary",
                  spinnerLabel: "Save Rates(s)",
                  formItemNames: this.ratesFormParam,
                  onclick: () => this.saveRates(),
                })}

              {this.state.activeEntryCount === 0 && recordViewComponent}
            </div>
          </Row>
        </Container>
      </>
    );
  }

  async saveRates() {
    const shippingRate = { shippingZoneId: this.props.currentZone.id };
    const rates = [];

    const data = this.state.data;
    const entries = this.state.entries;
    try {
      for (let index = 0; index < entries.length; index++) {
        if (entries[index]) {
          rates[rates.length] = {
            aboveWeight: data["aboveWeight_" + index],
            endWeight: data["endWeight_" + index],
            rate: (data["rate_" + index] + "").replace(/\s/g, ""),
          };
        }
      }
      shippingRate.rates = rates;

      this.setState({ formSubmitted: true });
      const response = await shippingRateService.updateShippingRate(
        shippingRate
      );
      toast.success(response.message);
      this.setState({ formSubmitted: false });
    } catch (ex) {
      this.setState({ formSubmitted: false });
    }
  }

  entryFactoryButton() {
    return (
      <form
        role="form"
        onSubmit={(e) =>
          this.handleSubmit({
            e,
            formItemNames: this.generateEntryFormParam,
            submitFunction: () => {
              this.setState({ update: true, generateRange: true }, () => {
                this.rateEntryFields();
                this.setState({ update: false });
              });
            },
          })
        }
      >
        <Row>
          <Col>
            {this.renderInput({
              label: "Entry Count",
              mandatory: true,
              type: "text",
              name: "entryCount",
              placeholder: "Entry Count",
            })}
          </Col>
          <Col>
            {this.renderInput({
              label: "Start Weight",
              mandatory: true,
              type: "text",
              name: "startWeight",
              placeholder: "Start Weight",
            })}
          </Col>
          <Col>
            {this.renderInput({
              label: "Increment",
              mandatory: true,
              type: "text",
              name: "increment",
              placeholder: "Increment",
            })}
          </Col>
          <Col className="pt-4">
            {this.renderButton(
              "Generate Entry(s)",
              "btn-primary",
              "Generate Entry(s)",
              this.generateEntryFormParam
            )}
          </Col>
          <Col className="pt-4">
            {this.renderSimpleButton({
              label: "Add Empty Entry",
              classs: "btn-primary",
              spinnerLabel: "Add Empty Entry",
              formItemNames: this.generateEntryFormParam,
              onclick: () => {
                this.setState({ update: true, generateRange: false }, () => {
                  this.rateEntryFields();
                  this.setState({ update: false });
                });
              },
            })}
          </Col>
        </Row>
      </form>
    );
  }

  initEntryFields(rates) {
    const entries = [];
    let activeEntryCount = this.state.activeEntryCount;

    for (let index = 0; index < rates.length; index++) {
      activeEntryCount++;

      this.updateSchemaAndData(
        index,
        rates[index].aboveWeight,
        rates[index].endWeight,
        parseFloat(rates[index].rate)
      );

      entries[index] = this.generateEntries(index, true);
    }
    this.setState({ entries, activeEntryCount });
  }

  rateEntryFields() {
    let count = 0,
      lastEndWeight = 0,
      tempWeight = parseFloat(this.state.data.startWeight);
    const entries = this.state.entries;
    let activeEntryCount = this.state.activeEntryCount;

    if (this.state.update) {
      count = this.state.data.entryCount;
    }

    for (let index = 0; index < count; index++) {
      activeEntryCount++;
      const prefix = entries.length;

      lastEndWeight = tempWeight + parseFloat(this.state.data.increment);
      this.updateSchemaAndData(prefix, tempWeight, lastEndWeight);

      entries[prefix] = this.generateEntries(prefix);

      tempWeight = lastEndWeight;
    }
    this.lastEndWeight = tempWeight;
    this.setState({ entries, activeEntryCount });
  }

  generateEntries(prefix, nonEditable) {
    return (
      <>
        <Row key={`rowentry-${prefix}`}>
          <Col>
            {this.renderInput({
              // label: "Above Weight",
              mandatory: true,
              type: "text",
              name: "aboveWeight_" + prefix,
              placeholder: "Above Weight",
              classes: `${
                this.state.generateRange || nonEditable
                  ? "bg-secondary font-weight-bold"
                  : ""
              }`,
            })}
          </Col>
          <Col>
            {this.renderInput({
              // label: "End Weight",
              mandatory: true,
              type: "text",
              name: "endWeight_" + prefix,
              placeholder: "End Weight",
              classes: `${
                this.state.generateRange || nonEditable
                  ? "bg-secondary font-weight-bold"
                  : ""
              }`,
            })}
          </Col>
          <Col>
            {this.renderInput({
              // label: "Rate",
              mandatory: true,
              type: "text",
              name: "rate_" + prefix,
              placeholder: "Rate",
              classes: `${nonEditable ? "bg-secondary font-weight-bold" : ""}`,
            })}
          </Col>
          <Col>
            <Button
              onClick={() => {
                // this.copyServiceToState(row);
                this.removeRateFromForm(prefix);
                this.setState({ currentRateIndex: prefix });
                // this.toggleModal("deleteRateModal");
              }}
              className="btn-danger btn-sm"
            >
              <i className="fas fa-trash-alt" />
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  updateSchemaAndData(prefix, aboveWeight, endWeight, rate) {
    this.ratesFormParam[this.ratesFormParam.length] = "aboveWeight_" + prefix;
    this.ratesFormParam[this.ratesFormParam.length] = "endWeight_" + prefix;
    this.ratesFormParam[this.ratesFormParam.length] = "rate_" + prefix;

    this.schema["aboveWeight_" + prefix] = Joi.number()
      .min(0)
      .max(1000)
      .required()
      .label("Above Weight");
    this.schema["endWeight_" + prefix] = Joi.number()
      .min(0.5)
      .max(1000)
      .required()
      .label("End Weight");
    this.schema["rate_" + prefix] = Joi.number()
      .min(500)
      .max(999999999999)
      .required()
      .label("Increment");

    const data = this.state.data;

    if (this.state.generateRange) {
      data["aboveWeight_" + prefix] = aboveWeight;
      data["endWeight_" + prefix] = endWeight;
      this.setState({ data });
    } else if (rate !== null) {
      data["aboveWeight_" + prefix] = aboveWeight;
      data["endWeight_" + prefix] = endWeight;
      data["rate_" + prefix] = rate;
      this.setState({ data });
    }
  }

  removeRateFromForm(prefix) {
    const entries = this.state.entries;
    entries[prefix] = null;
    const activeEntryCount = this.state.activeEntryCount - 1;

    this.setState({ currentRateIndex: prefix, entries, activeEntryCount });

    let index = this.ratesFormParam.indexOf("aboveWeight_" + prefix);
    if (index > -1) {
      this.ratesFormParam.splice(index, 1);
    }
    index = this.ratesFormParam.indexOf("endWeight_" + prefix);
    if (index > -1) {
      this.ratesFormParam.splice(index, 1);
    }
    index = this.ratesFormParam.indexOf("rate_" + prefix);
    if (index > -1) {
      this.ratesFormParam.splice(index, 1);
    }

    delete this.schema["aboveWeight_" + prefix];
    delete this.schema["endWeight_" + prefix];
    delete this.schema["rate_" + prefix];
  }
}

export default LocalRates;
