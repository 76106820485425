import httpService from "services/httpService";

const baseApiEndPoint = process.env.REACT_APP_API_ENDPOINT;

const apiEndPoint = baseApiEndPoint + "/product";

export async function create(model) {
  const { data } = await httpService.post(apiEndPoint, {
    ...model,
  });
  return data;
}

export async function update(model) {
  const { data } = await httpService.put(apiEndPoint + "/" + model.id, {
    ...model,
  });
  return data;
}

export async function deleteProduct(id) {
  const { data } = await httpService.delete(apiEndPoint + "/" + id);
  return data;
}

export async function fetchAllForCurrentOrganization() {
  const { data } = await httpService.get(apiEndPoint + "/all");
  return data;
}

export async function uploadNewImage(cacheFileName, formData) {
  const { data } = await httpService.post(
    apiEndPoint + "/image/" + cacheFileName,
    formData
  );
  return data;
}

export async function replaceImage(cacheFileName, formData) {
  const { data } = await httpService.put(
    apiEndPoint + "/image/" + cacheFileName,
    formData
  );
  return data;
}

export async function fetchCategoryGroups() {
  const { data } = await httpService.get(apiEndPoint + "/category_groups");
  return data;
}

export async function fetchCurrencyList() {
  const { data } = await httpService.get(apiEndPoint + "/currency_list");
  return data;
}

export default {
  create,
  update,
  deleteProduct,
  fetchAllForCurrentOrganization,
  uploadNewImage,
  replaceImage,
  fetchCategoryGroups,
  fetchCurrencyList,
};
