import React from "react";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
// reactstrap components
import { Modal, Button, Card, Container, Row } from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import UncontrolledDropdown from "reactstrap/lib/UncontrolledDropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import Joi from "joi-browser";
import ValidationForm from "../../../../validation/form";
import { toast } from "react-toastify";
import billingGroupService from "services/billingGroupService";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
import { pagination } from "config/config";
import {
  showNoRecordsMessage,
  showLoadingRecordsMessage,
  showCouldNotLoadRecordsMessage,
} from "config/config";
import TableActionButtons from "../TableActionButtons";

class BillingGroups extends ValidationForm {
  state = {
    alert: null,
    newBillingGroupModal: false,
    billingGroupDetailModal: false,
    billingGroups: {},
    currentBillingGroup: {},
    data: {},
    errors: {},
    formSubmitted: false,
    forceTableRefreshId: new Date().getMilliseconds(),
    loadingTableRecords: true,
    couldNotLoadTableRecords: false,
  };
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  schema = {
    name: Joi.string().required().label("Name"),
    active: Joi.boolean().label("Make Active"),
  };

  async componentDidMount() {
    try {
      const billingGroups = await billingGroupService.fetchAll();
      this.setState({
        billingGroups,
        couldNotLoadTableRecords: false,
        loadingTableRecords: false,
      });
    } catch (ex) {
      this.setState({
        couldNotLoadTableRecords: true,
        loadingTableRecords: false,
      });
    }
  }

  doSubmit = async () => {
    this.setState({ formSubmitted: true });
    let { currentBillingGroup, data, billingGroups } = this.state;
    try {
      if (currentBillingGroup.id) {
        currentBillingGroup = { id: currentBillingGroup.id, ...data };
        const response = await billingGroupService.update(currentBillingGroup);
        toast.success(response.message);
        this.updateTableStateRecords(currentBillingGroup.id, response.data);
        this.setState({
          formSubmitted: false,
          currentBillingGroup: response.data,
        });
      } else {
        const response = await billingGroupService.create(data);
        toast.success(response.message);
        response.data.index = billingGroups.length + 1;
        billingGroups = [...billingGroups, response.data];
        this.setState({
          formSubmitted: false,
          currentBillingGroup: response.data,
          billingGroups,
        });
      }
    } catch (ex) {
      this.setState({ formSubmitted: false });
    }
  };

  render() {
    let recordViewComponent;
    if (this.state.loadingTableRecords) {
      recordViewComponent = showLoadingRecordsMessage();
    } else if (this.state.couldNotLoadTableRecords) {
      recordViewComponent = showCouldNotLoadRecordsMessage();
    } else {
      recordViewComponent =
        this.state.billingGroups.length > 0
          ? this.loadTable()
          : showNoRecordsMessage(
              "No billing group present. You need to create one."
            );
    }

    return (
      <>
        {this.newBillingGroupModal()}
        {this.billingGroupDetailModal()}
        <SimpleHeader
          name="Billing Groups"
          parentName="Tables"
          description="An billing group is a business on the platform."
          toggleModal={() => {
            this.resetStateBillingGroup();
            this.toggleModal("newBillingGroupModal");
          }}
          newItemButtonLabel="New Billing Group"
        />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">{recordViewComponent}</div>
          </Row>
        </Container>
      </>
    );
  }

  resetStateBillingGroup() {
    const { data } = this.state;
    data.name = "";
    data.active = false;
    this.setState({ data, currentBillingGroup: {}, formSubmitted: false });
  }

  copyBillingGroupToState(source) {
    const { data } = this.state;
    data.name = source.name;
    data.active = source.active;
    this.setState({ data, formSubmitted: false });
  }

  updateTableStateRecords(currentBillingGroupId, updateData) {
    let { billingGroups } = this.state;
    billingGroups = billingGroups.map((org) => {
      if (org.id === currentBillingGroupId) {
        updateData.index = org.index;
        Object.assign(org, updateData);
      }
      return org;
    });

    this.setState({ billingGroups });
  }

  loadTable() {
    return (
      <Card>
        <ToolkitProvider
          data={this.state.billingGroups}
          keyField="index"
          columns={[
            {
              dataField: "index",
              text: "#",
              sort: true,
            },
            {
              dataField: "name",
              text: "Name",
              sort: true,
            },
            {
              dataField: "dateCreated",
              text: "Date Created",
              sort: true,
            },
            {
              dataField: "createdBy",
              text: "Created By",
              sort: true,
            },
            {
              dataField: "status",
              text: "Status",
              sort: true,
              formatter: (cell, row) => {
                return <span className="text-default">{row.status}</span>;
              },
            },
            {
              dataField: "menu",
              text: "",
              sort: false,
              formatter: (cell, row) =>
                this.loadTableContextMenu(this.toggleModal, row),
            },
          ]}
          search
          exportCSV
        >
          {(props) => (
            <div className="py-4 table-responsive">
              <TableActionButtons {...props}>
                <ReactToPrint
                  trigger={() => (
                    <Button
                      color="primary"
                      size="sm"
                      className="buttons-copy buttons-html5"
                      id="print-tooltip"
                    >
                      Print
                    </Button>
                  )}
                  content={() => this.componentRef}
                />
              </TableActionButtons>
              <BootstrapTable
                size="sm"
                ref={(el) => (this.componentRef = el)}
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                id="react-bs-table"
                striped
                hover
                condensed
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    );
  }

  loadTableContextMenu(toggleModal, row) {
    return (
      <UncontrolledDropdown>
        <DropdownToggle
          className="btn-icon-only text-light h-25"
          color=""
          role="button"
          size="sm"
        >
          <i className="fas fa-ellipsis-v" />
        </DropdownToggle>
        <DropdownMenu
          className="dropdown-menu-arrow pt-0 overflow-hidden "
          right
        >
          <div className="px-3 py-2 mb-1 bg-primary">
            <h6 className="text-sm text-white m-0">{row.name}</h6>
          </div>
          <DropdownItem
            onClick={() => {
              this.copyBillingGroupToState(row);
              this.setState({ currentBillingGroup: row });
              this.toggleModal("billingGroupDetailModal");
            }}
            className="text-default"
          >
            <i className="fas fa-eye" /> View Details
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              this.copyBillingGroupToState(row);
              this.setState({ currentBillingGroup: row });
              this.toggleModal("newBillingGroupModal");
            }}
            className="text-default"
          >
            <i className="fas fa-pencil-alt" /> Edit Details
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }

  newBillingGroupModal = () => {
    const currentBillingGroup = this.state.currentBillingGroup;

    return (
      <Modal
        className="modal-dialog-centered"
        contentClassName="bg-secondary"
        isOpen={this.state.newBillingGroupModal}
        toggle={() => this.toggleModal("newBillingGroupModal")}
        backdrop="static"
      >
        <div className="modal-header bg-primary">
          <h6
            className="modal-title text-secondary"
            id="modal-title-notification"
          >
            New Billing Group
          </h6>
          <button
            aria-label="Close"
            className="close text-secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("newBillingGroupModal")}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <form
          onSubmit={(e) => {
            this.handleSubmit({
              e,
            });
          }}
        >
          <div className="modal-body">
            {this.renderStyledInput({
              label: "Name",
              mandatory: true,
              type: "text",
              name: "name",
              placeholder: "Billing Group Name",
              icon: "ni ni-building",
            })}

            {this.renderCheckbox({
              mandatory: false,
              name: "active",
              label: "Make Active",
              checked: this.state.data.active,
              disabled: false,
              iconClass: "text-muted ni ni-user-run",
            })}
          </div>
          <div className="modal-footer bg-white">
            {this.renderButton(
              `${currentBillingGroup.id ? "Update" : "Create"} Billing Group`,
              "btn-primary",
              `${currentBillingGroup.id ? "Updating..." : "Creating..."}`
            )}
            <Button
              className="ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("newBillingGroupModal")}
            >
              Close
            </Button>
          </div>
        </form>
      </Modal>
    );
  };

  billingGroupDetailModal = () => {
    const currentBillingGroup = this.state.currentBillingGroup;
    return (
      currentBillingGroup && (
        <Modal
          className="modal-dialog-centered"
          contentClassName="bg-secondary"
          isOpen={this.state.billingGroupDetailModal}
          toggle={() => this.toggleModal("billingGroupDetailModal")}
          backdrop="static"
        >
          <div className="modal-header bg-primary">
            <h6
              className="modal-title text-secondary"
              id="modal-title-notification"
            >
              Billing Group Details
            </h6>
            <button
              aria-label="Close"
              className="close text-secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("billingGroupDetailModal")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <p className="font-weight-bold p-0 m-0 text-primary">
              Billing Group Name
            </p>
            <p className="p-0 m-0">{currentBillingGroup.name}</p>
            <br />
            <p className="font-weight-bold p-0 m-0 text-primary">
              Date Created
            </p>
            <p className="p-0 m-0">{currentBillingGroup.dateCreated}</p>
            <br />
            <p className="font-weight-bold p-0 m-0 text-primary">Created By</p>
            <p className="p-0 m-0">{currentBillingGroup.createdBy}</p>
            <br />
            <p className="font-weight-bold p-0 m-0 text-primary">Status</p>
            <p>{currentBillingGroup.status}</p>
          </div>
          <div className="modal-footer bg-white">
            <Button
              className="ml-auto"
              color="dark"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("billingGroupDetailModal")}
            >
              Close
            </Button>
          </div>
        </Modal>
      )
    );
  };
}

export default BillingGroups;
