import React from "react";

const SwitchButton = ({
                          name,
                          label,
                          error,
                          mandatory,
                          fieldIsProcessing,
                          className,
                          checked,
                          ...rest
                      }) => {
    return (
        <div className={`custom-control custom-switch ${className}`}>
            <input
                {...rest}
                id={name}
                name={name}
                checked={checked}
                type="checkbox"
                className="custom-control-input reflection-pointer"
            />
            {label && (
                <label
                    className="custom-control-label reflection-pointer pt-1"
                    htmlFor={name}
                >
                    {mandatory && <span className="text-danger">*</span>} {label}
                    {fieldIsProcessing}
                </label>
            )}
            &nbsp;&nbsp;&nbsp;
            {checked && (
                <span className="badge badge-success font-weight-bolder">
          Live Mode
        </span>
            )}
            {!checked && <span className="badge badge-danger"> Setup Mode</span>}
        </div>
    );
};

export default SwitchButton;
