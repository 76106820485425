import React from "react";
import { Card, CardBody } from "reactstrap";
import Container from "reactstrap/lib/Container";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ValidationForm from "../../../validation/form";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import CardHeader from "reactstrap/lib/CardHeader";

import Joi from "joi-browser";
import Button from "reactstrap/lib/Button";
import { showLoadingRecordsMessage } from "config/config";

// react plugin used to create datetimepicker
import { GLOBAL_PROPERTY } from "constants/constants";
import { toast } from "react-toastify";
import userService from "services/userService";

class Profile extends ValidationForm {
  state = {
    data: {},
    errors: {},
    editName: false,
    editPhoneNumber: false,
    fullNameSubmitted: false,
    phoneNumberSubmitted: false,
    isLoading: false,
  };

  schema = {
    fullName: Joi.string().min(2).required().label("Full Name"),
    phoneNumber: Joi.string().min(11).max(15).required().label("Phone Number"),
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  componentDidMount() {
    if (GLOBAL_PROPERTY.userProfile) {
      this.setState({ userProfile: GLOBAL_PROPERTY.userProfile });
    }
  }

  async updateFullName() {
    try {
      this.setState({
        fullNameSubmitted: true,
      });
      const response = await userService.updateFullName(
        this.state.data.fullName
      );
      toast.success(response.message);

      GLOBAL_PROPERTY.userProfile.fullName = this.state.data.fullName;

      this.setState({
        fullNameSubmitted: false,
        editName: false,
        userProfile: GLOBAL_PROPERTY.userProfile,
      });

      this.props.updateFullName(this.state.data.fullName);
    } catch (e) {
      this.setState({
        fullNameSubmitted: false,
      });
    }
  }

  async updatePhoneNumber() {
    try {
      this.setState({
        phoneNumberSubmitted: true,
      });
      const response = await userService.updatePhoneNumber(
        this.state.data.phoneNumber
      );
      toast.success(response.message);

      GLOBAL_PROPERTY.userProfile.phoneNumber = this.state.data.phoneNumber;

      this.setState({
        phoneNumberSubmitted: false,
        editPhoneNumber: false,
        userProfile: GLOBAL_PROPERTY.userProfile,
      });
    } catch (e) {
      this.setState({
        phoneNumberSubmitted: false,
      });
    }
  }

  render() {
    return (
      <>
        {!this.props.userProfile.fullName
          ? showLoadingRecordsMessage(
              "Fetching profile details. Please wait..."
            )
          : this.loadProfileView()}
      </>
    );
  }

  loadProfileView() {
    const userProfile = this.state.userProfile
      ? this.state.userProfile
      : this.props.userProfile;

    return (
      <>
        <Row className="border-bottom pb-4">
          <Col>
            <span className="font-weight-bold">Full name</span>
          </Col>
          <Col>
            <form
              onSubmit={(e) => {
                this.handleSubmit({
                  e,
                  submitFunction: () => this.updateFullName(),
                  formItemNames: ["fullName"],
                });
              }}
            >
              <div className={`${this.state.editName ? "d-none" : ""}`}>
                {userProfile.fullName}
              </div>
              <div className={`${this.state.editName ? "" : "d-none"}`}>
                {this.renderStyledInput({
                  type: "text",
                  name: "fullName",
                  placeholder: `${userProfile.fullName}`,
                  icon: "ni ni-circle-08",
                  classes: "sm",
                })}
                {this.renderButton(
                  "Update Full Name",
                  "btn-primary btn-block btn-sm ",
                  "Updating Full Name",
                  ["fullName"],
                  null,
                  this.state.fullNameSubmitted
                )}
              </div>
            </form>
          </Col>
          <Col className="text-right">
            <div className={`${this.state.editName ? "d-none" : ""}`}>
              <Button
                outline
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  this.toggleModal("editName");
                }}
              >
                Edit
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="border-bottom py-4">
          <Col>
            <span className="font-weight-bold">Sign up method</span>
          </Col>
          <Col>
            <div className="font-weight-bold text-capitalize">
              {userProfile.authProvider}
            </div>
            <div>{userProfile.username}</div>
          </Col>
          <Col></Col>
        </Row>
        <Row className="border-bottom py-4">
          <Col>
            <span className="font-weight-bold">Phone number</span>
          </Col>
          <Col>
            <form
              onSubmit={(e) => {
                this.handleSubmit({
                  e,
                  submitFunction: () => this.updatePhoneNumber(),
                  formItemNames: ["phoneNumber"],
                });
              }}
            >
              <div className={`${this.state.editPhoneNumber ? "d-none" : ""}`}>
                {userProfile.phoneNumber}
              </div>
              <div className={`${this.state.editPhoneNumber ? "" : "d-none"}`}>
                {this.renderStyledInput({
                  type: "text",
                  name: "phoneNumber",
                  placeholder: `${userProfile.phoneNumber}`,
                  icon: "fas fa-phone",
                  classes: "sm",
                })}
                {this.renderButton(
                  "Update Phone Number",
                  "btn-primary btn-block btn-sm ",
                  "Updating Phone Number",
                  ["phoneNumber"],
                  null,
                  this.state.phoneNumberSubmitted
                )}
              </div>
            </form>
          </Col>
          <Col className="text-right">
            <div className={`${this.state.editPhoneNumber ? "d-none" : ""}`}>
              <Button
                outline
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  this.toggleModal("editPhoneNumber");
                }}
              >
                Edit
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="border-bottom py-4">
          <Col>
            <span className="font-weight-bold">Deactivate account</span>
          </Col>
          <Col>
            You will no longer be able to sign in if you deactivate your
            account.
          </Col>
          <Col className="text-right">
            <Button outline color="danger">
              Deactivate
            </Button>
          </Col>
        </Row>
      </>
    );
  }
}

export default Profile;
