import httpService from "services/externalHttpService";

const apiEndPoint = process.env.REACT_APP_LOCATION_CDN_ENDPOINT;

export async function fetchLocation(id) {
  const { data } = await httpService.get(apiEndPoint + id + ".json");
  return data;
}

export default {
  fetchLocation,
};
