import httpService from "services/httpService";

const baseApiEndPoint = process.env.REACT_APP_API_ENDPOINT;

const apiEndPoint = baseApiEndPoint + "/files";

export async function getWaybillDownloadToken(shipmentTrackingNumber) {
  const { data } = await httpService.get(
    apiEndPoint + "/token/waybill/" + shipmentTrackingNumber
  );
  return data;
}

export async function getInvoiceDownloadToken(shipmentTrackingNumber) {
  const { data } = await httpService.get(
    apiEndPoint + "/token/invoice/" + shipmentTrackingNumber
  );
  return data;
}

export function getFileDownloadUrl(token) {
  return apiEndPoint + "/download?token=" + encodeURI(token);
}

export default {
  getWaybillDownloadToken,
  getInvoiceDownloadToken,
  getFileDownloadUrl,
};
