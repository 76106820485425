import React, { Component } from "react";
import Joi from "joi-browser";
import { Spinner } from "reactstrap";
import Input from "./input";
import Select from "./select";
import Checkbox from "./checkbox";
import ReactSelect from "./reactSelect";
import Radio from "./radio";
import TextArea from "./textArea";
import ThresholdInput from "./thresholdInput";
import StyledInput from "./styledInput";
import _ from "lodash";
import SwitchButton from "./switchButton";
import Switch from "./switch";

class Form extends Component {
  getSchemaFromItemNames(formItemNames) {
    const schema = {};
    for (var i = 0; i < formItemNames.length; i++) {
      schema[formItemNames[i]] = this.schema[formItemNames[i]];
    }
    return schema;
  }

  getDataFromItemNames(formItemNames) {
    const data = {};
    for (var i = 0; i < formItemNames.length; i++) {
      data[formItemNames[i]] = this.state.data[formItemNames[i]];
    }
    return data;
  }

  validate = (formItemNames) => {
    const schema = formItemNames
      ? this.getSchemaFromItemNames(formItemNames)
      : this.schema;

    // console.log(schema);
    const data = formItemNames
      ? this.getDataFromItemNames(formItemNames)
      : this.state.data;

    const joiOptions = { abortEarly: false };
    const { error } = Joi.validate(data, schema, joiOptions);

    if (!error) return null;

    const errors = {};
    error.details.map((item) => (errors[item.path[0]] = item.message));

    // console.log(errors);
    return errors;
  };

  validateProperty = ({ name, value }, disallowedTexts) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);

    if (error) {
      return error.details[0].message;
    } else if (disallowedTexts) {
      const values = value.split(" ");
      for (let i = 0; i < values.length; i++) {
        if (disallowedTexts.words.includes(values[i].toUpperCase())) {
          return (
            <>
              <b>{disallowedTexts.message[0].replace("*&*", values[i])} </b>
              {disallowedTexts.message[1].replace("*&*", values[i])}
            </>
          );
        }
      }
    }
    return null;
  };

  handleSubmit = ({ e, formItemNames, submitFunction }) => {
    e.preventDefault();
    const errors = this.validate(formItemNames) || {};
    this.setState({ errors });

    if (_.isEmpty(errors)) {
      if (submitFunction) {
        submitFunction();
      } else {
        this.doSubmit();
      }
    }
  };

  handleChange = ({ currentTarget: input }, callback, disallowedTexts) => {
    const error = this.validateProperty(input, disallowedTexts);
    const errors = error ? { [input.name]: error } : {};
    const data = { ...this.state.data };
    data[input.name] = input.type == "checkbox" ? input.checked : input.value;
    this.setState({ data, errors }, () => callback && callback());

    if (
      input.type === "select-one" &&
      this.state.selectOnChange &&
      this.state.selectOnChange.selectName === input.name
    ) {
      this.setState({
        selectOnChange: { selectName: input.name, selectValue: input.value },
      });
    }
  };

  renderButton(label, classs, spinnerLabel, formItemNames, disable, busy) {
    let classes = "btn btn-primary ";
    classes += classs ? classs : "";

    return (
      <button
        disabled={disable || busy || this.state.formSubmitted}
        className={classes}
      >
        <span className={this.state.formSubmitted || busy ? "" : "d-none"}>
          <Spinner color="" type="border" size="sm"></Spinner> {spinnerLabel}
        </span>
        <span className={this.state.formSubmitted || busy ? "d-none" : ""}>
          {label}
        </span>
      </button>
    );
  }

  renderSimpleButton({
    label,
    classs,
    spinnerLabel,
    formItemNames,
    disable,
    busy,
    onclick,
  }) {
    let classes = "btn btn-primary ";
    classes += classs ? classs : "";

    return (
      <button
        type="button"
        disabled={
          disable ||
          busy ||
          this.state.formSubmitted ||
          (disable == undefined && this.validate(formItemNames))
        }
        className={classes}
        onClick={onclick}
      >
        <span className={this.state.formSubmitted || busy ? "" : "d-none"}>
          <Spinner color="" type="border" size="sm"></Spinner> {spinnerLabel}
        </span>
        <span className={this.state.formSubmitted || busy ? "d-none" : ""}>
          {label}
        </span>
      </button>
    );
  }

  renderInput({
    mandatory = false,
    name,
    label,
    type = "text",
    placeholder,
    classes,
    disabled = false,
    isSmall = false,
    containerClass,
    disallowedTexts,
  }) {
    const { data, errors, fieldIsProcessing } = this.state;

    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={(e) => {
          this.handleChange(e, null, disallowedTexts);
        }}
        error={errors[name]}
        className={classes}
        placeholder={placeholder}
        containerClass={containerClass}
        isSmall={isSmall}
        disabled={
          disabled ||
          this.state.formSubmitted ||
          (fieldIsProcessing && fieldIsProcessing[name])
        }
        mandatory={mandatory}
        fieldIsProcessing={
          <Spinner
            color="primary"
            type="grow"
            size="sm"
            className={
              fieldIsProcessing && fieldIsProcessing[name] ? "" : "d-none"
            }
          ></Spinner>
        }
      />
    );
  }

  renderStyledInput({
    mandatory = false,
    name,
    type = "text",
    placeholder,
    classes,
    disabled = false,
    icon,
    label,
    currency,
    ref,
    info,
    size,
  }) {
    const { data, errors, fieldIsProcessing } = this.state;

    return (
      <StyledInput
        ref={ref}
        type={type}
        name={name}
        value={data[name] || ""}
        label={label}
        icon={icon}
        currency={currency}
        onChange={this.handleChange}
        error={errors[name]}
        className={`${classes} text-default rounded-lg`}
        placeholder={placeholder}
        info={info}
        disabled={
          disabled ||
          this.state.formSubmitted ||
          (fieldIsProcessing && fieldIsProcessing[name])
        }
        size={size}
        mandatory={mandatory}
        fieldIsProcessing={
          <Spinner
            color="primary"
            type="grow"
            size="sm"
            className={
              fieldIsProcessing && fieldIsProcessing[name] ? "" : "d-none"
            }
          ></Spinner>
        }
      />
    );
  }

  renderSelect(mandatory = false, name, label, options, placeholder, classes) {
    const { data, errors, fieldIsProcessing } = this.state;
    return (
      <Select
        name={name}
        value={data[name]}
        label={label}
        options={options}
        className={classes}
        onChange={this.handleChange}
        error={errors[name]}
        disabled={
          this.state.formSubmitted ||
          (fieldIsProcessing && fieldIsProcessing[name])
        }
        placeholder={placeholder}
        mandatory={mandatory}
        fieldIsProcessing={
          <Spinner
            color="primary"
            type="grow"
            size="sm"
            className={
              fieldIsProcessing && fieldIsProcessing[name] ? "" : "d-none"
            }
          ></Spinner>
        }
      />
    );
  }

  renderCheckbox({
    mandatory = false,
    name,
    label,
    classes,
    checked = false,
    disabled = false,
    iconClass,
    isPrivacyPolicy = false,
    showError = false,
    info,
    infoIcon = "fas fa-info-circle",
  }) {
    const { data, errors, fieldIsProcessing } = this.state;

    return (
      <Checkbox
        type="checkbox"
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={showError ? errors[name] : ""}
        className={classes}
        iconClass={iconClass}
        disabled={
          disabled ||
          this.state.formSubmitted ||
          (fieldIsProcessing && fieldIsProcessing[name])
        }
        checked={checked}
        mandatory={mandatory}
        fieldIsProcessing={
          <Spinner
            color="primary"
            type="grow"
            size="sm"
            className={
              fieldIsProcessing && fieldIsProcessing[name] ? "" : "d-none"
            }
          ></Spinner>
        }
        isPrivacyPolicy={isPrivacyPolicy}
        info={info}
        infoIcon={infoIcon}
      />
    );
  }

  renderRadio(
    mandatory = false,
    id,
    name,
    value,
    label,
    disabled = false,
    onchange
  ) {
    const { data, errors, fieldIsProcessing } = this.state;

    return (
      <Radio
        id={id}
        name={name}
        value={value}
        label={label}
        onChange={(e) => {
          data[name] = value;
          onchange && onchange();
          this.setState(data);
        }}
        error={errors[name]}
        checked={data[name] === value}
        disabled={
          disabled ||
          this.state.formSubmitted ||
          (fieldIsProcessing && fieldIsProcessing[name])
        }
        mandatory={mandatory}
        fieldIsProcessing={
          <Spinner
            color="primary"
            type="grow"
            size="sm"
            className={
              fieldIsProcessing && fieldIsProcessing[name] ? "" : "d-none"
            }
          ></Spinner>
        }
      />
    );
  }

  renderSelect2({
    mandatory = false,
    name,
    label,
    options,
    placeholder,
    classes,
    isMulti = false,
    onchange,
    isCreatableSelect = false,
    icon,
    disabled = false,
    tooltipConfig,
  }) {
    const { data, errors, fieldIsProcessing } = this.state;
    return (
      <ReactSelect
        value={data[name]}
        options={options}
        icon={icon}
        name={name}
        label={label}
        className={classes}
        isMulti={isMulti}
        isCreatableSelect={isCreatableSelect}
        onChange={(selectedValue) => {
          let removed = [];

          if (data[name] && Array.isArray(data[name])) {
            removed = data[name].filter((x) => !selectedValue.includes(x)); // calculates diff
          }

          this.handleChange({
            currentTarget: { name: name, value: selectedValue },
          });

          let val = null;

          if (isMulti) {
            if (selectedValue.length > 0) {
              val = selectedValue[selectedValue.length - 1];
            }
          } else {
            val = selectedValue ? selectedValue : null;
          }
          onchange && selectedValue && onchange(val, removed);
        }}
        error={errors[name]}
        isDisabled={
          disabled ||
          this.state.formSubmitted ||
          (fieldIsProcessing && fieldIsProcessing[name])
        }
        placeholder={placeholder}
        closeMenuOnSelect={!isMulti}
        mandatory={mandatory}
        fieldIsProcessing={
          <Spinner
            color="primary"
            type="grow"
            size="sm"
            className={
              fieldIsProcessing && fieldIsProcessing[name] ? "" : "d-none"
            }
          ></Spinner>
        }
        tooltipConfig={tooltipConfig}
      />
    );
  }

  renderTextArea(
    mandatory = false,
    name,
    label,
    rowCount = 3,
    placeholder,
    classes,
    disabled = false
  ) {
    const { data, errors, fieldIsProcessing } = this.state;

    return (
      <TextArea
        rows={rowCount}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
        className={classes}
        placeholder={placeholder}
        disabled={
          disabled ||
          this.state.formSubmitted ||
          (fieldIsProcessing && fieldIsProcessing[name])
        }
        mandatory={mandatory}
        fieldIsProcessing={
          <Spinner
            color="primary"
            type="grow"
            size="sm"
            className={
              fieldIsProcessing && fieldIsProcessing[name] ? "" : "d-none"
            }
          ></Spinner>
        }
      />
    );
  }

  renderThresholdInput(
    mandatory = false,
    name,
    label,
    type = "text",
    placeholder,
    classes,
    disabled = false
  ) {
    const { data, errors } = this.state;

    return (
      <ThresholdInput
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
        className={classes}
        placeholder={placeholder}
        disabled={disabled || this.state.formSubmitted}
        mandatory={mandatory}
      />
    );
  }

  renderSwitchButton({
    mandatory = false,
    name,
    label,
    classes,
    disabled = false,
    checked = false,
    onchange,
  }) {
    const { data, errors, fieldIsProcessing } = this.state;

    return (
      <SwitchButton
        type="checkbox"
        name={name}
        value={data[name]}
        label={label}
        className={classes}
        checked={checked}
        onChange={(e) => {
          if (onchange) {
            this.handleChange(e, () => onchange(e));
          } else {
            this.handleChange(e);
          }
        }}
        error={errors[name]}
        disabled={
          disabled ||
          this.state.formSubmitted ||
          (fieldIsProcessing && fieldIsProcessing[name])
        }
        mandatory={mandatory}
        fieldIsProcessing={
          <Spinner
            color="primary"
            type="grow"
            size="sm"
            className={
              fieldIsProcessing && fieldIsProcessing[name] ? "" : "d-none"
            }
          ></Spinner>
        }
      />
    );
  }

  renderSwitch({
    mandatory = false,
    name,
    label,
    classes,
    disabled = false,
    checked = false,
    onchange,
  }) {
    const { data, errors, fieldIsProcessing } = this.state;

    return (
      <Switch
        name={name}
        value={data[name]}
        label={label}
        checked={checked}
        onChange={(e) => {
          if (onchange) {
            this.handleChange(e, () => onchange(e));
          } else {
            this.handleChange(e);
          }
        }}
        error={errors[name]}
        disabled={
          disabled ||
          this.state.formSubmitted ||
          (fieldIsProcessing && fieldIsProcessing[name])
        }
        mandatory={mandatory}
        fieldIsProcessing={
          <Spinner
            color="primary"
            type="grow"
            size="sm"
            className={
              fieldIsProcessing && fieldIsProcessing[name] ? "" : "d-none"
            }
          ></Spinner>
        }
      />
    );
  }
}

export default Form;
