import React from "react";
import { Redirect } from "react-router-dom";
import authService from "services/authService";
import { loadingComponent } from "config/config";
import ValidationForm from "validation/form";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Joi from "joi-browser";
import Alert from "reactstrap/lib/Alert";

class OAuth2RedirectHandler extends ValidationForm {
  state = {
    data: {},
    errors: {},
    displayHomePage: false,
    doValidation: false,
    referralCode: 0,
  };

  schema = {
    phoneNumber: Joi.string()
      .required()
      .label("Phone Number")
      .regex(/^\+(?:[0-9]●?){6,14}[0-9]$|^[0]\d{10}$/g)
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = '"Phone Number" should not be empty';
              break;
            default:
              err.message = "Enter a valid Phone Number";
              break;
          }
        });
        return errors;
      }),
  };

  componentDidMount() {
    const search = this.props.location.search;
    const ref = new URLSearchParams(search).get("ref");

    let referralCode = 0;
    if (ref) {
      if (isNaN(ref)) {
        authService.updateReferralCodeHeader();
        referralCode = this.getReferralCode();
      } else {
        referralCode = ref;
        authService.updateReferralCode(ref);
      }
    } else {
      referralCode = this.getReferralCode();
    }

    this.setState({ referralCode });
  }

  getReferralCode() {
    const referralCode = authService.getReferralCode();
    // console.log("REFERAL CODE  *** >>>> " + referralCode);
    if (referralCode) {
      return referralCode;
    } else {
      return 0;
    }
  }

  validateToken(token) {
    authService.updateToken("Bearer " + token);
    authService.ping(this);
  }

  completeSignUp(token) {
    // console.log(
    //     "SIGN UP this.state.referralCode >>>>>>> " + this.state.referralCode
    // );
    authService.updateToken("Bearer " + token);
    authService.completeSignUp(
      this,
      this.state.data.phoneNumber,
      this.state.referralCode
    );
  }

  tokenIsValid() {
    this.setState({ displayHomePage: true });
  }

  getUrlParameter(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");

    var results = regex.exec(this.props.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  render() {
    const isSignUp = this.props.location.pathname.endsWith("signup");
    const token = this.getUrlParameter("token");
    const errorMessage = this.getUrlParameter("error");

    if (errorMessage) {
      return this.redirectToLoginPage(errorMessage);
    } else if (token && this.state.displayHomePage) {
      return this.redirectToHomePage();
    } else if (this.state.doValidation) {
      this.completeSignUp(token);
      return <>{loadingComponent()}</>;
    } else if (isSignUp) {
      return this.capturePhoneNumber();
    } else if (!isSignUp) {
      this.validateToken(token);
      return <>{loadingComponent()}</>;
    }
  }

  redirectToHomePage() {
    const currentUser = authService.getCurrentUser();
    return (
      <Redirect
        to={{
          pathname: authService.resolveLandingPageUrl(),
          state: {
            // from: this.props.location,
            fullname: currentUser.firstname + " " + currentUser.lastname,
          },
        }}
      />
    );
  }

  redirectToLoginPage(errorMessage) {
    if (localStorage.getItem("token")) {
      authService.logout();
    }

    return (
      <Redirect
        to={{
          pathname: "/signin",
          state: {
            from: this.props.location,
            error: errorMessage,
          },
        }}
      />
    );
  }

  capturePhoneNumber() {
    return (
      <>
        <form
          onSubmit={(e) => {
            this.handleSubmit({
              e,
              submitFunction: () =>
                this.setState({
                  doValidation: true,
                  capturePhoneNumber: false,
                }),
            });
          }}
        >
          <Row className="mt-5">
            <Col className="offset-md-3 col-md-6">
              <Alert color="dark">
                <Row>
                  <Col className="col-8">
                    <h2 className="font-weight-bold text-white text-underline">
                      One More Step to Go!{" "}
                    </h2>
                  </Col>
                  <Col>
                    <div className="bg-secondary rounded p-1">
                      <img
                        className="img-fluid"
                        alt="..."
                        src={require("assets/img/brand/wirepackage-colour.png")}
                      />
                    </div>
                  </Col>
                </Row>
                <p className="font-weight-bold mt-2">
                  Please enter your phone number to complete sign up.
                </p>
              </Alert>
            </Col>
          </Row>
          <Row>
            <Col className="offset-md-4 col-md-4">
              {this.renderStyledInput({
                mandatory: true,
                type: "text",
                name: "phoneNumber",
                placeholder: "Phone Number",
                icon: "fas fa-phone",
                label: "Phone Number",
              })}
            </Col>
          </Row>
          <Row>
            <Col className="offset-md-4 col-md-4">
              {this.renderButton(
                <>
                  <div>Complete Sign Up</div>
                </>,
                "btn-primary btn-block",
                "Complete Sign Up"
              )}
            </Col>
          </Row>
        </form>
      </>
    );
  }
}

export default OAuth2RedirectHandler;
