import React, { useEffect, useState } from "react";
import { Container, Button } from "reactstrap";
import authService from "services/authService";
import Spinner from "reactstrap/lib/Spinner";
import DashboardTheme from "components/theme/DashboardTheme";
import AdminFooter from "components/Footers/AdminFooter";
import { LOGIN_DASHBOARD_URL } from "constants/index";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";

export default function Dashboard(props) {
  const [verifyingAccount, setVerifyingAccount] = useState(true);
  const [verificationSuccessful, setVerificationSuccessful] = useState(false);
  const [signupMessage, setSignupMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      const response = await authService.verifyAccount(
        props.match.params.token
      );
      setSignupMessage(response.message);
      setVerifyingAccount(false);
      setVerificationSuccessful(true);
    } catch (ex) {
      setVerifyingAccount(false);
      setVerificationSuccessful(false);
      console.log(ex);
    }
  }

  if (!verifyingAccount && verificationSuccessful) {
    return (
      <Redirect
        to={{
          pathname: LOGIN_DASHBOARD_URL,
          state: {
            newAccount: true,
            signupMessage: signupMessage,
          },
        }}
      />
    );
  }

  return (
    <div>
      <DashboardTheme />
      <Container className="mt--6" fluid>
        <div className="d-flex vh-100 justify-content-center">
          <div className="align-self-center text-center">
            <h1>
              {verifyingAccount && (
                <>
                  <Spinner color="primary" type="border" size="xl"></Spinner>{" "}
                  Verifying account...
                </>
              )}

              {!verifyingAccount && !verificationSuccessful && (
                <>
                  <div>
                    <i className="fas fa-ban text-danger" /> Account
                    verification failed. Invalid token.
                  </div>
                  <Button
                    className="btn-icon mb-3 mb-sm-0"
                    size="sm"
                    color="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      props.history.replace("/");
                    }}
                  >
                    <span className="btn-inner--text text-capitalize">
                      <strong>Back to Home Page</strong>
                    </span>
                  </Button>
                </>
              )}
            </h1>
          </div>
        </div>
        <AdminFooter />
      </Container>
    </div>
  );
}
