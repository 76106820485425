import httpService from "services/httpService";

const baseApiEndPoint = process.env.REACT_APP_API_ENDPOINT;

const apiEndPoint = baseApiEndPoint + "/user";

export async function initialializeApp() {
  const { data } = await httpService.get(baseApiEndPoint + "/init");
  return data;
}

export async function changePassword(
  currentPassword,
  newPassword,
  newPasswordConfirmation
) {
  const { data } = await httpService.put(apiEndPoint + "/changePassword", {
    currentPassword,
    newPassword,
    newPasswordConfirmation,
  });
  return data;
}

export async function fetchUserList(queryString) {
  queryString = queryString ? `?condition=${encodeURI(queryString)}` : "";
  const { data } = await httpService.get(
    apiEndPoint + "/advanced/all" + queryString
  );
  return data;
}

export async function fetchUserDetail(userId) {
  const { data } = await httpService.get(apiEndPoint + "/" + userId);
  return data;
}

export async function blockAccount(userId) {
  const { data } = await httpService.put(apiEndPoint + "/block/" + userId);
  return data;
}

export async function unblockAccount(userId) {
  const { data } = await httpService.put(apiEndPoint + "/unblock/" + userId);
  return data;
}

export async function fetchInitialisationParams() {
  const { data } = await httpService.get(apiEndPoint + "/init");
  return data;
}

export async function createUser(
  username,
  firstname,
  lastname,
  phoneNumber,
  role,
  approvalGroup,
  status
) {
  const { data } = await httpService.post(apiEndPoint + "/create", {
    username,
    firstname,
    lastname,
    phoneNumber,
    status,
    roles: [role],
    approvalGroups: [approvalGroup],
  });
  return data;
}

export async function editUser(userId) {
  const { data } = await httpService.get(apiEndPoint + "/edit/" + userId);
  return data;
}

export async function updateUser(
  userId,
  username,
  firstname,
  lastname,
  phoneNumber,
  role,
  approvalGroup,
  status
) {
  const { data } = await httpService.put(apiEndPoint + "/" + userId, {
    username,
    firstname,
    lastname,
    phoneNumber,
    status,
    roles: [role],
    approvalGroups: [approvalGroup],
  });
  return data;
}

export async function fetchUserProfile() {
  const { data } = await httpService.get(apiEndPoint + "/profile");
  return data;
}

export async function createAccount(
  username,
  firstname,
  lastname,
  phoneNumber
) {
  const { data } = await httpService.post(baseApiEndPoint + "/createAccount", {
    username,
    firstname,
    lastname,
    phoneNumber,
  });
  return data;
}

export async function fetchUserFullname() {
  const { data } = await httpService.get(apiEndPoint + "/fullname");
  return data;
}

export async function updateFullName(fullName) {
  const { data } = await httpService.put(apiEndPoint + "/fullName", {
    fullName,
  });
  return data;
}

export async function updatePhoneNumber(phoneNumber) {
  const { data } = await httpService.put(apiEndPoint + "/phoneNumber", {
    phoneNumber,
  });
  return data;
}

export async function fetchReferralStats() {
  const { data } = await httpService.get(apiEndPoint + "/referral_stats");
  return data;
}

export default {
  initialializeApp,
  changePassword,
  fetchUserList,
  fetchUserDetail,
  blockAccount,
  unblockAccount,
  fetchInitialisationParams,
  createUser,
  editUser,
  updateUser,
  fetchUserProfile,
  createAccount,
  fetchUserFullname,
  updateFullName,
  updatePhoneNumber,
  fetchReferralStats,
};
