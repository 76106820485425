import React from "react";

const ThresholdInput = ({ name, label, error, mandatory, ...rest }) => {
  return (
    <div className="form-group mb-0 pb-0">
      <label className="form-control-label" htmlFor={name}>
        {mandatory && <span className="text-danger">*</span>} {label}
      </label>
      <input
        // autoFocus
        {...rest}
        id={name}
        name={name}
        className={`form-control ${error ? "is-invalid" : ""} p-2 `}
      />
      {error ? <div className="text-danger invalid-feedback">{error}</div> : ""}
    </div>
  );
};

export default ThresholdInput;
