import httpService from "services/httpService";

const baseApiEndPoint = process.env.REACT_APP_API_ENDPOINT;

const apiEndPoint = baseApiEndPoint + "/shippingZone/city";

export async function createCityShippingZone(model) {
  const { data } = await httpService.post(apiEndPoint, {
    ...model,
  });
  return data;
}

export async function updateCityShippingZone(model) {
  const { data } = await httpService.put(apiEndPoint + "/" + model.id, {
    ...model,
  });
  return data;
}

export async function getCityShippingZones(
  courierCode,
  originCountryCode,
  originStateCode
) {
  const { data } = await httpService.get(
    apiEndPoint +
      "/" +
      courierCode +
      "/" +
      originCountryCode +
      "/" +
      originStateCode
  );
  return data;
}

export async function getCityShippingZonesDetail(shippingZoneId) {
  const { data } = await httpService.get(
    apiEndPoint + "/detail/" + shippingZoneId
  );
  return data;
}

export async function deleteShippingZone(shippingZoneId) {
  const { data } = await httpService.delete(apiEndPoint + "/" + shippingZoneId);
  return data;
}

export default {
  getCityShippingZones,
  createCityShippingZone,
  updateCityShippingZone,
  getCityShippingZonesDetail,
  deleteShippingZone,
};
