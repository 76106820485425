import React from "react";

// Core Components
import { Route, Switch } from "react-router";

import NotFound from "views/Error404.js";
import { loadingComponent } from "config/config";

//create components using React.lazy
const IndexTheme = React.lazy(() => import("components/theme/IndexTheme"));
const RegisterPage = React.lazy(() =>
  import("views/pages/register/RegisterPage.js")
);
const PasswordResetPage = React.lazy(() =>
  import("views/pages/register/PasswordResetPage.js")
);
const LandingPage = React.lazy(() =>
  import("views/pages/general/LandingPage.js")
);
const Logout = React.lazy(() => import("views/pages/general/logout"));

const TrackShipment = React.lazy(() =>
  import("views/pages/general/TrackShipment.js")
);
const ShippingRates = React.lazy(() =>
  import("views/pages/general/ShippingRates.js")
);

const BrandedTracking = React.lazy(() =>
  import("views/pages/general/BrandedTracking/Index.js")
);

const BrandedNotification = React.lazy(() =>
  import("views/pages/general/BrandedNotification/Index.js")
);

function IndexRoute(props) {
  React.useEffect(() => {});

  return (
    <React.Suspense fallback={<>{loadingComponent()}</>}>
      <IndexTheme />

      <Switch>
        <Route
          path={["/track", "/track/:trackingNumber"]}
          exact
          render={(props) => <TrackShipment {...props} />}
        />
        <Route
          path={"/rates"}
          exact
          render={(props) => <ShippingRates {...props} />}
        />
        <Route
          path={"/branded-tracking"}
          exact
          render={(props) => <BrandedTracking {...props} />}
        />
        <Route
          path={"/branded-notification"}
          exact
          render={(props) => <BrandedNotification {...props} />}
        />
        <Route
          path="/register"
          exact
          render={(props) => <RegisterPage isSignUp={true} {...props} />}
        />
        <Route
          path="/reset"
          exact
          render={(props) => <PasswordResetPage {...props} />}
        />
        <Route
          path="/signin"
          exact
          render={(props) => <RegisterPage isSignUp={false} {...props} />}
        />
        <Route path="/logout" exact render={(props) => <Logout {...props} />} />
        <Route path="/" exact render={(props) => <LandingPage {...props} />} />
        <Route component={NotFound} />
      </Switch>
    </React.Suspense>
  );
}

export default IndexRoute;
