import React from "react";
import Home from "views/pages/member/Home";
import BookShipment from "views/pages/member/BookShipment";
import TrackShipment from "views/pages/member/TrackShipment";
import CheckShippingRate from "views/pages/member/CheckShippingRate";
import MyWallet from "views/pages/member/MyWallet";
import ShippingHistory from "views/pages/member/ShippingHistory";
import TransactionHistory from "views/pages/member/TransactionHistory";
import FundWallet from "views/pages/member/FundWallet";
import Withdrawal from "views/pages/member/Withdrawal";
import Business from "views/pages/member/Business";
// import LinkBankAccount from "views/pages/member/LinkBankAccount";
import Settings from "views/pages/settings/Index";
import ExportInternationalShippingZone from "views/pages/admin/internationalRate/Index";
import ImportShippingZone from "views/pages/admin/importRate/ImportIndex";
import LocalShippingZone from "views/pages/admin/localRate/Index";
import CityShippingZone from "views/pages/admin/cityRate/Index";
import Organizations from "views/pages/admin/organization/Organizations";
import BillingGroups from "views/pages/admin/config/BillingGroups";
import Referrals from "views/pages/member/Referrals";
import Products from "views/pages/member/Products";
import BrandedTrackingSetup from "views/pages/member/BrandedTrackingSetup";
import BrandedNotification from "views/pages/member/BrandedNotification";
import HubShippingHistory from "views/pages/member/HubShippingHistory";
import ImportShippingHistory from "views/pages/admin/ImportShippingHistory";
import Users from "views/pages/admin/users/Users";

export const adminRoutes = [
  {
    roles: ["ADMIN", "ROOT"],
    routes: [
      {
        path: "/home",
        name: "Welcome",
        icon: "fas fa-home text-default",
        component: Home,
        layout: "/admin",
      },
    ],
  },
  {
    roles: ["ADMIN", "ROOT"],
    title: "Shipping Zones & Rates",
    shortTitle: "Z",
    routes: [
      {
        path: "/zones/city",
        name: "City Zones",
        icon: "fas fa-cogs text-primary",
        component: CityShippingZone,
        layout: "/admin",
      },
      {
        path: "/zones/local",
        name: "Local Zones",
        icon: "fas fa-cogs text-primary",
        component: LocalShippingZone,
        layout: "/admin",
      },
      {
        path: "/zones/export",
        name: "Export Shipping Zones",
        icon: "fas fa-cogs text-primary",
        component: ExportInternationalShippingZone,
        layout: "/admin",
      },
      {
        path: "/zones/import",
        name: "Import Shipping Zones",
        icon: "fas fa-cogs text-primary",
        component: ImportShippingZone,
        layout: "/admin",
      },
    ],
  },
  {
    roles: ["ADMIN", "ROOT"],
    title: "Configurations",
    shortTitle: "R",
    routes: [
      {
        path: "/config/organizations",
        name: "Organizations",
        icon: "ni ni-building text-primary",
        component: Organizations,
        layout: "/admin",
      },
      {
        path: "/config/billingGroups",
        name: "Billing Group",
        icon: "ni ni-building text-primary",
        component: BillingGroups,
        layout: "/admin",
      },
    ],
  },
];

export const dashboardRoutes = [
  {
    roles: ["MEMBER", "MANAGER", "ADMIN", "ROOT"],
    routes: [
      {
        path: "/home",
        name: "Welcome",
        icon: "fas fa-home text-primary",
        component: Home,
        layout: "/base",
      },
    ],
  },
  // {
  //   roles: ["MANAGER", "ADMIN", "ROOT"],
  //   title: "My Hub",
  //   shortTitle: "H",
  //   routes:
  //   [
  //     {
  //       path: "/hub/receive",
  //       name: "Shipments",
  //       icon: "fas fa-history text-primary",
  //       component: HubShippingHistory,
  //       layout: "/base",
  //     },
  //   ],
  // },
  {
    roles: ["MEMBER", "MANAGER", "ADMIN", "ROOT"],
    title: "Shipments",
    shortTitle: "S",
    routes: [
      {
        path: "/shipment/rate",
        name: "Check Rates",
        icon: "fas fa-money-check text-primary",
        component: CheckShippingRate,
        layout: "/base",
      },
      {
        path: "/shipment/book",
        name: "Book Shipment",
        icon: "fas fa-shipping-fast text-primary",
        component: BookShipment,
        layout: "/base",
      },
      {
        path: "/shipment/track",
        name: "Track Shipments",
        icon: "ni ni-pin-3 text-primary",
        component: TrackShipment,
        layout: "/base",
      },
      {
        path: "/shipment/history",
        name: "Shipment History",
        icon: "fas fa-history text-primary",
        component: ShippingHistory,
        layout: "/base",
      },
    ],
  },
  {
    roles: ["MEMBER", "MANAGER", "ADMIN", "ROOT"],
    title: "My Wallet",
    shortTitle: "W",
    routes: [
      {
        path: "/wallet/balance",
        name: "View Balance",
        icon: "ni ni-money-coins text-primary",
        component: MyWallet,
        layout: "/base",
      },
      {
        path: "/wallet/fund",
        name: "Fund Wallet",
        icon: "fas fa-wallet text-primary",
        component: FundWallet,
        layout: "/base",
      },
      {
        path: "/wallet/withdraw",
        name: "Withdraw Funds",
        icon: "fas fa-hand-holding-usd text-primary",
        component: Withdrawal,
        layout: "/base",
      },
      {
        path: "/wallet/transactions",
        name: "Transaction History",
        icon: "fas fa-history text-primary",
        component: TransactionHistory,
        layout: "/base",
      },
      // {
      //   path: "/wallet/link",
      //   name: "Link Bank Account",
      //   icon: "fas fa-link text-primary",
      //   component: LinkBankAccount,
      //   layout: "/base",
      // },
    ],
  },
  {
    roles: ["MEMBER", "MANAGER", "ADMIN", "ROOT"],
    title: "Business Operations",
    shortTitle: "-",
    routes: [
      {
        path: "/business",
        name: "Business Profile",
        icon: "ni ni-building wirepackage-text-success",
        component: Business,
        layout: "/base",
      },
      {
        path: "/products",
        name: "Products Setup",
        icon: "fab fa-product-hunt wirepackage-text-success",
        component: Products,
        layout: "/base",
      },
      {
        path: "/branded-tracking",
        name: "Branded Tracking",
        icon: "fas fa-location-arrow wirepackage-text-success",
        component: BrandedTrackingSetup,
        layout: "/base",
      },
      {
        path: "/branded-notification",
        name: "Branded Notifications",
        icon: "fas fa-envelope wirepackage-text-success",
        component: BrandedNotification,
        layout: "/base",
      },
      // {
      //   path: "/business",
      //   name: "Generate Invoice",
      //   icon: "ni ni-building wirepackage-text-success",
      //   component: Business,
      //   layout: "/base",
      // },
      // {
      //     path: "/wallet/balance",
      //     name: "Discount Program",
      //     icon: "ni ni-money-coins wirepackage-text-success",
      //     component: MyWallet,
      //     layout: "/base",
      // },
    ],
  },
  {
    roles: ["MANAGER", "ADMIN", "ROOT"],
    title: "WirePackage Operations",
    shortTitle: "OP",
    routes: [
      {
        path: "/import/history",
        name: "WirePackage Express",
        icon: "fas fa-plane-departure text-primary",
        component: ImportShippingHistory,
        layout: "/base",
      },
      {
        path: "/users",
        name: "Users",
        icon: "fas fa-users text-primary",
        component: Users,
        layout: "/base",
      },
      {
        path: "/discounts",
        name: "Discount Codes",
        icon: "fas fa-users text-primary",
        component: Users,
        layout: "/base",
      },
    ],
  },
  // {
  //   roles: ["MEMBER", "MANAGER", "ADMIN", "ROOT"],
  //   title: "Platforms",
  //   shortTitle: "P",
  //   routes: [
  //     {
  //       path: "/apps",
  //       name: "Apps",
  //       icon: "fas fa-plug wirepackage-text-success",
  //       component: Referrals,
  //       layout: "/base",
  //     },
  //     // {
  //     //     path: "/wallet/balance",
  //     //     name: "Discount Program",
  //     //     icon: "ni ni-money-coins wirepackage-text-success",
  //     //     component: MyWallet,
  //     //     layout: "/base",
  //     // },
  //   ],
  // },
  {
    roles: ["MEMBER", "MANAGER", "ADMIN", "ROOT"],
    title: <hr className="m-0" />,
    shortTitle: "-",
    routes: [
      {
        path: "/referrals",
        name: "Referral Program",
        icon: "fas fa-share-alt wirepackage-text-success",
        component: Referrals,
        layout: "/base",
      },
      // {
      //     path: "/wallet/balance",
      //     name: "Discount Program",
      //     icon: "ni ni-money-coins wirepackage-text-success",
      //     component: MyWallet,
      //     layout: "/base",
      // },
    ],
  },

  // {
  //   title: "Support",
  //   shortTitle: "P",
  //   routes: [
  //     {
  //       path: "/info/contact",
  //       name: "Contact Us",
  //       icon: "fas fa-at text-success",
  //       component: MyWallet,
  //       layout: "/base",
  //     },
  //     {
  //       path: "/info/contact",
  //       name: "Complaint",
  //       icon: "fas fa-phone text-success",
  //       component: MyWallet,
  //       layout: "/base",
  //     },
  //     {
  //       path: "/info/contact",
  //       name: "Request for Feature",
  //       icon: "fas fa-chalkboard-teacher text-success",
  //       component: MyWallet,
  //       layout: "/base",
  //     },
  //   ],
  // },
];

export const otherDashboardRoutes = [
  {
    roles: ["MEMBER", "MANAGER", "ADMIN", "ROOT"],
    routes: [
      {
        path: "/dashboard/:id",
        name: "Dashboard",
        component: Home,
        layout: "/base",
      },
      {
        path: "/profile/settings",
        name: "Settings",
        component: Settings,
        layout: "/base",
      },
    ],
  },
];

export const otherAdminRoutes = [
  {
    roles: ["MEMBER", "MANAGER", "ADMIN", "ROOT"],
    routes: [
      {
        path: "/profile/settings",
        name: "Settings",
        component: Settings,
        layout: "/base",
      },
    ],
  },
];

export default { adminRoutes, dashboardRoutes, otherDashboardRoutes };
