import React from "react";
import FormGroup from "reactstrap/lib/FormGroup";
import Input from "reactstrap/lib/Input";
import InputGroup from "reactstrap/lib/InputGroup";
import InputGroupAddon from "reactstrap/lib/InputGroupAddon";
import InputGroupText from "reactstrap/lib/InputGroupText";

const StyledInput = ({
                       type,
                       name,
                       placeholder,
                       label,
                       icon,
                       error,
                       mandatory,
                       fieldIsProcessing,
                       currency,
                       info,
                       size,
                       ...rest
                     }) => {
  const [fieldFocus, setFieldFocus] = React.useState("");

  return (
      <FormGroup className={"mb-3 " + fieldFocus}>
        {label && (
            <label className="form-control-label" htmlFor={name}>
              {mandatory && <span className="text-danger">*</span>} {label}
              {fieldIsProcessing}
            </label>
        )}
        <InputGroup
            className="input-group-alternative border border-light"
            size={size ? size : "md"}
        >
          {icon && (
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className={icon}></i>
                  {currency && <span> {currency}</span>}
                </InputGroupText>
              </InputGroupAddon>
          )}
          <Input
              placeholder={placeholder}
              type={type}
              onFocus={() => setFieldFocus("focused")}
              onBlur={() => setFieldFocus("")}
              htmlFor={name}
              id={name}
              name={name}
              {...rest}
          ></Input>
        {fieldIsProcessing}
      </InputGroup>
      {info ? <div className="text-gray-dark text-sm">{info}</div> : ""}
      {error ? <div className="text-danger text-sm">{error}</div> : ""}
    </FormGroup>

    // <div className="form-group">
    //   <label className="form-control-label" htmlFor={name}>
    //     {mandatory && <span className="text-danger">*</span>} {label}{" "}
    //     {fieldIsProcessing}
    //   </label>
    //   <input
    //     autoFocus
    //     {...rest}
    //     id={name}
    //     name={name}
    //     className={`form-control ${error ? "is-invalid" : ""}`}
    //   />
    //   {error ? <div className="text-danger invalid-feedback">{error}</div> : ""}
    // </div>
  );
};

export default StyledInput;
