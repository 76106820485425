import mixpanel from "mixpanel-browser";
import auth from "services/authService";

function transformToReactSelectModel(reflectionModel, isString = false) {
  const rsm = [];
  reflectionModel.map((rec) => {
    rsm[rsm.length] = {
      value: isString ? rec.key + "" : rec.key,
      label: isString ? rec.value + "" : rec.value,
    };

    if (rec.id) {
      rsm[rsm.length - 1].id = rec.id;
    }
  });

  return rsm;
}

function transformReactSelectModelToKeyValue(reflectionModel) {
  const rsm = [];
  reflectionModel.map((rec) => {
    rsm[rsm.length] = {
      key: rec.value,
      value: rec.label,
    };

    if (rec.id) {
      rsm[rsm.length - 1].id = rec.id;
    }
  });

  return rsm;
}

function numberFormatter(num, decimal) {
  num = Number(num);
  return num.toFixed(decimal).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

function passwordIsValid(password) {
  var regex =
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
  return regex.test(password);
}

function logEvent({page, source, custom}) {
  // Enabling the debug mode flag is useful during implementation,
  // but it's recommended you remove it for production
  mixpanel.init("3cfa5a1ab6c6f470bc75e077bda428a7", {
    debug: false,
    ignore_dnt: true,
  });

  if (auth.getCurrentUser()) {
    mixpanel.identify(auth.getCurrentUserEmail());
    mixpanel.people.set({fullname: auth.getCurrentUserFullname()});
    mixpanel.people.set({email: auth.getCurrentUserEmail()});
  } else {
    mixpanel.identify(0);
  }

  mixpanel.track(page, {
    source,
    ...custom,
  });
}

function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

export default {
  transformToReactSelectModel,
  transformReactSelectModelToKeyValue,
  numberFormatter,
  passwordIsValid,
  logEvent,
  shuffleArray,
};
