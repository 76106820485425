import React from "react";

// Core Components
import { Route, Switch } from "react-router";

import IndexRoute from "views/pages/general/IndexRoute.js";
import NotFound from "views/Error404.js";
import { loadingComponent } from "config/config";
//create components using React.lazy
const IndexTheme = React.lazy(() => import("components/theme/IndexTheme"));

const TrackShipment_1 = React.lazy(() =>
  import("views/pages/branded/TrackShipment_1.js")
);

function BrandedIndexRoute(props) {
  React.useEffect(() => {});
  const subdomain = props.subdomain;
  const isDefaultPage = props.isDefaultPage;
  return (
    <React.Suspense fallback={<>{loadingComponent()}</>}>
      <IndexTheme />
      <Switch>
        <Route
          path={["/track/:trackingNumber"]}
          exact
          render={(props) => (
            <TrackShipment_1
              {...props}
              subdomain={subdomain}
              isDefaultPage={isDefaultPage}
            />
          )}
        />
        <Route path="/" render={(props) => <IndexRoute {...props} />} />
        <Route component={NotFound} />
      </Switch>
    </React.Suspense>
  );
}

export default BrandedIndexRoute;
