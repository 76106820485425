import httpService from "services/httpService";

const baseApiEndPoint = process.env.REACT_APP_API_ENDPOINT;

const apiEndPoint = baseApiEndPoint + "/transactions";

export async function fetchTransactionHistory(startDate, endDate) {
  const { data } = await httpService.get(
    apiEndPoint + "/history?startDate=" + startDate + "&endDate=" + endDate
  );
  return data;
}

export async function fetchRecentTransactionHistory() {
  const { data } = await httpService.get(apiEndPoint + "/history/recent");
  return data;
}

export async function fetchTransactionDetail(id) {
  const { data } = await httpService.get(apiEndPoint + "/" + id + "/detail");
  return data;
}

export async function fetchPaymentReference(paymentAmount) {
  const { data } = await httpService.get(
    apiEndPoint + "/payment_reference?paymentAmount=" + paymentAmount
  );
  return data;
}

export async function fetchUserRecentTransactionHistory(userId) {
  const { data } = await httpService.get(
    apiEndPoint + "/advanced/recent/user/" + userId
  );
  return data;
}

export default {
  fetchTransactionHistory,
  fetchTransactionDetail,
  fetchPaymentReference,
  fetchRecentTransactionHistory,
  fetchUserRecentTransactionHistory,
};
