import httpService from "services/httpService";

const baseApiEndPoint = process.env.REACT_APP_API_ENDPOINT;

const apiEndPoint = baseApiEndPoint + "/branded_tracking";

export async function fetchPreferences() {
  const { data } = await httpService.get(apiEndPoint + "/preferences");
  return data;
}

export async function updatePreferences(model) {
  const { data } = await httpService.put(apiEndPoint + "/preferences", {
    ...model,
  });
  return data;
}

export async function fetchAllProductsForCurrentOrganization() {
  const { data } = await httpService.get(apiEndPoint + "/products");
  return data;
}

export async function updateSelectedProductsForCurrentOrganization(model) {
  const { data } = await httpService.post(apiEndPoint + "/products", [
    ...model,
  ]);
  return data;
}

export async function fetchThemes() {
  const { data } = await httpService.get(apiEndPoint + "/theme/all");
  return data;
}

export async function updateTheme(model) {
  const { data } = await httpService.put(apiEndPoint + "/theme", {
    ...model,
  });
  return data;
}

export async function enableBrandedTracking(enable) {
  const { data } = await httpService.put(apiEndPoint + "/enable/" + enable);
  return data;
}

export default {
  fetchPreferences,
  updatePreferences,
  fetchAllProductsForCurrentOrganization,
  updateSelectedProductsForCurrentOrganization,
  fetchThemes,
  updateTheme,
  enableBrandedTracking,
};
