import React from "react";

const Select = ({
  name,
  label,
  options,
  placeholder,
  error,
  mandatory,
  fieldIsProcessing,
  ...rest
}) => {
  return (
    <>
      <div className="form-group">
        <label className="form-control-label" htmlFor={name}>
          {mandatory && <span className="text-danger">*</span>} {label}{" "}
          {fieldIsProcessing}
        </label>
        <select
          name={name}
          id={name}
          {...rest}
          className={`form-control ${error ? "is-invalid" : ""}`}
        >
          {placeholder && (
            <option placeholder="true" key={placeholder} value="">
              {placeholder}
            </option>
          )}
          {options
            ? options.map((option) => (
                <option
                  key={option.key ? option.key : option.id}
                  value={option.key ? option.key : option.id}
                  selected={option.selected}
                >
                  {option.value ? option.value : option.name}
                </option>
              ))
            : ""}
        </select>
        {error && <div className="text-danger text-sm">{error}</div>}
      </div>
      {/* <div className="text-danger">good{error}</div> */}
    </>
  );
};

export default Select;
