import React from "react";

const Input = ({
                 name,
                 label,
                 error,
                 mandatory,
                 fieldIsProcessing,
                 className,
                 containerClass,
                 isSmall,
                 ...rest
               }) => {
  return (
      <div className={`form-group ${containerClass ? containerClass : ""}`}>
        {label && (
            <label className="form-control-label" htmlFor={name}>
              {mandatory && <span className="text-danger">*</span>} {label}
              {fieldIsProcessing}
            </label>
        )}
        <input
            {...rest}
            id={name}
            name={name}
            className={`form-control ${error ? "is-invalid" : ""} ${className} ${
                isSmall ? "form-control-sm" : ""
            }`}
        />
      {error ? <div className="text-danger invalid-feedback">{error}</div> : ""}
    </div>
  );
};

export default Input;
